import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
// import useAppController from '../../hooks/useAppController';
import Select from '../shared/elements/material/Select';
import { colors } from '../FlowMaterialTheme';
import Button from '@mui/material/Button';
import Close from '../shared/icons/Close';
import useAppController from '../../hooks/useAppController';

const isDateValid = dayjsDate => dayjsDate.isValid() && dayjsDate.isSameOrAfter(dayjs('2020-01-01')) && dayjsDate.isSameOrBefore(dayjs());

const InputContainer = ({ className, children }) => (
  <div className={ `inline-block bo-radius-2 bg-gray5 relative ${ className }` }>
    { children }
    <div className="bob-1 bo-radius-6 bo-gray3 absolute left-4 right-4 bottom-0 h-0" />
  </div>
);

const padTime = num => !num
  ? '00'
  : num < 10
    ? '0' + num
    : num;
const Seperator = () => <div className="mh-4">•</div>;

const LitmusReportSettings = ({ reportSettings, setReportSettings }) => {
  const { mapController, params } = useAppController();

  const [reportType, setReportType] = useState(null);
  const [periodDays, setPeriodDays] = useState('28');

  const [beforeString, _setBeforeString] = useState('');
  const [beforeIsEnding, setBeforeIsEnding] = useState(true);

  const [afterString, _setAfterString] = useState('');
  const [afterIsEnding, setAfterIsEnding] = useState(false);

  const afterHasBeenChanged = useRef(false);

  const { regionData } = mapController;
  const { regionId, networkId, todId } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  const setBeforeString = newString => {
    _setBeforeString(newString);

    const newDate = dayjs(newString);

    if (isDateValid(newDate) && (!afterHasBeenChanged.current || !afterString || !isDateValid(dayjs(afterString)))) {
      _setAfterString(newDate.add(1, 'day').format('YYYY-MM-DD'));
    }
  };

  const setAfterString = newString => {
    _setAfterString(newString);

    afterHasBeenChanged.current = true;
  };

  const [daysOfWeek, setDaysOfWeek] = useState([0, 1, 2, 3, 4]);
  const [timeStart, setTimeStart] = useState('06:00');
  const [timeEnd, setTimeEnd] = useState('09:30');

  const [settingsAreExpanded, setSettingsAreExpanded] = useState(true);

  const network = regionData.data?.user.region.networks.find(network => network.id === networkId);
  const tod = network?.tods.find(tod => tod.id === todId);

  useEffect(() => {
    setSettingsAreExpanded(true);
    if (tod) {
      setTimeStart(padTime(tod.startTime.hours) + ':' + padTime(tod.startTime.minutes));
      setTimeEnd(padTime(tod.endTime.hours) + ':' + padTime(tod.endTime.minutes));
    }
  }, [tod]);

  if (!tod) {
    return null;
  }

  return (
    <>
      <div
        className="bg-gray6 bo-radius-3 mb-7 z-2 w-100% h-auto duration-1 overflow-hidden"
        style={{
          minHeight: settingsAreExpanded ? '26.5rem' : '0',
          maxHeight: settingsAreExpanded ? '30rem' : '0',
          willChange: 'max-height, min-height',
          transitionProperty: 'max-height, min-height',
        }}
      >
        {
          settingsAreExpanded && (
            <div className="pa-7">
              <div className="fs-3 medium white mb-5">Project ROI Calculator</div>

              <div className="flex items-center mt-8 mb-9">
                <div className="w-15% gray1 normal">Report Type</div>
                <ToggleButtonGroup
                  value={ reportType }
                  exclusive
                  onChange={ e => setReportType(e.target.value) }
                >
                  <ToggleButton value="signals">
                    Signals
                  </ToggleButton>
                  <ToggleButton value="routes">
                    Routes
                  </ToggleButton>
                  <ToggleButton value="major-roads">
                    Major Roads
                  </ToggleButton>
                  <ToggleButton value="all-roads">
                    All Roads
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>


              {
                !reportType ? null : (
                  <div className="flex items-center mb-9">
                    <div className="w-15% w-11-w gray1 normal">Before Period</div>
                    <InputContainer className="pv-2">
                      <input
                        className="boa-0 outline-0 pv-4 tc bg-transparent white"
                        style={{ width: '4rem' }}
                        onChange={e => setPeriodDays(e.target.value)}
                        value={periodDays || ''}
                      />
                    </InputContainer>
                    <div className="inline-block mh-8">days</div>
                    {
                      (reportType && periodDays) ? (
                        <>
                          <InputContainer>
                            <Select
                              width="8"
                              iconPosition="none"
                              renderValue={
                                value => (
                                  <div className="white pv-5">
                                    { value ? 'ending' : 'starting' }
                                  </div>
                                )
                              }
                              value={ beforeIsEnding }
                              onChange={ e => setBeforeIsEnding(e.target.value) }
                              options={ [false, true].map(key => ({ value: key, label: key ? 'ending' : 'starting' })) }
                              className="bg-transparent"
                              sx={{
                                textAlign: 'center',
                                padding: '0',
                                margin: '0',
                                '& .MuiSelect-select': {
                                  padding: '0',
                                  margin: '0',
                                },
                                '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
                                  paddingRight: '0',
                                },
                                '& .MuiSelect-select:focus': {
                                  background: 'transparent',
                                },
                              }}
                            />
                          </InputContainer>
                          <div className="inline-block mh-8">on</div>
                          <InputContainer className="pv-2 ph-8">
                            <input
                              type="date"
                              className="boa-0 outline-0 pv-4 tc bg-transparent white"
                              onChange={e => setBeforeString(e.target.value)}
                              min="2020-01-01"
                              max={dayjs().subtract(3, 'day').format('YYYY-MM-DD')}
                              value={beforeString}
                            />
                          </InputContainer>
                        </>
                      ) : null
                    }
                  </div>
                )
              }

              {
                !(reportType && periodDays && (afterString || dayjs(beforeString).isValid())) ? null : (
                  <div className="flex items-center mb-9">
                    <div className="w-15% w-11-w gray1 normal">After Period</div>
                    <div className="inline-block gray3 w-7 pv-2 tc mh-1">
                      { periodDays || '' }
                    </div>
                    <div className="inline-block mh-8">days</div>
                    <InputContainer>
                      <Select
                        width="8"
                        iconPosition="none"
                        renderValue={
                          value => (
                            <div className="white pv-5">
                              { value ? 'ending' : 'starting' }
                            </div>
                          )
                        }
                        value={ afterIsEnding }
                        onChange={ e => setAfterIsEnding(e.target.value) }
                        options={ [false, true].map(key => ({ value: key, label: key ? 'ending' : 'starting' })) }
                        className="bg-transparent"
                        sx={{
                          textAlign: 'center',
                          padding: '0',
                          margin: '0',
                          '& .MuiSelect-select': {
                            padding: '0',
                            margin: '0',
                          },
                          '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
                            paddingRight: '0',
                          },
                          '& .MuiSelect-select:focus': {
                            background: 'transparent',
                          },
                        }}
                      />
                    </InputContainer>
                    <div className="inline-block mh-8">on</div>
                    <InputContainer className="pv-2 ph-8">
                      <input
                        type="date"
                        className="boa-0 outline-0 pv-4 tc bg-transparent white"
                        onChange={e => setAfterString(e.target.value)}
                        min={dayjs(beforeString).add(1, 'day').format('YYYY-MM-DD')}
                        max={dayjs().subtract(3, 'day').format('YYYY-MM-DD')}
                        value={afterString}
                      />
                    </InputContainer>
                  </div>
                )
              }

              {
                !(reportType && periodDays && beforeString && afterString) ? null : (
                  <div className="flex items-center mb-9">
                    <div className="w-15% w-11-w gray1 normal">
                      <div>Day(s) of Week</div>
                    </div>
                    <ToggleButtonGroup
                      value={ daysOfWeek }
                      onChange={ (e, val) => setDaysOfWeek(val) }
                    >
                      {
                        ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'].map((label, index) => <ToggleButton key={ index } value={ index } className="w-5">{label}</ToggleButton>)
                      }
                    </ToggleButtonGroup>
                  </div>
                )
              }

              {
                !(reportType && periodDays && beforeString && afterString && daysOfWeek.length) ? null : (
                  <div className="flex items-center mb-9">
                    <div className="w-15% w-11-w gray1 normal">
                      <div>Time of Day</div>
                    </div>
                    <div className="inline-block ml-7 mr-8">From</div>
                    <InputContainer className="pv-2 ph-8">
                      <input
                        type="time"
                        className="boa-0 outline-0 pv-4 tc bg-transparent white"
                        onChange={e => setTimeStart(e.target.value)}
                        value={timeStart || ''}
                      />
                    </InputContainer>
                    <div className="inline-block mh-8">to</div>
                    <InputContainer className="pv-2 ph-8">
                      <input
                        type="time"
                        className="boa-0 outline-0 pv-4 tc bg-transparent white"
                        onChange={e => setTimeEnd(e.target.value)}
                        value={timeEnd || '' }
                      />
                    </InputContainer>
                  </div>
                )
              }

              {
                !(reportType && periodDays && beforeString && afterString && daysOfWeek.length) ? null : (
                  <div className="flex justify-end pb-3">
                    <Button
                      size="medium"
                      variant="text"
                      className="mr-5"
                      type="reset"
                    >
                      Reset
                    </Button>
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={
                        () => {
                          const beforeDate = beforeIsEnding
                            ? dayjs(beforeString)
                            : dayjs(beforeString).add(28, 'days');

                          const afterDate = afterIsEnding
                            ? dayjs(afterString)
                            : dayjs(afterString).add(28, 'days');

                          // @todo use the following fields to generate the report:
                          //   reportType
                          //   periodDays
                          //   daysOfWeek
                          //   timeStart
                          //   timeEnd

                          setReportSettings({
                            beforeDate,
                            afterDate,

                            // only used for UI right now
                            daysOfWeek,
                            timeStart,
                            timeEnd,
                          });

                          setSettingsAreExpanded(false);
                        }
                      }
                    >
                      Generate Report
                    </Button>
                  </div>
                )
              }

            </div>
          )
        }
      </div>
      {
        !settingsAreExpanded && (
          <div className="w-100%">
            <div className="flex mb-7 fs-6 gray2">
              {/*<div className="pr-8">*/}
{/*
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setSettingsAreExpanded(true);
                  }}
                  sx={{
                    color: colors.gray1,
                    fontWeight: 'normal',
                  }}
                >
                  <div className="pv-1">
                    New Report
                  </div>
                </Button>
*/}
              {/*</div>*/}

              Signals
              <Seperator />
              28 days ending on { reportSettings.beforeDate.format('MM/DD/YY') }
              <Seperator />
              28 days starting on { reportSettings.afterDate.subtract(28, 'days').format('MM/DD/YY') }
              <Seperator />
              {
                ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su']
                  .map((label, index) => daysOfWeek.includes(index) ? label : null)
                  .filter(Boolean)
                  .join(', ')
              }
              <Seperator />
              { dayjs(dayjs().format('MM/DD/YY') + ' ' + timeStart, 'MM/DD/YY HH:mm').format('h:mma') }
              {' '}
              -
              {' '}
              { dayjs(dayjs().format('MM/DD/YY') + ' ' + timeEnd, 'MM/DD/YY HH:mm').format('h:mma') }
              <div
                onClick={() => {
                  setSettingsAreExpanded(true);
                }}
                className="pl-7 pointer white flex items-center"
              >
                Clear
                <div className="pl-1">
                  <Close width="2" height="2" color="gray2" />
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};

export default observer(LitmusReportSettings);
