import React from 'react';

const Logo = (
  {
    className = '',
    style,
    width,
    height,
    color = 'gradient',
    svgProps = {},
    ...props
  }) => {

  if (color !== 'gradient') {
    className += ` fill-${ color }`;
  }

  if (typeof width !== 'undefined') {
    className += ` w-${ width }`;
  }

  if (typeof height !== 'undefined') {
    className += ` h-${ height }`;
  }

  return (
    <div className={ className } style={ style } { ...props }>
      <svg width="100%" height="100%" viewBox="0 0 24 24" stroke="none" xmlns="http://www.w3.org/2000/svg" { ...svgProps }>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.7012 12.0166L15.5583 10.0367H12.4741L13.2674 3L7 11.9832L8.14328 13.9629H11.2274L10.4338 21L16.7012 12.0166Z" fill={ (color === 'gradient') ? 'url(#paint0_linear)' : undefined }/>
        {
          (color === 'gradient') && (
            <defs>
              <linearGradient id="paint0_linear" x1="11.8506" y1="3" x2="11.8506" y2="21" gradientUnits="userSpaceOnUse">
                <stop stopColor="#5CF6FF"/>
                <stop offset="1" stopColor="#006AFF"/>
              </linearGradient>
            </defs>
          )
        }
      </svg>
    </div>
  );
};

export default Logo;
