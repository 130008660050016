import React from 'react';
import IconWrapper from './IconWrapper';

const Arrow = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="-1 -1 18 18">
    <path d="M8 1.75L3.5 5.9924M8 1.75L12.5 5.9924M8 1.75V14.25" strokeWidth="1.5" strokeLinecap="round" />
  </IconWrapper>
);

export default Arrow;
