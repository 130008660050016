import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

// import AbacusMetricsViewSwitcher from './AbacusMetricsViewSwitcher';
import useAppController from '../../hooks/useAppController';
import AbacusNetwork from './AbacusNetwork';
import AbacusSignal from './AbacusSignal';
// import { stringify } from '../../helpers/stableSerialize';
// import useMediaQueryHeight from '../../hooks/useMediaQueryHeight';

// const defaultMetricsView = 'operations';
// const defaultAggregationPeriod = 'latest-28-days';

const Abacus = () => {
  const { params, /*searchController*/ } = useAppController();
  const { focus, todId, aggregationPeriodDays } = params || {};

  // TODO: Set up search functionality
  // useEffect(() => {
  //   searchController.setParams(params);
  // });


  // TODO: Set up backup default params
  // const fetchParams = { regionId, aggregationPeriodDays, networkId, todId, signalId, phaseId, routeId, };

  // const fetchParamsStringified = stringify(fetchParams);

  // useEffect(() => {
  //   abacusController.setParams(fetchParams);
  // }, [fetchParamsStringified]);

  // const switcherMargin = useMediaQueryHeight([7, 7, 6, 5, 5]);

  if (!aggregationPeriodDays || !todId) {
    return null;
  }

  return (
    <>
      {focus === 'network'
        ? <AbacusNetwork />
        : (focus === 'signal' || focus === 'phase')
          ? <AbacusSignal />
          : 'Coming soon!'
      }
      {/* <div className="flex flex-column w-100% h-100%">
        <div className="flex-grow-1 flex w-100% h-100% relative overflow-hidden">
          {
            (abacusController.view !== 'region') && (
              <div className={ `bg-white bo-radius-pill ph-6 absolute top-${ switcherMargin } left-${ switcherMargin }` }>
                <AbacusMetricsViewSwitcher />
              </div>
            )
          }
        </div>
      </div> */}
    </>
  );
};

export default observer(Abacus);
