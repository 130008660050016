import React from 'react';
import IconWrapper from './IconWrapper';

const Expand = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="1 1 14 14">
    <path d="M4 12L12 4M4 12H6.5M4 12V9.5M12 4V6.5M12 4H9.5" strokeLinecap="round" />
  </IconWrapper>
);

export default Expand;
