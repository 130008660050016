import { types } from 'mobx-state-tree';
import AbacusController from './AbacusController';
import ChartController from './ChartController';
import MapController from './MapController';
import LitmusController from './LitmusController';

export default types
  .model({
    isHeaderEnabled: true,
    isLayoutFixedHeight: true,

    abacusController: AbacusController,
    chartController: ChartController,
    litmusController: LitmusController,
    mapController: MapController,

    params: types.frozen({}),
  })
  .views(self => ({
  }))
  .actions(self =>({
    setPageDefaults: () => {
      self.isHeaderEnabled = true;
      self.isLayoutFixedHeight = true;
    },
    setParams: params => self.params = params,
    setIsHeaderEnabled: isHeaderEnabled => self.isHeaderEnabled = isHeaderEnabled,
    setIsLayoutFixedHeight: isLayoutFixedHeight => self.isLayoutFixedHeight = isLayoutFixedHeight,
  }))
;
