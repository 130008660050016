import capitalize from "lodash/capitalize";


/*
  Given an array of entries, where an entry is of the form [ key, values[] ],
  and trims all 
*/
const trimZeroesAcrossAllEntries = (entries, {isTrimBeginning = true, isTrimEnd = true, beginningBuffer = 0, endBuffer = 0}) => {
  return entries;
}

/*
  HISTOGRAM_DATA_CONFIGS Conventions:
    formatAllData: Fn that overrides default formatting and input assumptions.
    formatBucket: Fn that uses the default formatting but modifies data of a given field or interval index; Receives metadata through `histogramNameToRawDataMap`
*/ 
// TODO: Optimize: Currently passing in the entire `histogramNameToRawDataMap` for object metadata to customize formatting
// TODO: Move custom histogram data formatting configs elsewhere?
const HISTOGRAM_DATA_CONFIGS = {
  // Phase Level Histograms
  greenTimeHistograms: {
    // Ignore Skips On Phases 2 and 6
    formatBucket: ({field, index, histogramNameToRawDataMap}) => (
      field === 'skips'
      && (histogramNameToRawDataMap.number === 2 
          || histogramNameToRawDataMap.number === 6) 
      ? 0 
      : histogramNameToRawDataMap['greenTimeHistograms'][field][index]
    ),
    ignoreIntervals: [0],
    trimZeroesOptions: {
      isTrimBeginning: true,
      isTrimEnd: true,
      beginningBuffer: 0,
      endBuffer: 0,
    },
  },
  approachSpeedHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  arrivalModulusCycleLengthHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  // Phase, Signal, Network Level Histograms
  aggressiveBrakingHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  controlDelayHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  RLRHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  aggressiveBrakingCauseBarChart: {
    /*
      NOTE: Does not follow usual histograms data schema.
      Raw:
      {
        "dilemmaZone": 8,
        "queueing": 149,
        "other": 98,
      }
      Formatted:
      [
        {
          "objectType_aggressiveBrakingCauseBarChart_type": "dilemmaZone",
          "objectType_aggressiveBrakingCauseBarChart_dilemmaZone_quantity": 8,
        },
        {
          "objectType_aggressiveBrakingCauseBarChart_type": "queueing",
          "objectType_aggressiveBrakingCauseBarChart_queueing_quantity": 149,
        },
        {
          "objectType_aggressiveBrakingCauseBarChart_type": "other",
          "objectType_aggressiveBrakingCauseBarChart_other_quantity": 98,
        },
      ]
    */
    formatAllData: function (objectType, histogramName, data) {
      let formattedDataArray = [];
      Object.keys(data).forEach(field => {
        // Rename DZE field & Prettify UI label
        const fieldLabel = field === 'dilemmaZone' ? 'Dilemma Zone Entry' : capitalize(field);

        formattedDataArray.push({[`${objectType}_${histogramName}_type`]: fieldLabel,
                                 [`${objectType}_${histogramName}_${field}_quantity`]: data[field]})
      });
      return formattedDataArray;
    },
  },
  //Edge Level Histograms
  delayHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  speedHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  minAccelerationHistogram: {
    ignoreIntervals: [0, 'last'],
  },
  maxAccelerationHistogram: {
    ignoreIntervals: [0, 'last'],
  },
};


/*
  Creates an array of bucketed data for a histogram when given a map of `histogramName->histogramRawData`.
  Data formatting can be customized by histogram type according to `HISTOGRAM_DATA_CONFIGS`.
    - Currently pulls any metadata for (e.g. for custom formatting) from the input map.

  INPUTS:
    objectType is a string, intended to be one of: 'phase', 'signal', or 'network'.
    It is used to specify the key for the histogram and its data for its objectType, e.g. "phase_histogram1, signal_histogram1_field1",
    which is needed for re-charts to display histograms that are available across multiple objectTypes.

    histogramName is a string containing the name of the histogram.

    The input map's structure is as follows:
    {
      histogram1Name: {
                        field1:      [0,              f1ValForInterval1, f1ValForInterval2, ..., f1ValForLastInterval],
                        field2:      [0,              f2ValForInterval1, f2ValForInterval2, ..., f2ValForLastInterval],
                        ...,
                        binMinUnits: [null,           interval1Start,    interval2Start,    ..., lastIntervalStart],
                        binMaxUnits: [interval1Start, interval1End,      interval2End,      ..., null]
                      },
      ...,
      object_metadata...,
    },
    where, for a histogram, each bucket's interval is [min, max), starting at [null, firstIntervalStart) and ending at [lastIntervalStart, null).
    NOTE: For a histogram, we assume only one pair of fields starts with the substring 'mins' and 'maxes', possibly followed by a capital letter.
          These fields will be used as the interval delimiters.
    NOTE: For a histogram, we assume that valid fields are arrays of the same length as the 'mins' field. Invalid field arrays will be filtered out and not displayed.
    NOTE: We also skip the first bucket since the interval [null, firstIntervalStart) is always empty.

  OUTPUTS:
    For use in re-charts, we format the data so that the histogram's data is an array that contains
    each bucket as a map keyed with its interval and associated fields.

    So output structure is as follows:
    [
      {
        objectType_histName_interval: { start: interval1Start, end: interval1End },
        objectType_histName_field1: f1ValForInterval1,
        objectType_histName_field2: f2ValForInterval1,
        ...,
      },
      {
        objectType_histName_interval: { start: interval2Start, end: interval2End },
        objectType_histName_field1: f1ValforInterval2,
        objectType_histName_field2: f2ValforInterval2,
        ...,
      },
      ...,
    ]
*/
const formatHistogramData = (objectType, histogramName, histogramNameToRawDataMap) => {

    const histogramRawData = histogramNameToRawDataMap?.[histogramName];
    if (typeof histogramRawData !== 'object') {
      return [];
    }

    // Load any data formatting config for the histogram.
    const histogramDataConfig = HISTOGRAM_DATA_CONFIGS?.[histogramName];

    // Use custom formatting fn if provided.
    if (histogramDataConfig?.formatAllData) {
      const customHistogramData = histogramDataConfig.formatAllData(objectType, histogramName, histogramRawData);
      return customHistogramData;
    }

    let histogramFormattedData = [];

    // Look for the interval delimiter fields for the histogram (i.e. describing interval mins and maxes).
    // We assume only one pair of fields starts with the substrings 'binMin' and 'binMax', possibly followed by a capital letter then more.
    const minsField = Object.keys(histogramRawData).find(field => /^binMin[A-Z]\w*/g.test(field) || /^binMin$/g.test(field) );
    const maxesField = Object.keys(histogramRawData).find(field => /^binMax[A-Z]\w*/g.test(field) || /^binMax$/g.test(field) );
    if (
      minsField && maxesField
      && histogramRawData[minsField] && histogramRawData[maxesField]
      && Array.isArray(histogramRawData[minsField]) && Array.isArray(histogramRawData[maxesField])
      && histogramRawData[minsField].length === histogramRawData[maxesField].length
    ) {

      const { [minsField]: mins, [maxesField]: maxes, ...fields } = histogramRawData;

      // Process one interval at a time through every field,
      // arbitrarily using `minsField` to drive bucket creation since all arrays should be the same length.
      mins.forEach((min, index) => {

        // Ignore intervals if specified in config.
        const intervalsToIgnore = histogramDataConfig?.ignoreIntervals;
        if (
          intervalsToIgnore
          && (intervalsToIgnore.includes(index)
              || (intervalsToIgnore.includes('last') && index === mins.length - 1))
        ) {
          return;
        }

        // Create an array of valid data entries of the form [field, field_data_for_interval] for each interval/bucket.
        // NOTE: Valid data is non-nullish and has the same length as the mins field.
        const fieldsToBucketVals = Object.keys(fields)
                                         .filter(field => histogramRawData[field] !== null && histogramRawData[field] !== undefined && histogramRawData[field] !== [])
                                         .filter(field => histogramRawData[field].length === mins.length)
                                         .map(field => histogramDataConfig?.formatBucket
                                                       ? [ `${objectType}_${histogramName}_${field}`, histogramDataConfig.formatBucket({field: field, index: index, histogramNameToRawDataMap: histogramNameToRawDataMap}) ]
                                                       : [ `${objectType}_${histogramName}_${field}`, histogramRawData[field][index] ]);

        const fieldsToBucketValsWithInterval = [
          [ `${objectType}_${histogramName}_interval`, min ],
          ...fieldsToBucketVals,
        ];

        // Trim zeroes across all fields, if specified in config.
        const bucketEntries = histogramDataConfig?.trimZeroesOptions 
                                ? trimZeroesAcrossAllEntries(fieldsToBucketValsWithInterval, histogramDataConfig.trimZeroesOptions)
                                : fieldsToBucketValsWithInterval;

        // Create the bucket.
        const bucket = Object.fromEntries(bucketEntries);

        // Then append the bucket to the histogram's data array.
        histogramFormattedData.push(bucket);
      });
    }

  return histogramFormattedData;
} 

export default formatHistogramData;