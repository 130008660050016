import React from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { default as MaterialSelect } from '@mui/material/Select';

import { elementSizes, themeOptions } from '../../../FlowMaterialTheme';
import ArrowHead from '../../icons/ArrowHead';
import { observer } from 'mobx-react-lite';

const Select = (
  {
    width = '10',
    FormControlProps,
    options,
    iconPosition = 'none',
    variant = 'standard',
    className,
    renderBeforeMenuItems,
    renderAfterMenuItems,

    ...props
  },
) => {

  const iconClass = 'rotate-180 absolute pointer-events-none ' +
    (
      iconPosition === 'left'
        ? 'left-7 ml-3'
        : 'right-7 mr-3'
    );

  const IconComponent = iconPosition === 'none'
    ? 'div'
    : () =>
      <ArrowHead
        width="3"
        height="3"
        color={ variant === 'standard' ? 'blue2' : 'gray2' }
        className={ iconClass }
      />;

  const MenuProps = {
    className: 'selectVariant-' + variant,
    ...themeOptions.components.MuiSelect.defaultProps.MenuProps,
  };

  return (
    <div className={ 'inline-block w-' + width }>
      <FormControl fullWidth {...FormControlProps}>
        <MaterialSelect
          IconComponent={ IconComponent }
          variant={ variant }
          className={ className + ' iconPosition-' + iconPosition }
          MenuProps={ MenuProps }
          // inputProps={{ className: 'iconPosition-' + iconPosition }}
          { ...props }
        >
          { renderBeforeMenuItems?.() }
          {
            options.map(({ value, label, ...props }, index) => {
              if (typeof value === 'undefined') {
                if (typeof label === 'function') {
                  const Component = label;
                  return <Component key={index} />;
                }
                return <div key={ index }>{ label }</div>;
              }

              return (
                <MenuItem key={ index } className={ `w-${ width }` } value={ value } { ...props }>
                  { typeof label === 'function' ? label() : label }
                </MenuItem>
              );
            })
          }
          { renderAfterMenuItems?.() }
        </MaterialSelect>
      </FormControl>
    </div>
  );
};

export default observer(Select);
