import AbacusController from '../stores/controllers/AbacusController';
import AppController from '../stores/controllers/AppController';
import ChartController from '../stores/controllers/ChartController';
import MapController from '../stores/controllers/MapController';
import LitmusController from '../stores/controllers/LitmusController';

let appController;

/*
Important: If you use this hook (no pun intended), be sure to wrap your component with the `observer` HOC,
if you want to efficiently re-render when the parts of the app state you depend on change.
The `observer` component is 'smart' and knows only to re-render when necessary, so it doesn't re-render on
changes in app state that don't affect your particular component.

  import { observer } from 'mobx-react-lite';
  import useAppController from 'path/to/hooks/useAppController';

  const MyComponent = () => {
    const appController = useAppController();
    const someData = appController.mapController.foo.bar;
    return (
      <div>
        { someData }
      </div>
    );
  }

  export default observer(MyComponent);

So if you use <MyComponent /> somewhere, and `appController.cerberusController.some.unrelated.data` changes, then MyComponent
will not re-render. But if `appController.mapController.foo.bar` (or data it depends on) changes, MyComponent will re-render as expected.
*/
const useAppController = () => {
  if (!appController) {
    // Notes:
    // - this creation and assignment has to be synchronous and atomic
    // - we only want one instance of this for the whole app, so we don't wanna utilize useRef
    // - we can technically do this with a ref on a high-level component and context,
    //   but that seems more complicated and has no advantages that i know of
    appController = AppController.create({
      abacusController: AbacusController.create({}),
      chartController: ChartController.create({}),
      litmusController: LitmusController.create({}),
      mapController: MapController.create({}),
    });
  }

  return appController;
};

export default useAppController;
