import { Area, Bar, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { colors, spacingSizes } from '../../FlowMaterialTheme';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useMediaQueryWidth from '../../../hooks/useMediaQueryWidth';
import useAppController from '../../../hooks/useAppController';
import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import { PHASE_HISTOGRAMS, SIGNAL_HISTOGRAMS, NETWORK_HISTOGRAMS, MAINROUTEEDGES_HISTOGRAMS, X_AXIS_FIELDS, CHART_NAMES_TO_TITLES, formatCurrChartToChartName, CHARTS_WITHOUT_LEGEND, ALL_CHART_FIELDS, PHASE_TIME_SERIES, SIGNAL_TIME_SERIES, CHART_FIELD_CONFIGS_BASE, NETWORK_TIME_SERIES, ROUTE_TIME_SERIES, NETWORK_BAR_CHARTS, SIGNAL_BAR_CHARTS, ROUTE_BAR_CHARTS } from '../../../displayConfigs/chartDefinitions';


/* Height of graph should be between [280px, 800px] by design */
export const MIN_Y = 280;
export const MAX_Y = 800;

const CHART_TYPE_TO_COMPONENT_MAP = {
  area: Area,
  bar: Bar,
  default: Line,
};

/*****

 * re-charts UI Components for Individual Graphs

 *****/
const DateTick = ({ x, y, payload, previousMonth }) => {

  const [isNewMonth, setIsNewMonth] = useState(false);
  const [day, month] = payload?.value?.split(' ');

  useEffect(() => {
    if ((month && previousMonth.current === null) || month !== previousMonth.current) {
      setIsNewMonth(true);
    } else if (isNewMonth) {
      setIsNewMonth(false);
    }
    previousMonth.current = month;
  }, []);

  return (
    <>
      <text x={x} y={y + 10} textAnchor="middle" alignmentBaseline="central" className="fill-gray2 normal" fontSize="11">
        { day }
      </text>
      {
        isNewMonth
          ? (
            <text x={x} y={y + 26} textAnchor="middle" alignmentBaseline="central" className="fill-gray2 normal" fontSize="9">
              { month }
            </text>
            )
          : null 
      }
    </>
  );
};


const ScoreDot = ({ dotFillColor = 'black', dotStrokeColor = 'gray', cx, cy, textColor = 'black', value, dataKey, type, chartFieldConfig }) => {
  // for area graphs, we currently assume area is from 0 for our value, and
  // therefore we only care about value[1] (i.e. value[0] is always 0 right now)
  value = type === 'area' ? value[1] : value;

  let displayValue = (value === null || value === undefined)
    ? null
    : chartFieldConfig?.getDisplayValueDot
      ? chartFieldConfig.getDisplayValueDot(value)
      : chartFieldConfig?.getDisplayValue
        ? chartFieldConfig.getDisplayValue(value)
        : value;

  if (displayValue === null) {
    return null;
  }

  const fontSize = displayValue.length === 1 ? 12 : displayValue.length === 2 ? 9 : 7;

  return (
    <>
      <circle
        cx={ cx }
        cy={ cy }
        r="9"
        strokeWidth="2"
        className={ `drop-shadow-gray2 stroke-${ dotStrokeColor } fill-${ dotFillColor }` }
      />
      <text
        fontSize={ fontSize }
        x={ cx }
        y={ cy }
        textAnchor="middle"
        alignmentBaseline="central"
        className={ `fw-2 fill-${ textColor }` }
      >
        { displayValue }
      </text>
    </>
  );
};


const TooltipCursor = ({ points, currCursorCoor }) => {
  const HEIGHT_REDUCTION = 0;

  useEffect(() => {
    // NOTE: points[0] contains the coors of the top of the currently hovered recharts column,
    //       whereas points[1] contains the coors of the bottom.
    if (!currCursorCoor.current) {
      currCursorCoor.current = { 
                                 x: points[0].x,
                                 y: points[0].y,
                               };
    } else {
      if (currCursorCoor.current.x !== points[0].x) {
        currCursorCoor.current.x = points[0].x;
      }
      if (currCursorCoor.current.y !== points[0].y) {
        currCursorCoor.current.y = points[0].y;
      }
    }
  }, [points]);

  return (
    <>
      <g>
        <line 
          x1={`${points[0].x}`}
          y1={`${points[0].y + HEIGHT_REDUCTION}`}
          x2={`${points[1].x}`}
          y2={`${points[1].y}`}
          stroke={`${colors.gray4}`}/>
      </g>
    </>
  )
};


const CustomLegend = ({ payload, chartFieldConfigs }) => {
  const customLegendFontSize = useMediaQueryWidth([.6875, .625, .5625, .5]);

  return (
    <div className="flex flex-wrap justify-start" style={{ fontSize: customLegendFontSize + 'rem' }}>
      {
        payload.map(({ dataKey, value, color, ...stuff }, index) => {
          const { opacity, type, chartProps } = chartFieldConfigs[dataKey] || {};
          const { strokeDasharray, isHideInLegend = false, legendLabel } = chartProps || {};

          return (
            isHideInLegend 
            ? null
            : 
            <div key={ index } className="inline-flex items-start mb-4">
                <div
                  className={ `w-2 mr-4 flex justify-around ${ opacity ? ('o-' + opacity) : '' }` }
                  style={{ backgroundColor: color, height: spacingSizes['5'] }}
                >
                  {
                    strokeDasharray
                      ? (
                        <>
                          <div className="bg-white h-100%" style={{ width: '.125rem' }} />
                          <div className="bg-white h-100%" style={{ width: '.125rem' }} />
                        </>
                      )
                      : null
                  }
                </div>
                <div className={ `nowrap ${ (index === payload.length - 1) ? '' : 'mr-8' }` }>{ legendLabel ? legendLabel : value }</div>
              </div>
          );
        })
      }
    </div>
)};


//TODO: Refactor to make TooltipContent accept this list of cols to show at bottom dynamically
const LAST_COLUMNS_IN_TOOLTIP = new Set(['flowLabsVolumeCountPerHour', 'programmedSplitTimeMode', 'programmedCycleTimeMode']);

/*
Displays re-charts tooltip content for a data entity, e.g. Signal, Phase,
based on fields described in `chartFieldConfigs`.

Since the tooltip mounts and unmounts based on hovering over specific columns
in the re-chart, we can use it to identify the specific data entity being
referred to by the currently hovered column.
This can be used to update a related passed-in ref dependent on hover behavior
over the re-chart column, using elType, elId, hoveredElId.
@param {string, optional} elTitle - Title of data entity, e.g. "Signal"
@param {string, optional} elId - Identifier for data entity, e.g. payload.[0].payload.signalCode
@param {React.MutableRefObject, optional} hoveredElId - Ref for data entity for external behavior e.g. onHover
@returns 
*/
const TooltipContent = ({ payload, chartFieldConfigs, /* elType = null, elId = null, hoveredElId = null, chartFieldConfigs */}) => {
  
  //TODO: Hovering functionality
  // elId = payload?.[0].payload.signalCode; //TODO: Remove after testing
  // Slight hack: Updates el ref, if provided, to current el in payload for
  // external behavior, e.g. onHover.
  // useEffect(() => {
  //   // if (elType && elId && hoveredElId) {
  //     if (hoveredElId && (hoveredElId.current !== elId)) {
  //       hoveredElId.current = elId;
  //     }
      
  //     return () => {
  //       if (hoveredElId) {
  //         hoveredElId.current = null;
  //       }
  //     };
  //   // }
  // }, [elId]);
  // if (!referenceDate || !elId) {
  //   return null;
  // }

  // (Slight Hack) Display x axis information as tooltip title.
  const tooltipFields = payload?.[0]?.payload
                          ? Object.keys(payload[0].payload)
                          : undefined;
  const xAxisField = tooltipFields 
                       ? tooltipFields.filter(field => X_AXIS_FIELDS.has(field))[0] // Assumes each tooltip payload has only one x axis field.
                       : undefined;
  const xAxisDisplayValue = !xAxisField
                              ? undefined
                              : payload[0].payload[xAxisField];
  const xAxisDisplayField = chartFieldConfigs[xAxisField]?.tooltipDisplayField !== undefined
                              ? chartFieldConfigs[xAxisField].tooltipDisplayField
                              : xAxisField;
  const title = (xAxisDisplayField === undefined && xAxisDisplayValue === undefined)
                  ? `Data:`
                  : xAxisDisplayField === null
                    ? xAxisDisplayValue
                    : `${xAxisDisplayField} ${xAxisDisplayValue}`;
  
  const columns = [
    ...payload.filter(column => (!LAST_COLUMNS_IN_TOOLTIP.has(column.dataKey))),
    ...payload.filter(column => (LAST_COLUMNS_IN_TOOLTIP.has(column.dataKey))),
  ];

  return (
      <div>
        <div className="max-w-14 h-auto" style={{ /*width: '300px',*/ minWidth: '300px', /*transform: 'translateY(calc(10% - 3rem))'*/ }}>
          <div className=" bg-gray5 bo-gray6 boa-1 pv-4 ph-7 white" /*box-shadow-gray3*/>
            <div className="pa-4">{ title }</div>
            <table className="w-100% line-height--1">
              <tbody>
              {
                columns.map(({ color, name, value, dataKey }, index) => {
                  const chartFieldConfig = chartFieldConfigs[dataKey];
                  const isHideInTooltip = chartFieldConfig?.chartProps?.isHideInTooltip;
                  const tooltipLabel = chartFieldConfig?.chartProps?.tooltipLabel;
                  const displayValue = value === null
                    ? 'N/A'
                    : chartFieldConfig?.getDisplayValue
                      ? chartFieldConfig.getDisplayValue(value)
                      : value;

                  return (
                    isHideInTooltip
                    ? null
                    : <tr key={ index }>
                        <td className="mr-4">
                          <div className="boa-1 bo-gray4 w-2 h-2 box-shadow-gray2" style={{ backgroundColor: color }} />
                        </td>
                        <td className="pv-4">{ tooltipLabel ? tooltipLabel : name }</td>
                        <td align="right" className="white">{ displayValue }</td>
                      </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
};


/*****

 * Data Utilities for Generating re-charts Graphs

 *****/
// Formats raw chart data based on field's config in chartFieldConfigs
const formatChartData = (dataRow, chartFieldConfigs) => {
  const formattedDataRow = {};

  if (dataRow) {
    Object.keys(chartFieldConfigs).forEach(fieldKey => {
      if (chartFieldConfigs.hasOwnProperty(fieldKey)) {
        const formatter = chartFieldConfigs[fieldKey].sanitizeData;
        const getValue = chartFieldConfigs[fieldKey].getValue || (dataRow => dataRow.hasOwnProperty(fieldKey) ? dataRow[fieldKey] : null);

        const originalValue = getValue(dataRow);

        formattedDataRow[fieldKey] = formatter ? formatter(originalValue) : originalValue;
      }
    });
  }

  return formattedDataRow;
};


/*
  A Generalized `re-charts` Chart for intersection view (signal + phases).
  Can contain multiple Graphs, each with their own data and UI config.
  Renders one Graph at a time with transition animations when switching between Graphs.
  Can support graphCol->elRef onHover functionality via TooltipContent hack.
  
  General structure:
    1. Prepares UI configs for each of the chart's fields (which can either describe an XAxis OR a YAxis along with its graph).
    2. Gets data for the traffic entity of interest.
    3. Render.
*/
const Chart = ({ isTestMode = false, currChart, extraY }) => {

  const { params: URLParams, chartController, abacusController } = useAppController();

  const previousMonth = useRef(null);
  const currRechartsCursorCoor = useRef(null);

  // TODO?: Genericize for optional hover behavior? (hoveredSignalId->hoveredElId?)
  // graphCol->elRef onHover functionality
  // const hoveredSignalCode = useRef(null);


  const { params: testParams,
          
          // Network Charts
          networkTodTimeSeriesData,
          getNetworkTodTimeSeries,

          networkHistogramsData,
          getNetworkHistogram,

          // Route Charts
          routeTimeSeriesData,
          getRouteTimeSeries,

          // routeSignalMetricsData,
          // getRouteSignalMetrics,

          // Edge Charts
          mainRouteEdgesHistogramsData,
          getMainRouteEdgesHistogram,

          mainRouteEdgeId, // TODO: Remove when edges are specified

          // Signal + Phase Charts
          signalTimeSeriesData,
          getSignalTimeSeries,
          getPhaseTimeSeries,

          signalHistogramsData,
          getSignalHistogram,
          getPhaseHistogram,
        
        } = chartController;
  
  // Test Mode Params override existing normal params
  const params = isTestMode ? { ...URLParams,
                                ...testParams }
                            : URLParams;

  const { networkId, 
          routeId,
          signalId,
          phaseId,
          todId, aggregationPeriodDays
        } = params;


  // Fetch raw data for charts.
  useEffect(() => {
    if (
      [...PHASE_TIME_SERIES, ...SIGNAL_TIME_SERIES].includes(currChart)
      && signalId && todId && aggregationPeriodDays
    ) {
      signalTimeSeriesData.fetch({ signalId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    }
  }, [currChart, signalId, aggregationPeriodDays, todId]);

  useEffect(() => {
    if (
      [...PHASE_HISTOGRAMS, ...SIGNAL_HISTOGRAMS].includes(currChart)
      && signalId && todId && aggregationPeriodDays
    ) {
      signalHistogramsData.fetch({ signalId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    }
  }, [currChart, signalId, aggregationPeriodDays, todId]);

  useEffect(() => {
    if (
      ROUTE_TIME_SERIES.includes(currChart)
      && routeId && todId && aggregationPeriodDays
      ) {
      routeTimeSeriesData.fetch({ routeId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    }
  }, [currChart, routeId, aggregationPeriodDays, todId]);

  useEffect(() => {
    if (
      NETWORK_TIME_SERIES.includes(currChart)
      && networkId && todId && aggregationPeriodDays
    ) {
      networkTodTimeSeriesData.fetch({ networkId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    }
  }, [currChart, networkId, aggregationPeriodDays, todId]);

  useEffect(() => {
    if (
      NETWORK_HISTOGRAMS.includes(currChart)
      && networkId && todId && aggregationPeriodDays
    ) {
      networkHistogramsData.fetch({ networkId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    } else if (
      MAINROUTEEDGES_HISTOGRAMS.includes(currChart)
      && networkId && todId && aggregationPeriodDays
    ) {
      mainRouteEdgesHistogramsData.fetch({ networkId, aggregationPeriodDays: Number(aggregationPeriodDays), todId });
    }
  }, [currChart, networkId, aggregationPeriodDays, todId]);


  // TODO: Move bar chart data fetching to ChartController (coordinate with AbacusController data?)
  const { 

    // Network Bar Charts
    networkTodMetricsData,
    getNetworkTodMetrics,
    
    // Signal + Phase Bar Charts
    signalMetricsData,
    getSignalMetrics,

  } = abacusController;

  useEffect(() => {
    if (signalId && SIGNAL_BAR_CHARTS.includes(currChart)) {
      signalMetricsData.fetch({ signalId, todId, aggregationPeriodDays: Number(aggregationPeriodDays) });
    }
  },[signalMetricsData, phaseId, signalId, todId, aggregationPeriodDays]);

  useEffect(() => {
    if (networkId && (NETWORK_BAR_CHARTS.includes(currChart) || ROUTE_BAR_CHARTS.includes(currChart))) {
      networkTodMetricsData.fetch({ networkId, todId, aggregationPeriodDays: Number(aggregationPeriodDays) });
    }
  }, [networkTodMetricsData, networkId, todId, aggregationPeriodDays]);


  // TODO: Re-introduce Route Signal charts?
  // useEffect(() => {
  //   if (routeId && aggregationPeriodDays && todId) {
  //     routeSignalMetricsData.fetch({ routeId, aggregationPeriodDays, todId });
  //     routeTimeSeriesData.fetch({ routeId, aggregationPeriodDays, todId });
  //   }
  // }, [routeId, aggregationPeriodDays, todId]);
  //
  // const signals = networkData.data?.user?.network?.signals || [];
  // const signalsById = {};
  // const signalsByCode = {};
  // signals.forEach(signal => {
  //   signalsById[signal.id] = signal;
  //   signalsByCode[signal.code] = signal;
  // });
  // const route = networkData.data?.user?.network?.routes?.find?.(r => r?.id === routeId) || {};


  // TODO: Hovering functionality
  // const unhoverSignal = useCallback(() => {
  //   setHoveredSignalId(null);
  // }, []);

  // useEffect(() => {
  //   // unhover when unmounting this component
  //   return unhoverSignal;
  // }, []);


  /*
    1. Prepares UI config for the specific chart.
       Filters `chartFieldConfigs` for the configs for each of the chart's passed-in `fields` and any other relevant fields.
       A chartFieldConfig can either describe an XAxis (via axisProps) OR
                                               a YAxis (via axisProps) along with its graph (via chartProps).
  */

  if (!currChart) {
    console.log(`No current chart ${currChart}`);
    return null;
  }

  const allChartFields = ALL_CHART_FIELDS[currChart];

  // Convention: When adding a new chart field's config,
  //             first try to add it to CHART_FIELD_CONFIGS_BASE.
  //             Then if a part of the config needs to be dynamically added,
  //             put that code here.
  // Add dynamic chart field configs.
  const chartFieldConfigs = { ...CHART_FIELD_CONFIGS_BASE }
  chartFieldConfigs['referenceDate'].axisProps['tick'] = <DateTick previousMonth={previousMonth} />;

  if (formatCurrChartToChartName(currChart) === 'healthScoreTimeSeries') {
    chartFieldConfigs['healthScore'].chartProps = {
      name: phaseId ? 'Phase Health Score' : 'Intersection Health Score',
    };
  } 
  // TODO: Re-introduce Route Signal charts?
  // else if (ALL_ROUTE_CHARTS.includes(currChart)) {
  //   chartFieldConfigs['signalCode'].getValue = row => signalsById[row?.signalId]?.code;
  // }

  const axes = [];
  const graphs = [];

  // we could use Map() instead of Set() + array, but Map() syntax is confusing
  const uniqueAxes = new Set();
  const usedXAxesByChartName = {}; // A map of arrays containing xAxisIds used by each chart at the time of processing, used to determine orientation of YAxes

  allChartFields.forEach(
    field => {
      // Set up field's key
      if (typeof field === 'string') {
        field = { id: field };
      }

      const fieldKey = field.id;

      // Get specific chart field's config by field key
      const chartFieldConfig = {
        fieldKey,
        ...(chartFieldConfigs[fieldKey] || {}),
        ...field,
      };

      // Get field's UI properties from its config.
      // A field config can uniquely describe an XAxis OR a YAxis along with its graph.
      let {
        dataKey = fieldKey,
        axisDataKey = dataKey,
        isXAxis,
        xAxisId,
        yAxisId = (isXAxis ? undefined : fieldKey),
        axisProps = {},
        chartProps = {},
        color = 'blue4',
        contrastColor,
        graphScale = 'linear',

        type,
        secondaryConfig,
      } = chartFieldConfig;

      // Add field's axis.
      if ((isXAxis && !uniqueAxes.has(xAxisId)) || (!isXAxis && !uniqueAxes.has(yAxisId))) {
        if (isXAxis) {
          uniqueAxes.add(xAxisId);
        } else {
          uniqueAxes.add(yAxisId);
        }

        if (!isXAxis && usedXAxesByChartName?.[currChart] && usedXAxesByChartName[currChart].includes(xAxisId)) {
          // If multiple fields appear in the same graph and use the same xAxisId,
          // then keep one on the left and move the rest to the right.
          axisProps = { 
            ...axisProps,
            orientation: 'right',
          };
        }

        usedXAxesByChartName[currChart] = usedXAxesByChartName?.[currChart] 
        ? [
            ...usedXAxesByChartName[currChart],
            xAxisId,
          ]
        : [xAxisId];

        axes.push({
          Component: isXAxis ? XAxis : YAxis,
          props: {
            ...axisProps,
            dataKey: isXAxis ? axisDataKey : undefined, // This structure is needed for proper assignment of XAxis and YAxis. Otherwise, a symptom is that the domain would be incorrect.
            xAxisId,
            yAxisId,
          },
        });
      }

      // We finish here for XAxis fields, since an XAxis is described solely by itself.
      // Otherwise, in addition to a field's YAxis, we continue to add the graph of the field.
      if (isXAxis) {
        return;
      }

      // Add in custom props for charts that display a field differently than its "usual" config.
      if (currChart === 'network_controlDelayMedianTimeSeries') {
        if (secondaryConfig) {
          type = secondaryConfig?.type ? secondaryConfig.type : type;
          color = secondaryConfig?.color ? secondaryConfig.type : color;
          chartProps = secondaryConfig?.chartProps 
            ? {
              ...chartProps,
              ...secondaryConfig.chartProps,
            }
            : chartProps;
        }
      }

      graphs.push({
        Component: CHART_TYPE_TO_COMPONENT_MAP.hasOwnProperty(type)
        ? CHART_TYPE_TO_COMPONENT_MAP[type]
        : CHART_TYPE_TO_COMPONENT_MAP.default,
        props: {
          animationDuration: 400,
          dot: false,
          strokeWidth: 2,
          activeDot: type === 'bar' ? false : <ScoreDot dotFillColor={ color } dotStrokeColor={ color } textColor={ contrastColor } type={ type } chartFieldConfig={ chartFieldConfig } />,
          stroke: (type === 'area' || type === 'bar') ? 'none' : colors[color],
          fill: (type === 'area' || type === 'bar') ? colors[color] : 'none',
          fillOpacity: (type === 'area' || type === 'bar') ? 1 : undefined,
          connectNulls: true,
          type: graphScale,

          ...chartProps,

          dataKey,
          xAxisId,
          yAxisId,
        }
      });
    }
  );


  /*
      2. Get formatted data for the traffic entity of interest.
         First tries to get histogram data, then falls back to time series data.
   */
  let chartData = undefined;

  // Phases
  // console.log(`${currChart} is in phase histograms: ${PHASE_HISTOGRAMS.includes(currChart)}`);
  if (PHASE_HISTOGRAMS.includes(currChart)) {
      chartData = getPhaseHistogram(phaseId, formatCurrChartToChartName(currChart));
  } 
  else if (PHASE_TIME_SERIES.includes(currChart)) {
      chartData = getPhaseTimeSeries(phaseId);
  }
  // Routes
  else if (ROUTE_TIME_SERIES.includes(currChart)) {
    chartData = getRouteTimeSeries(routeId);
  }
  else if (ROUTE_BAR_CHARTS.includes(currChart)) {
    // TODO: Make dedicated MobX getter
    const networkMetrics = networkTodMetricsData?.data?.user?.network;
    if (networkMetrics?.routes) {
      const routeMetrics = networkMetrics.routes.find(route => route.id === routeId);

      // route.signalIds is used to list the signals in their order along the Route
      if (!routeMetrics?.latestRouteSignalMetrics || !routeMetrics?.signalIds) {
         chartData = [];
      } else {
        const dataByRouteSignalId = {};
        routeMetrics.latestRouteSignalMetrics.forEach(routeSignal => {
          if (routeSignal && routeSignal?.signal?.id !== undefined) {
            const routeSignalMetricsBar = Object.keys(routeSignal)
              .filter(metricsFieldKey => routeSignal[metricsFieldKey] !== null || routeSignal[metricsFieldKey]!== undefined)
              .map(metricsFieldKey => metricsFieldKey === 'signal'
                ? [`signalCode`, routeSignal.signal.code]
                : [`signal_${metricsFieldKey}_bar`, routeSignal[metricsFieldKey]]);

            dataByRouteSignalId[routeSignal.signal.id] = Object.fromEntries(routeSignalMetricsBar);
          }
        });

        chartData = routeMetrics.signalIds
          .map(signalId => dataByRouteSignalId[signalId] || null);
      }
    }
  }
  // Signals
  else if (SIGNAL_HISTOGRAMS.includes(currChart)) {
    chartData = getSignalHistogram(signalId, formatCurrChartToChartName(currChart));
  } 
  else if (SIGNAL_TIME_SERIES.includes(currChart)) {
    chartData = getSignalTimeSeries(signalId);
  } 
  // TODO: Move data fetching to ChartController (coordinate with AbacusController data?)
  // TODO: Allow fields to have configs for multiple kinds of charts
  else if (SIGNAL_BAR_CHARTS.includes(currChart)) {
    const signalMetrics = getSignalMetrics(signalId);

    if (signalMetrics?.phases) {
      const dataByPhase = signalMetrics.phases.reduce((acc, phase) => {
        if (!phase?.latestMetrics) {
          return acc;
        }
        const phaseMetricsBar = Object.keys(phase?.latestMetrics)
          .filter(metricsFieldKey => phase.latestMetrics[metricsFieldKey] !== null || phase.latestMetrics[metricsFieldKey]!== undefined)
          .map(metricsFieldKey => [`phase_${metricsFieldKey}_bar`, phase.latestMetrics[metricsFieldKey]]);
        return (
          [ ...acc,
            Object.fromEntries([
              [`number`, phase.number],
              ...phaseMetricsBar,
            ])
          ]
        );
      }, []);
      chartData = dataByPhase;
    }
  } 
  // Networks
  else if (NETWORK_HISTOGRAMS.includes(currChart)) {
    chartData = getNetworkHistogram(networkId, formatCurrChartToChartName(currChart));
  } 
  else if (NETWORK_TIME_SERIES.includes(currChart)) {
    chartData = getNetworkTodTimeSeries(todId);
  } 
  else if (NETWORK_BAR_CHARTS.includes(currChart)) {
    // TODO: Make dedicated MobX getter
    const networkMetrics = networkTodMetricsData?.data?.user?.network;
    if (networkMetrics?.signals) {
      const dataBySignal = networkMetrics.signals.reduce((acc, signal) => {
        if (!signal?.latestMetrics) {
          return acc;
        }
        const signalMetricsBar = Object.keys(signal?.latestMetrics)
          .filter(metricsFieldKey => signal.latestMetrics[metricsFieldKey] !== null || signal.latestMetrics[metricsFieldKey]!== undefined)
          .map(metricsFieldKey => [`signal_${metricsFieldKey}_bar`, signal.latestMetrics[metricsFieldKey]]);
        return (
          [ ...acc,
            Object.fromEntries([
              [`signalCode`, signal.code],
              ...signalMetricsBar,
            ])
          ]
        );
      }, []);
      chartData = dataBySignal;
    }
  }
  // MainRouteEdges
  else if (MAINROUTEEDGES_HISTOGRAMS.includes(currChart)) {
    chartData = getMainRouteEdgesHistogram(mainRouteEdgeId, formatCurrChartToChartName(currChart));
  } 
  else {
    console.error('No valid currChart specified.');
  }

  chartData = chartData ? chartData.map(dataRow => formatChartData(dataRow, pick(chartFieldConfigs, allChartFields))) : [];


  /*
     3. Render.
   */
  if (!chartData?.length) {
    if ([networkTodMetricsData,
         signalMetricsData,
         networkHistogramsData,
         mainRouteEdgesHistogramsData,
         networkTodTimeSeriesData,
         routeTimeSeriesData,
         signalHistogramsData,
         signalTimeSeriesData,].some(dataFetcher => dataFetcher.isLoading)
    ) {
      return <div className="h-100% w-100% flex justify-center items-center">Loading...</div>;
    } else {
      // Note: This would render when clicking between networks, even if they have loaded and contain data.
      //       So, to keep things clean, we render null for now.
      console.log('No data available');
      return null;
    }
  }

  return (
    <div className="flex-grow-1 flex flex-column bg-gray6">
      <p className="white fw-3 pa-0 mt-8 mb-0 ml-8">{CHART_NAMES_TO_TITLES[formatCurrChartToChartName(currChart)]}</p>
      <ResponsiveContainer className="flex-grow-1" width="100%" height="100%" minHeight={`${MIN_Y}px`} /**maxHeight={`${MAX_Y}px`} */>
        <ComposedChart
          data={chartData}
          margin={{
            top: 35,
            right: 0,
            left: 0,
            bottom: 20, //80
          }}
          // NOTE: graphCol->elRef onHover functionality
          // onMouseMove={e => {
          //   const newHoveredSignalId = signalsByCode.hasOwnProperty(hoveredSignalCode.current) ? signalsByCode[hoveredSignalCode.current].id : null;
          //   if (hoveredSignalId !== newHoveredSignalId) {
          //     setHoveredSignalId(newHoveredSignalId);
          //   }
          // }}
        >

          {
            axes.map(
              ({ Component, props }, index) => (
                <Component key={ 'axis' + index } { ...props } />
              )
            )
          }
          {
            !CHARTS_WITHOUT_LEGEND.has(currChart) &&
            <Legend
              content={ <CustomLegend chartFieldConfigs={chartFieldConfigs} /> }

              wrapperStyle={{ left: '4.25rem', bottom: '.1rem', maxWidth: '85%' }}
            />
          }

          <Tooltip
            cursor={ <TooltipCursor currCursorCoor={currRechartsCursorCoor} /> }
            content={ <TooltipContent /* hoveredSignalCode={ hoveredSignalCode } NOTE: graphCol->elRef onHover functionality */ 
                                      chartFieldConfigs={ chartFieldConfigs } /> }
            // Hack: transform + position used to move Tooltip along only one axis
            wrapperStyle={{
              transform: `translate(${currRechartsCursorCoor.current?.x ? currRechartsCursorCoor.current.x : 0}px, ${currRechartsCursorCoor.current?.y ? currRechartsCursorCoor.current.y : 0} px)`,
            }}
            position={{ y: 30 }}
            allowEscapeViewBox={ { x: false } }
            isAnimationActive={ false }
            filterNull={ false }
          />

          {
            // NOTE: Multiple graphs are organized in a Chart to allow 
            //       re-charts transitions while switching between graph views
            graphs.map(
              ({ Component, props, cellRenderer }, index) => (
                <Component key={ 'graph' + index } { ...props } />
              )
            )
          }

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default observer(Chart);
