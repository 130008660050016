import AbacusDetectorsTable from '../components/abacus/AbacusDetectorsTable';
import Chart from '../components/shared/elements/Chart';


const ABACUS_TAB_DIAGNOSTIC = "Diagnostic";
const ABACUS_TAB_TIME_OF_DAY = "Time Of Day";
const ABACUS_TAB_TIME_SERIES = "Time Series";

// For each level's abacusMetric, each tab is listed as an array of the form [tabLabel, componentEl]
// An array is used for each abacusMetric to preserve tab order.
export const ABACUS_METRIC_TO_TABS_BY_LEVEL = {
  network: {
    // Operations
    flowLabsVolumeCountPerHour: [
      [ABACUS_TAB_DIAGNOSTIC, 'flowLabsVolumeCountPerHourBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'network_flowLabsVolumeCountPerHourTimeSeries'],
    ],
    controlDelayMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'controlDelayMedianBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'network_controlDelayMedianTimeSeries'],
    ],
    arrivalsOnGreenRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'arrivalsOnGreenRatioBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_arrivalsOnGreenRatioTimeSeries'],
    ],
    platoonRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'platoonRatioBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_platoonRatioTimeSeries'],
    ],
    avgSplitFailuresPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'avgSplitFailuresPerCycleBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_avgSplitFailuresPerCycleTimeSeries'],
    ],
    queueLengthMedianVehicleNumber: [
      [ABACUS_TAB_DIAGNOSTIC, 'queueLengthMedianVehicleNumberBySignalBarChart'], // TODO2: Box and whiskers plot
      [ABACUS_TAB_TIME_SERIES,  'network_queueLengthMedianVehicleNumberTimeSeries'],
    ],
    programmedCycleTimeAvg: [
      [ABACUS_TAB_DIAGNOSTIC, 'programmedCycleTimeModeBySignalBarChart'],
    ],
    // avgGreenTimePerCycle: [
    //   [ABACUS_TAB_DIAGNOSTIC, 'avgGreenTimePerCycleBySignalBarChart'],
    //   [ABACUS_TAB_TIME_SERIES,  'network_avgGreenTimePerCycleTimeSeries'],
    // ],
    // Mobility
    controlDelayMean: [
      [ABACUS_TAB_DIAGNOSTIC, 'controlDelayMeanBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'route_controlDelayMeanTimeSeries'], //TODO2: (controlDelay15th, controlDelayMedian, controlDelay85th)
    ],
    travelTimeMedian: [
      [ABACUS_TAB_TIME_SERIES, 'route_travelTimeMedianTimeSeries'],
    ],
    stopCountMean: [
      [ABACUS_TAB_DIAGNOSTIC, 'stopCountMeanBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'route_stopCountMeanTimeSeries'],
    ],
    // Safety
    throughLeftRedLightRunRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'throughLeftRedLightRunRatioBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_throughLeftRedLightRunRatioTimeSeries'],
    ],
    dilemmaZoneRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'dilemmaZoneRatioBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_dilemmaZoneRatioTimeSeries'],
    ],
    deceleration10FpssRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'deceleration10FpssRatioBySignalBarChart'],
      [ABACUS_TAB_TIME_SERIES,  'network_deceleration10FpssRatioTimeSeries'],
    ],
    approachVelocityMphMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'approachVelocityMphMedianBySignalBarChart'], // TODO2: Box and whiskers plot
      [ABACUS_TAB_TIME_SERIES,  'network_approachVelocityMphMedianTimeSeries'],
    ],
    // Detection
    detectorHealthScore: [
      [ABACUS_TAB_DIAGNOSTIC,  <AbacusDetectorsTable />],
    ],
    // Environmental
    fuelGallonsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES,  'network_fuelGallonsVolumeWeightedTotalRaTimeSeries'],
    ],
    co2EquivalentGramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES,  'network_co2EquivalentGramsVolumeWeightedTotalRaTimeSeries'],
    ],
    no2GramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES, 'network_no2GramsVolumeWeightedTotalRaTimeSeries'],
    ],
  },
  signal: {
    // Operations
    flowLabsVolumeCountPerHour: [
      [ABACUS_TAB_DIAGNOSTIC, 'flowLabsVolumeCountPerHourByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_flowLabsVolumeCountPerHourTimeSeries'],
    ],
    controlDelayMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'controlDelayMedianByPhaseBarChart'], //TODO2: box-whiskers plot)
      [ABACUS_TAB_TIME_SERIES, 'signal_controlDelayMedianTimeSeries'],
    ],
    arrivalsOnGreenRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'arrivalsOnGreenRatioByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_arrivalsOnGreenRatioTimeSeries'],
    ],
    phaseSkipsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phaseSkipsPerCycleByPhaseBarChart'],
    ],
    platoonRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'platoonRatioByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_platoonRatioTimeSeries'],
    ],
    avgSplitFailuresPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'avgSplitFailuresPerCycleByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_avgSplitFailuresPerCycleTimeSeries'],
    ],
    queueLengthMedianVehicleNumber: [
      [ABACUS_TAB_DIAGNOSTIC, 'queueLengthMedianVehicleNumberByPhaseBarChart'], // TODO2: Box and whiskers plot
      [ABACUS_TAB_TIME_SERIES, 'signal_queueLengthMedianVehicleNumberTimeSeries'],
    ],
    avgForceOffsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'avgForceOffsPerCycleByPhaseBarChart'],
    ],
    avgGapOutsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'avgGapOutsPerCycleByPhaseBarChart'],
    ],
    programmedCycleTimeMode: [
      [ABACUS_TAB_DIAGNOSTIC, 'programmedSplitTimeModeByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_programmedCycleTimeModeTimeSeries'],
    ],
    // avgGreenTimePerCycle: [
    //   [ABACUS_TAB_DIAGNOSTIC, 'avgGreenTimePerCycleByPhaseBarChart'],
    //   [ABACUS_TAB_TIME_SERIES, 'signal_avgGreenTimePerCycleTimeSeries'],
    // ],
    // Safety
    throughLeftRedLightRunRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'throughLeftRedLightRunRatioByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_throughLeftRedLightRunRatioTimeSeries'],
    ],
    dilemmaZoneRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'dilemmaZoneRatioByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_dilemmaZoneRatioTimeSeries'],
    ],
    deceleration10FpssRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'deceleration10FpssRatioByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_deceleration10FpssRatioTimeSeries'],
    ],
    approachVelocityMphMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'approachVelocityMphMedianByPhaseBarChart'], // TODO2: Box and whiskers plot
      [ABACUS_TAB_TIME_SERIES, 'signal_approachVelocityMphMedianTimeSeries'],
    ],
    // Detection
    detectorHealthScore: [
      [ABACUS_TAB_DIAGNOSTIC, <AbacusDetectorsTable />],
    ],
    // Environmental
    controlFuelGallonsVolumeWeightedTotalRa: [
      [ABACUS_TAB_DIAGNOSTIC, 'controlFuelGallonsVolumeWeightedTotalRaByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_controlFuelGallonsVolumeWeightedTotalRaTimeSeries'],
    ],
    controlCo2EquivalentGramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_DIAGNOSTIC,  'controlCo2EquivalentGramsVolumeWeightedTotalRaByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_controlCo2EquivalentGramsVolumeWeightedTotalRaTimeSeries'],
    ],
    controlNo2GramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_DIAGNOSTIC, 'controlNo2GramsVolumeWeightedTotalRaByPhaseBarChart'],
      [ABACUS_TAB_TIME_SERIES, 'signal_controlNo2GramsVolumeWeightedTotalRaTimeSeries'],
    ],
  },
  phase: {
    // Operations
    flowLabsVolumeCountPerHour: [
      // [ABACUS_TAB_TIME_OF_DAY, null], // TODO2: Plot of hourly volume at different times of the day
      [ABACUS_TAB_TIME_SERIES, 'phase_flowLabsVolumeCountPerHourTimeSeries'],
    ],
    controlDelayMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_controlDelayHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_controlDelayMedianTimeSeries'],
    ],
    arrivalsOnGreenRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_arrivalModulusCycleLengthHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_arrivalsOnGreenRatioTimeSeries'],
    ],
    phaseSkipsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_greenTimeHistograms'],
      [ABACUS_TAB_TIME_SERIES, 'phase_phaseSkipsPerCycleTimeSeries'],
    ],
    platoonRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_arrivalModulusCycleLengthHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_platoonRatioTimeSeries'],
    ],
    avgSplitFailuresPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_greenTimeHistograms'],
      [ABACUS_TAB_TIME_SERIES, 'phase_avgSplitFailuresPerCycleTimeSeries'],
    ],
    queueLengthMedianVehicleNumber: [
      [ABACUS_TAB_TIME_SERIES, 'phase_queueLengthMedianVehicleNumberTimeSeries'],
    ],
    avgForceOffsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_greenTimeHistograms'],
      [ABACUS_TAB_TIME_SERIES, 'phase_avgForceOffsPerCycleTimeSeries'],
    ],
    avgGapOutsPerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_greenTimeHistograms'],
      [ABACUS_TAB_TIME_SERIES, 'phase_avgGapOutsPerCycleTimeSeries'],
    ],
    programmedSplitTimeMode: [
      // [ABACUS_TAB_TIME_SERIES, 'phase_programmedSplitTimeModeTimeSeries'],
    ],
    avgGreenTimePerCycle: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_greenTimeHistograms'],
      [ABACUS_TAB_TIME_SERIES, 'phase_avgGreenTimePerCycleTimeSeries'],
    ],
    // Safety
    throughLeftRedLightRunRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_RLRHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_throughLeftRedLightRunRatioTimeSeries'],
    ],
    dilemmaZoneRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_RLRHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_dilemmaZoneRatioTimeSeries'],
    ],
    deceleration10FpssRatio: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_aggressiveBrakingHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_deceleration10FpssRatioTimeSeries'],
    ],
    approachVelocityMphMedian: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_approachSpeedHistogram'],
      [ABACUS_TAB_TIME_SERIES, 'phase_approachVelocityMphMedianTimeSeries'],
    ],
    aggressiveBrakingCause: [
      [ABACUS_TAB_DIAGNOSTIC, 'phase_aggressiveBrakingCauseBarChart'],
    ],
    // Detection
    detectorHealthScore: [
      [ABACUS_TAB_DIAGNOSTIC, <AbacusDetectorsTable />],
      [ABACUS_TAB_TIME_SERIES, 'phase_detectorHealthScoreTimeSeries'], // TODO2: one line for each detector of detector health (score) for each phase of the signal per day for 28 days
    ],
    // Environmental
    controlFuelGallonsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES, 'phase_controlFuelGallonsVolumeWeightedTotalRaTimeSeries'],
    ],
    controlCo2EquivalentGramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES, 'phase_controlCo2EquivalentGramsVolumeWeightedTotalRaTimeSeries'],
    ],
    controlNo2GramsVolumeWeightedTotalRa: [
      [ABACUS_TAB_TIME_SERIES, 'phase_controlNo2GramsVolumeWeightedTotalRaTimeSeries'],
    ],
  },
};
