import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { ABACUS_METRIC_TO_TABS_BY_LEVEL } from '../../displayConfigs/abacusTabsDefinitions';
import useAppController from '../../hooks/useAppController';
import Chart from '../shared/elements/Chart';

const getLabel = (tabConfig) => tabConfig?.[0];
const getElement = (tabConfig) => {
  const configValue = tabConfig?.[1];
  if (typeof configValue === 'string') {
    return <Chart key="theChart" currChart={ configValue } />;
  }

  return configValue || null;
}

const AbacusTabsContainer = ({ level }) => {
  const { params } = useAppController();
  const { abacusMetric } = params || {};

  // Needed for MUI Tabs indicator bar animation
  const [currTabValue, setCurrTabValue] = useState(0);

  // Render first tab and corresponding component
  useEffect(() => {
    setCurrTabValue(0);
  }, [level, abacusMetric]);

  const currentTabConfig = ABACUS_METRIC_TO_TABS_BY_LEVEL?.[level]?.[abacusMetric]?.[currTabValue];

  if (!currentTabConfig) {
    return null;
  }

  const currEl = getElement(currentTabConfig);

  return (
    <div className="flex-grow-1 flex flex-column w-100% bg-black" >
      <div className="flex-shrink-0">
        <div className="flex max-w-100% overflow-auto thin-scrollbars">
          {
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              value={currTabValue}
            >
              {
                // For each level's abacusMetric, each tab is listed as an array of the form [tabLabel, componentEl]
                ABACUS_METRIC_TO_TABS_BY_LEVEL[level][abacusMetric]
                  .map((tabConfig, index) => (
                      <Tab
                        key={index}
                        className={'pa-6 fs-5'}
                        wrapped
                        label={getLabel(tabConfig)}
                        onClick={() => setCurrTabValue(index)}
                      />
                    )
                  )
              }
            </Tabs>
          }
        </div>
      </div>
      { currEl }
    </div>
  );
};

export default observer(AbacusTabsContainer);
