import React from 'react';
import IconWrapper from './IconWrapper';

const Triangle = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="2 2 12 12">
    <path d="M4.03086 10.3351C4.13501 10.4451 4.25847 10.5 4.40112 10.5L11.7732 10.5C11.9159 10.5 12.0393 10.4451 12.1435 10.3351C12.2477 10.225 12.2998 10.0948 12.2998 9.94438C12.2998 9.79395 12.2477 9.66379 12.1435 9.5538L8.45745 5.66503C8.3531 5.55506 8.22973 5.5 8.08714 5.5C7.94454 5.5 7.82106 5.55506 7.71691 5.66503L4.03086 9.55382C3.92674 9.66379 3.87447 9.79395 3.87447 9.94441C3.87447 10.0948 3.92674 10.225 4.03086 10.3351Z" />
  </IconWrapper>
);

export default Triangle;
