import React, { memo, useRef, useState } from 'react';
import { ComposedChart, Area, XAxis, YAxis, Line, Tooltip, ResponsiveContainer, Legend } from 'recharts';

import AlertTriangle from './shared/icons/AlertTriangle';
import Arrow from './shared/icons/Arrow';
import ArrowHead from './shared/icons/ArrowHead';
import Checkmark from './shared/icons/Checkmark';
import Close from './shared/icons/Close';
import Delete from './shared/icons/Delete';
import Delta from './shared/icons/Delta';
import Dot from './shared/icons/Dot';
import DoubleArrowHead from './shared/icons/DoubleArrowHead';
import Draggable from './shared/icons/Draggable';
import Expand from './shared/icons/Expand';
import Export from './shared/icons/Export';
import GearThick from './shared/icons/GearThick';
import GearThin from './shared/icons/GearThin';
import LightningBolt from './shared/icons/LightningBolt';
import Minus from './shared/icons/Minus';
import Plus from './shared/icons/Plus';
import Reject from './shared/icons/Reject';
import Triangle from './shared/icons/Triangle';
import TripleDot from './shared/icons/TripleDot';
import MagnifyingGlass from './shared/icons/MagnifyingGlass';

import TestPageChartContainer from './TestPageChartContainer';

import { colors } from './FlowMaterialTheme';




const volumes = [
  ...Array(14).fill(0).map(() => 5000 + Math.floor(Math.random() * 1000)),
  ...Array(14).fill(0).map(() => 6000 + Math.floor(Math.random() * 6000)),
];

const phaseHealthScores = [
  ...Array(14).fill(0).map(() => 50 + Math.floor(Math.random() * 51)),
  ...Array(14).fill(0).map(() => 20 + Math.floor(Math.random() * 81)),
];

const detectorHealthScores = [
  ...Array(28).fill(0).map(() => Math.floor(Math.random() * 31)),
];

const cycleLengths = [
  ...Array(28).fill(140),
];

const dates = [
  '31 May',
  '1 Jun',
  '2 Jun',
  '3 Jun',
  '4 Jun',
  '5 Jun',
  '6 Jun',
  '7 Jun',
  '8 Jun',
  '9 Jun',
  '10 Jun',
  '11 Jun',
  '12 Jun',
  '13 Jun',
  '14 Jun',
  '15 Jun',
  '16 Jun',
  '17 Jun',
  '18 Jun',
  '19 Jun',
  '20 Jun',
  '21 Jun',
  '22 Jun',
  '23 Jun',
  '24 Jun',
  '25 Jun',
  '26 Jun',
  '27 Jun',
];

const graphData = volumes.map((x, index) => ({
  date: dates[index],
  volume: volumes[index],
  phaseHealthScore: phaseHealthScores[index],
  detectorHealthScore: detectorHealthScores[index],
  cycleLength: cycleLengths[index],
}));

const graphConfig = {
  date: {},
  volume: {
    type: 'area',
    opacity: '20',
  },
  phaseHealthScore: {},
  detectorHealthScore: {},
  cycleLength: {},
}

// console.log('graphData', graphData);

const TooltipCursor = ({ points }) => (
  <>
    <g filter="url(#filter0_bd)" transform={ `translate(${points[0].x - 92} 10)` }>
      <path d="M72 28C72 16.9543 80.9543 8 92 8V8C103.046 8 112 16.9543 112 28V246C112 247.105 111.105 248 110 248H74C72.8954 248 72 247.105 72 246V28Z" fill="url(#paint0_linear)"/>
    </g>
    <defs>
      <filter id="filter0_bd" x="-28" y="-92" width="240" height="476" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="50"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="64"/>
        <feGaussianBlur stdDeviation="36"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.825 0 0 0 0 0.8635 0 0 0 0 1 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur" result="effect2_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear" x1="6.62776" y1="228.471" x2="93.063" y2="236.373" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0038FF" stopOpacity="0.15"/>
        <stop offset="1" stopColor="#0038FF" stopOpacity="0.01"/>
      </linearGradient>
    </defs>
  </>
);

const TooltipContent = (props) => {
  // console.log('tooltip props', props);
  const { payload } = props;
  const date = payload?.[0]?.payload?.date;

  if (!date) {
    return null;
  }

  const [day, month] = date.split(' ');

  return (
    <div
      className="bg-black pa-8 bo-radius-3 ma-9 gray3"
      style={{ transform: 'translateY(-50%)' }}
    >
      <div className="pa-4">{ month } { day }</div>
      {
        payload.slice().reverse().map(({ color, name, value }) => (
          <div className="pa-4 flex items-baseline">
            <div className="mr-4">
              <div className="bo-radius-circle boa-2 bo-black w-2 h-2" style={{ backgroundColor: color }} />
            </div>
            <div className="flex-grow-1">{ name }</div>
            <div className="ml-8 white">{ value }</div>
          </div>
        ))
      }
    </div>
  );
};

const CustomLegend = (props) => {
  // console.log('legend props', props);
  const { payload } = props;
  return (
    <div className="flex">
      {
        payload.slice().reverse().map(({ dataKey, value, color }) => {
          const { opacity, type } = graphConfig[dataKey];

          return (
            <div className="flex items-center">
              <div
                className={ `w-3 mr-4 ${ type === 'area' ? 'h-1' : 'boa-1 bo-transparent' } ${ opacity ? ('o-' + opacity) : '' }` }
                style={{ backgroundColor: color }}
              />
              <div className="mr-8">{ value }</div>
            </div>
          );
        })
      }
    </div>
  );
};

const DateTick = ({ x, y, payload, previousMonth }) => {
  const [day, month] = payload.value.split(' ');

  const displayMonth = (month !== previousMonth.current)
    ? (
      <text x={x} y={y + 30} textAnchor="middle" alignmentBaseline="central" className="fill-gray3" fontSize="11">
        { month }
      </text>
    )
    : null;

  previousMonth.current = month;

  return (
    <>
      <text x={x} y={y + 10} textAnchor="middle" alignmentBaseline="central" className="fill-gray3" fontSize="11">
        { day }
      </text>
      { displayMonth }
    </>
  );

};

const ScoreDot = ({ dotFillColor = 'white', dotStrokeColor = 'black', cx, cy, textColor = 'black', value, dataKey, payload }) => {
  const displayValue = (dataKey === 'volume' ? (Math.round(parseInt(payload[dataKey], 10) / 1000) + 'k') : value) + '';
  const fontSize = displayValue.length === 1 ? 12 : displayValue.length === 2 ? 9 : 7;
  return (
    <>
      <circle
        cx={ cx }
        cy={ cy }
        r="9"
        strokeWidth="2"
        className={ `drop-shadow-gray2 stroke-${ dotStrokeColor } fill-${ dotFillColor }` }
      />
      <text
        fontSize={ fontSize }
        x={ cx }
        y={ cy }
        textAnchor="middle"
        alignmentBaseline="central"
        className={ `fw-2 fill-${ textColor }` }
      >
        { displayValue }
      </text>
    </>
  );
};

const chartPadding = 12;

const TestPage = () => {
  const previousMonth = useRef(null);
  const [colorIndex, setColorIndex] = useState(0);
  const iconColors = ['green', 'blue', 'red', 'pink', 'gray'];

  const color = iconColors[colorIndex];
  const size = '4';

  return (
    <div className="flex flex-column h-100% overflow-auto">
      <div className="flex flex-row justify-around pa-7 pb-0" onClick={ () => setColorIndex((colorIndex === iconColors.length - 1) ? 0 : colorIndex + 1) }>

        <AlertTriangle width={ size } height={ size } color={ color } />
        <Arrow width={ size } height={ size } color={ color } />
        <ArrowHead width={ size } height={ size } color={ color } />
        <Checkmark width={ size } height={ size } color={ color } />
        <Close width={ size } height={ size } color={ color } />
        <Delete width={ size } height={ size } color={ color } />
        <Delta width={ size } height={ size } color={ color } />
        <Dot width={ size } height={ size } color={ color } />
        <DoubleArrowHead width={ size } height={ size } color={ color } />
        <Draggable width={ size } height={ size } color={ color } />
        <Expand width={ size } height={ size } color={ color } />
        <Export width={ size } height={ size } color={ color } />
        <GearThin width={ size } height={ size } color={ color } />
        <GearThick width={ size } height={ size } color={ color } />
        <LightningBolt width={ size } height={ size } color={ color } />
        <MagnifyingGlass width={ size } height={ size } color={ color } />
        <Minus width={ size } height={ size } color={ color } />
        <Plus width={ size } height={ size } color={ color } />
        <Reject width={ size } height={ size } color={ color } />
        <Triangle width={ size } height={ size } color={ color } />
        <TripleDot width={ size } height={ size } color={ color } />

      </div>
      <div className="flex justify-around pa-7 pb-0">

        <div className="flex items-center">
          <span><Delta width="4" height="4" color={ color } /></span>
          <span className={ `fs-3 ${ color }` }>55</span>
        </div>
        <div className="flex items-center">
          <span><Delta width="3" height="3" color={ color } /></span>
          <span className={ `fs-4 ${ color }` }>55</span>
        </div>
        <div className={ `bo-radius-circle bg-${ color } hover-bg-${ color }3 pointer w-4 h-4 flex items-center justify-around ph-2` }>
          <Checkmark className="flex items-center justify-around" color="gray6" width="4" height="4" />
        </div>
        <div className={ `bo-radius-circle bg-${ color } w-4 h-4 flex items-center justify-around ph-2` }>
          <Reject className="flex items-center justify-around" color="gray6" width="4" height="4" />
        </div>
        <div className={ `bo-radius-circle bg-${ color } w-4 h-4 flex items-center justify-around ph-2` }>
          <GearThick className="flex items-center justify-around" color="gray6" width="4" height="4" />
        </div>
        <div className={ `bo-radius-circle bg-${ color } w-3 h-3 flex items-center justify-around ph-1` }>
          <Checkmark className="flex items-center justify-around" color="gray6" width="3" height="3" />
        </div>
        <div className={ `bo-radius-circle bg-${ color } w-3 h-3 flex items-center justify-around ph-1` }>
          <Reject className="flex items-center justify-around" color="gray6" width="3" height="3" />
        </div>
        <div className={ `boa-1 bo-red bo-radius-2 bg-red3 w-${ size } h-${ size } flex items-center justify-around ph-2` }>
          <Draggable className="flex items-center justify-around" color="red" width="4" height="4" />
        </div>
        <div className={ `bo-radius-2 bg-green w-${ size } h-${ size } flex items-center justify-around ph-2` }>
          <Draggable className="flex items-center justify-around" color="gray6" width="4" height="4" />
        </div>
        <div className={ `w-6 h-4 flex justify-around relative` }>
          <AlertTriangle width="4" height="4" color={ color } />
          <div className={ `absolute mh-auto w-auto top-0 bottom-0 pt-2 bor-1 bo-transparent fw-3 ${ color }` }>!</div>
        </div>
        <div className={ `bo-radius-circle bg-${ color } w-3 h-3 flex items-center justify-around ph-1 gray6` }>
          ?
        </div>

      </div>
      <div className="relative flex-shrink-0" style={{ height: '330px' }}>
        <div className="absolute pa-7 top-0 left-0 right-0" style={{ height: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={graphData}
              margin={{
                top: 34,
                right: 0,
                left: 0,
                bottom: 110,
              }}
            >
              <XAxis
                tickLine={false}
                axisLine={false}
                dataKey="date"
                padding={{ left: chartPadding, right: chartPadding }}
                interval={1}
                tick={ <DateTick previousMonth={previousMonth} /> }
              />
              <YAxis
                hide={true}
                yAxisId="cycleLength"
                allowDataOverflow={true}
                padding={{ top: chartPadding, bottom: chartPadding }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={{ fill: colors.gray3, fontSize: 12 }}
                tickFormatter={val => (Math.round(val) / 1000) + 'k'}
                tickMargin={5}
                orientation="right"
                yAxisId="volume"
                padding={{ top: 3 * chartPadding }}
                label={ ({ viewBox }) => <text fill={colors.gray3} x={ viewBox.x + 10 } y="40" className="fs-6">Vol</text> }
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tick={{ fill: colors.gray3, fontSize: 12 }}
                domain={ [0, 100] }
                yAxisId="score"
                allowDataOverflow={true}
                padding={{ top: 3 * chartPadding, bottom: chartPadding }}
                label={ ({ viewBox }) => <text fill={colors.gray3} x={ viewBox.x + 16 } y="40" className="fs-6">Score</text> }
              />
              <Legend wrapperStyle={{ bottom: '60px', right: '32px', width: 'auto' }} align="right" content={ <CustomLegend /> } />
              <Tooltip cursor={ <TooltipCursor /> } content={ <TooltipContent /> } position={{ y: 0 }} allowEscapeViewBox={ true } isAnimationActive={ false } />
              <Area type="linear" dataKey="volume" name="Volume" stroke="none" fill={ colors.orchid } fillOpacity={1} className="o-20" yAxisId="volume" activeDot={<ScoreDot dotFillColor="orchid" textColor="white" />} />
              <Line type="linear" dataKey="cycleLength" name="Cycle Length" dot={false} activeDot={<ScoreDot dotFillColor="yellow" textColor="white" />} strokeWidth={2} stroke={ colors.yellow } yAxisId="cycleLength" />
              <Line type="linear" dataKey="phaseHealthScore" name="Phase Health Score" dot={<ScoreDot dotFillColor="gray6" />} strokeWidth={2} stroke={ colors.gray4 } activeDot={false} yAxisId="score" />
              <Line type="linear" dataKey="detectorHealthScore" name="Detector Health Score" dot={<ScoreDot dotFillColor="gray6" />} strokeWidth={2} stroke={ colors.green6 } activeDot={false} yAxisId="score" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="w-100% bg-black flex-shrink-0" style={{height: '900px',marginTop: '4.5rem', padding: '16px'}}>
        <TestPageChartContainer />
      </div>
    </div>
  );
};

export default memo(TestPage);
