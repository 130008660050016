/*
const sideMenuWidth = useMediaQueryWidth([576, 564, 552, 540, 528]);

is the same as:

const columnCount = useMediaQuery(
  // Media queries
  ['(min-width: 120rem)', '(min-width: 110rem)', '(min-width: 100rem)', '(min-width: 90rem)'],
  // Column counts (relates to above media queries by array index)
  [576, 564, 552, 540],
  // Default column count
  528, // at min-width 80rem
);
 */

import useMediaQuery from './useMediaQuery';

const useMediaQueryWidth = (values) => {
  const queries = [];
  let minWidth = 80 + (values.length - 1) * 10;
  while (minWidth > 80) {
    queries.push(`(min-width: ${ minWidth }rem)`);
    minWidth -= 10;
  }
  return useMediaQuery(queries, values.slice(0, values.length - 2), values[values.length - 1]);
};

export default useMediaQueryWidth;
