import React, { memo, useLayoutEffect, useState } from 'react';
import graphqlQuery, { login } from '../helpers/graphqlQuery';
import gql from 'graphql-tag';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import useAppController from '../hooks/useAppController';
import LogoText from './shared/icons/LogoText';
import LogoRings from './shared/icons/multicolor/LogoRings';

const loginMutation = gql`
  mutation ($email: String!, $password: String!) {
    public {
      loginAccount(input: { email: $email, password: $password }) {
        token
        errors {
          message
        }
      }
    }
  }
`;

const attemptLogin = async (appController, email, password, setError) => {
  const variables = { email, password };

  setError('');

  const { data, error } = await graphqlQuery(loginMutation, variables, { skipAuthCheck: true });

  const errors = error ? [error] : data?.public?.loginAccount?.errors;
  const token = data?.public?.loginAccount?.token;

  if (errors?.length) {
    setError(errors[0].message);
  } else if (!token) {
    setError('An error occurred during login, please try again later.');
  } else {
    appController.setIsHeaderEnabled(true);
    login(token);
  }
};

const LoginPage = () => {
  const appController = useAppController();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submitForm = async e => {
    e.preventDefault();
    await attemptLogin(appController, email, password, setError);
  };

  useLayoutEffect(() => {
    if (appController.isHeaderEnabled) {
      appController.setIsHeaderEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appController.isHeaderEnabled]);

  return (
    <div className="w-100% h-100% flex items-center">
      <div className="mh-auto flex flex-column items-center w-12">
        <div className="pv-6 ph-10 flex flex-column items-center">
          <LogoRings className="mb-8" style={{ width: '58px', height: '77px' }} />
          <LogoText />
        </div>
        <div className="pa-7 gray1 fs-3 semibold uppercase">Login</div>
        <div className="pa-7 w-12">
          <form onSubmit={submitForm}>
            <div className="mb-8">
              <TextField
                type="text"
                className="w-100%"
                size="large"
                value={email}
                onChange={e => setEmail(e.target.value)}
                autoComplete="username"
                placeholder="Email"
              />
            </div>
            <div className="flex">
              <TextField
                type="password"
                className="w-100%"
                size="large"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
                placeholder="Password"
              />
            </div>

            <Button className="flex mh-auto mt-11 mb-9" size="large" variant="contained" type="submit">Login</Button>

            <div className="red h-5 tc">{ error }</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default memo(observer(LoginPage));
