import React, { useEffect, memo } from 'react';
import { observer } from 'mobx-react-lite';
import { Inlet } from 'react-conduit';

import useAppController from '../../hooks/useAppController';
import MapContainerAndTileManager from './MapContainerAndTileManager';
import MapBoundsManagerAndSideContainer from './MapBoundsManagerAndSideContainer';
import MapBreadcrumbs from './MapBreadcrumbs';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import TodSelector from '../header/TodSelector';

const MapPage = () => {
  const { params, mapController } = useAppController();
  const { regionData } = mapController;
  const goToMaintain = useGoToMaintain('map');

  const network = params.focus !== 'system'
    ? regionData.data?.user.region.networks.find(network => network.id === params.networkId)
    : null;

  useEffect(() => {
    if (!params?.focus) {
      goToMaintain({
        focus: 'system',
      }, true);
    } else if (params.focus !== 'system' && network?.tods?.[1]?.id) {
      const missingAggregationPeriodDays = params.aggregationPeriodDays ? null : { aggregationPeriodDays: 28 };
      const missingTodId = (params.todId && network.tods.find(tod => tod.id === params.todId)) ? null : { todId: network.tods[1].id };

      if (missingAggregationPeriodDays || missingTodId) {
        goToMaintain({
          ...(missingAggregationPeriodDays || {}),
          ...(missingTodId || {}),
        }, true);
      }
    }
  }, [goToMaintain, params, network?.tods]);

  return (
    <div className="flex w-100% h-100% relative overflow-hidden">
      <div className="absolute fill">
        <div className="relative w-100% h-100% overflow-hidden">
          <MapContainerAndTileManager />
          <MapBreadcrumbs />
          <MapBoundsManagerAndSideContainer />
        </div>
      </div>

      <Inlet label="header-dropdown">
        <TodSelector />
      </Inlet>
    </div>
  );
};

export default memo(observer(MapPage));
