// score thresholds must be descending
// example, if thresholds are [75, 25], the logic is:
// if score is not a number (e.g. null), then valuesForThresholds[0] is used
// if score is above 75, then valuesForThresholds[1] is used
// if score is between 75 and 25, valuesForThresholds[2] is used
// if score is below 25, valuesForThresholds[3] is used
// so valuesForThresholds might look something like ['gray', 'green', 'yellow', red']

const colorContexts = {
  phaseHealthColor: {
    thresholds: [60],
    valuesForThresholds: ['gray4', 'gray2', 'red'],
  },
  networkHealthColor: {
    thresholds: [60],
    valuesForThresholds: ['gray4', 'gray2', 'red'],
  },
  networkHealthOpacity: {
    thresholds: [60],
    valuesForThresholds: [.4, .4, 1],
  },
};

const getScoreValue = (score, contextKey) => {
  if (!colorContexts.hasOwnProperty(contextKey)) {
    throw new Error(`No such score contextKey for getScoreColor: "${ contextKey }"`);
  }

  const { valuesForThresholds, thresholds, getValue } = colorContexts[contextKey];

  if (typeof getValue === 'function') {
    return getValue(score);
  }

  if (!Array.isArray(valuesForThresholds)) {
    return valuesForThresholds;
  }

  // score is loading or unavailable
  if (typeof score !== 'number') {
    return valuesForThresholds[0];
  }

  const thresholdIndex = thresholds.findIndex(threshold => (score > threshold));

  if (thresholdIndex === -1) {
    return valuesForThresholds[valuesForThresholds.length - 1];
  }

  return valuesForThresholds[thresholdIndex + 1];
};

export default getScoreValue;
