import { parse } from 'qs';
import { useLocation, useParams } from 'react-router-dom';

const useAllParams = () => {
  const location = useLocation();
  const routeParams = useParams();
  const queryParams = parse(location.search.substring(1));

  return {
    ...queryParams,
    ...routeParams, // route params take precedence
  };
};

export default useAllParams;
