import { useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '@mui/material';
import flatten from 'lodash/flatten';

import useAppController from '../../hooks/useAppController';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import columnDefinitions from '../../displayConfigs/columnDefinitions';
import { ABACUS_METRIC_TO_TABS_BY_LEVEL } from '../../displayConfigs/abacusTabsDefinitions';

import AbacusTabsContainer from './AbacusTabsContainer';


const BUTTON_WITH_UNAVAILABLE_VALUE_LABEL = '--';
const BUTTON_ROUTE_ID_PREFIX = '_routeId';
const BUTTON_ROUTE_INDEX_PREFIX = '_index';

const networkButtonConfigs = [
  {
    label: 'Network Operations',
    buttonRows: [
      ['flowLabsVolumeCountPerHour', 'programmedCycleTimeAvg', 'controlDelayMedian', 'queueLengthMedianVehicleNumber',],
      ['arrivalsOnGreenRatio', 'platoonRatio', 'avgSplitFailuresPerCycle',],
    ],
  },
  {
    label: 'Mobility',
    buttonRows: [
      ['controlDelayMean', 'travelTimeMedian', 'stopCountMean'],
    ],
  },
  {
    label: 'Safety',
    buttonRows: [
      ['throughLeftRedLightRunRatio', 'dilemmaZoneRatio', 'deceleration10FpssRatio', 'approachVelocityMphMedian'],
    ],
  },
  {
    label: 'Detection',
    buttonRows: [
      ['detectorHealthScore'],
    ],
    skipFormatButtons: new Set([
      'detectorHealthScore',
    ]),
  },
  {
    label: 'Environmental Impact',
    buttonRows: [
      ['fuelGallonsVolumeWeightedTotalRa', 'co2EquivalentGramsVolumeWeightedTotalRa', 'no2GramsVolumeWeightedTotalRa'],
    ],
  },
];


const AbacusNetwork = () => {
  const goToMaintain = useGoToMaintain('map');
  const { params, abacusController, mapController } = useAppController();

  const {
    regionData,
  } = mapController;

  const {
    networkTodMetricsData,

    getNetworkTodMetrics,
  } = abacusController;

  const { regionId, networkId, routeId, abacusMetric, todId, aggregationPeriodDays } = params || {};

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  useEffect(() => {
    if (networkId && todId && aggregationPeriodDays) {
      networkTodMetricsData.fetch({ networkId, todId, aggregationPeriodDays: Number(aggregationPeriodDays) });
    }
  }, [networkTodMetricsData, networkId, todId, aggregationPeriodDays]);

  // When switching networks, change to a routeId available to the new network,
  // If none exist, change to a non-Mobility abacusMetric.
  useEffect(() => {
    if (!networkTodMetricsData.data?.user?.network?.routes?.[0]?.id) {
      return;
    }
    // Assumes first two network.routes are the Main Routes
    const availableRoutes = Object.fromEntries(networkTodMetricsData.data.user.network.routes.slice(0, 2).map(routeData => ([routeData.id, routeData])));
    const isRouteIdMissing = !routeId || !availableRoutes?.hasOwnProperty(routeId);
    const isAbacusMetricMissing = !abacusMetric;

    if (isRouteIdMissing || isAbacusMetricMissing) {
      const maybeSetRouteId = isRouteIdMissing ? {
        routeId: Object.keys(availableRoutes)[0], // select first available routeId by default
      } : {};
      const maybeSetAbacusMetric = isAbacusMetricMissing ? {
        abacusMetric: networkButtonConfigs[0].buttonRows[0][0], // select first button by default
      } : {};

      goToMaintain({
        ...maybeSetRouteId,
        ...maybeSetAbacusMetric,
      }, true);
    }
  }, [routeId, networkTodMetricsData.data, abacusMetric, goToMaintain]);


  if (!regionData.data || !networkTodMetricsData.data || !networkId) {
    if ([networkTodMetricsData,
      regionData,].some(dataFetcher => dataFetcher.isLoading)
    ) {
      return <div className="flex-grow-1 w-100% flex justify-center items-center">Loading...</div>;
    } else {
      return null;
    }
  }

  const network = regionData.data.user.region.networks.find(network => network.id === networkId);
  const networkData = getNetworkTodMetrics(todId);
  const latestNetworkMetrics = networkData?.latestMetrics;
  // Assumes first two network.routes are the Main Routes
  const availableRoutes = Object.fromEntries(networkTodMetricsData.data.user.network.routes.slice(0, 2).map(routeData => ([routeData.id, routeData])));

  const buttonConfigs = networkButtonConfigs;

  return (
    <div className="flex-grow-1 flex flex-column">
      <div className="flex-grow-0 pa-7">

        <table className="w-100%" cellSpacing="4">
          <tbody>
          {
            buttonConfigs.map(({ label, buttonRows, blankLabelButtons, skipFormatButtons }) => {

              // Mobility Buttons Only: For each route, set up a set of Mobility metrics buttons
              if (label === 'Mobility') {
                buttonRows = flatten(Object.keys(availableRoutes).map(
                  (routeId, routeIndex) => buttonRows.map(
                    buttonRow => buttonRow.map(
                      buttonKey => `${buttonKey}${BUTTON_ROUTE_ID_PREFIX}${routeId}${BUTTON_ROUTE_INDEX_PREFIX}${routeIndex}`))));
              }

              return buttonRows.map(
                ((buttonRow, index) => (
                  <tr key={index}>
                    <td className="ph-3 pv-4 medium" valign="top" width="13%">{ (index === 0) ? label : '' }</td>
                    {
                      buttonRow.map(buttonKey => {

                          // Mobility Buttons Only: Extract routeId from Mobility metrics buttons
                          let btnRouteId, btnRouteIndex, btnRouteIdAndIndex;
                          if (buttonKey.includes(BUTTON_ROUTE_ID_PREFIX)) {
                            [buttonKey, btnRouteIdAndIndex] = buttonKey.split(BUTTON_ROUTE_ID_PREFIX);
                            [btnRouteId, btnRouteIndex] = btnRouteIdAndIndex.split(BUTTON_ROUTE_INDEX_PREFIX);
                          }
                          const isRouteButton = btnRouteId !== null && btnRouteId !== undefined;

                          let { buttonLabel, label, subLabel, format, getValue } = columnDefinitions[buttonKey] || { label: buttonKey };

                          const latestMetrics = isRouteButton ? availableRoutes?.[btnRouteId]?.latestMetrics : latestNetworkMetrics;

                          const value = latestMetrics && (latestMetrics?.[buttonKey] !== null && latestMetrics?.[buttonKey] !== undefined)
                            ? (
                              getValue ? getValue(latestMetrics) : latestMetrics[buttonKey]
                            )
                            : (!blankLabelButtons || (blankLabelButtons && !blankLabelButtons.has(buttonKey)))
                              ? BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                              : null;

                          const formattedValue = value !== BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                          && ((typeof format === 'function' && !skipFormatButtons)
                            || (typeof format === 'function' && (skipFormatButtons && !skipFormatButtons.has(buttonKey))))
                            ? format(value)
                            : value;

                          if (!buttonLabel) {
                            buttonLabel = label;
                          }

                          // Mobility Buttons Only: Add direction to button label
                          if (btnRouteId) {
                            const routeDirection = networkTodMetricsData?.data?.user?.network?.routes?.[btnRouteIndex]?.direction;
                            buttonLabel = routeDirection ? `${routeDirection} ${buttonLabel}` : buttonLabel;
                          }

                          const isInactive = value === BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                            || ABACUS_METRIC_TO_TABS_BY_LEVEL.network[buttonKey].length === 0;

                          let className = 'bo-radius-2 pv-4 ph-7 user-select-none flex ';

                          if (isInactive) {
                            className += 'bg-gray6';
                          } else if (
                            (buttonKey === abacusMetric && !btnRouteId)
                            || (buttonKey === abacusMetric && (btnRouteId && routeId === btnRouteId))
                          ) {
                            className += 'bg-blue';
                          } else {
                            className += 'bg-gray6 hover-bg-gray5 pointer';
                          }

                          return (
                            <td className="ph-3" valign="top" width="20%" key={buttonKey}>
                              <Tooltip key={ btnRouteId ? index + buttonKey + btnRouteId : index + buttonKey } PopperProps={{ style: { pointerEvents: 'none' }}} title={ <span className="white semibold">{ label }&nbsp;<span className="fs-8 lightgray4 normal">{ subLabel }</span></span> } enterDelay={ 500 } arrow>
                                <div className={ className } onClick={isInactive ? null
                                  : btnRouteId ? () => goToMaintain({ abacusMetric: buttonKey, routeId: btnRouteId }, true)
                                    : () => goToMaintain({ abacusMetric: buttonKey }, true) }>
                                  <div className="gray1 truncate-ellipsis flex items-center">{ buttonLabel }</div>
                                  {
                                    (!blankLabelButtons || (blankLabelButtons && !blankLabelButtons.has(buttonKey)))
                                      ? <div className="flex-shrink-0 ml-auto pl-5 tr semibold white flex items-center">{ formattedValue }</div>
                                      : null
                                  }
                                </div>
                              </Tooltip>
                            </td>
                          );
                        }
                      )
                    }
                    {
                      new Array(4 - buttonRow.length).fill().map((_, i) => <td key={i} width="20%" />)
                    }
                  </tr>
                ))
              )}
            )
          }
          </tbody>
        </table>
      </div>
      <div className="flex-grow-1 pa-7 w-100% flex flex-column">
        <AbacusTabsContainer level={ "network" }/>
      </div>
    </div>
  );
};

export default observer(AbacusNetwork);
