import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory, Redirect,
} from 'react-router-dom';
import { ConduitProvider } from 'react-conduit';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import { observer } from 'mobx-react-lite';
import 'mapbox-gl/dist/mapbox-gl.css';

import { config } from './helpers/graphqlQuery';
import FlowTheme from './components/FlowMaterialTheme';
import Layout from './components/Layout';
import LoginPage from './components/LoginPage';
import TestPage from './components/TestPage';
import paths, { getPathWithVariables } from './paths';
import MapPage from './components/map/MapPage';
import useUpdateAppControllerParams from './hooks/useUpdateAppControllerParams';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const WithParams = ({ children }) => {
  useUpdateAppControllerParams();
  return children;
};

const RoutedApp = observer(() => {
  const history = useHistory();

  useEffect(() => {
    if (history) {
      // as soon as history is ready, update the redirect function (used 'globally' when user is redirected to login page)
      config.redirectFunction = history.replace;
    }
  }, [history]);

  // IMPORTANT:  Adding a route?
  // 1. Make sure the new route has WithParams wrapping it, e.g:
  //    <WithParams> <YourPageComponent /> </WithParams />
  // 2. Make sure your page's top-level component (e.g. YourPageComponent above) is wrapped with React.memo() before
  //    you export it, e.g. YourPageComponent.js should look something like:
  //
  //    const YourPageComponent = ({ name }) => <div>Hello {name}</div>;
  //    export default React.memo(YourPageComponent);

  return (
    <Layout>
      <Switch>
        <Route path={ paths.login } exact render={ () => <WithParams><LoginPage /></WithParams> } />
        <Route path={ paths.map } exact render={ () => <WithParams><MapPage /></WithParams> } />
        <Route path="/test" exact render={ () => <WithParams><TestPage /></WithParams> } />
        <Redirect to={ getPathWithVariables('map', { regionId: 1 }) } />
      </Switch>
    </Layout>
  );
});

const App = () => (
  <BrowserRouter>
    <ConduitProvider>
      <FlowTheme>
        <RoutedApp />
      </FlowTheme>
    </ConduitProvider>
  </BrowserRouter>
);

export default App;
