import useGoTo from './useGoTo';
import { useCallback, useEffect, useRef } from 'react';
import useAllParams from './useAllParams';
import { stringify } from '../helpers/stableSerialize';

const existingVariablesRef = { current: null };

const useGoToMaintain = (pathName) => {
  const goTo = useGoTo(pathName);
  const existingVariables = useAllParams();
  const goToRef = useRef(goTo);

  const existingVariablesStr = stringify(existingVariables);

  useEffect(() => {
    existingVariablesRef.current = existingVariables;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingVariablesStr])

  useEffect(() => {
    goToRef.current = goTo;
  }, [goTo])

  return useCallback(
    (changedVariables = {}, replace = false) => {
      existingVariablesRef.current = {
        ...(existingVariablesRef.current),
        ...changedVariables,
      };
      goToRef.current(existingVariablesRef.current, replace)
    },
    [],
  );
};

export default useGoToMaintain;
