import React from 'react';
import { observer } from 'mobx-react-lite';

import columnDefinitions from '../../displayConfigs/columnDefinitions';
import useAppController from '../../hooks/useAppController';
import flatten from 'lodash/flatten';
import useMediaQuery from '../../hooks/useMediaQuery';

const detectorColumns = [
  'detector',
  'movement',
  'detectionTypes',
  'detectorHealthScore',
];

const AbacusSignalDetectorsTable = () => {
  const { params, abacusController } = useAppController();

  const {
    networkTodMetricsData,

    getNetworkTodMetrics,
    getSignalMetrics,
    getPhaseMetrics,
  } = abacusController;

  const { networkId, signalId, phaseId } = params || {};

  const fullMetrics = phaseId 
    ? getPhaseMetrics(phaseId) 
    : signalId
      ? getSignalMetrics(signalId)
      : networkTodMetricsData?.data?.user?.network
        ?? null; // getNetworkTodMetrics(networkId);

  const detectors = phaseId
    ? (fullMetrics?.detectors || [])
    : signalId 
      ? (fullMetrics?.phases ? flatten(fullMetrics.phases.map(phase => phase?.detectors).filter(Boolean)) : [])
      : (fullMetrics?.signals ? flatten(fullMetrics.signals.map(signal => signal?.phases ? flatten(signal.phases.map(phase => phase?.detectors).filter(Boolean)) : [])) : []);

  const maxHeightRem = useMediaQuery(["(min-height: 1600px)", "(min-height: 1440px)", "(min-height: 1080px)", "(min-height: 888px)", "(min-height: 800px)",],
                                  [65, 55, 33, 20, 20],
                                  14);

  if (!fullMetrics || detectors.length === 0) {
    return null;
  }

  return (
    <div className="h-100% w-100% mt-4 ml-4 overflow-auto bg-gray6 thin-scrollbars"
         style={{maxHeight: `${maxHeightRem}rem`}}>
      <table className="w-100% collapse fs-4">
        <tbody>
        {
          detectors
            .slice()
            // todo sort by volume?
            .filter(d => d.detectionTypes !== 'Lane-by-lane with Speed Restriction')
            .sort((d1, d2) => {
              if (!d1) {
                return 1;
              }

              if (!d2) {
                return -1;
              }

              if (d1.detectionTypes < d2.detectionTypes) {
                return 1;
              } else if (d1.detectionTypes > d2.detectionTypes) {
                return -1
              }

              return 0;
            })
            .map((detector, index) => {
              if (!detector) {
                return null;
              }

              // this looks like the row that cerberus uses, that way the getValue() below works
              const row = {
                detector,
                detectorHealthScore: detector.latestDetectorHealthScore,
              };

              return (
                <tr key={ index }>
                  {
                    detectorColumns.map(detectorColumn => {
                      const { id, getValue, format /*, label, valign = 'middle' */ } = columnDefinitions[detectorColumn];

                      const value = getValue ? getValue(row) : row[id];

                      const formattedValue = typeof format === 'function'
                        ? format(value)
                        : value;

                      return (
                        <td key={ id } className="pa-4">{ formattedValue }</td>
                      );
                    })
                  }
                </tr>
              );
            })
        }
        </tbody>
      </table>
    </div>
  );
}

export default observer(AbacusSignalDetectorsTable);
