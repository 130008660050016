import React from 'react';
import IconWrapper from './IconWrapper';

const Plus = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="1 1 14 14">
    <path d="M8 4V12M12 8H4" strokeWidth="1.5" strokeLinecap="round" />
  </IconWrapper>
);

export default Plus;
