import getScoreValue from '../../helpers/getScoreValue';
import Dot from '../shared/icons/Dot';
import useAppController from '../../hooks/useAppController';
import { observer } from 'mobx-react-lite';

const MapPhaseLabel = ({ goToMaintain, phase, metricsValue }) => {
  const color = getScoreValue(metricsValue, 'phaseHealthColor');
  const { params, mapController } = useAppController();
  const { hoveredPhaseId, setHoveredPhaseId } = mapController;
  const phaseId = parseInt(phase.id, 10);
  const onClick = () => goToMaintain({ focus: 'phase', phaseId: (params.phaseId === phase.id) ? null : phaseId }, true);

  return (
    <div
      onClick={ onClick }
      onMouseEnter={ () => setHoveredPhaseId(phaseId) }
      onMouseLeave={ () => setHoveredPhaseId(0) }
      className={ `bg-navy bo-radius-pill pv-1 ph-6 flex items-center fs-5 pointer uppercase gray1 semibold ${ (params.phaseId === phase.id) ? '' : (hoveredPhaseId === phaseId) ? 'o-80' : 'o-60' }` }
    >
      {
        (typeof metricsValue !== 'number' || metricsValue >= 60)
          ? <>PH { phase.number }</>
          : (
            <>
              <div className={ `pr-4` }>
                PH { phase.number }
              </div>
              <div className="h-2 bo-gray4 bor-1"></div>
              <div className="pl-4 pb-3">
                <Dot width={2} height={2} color={ color } />
              </div>
            </>
          )
      }
    </div>
  );
};

export default observer(MapPhaseLabel);
