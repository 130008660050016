import { types } from 'mobx-state-tree';
import createGraphqlFetcherType from '../models/createGraphqlFetcherType';
import gql from 'graphql-tag';

const regionQuery = gql`
  query ($regionId: String!) {
    user {
      region(id: $regionId) {
        id
        bounds {
          sw
          ne
        }
        networks {
          id
          name
          tods {
            id
            humanName
            startTime {
              hours
              minutes
            }
            endTime {
              hours
              minutes
            }
          }
          bounds {
            sw
            ne
          }
          routes {
            direction
          }
          signals {
            id
            code
            name
            secondaryName
            bounds {
              ne
              sw
            }
            phases(eagerlyLoadMovements: true) {
              id
              number
              movements {
                id
                turn
              }
            }
          }
        }
      }
    }
  }
`;

const edgeMetricsQuery = gql`
  query ($minX: Int!, $maxX: Int!, $minY: Int!, $maxY: Int!, $zoom: Int!, $todId: String, $metricsField: String!) {
    user(todId: $todId) {
      edgeTileMetrics(minX: $minX, maxX: $maxX, minY: $minY, maxY: $maxY, zoom: $zoom, metricsField: $metricsField) {
        ids
        values
      }
    }
  }
`;

const networkMapGeometryQuery = gql`
  query ($networkId: String!) {
    user {
      network(id: $networkId) {
        mapGeometry {
          phase {
            id
          }
          signal {
            id
            coordinates
          }
          pathCoordinates
        }
        signals {
          id
          coordinates
        }
      }
    }
  }
`;

const networkMetricsQuery = gql`
  query ($regionId: String!) {
    user {
      region(id: $regionId) {
        networks {
          id
          latestHealthScore
        }
      }
    }
  }
`;

const signalPhaseMetricsQuery = gql`
  query ($networkId: String!, $todId: String) {
    user(todId: $todId) {
      network(id: $networkId) {
        signals {
          id
          latestHealthScore
          phases(eagerlyLoadMovements: true) {
            id
            latestHealthScore
            movements {
              id
              latestAverageVolume
            }
          }
        }
      }
    }
  }
`;

const MapController = types
  .model({
    regionData: createGraphqlFetcherType(regionQuery),
    edgeMetricsData: createGraphqlFetcherType(edgeMetricsQuery),
    networkMapGeometryData: createGraphqlFetcherType(networkMapGeometryQuery),
    networkMetricsData: createGraphqlFetcherType(networkMetricsQuery),
    signalPhaseMetricsData: createGraphqlFetcherType(signalPhaseMetricsQuery),

    hoveredNetworkId: 0,
    hoveredPhaseId: 0,

    mapBoundsSw0: 0,
    mapBoundsSw1: 0,
    mapBoundsNe0: 0,
    mapBoundsNe1: 0,
    mapBasePadding: 0,
    mapPaddingTop: 0,
    mapPaddingRight: 0,
    mapPaddingBottom: 0,
    mapPaddingLeft: 0,
  })
  .volatile(self => ({
    map: null, // mapbox instance
    mapContainer: null, // div container, parent of mapbox instance
  }))
  .actions(self => ({
    setHoveredNetworkId: hoveredNetworkId => self.hoveredNetworkId = hoveredNetworkId,
    setHoveredPhaseId: hoveredPhaseId => self.hoveredPhaseId = hoveredPhaseId,
    setMap: map => self.map = map,
    setMapContainer: mapContainer => self.mapContainer = mapContainer,
    setMapBounds: (bounds, basePadding, padding) => {
      self.mapBoundsSw0 = bounds?.sw?.[0] || 0;
      self.mapBoundsSw1 = bounds?.sw?.[1] || 0;
      self.mapBoundsNe0 = bounds?.ne?.[0] || 0;
      self.mapBoundsNe1 = bounds?.ne?.[1] || 0;
      self.mapBasePadding = basePadding || 0;
      self.mapPaddingTop = padding?.top || 0;
      self.mapPaddingRight = padding?.right || 0;
      self.mapPaddingBottom = padding?.bottom || 0;
      self.mapPaddingLeft = padding?.left || 0;
    },
  }));

export default MapController;
