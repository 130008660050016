import React from 'react';
import IconWrapper from './IconWrapper';

const NotificationBellWithDot = ({ fillColor = 'gray1', strokeColor = 'gray1', strokeOpacity = '0.4', ...props }) => (
  <IconWrapper fillColor={fillColor} strokeColor={strokeColor} {...props} viewBox="-1 -1 19 19">
    <path stroke={strokeColor} strokeOpacity={strokeOpacity} d="M10 12C10 12.5304 9.78929 13.0391 9.41421 13.4142C9.03914 13.7893 8.53043 14 8 14C7.46957 14 6.96086 13.7893 6.58579 13.4142C6.21071 13.0391 6 12.5304 6 12"/>
    <path stroke={strokeColor} strokeOpacity={strokeOpacity} d="M3.5 11.5V7C3.5 4.51472 5.51472 2.5 8 2.5C10.4853 2.5 12.5 4.51472 12.5 7V11.5M1.5 11.5H14.5"/>
    <circle fill="#FB4108" stroke="#FB4108" cx="12" cy="4" r="2"/>
  </IconWrapper>
);

export default NotificationBellWithDot;