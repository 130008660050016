import React from 'react';
import IconWrapper from './IconWrapper';

const Close = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="0 0 16 16">
    <path d="M3.75 3.75L12.25 12.25M12.25 3.75L3.75 12.25" strokeWidth="1.5" strokeLinecap="round" />
  </IconWrapper>
);

export default Close;
