import {useEffect, useState} from 'react';

const getSize = () => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
});

const useWindowSize = () => {
  let [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    const handleResize = () => {
      const newSize = getSize();

      const somethingChanged = ['innerHeight', 'innerWidth', 'outerHeight', 'outerWidth']
        .find(field => (newSize[field] !== windowSize[field]));

      if (somethingChanged) {
        setWindowSize(newSize);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  return windowSize;
};

export default useWindowSize;
