// suppress transpiling mapbox-gl
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import useAppController from '../../hooks/useAppController';
import MapNetworkLabel from './MapNetworkLabel';

const useMapboxNetworkLabels = (map) => {
  const goToMaintain = useGoToMaintain('map');
  const { params, mapController } = useAppController();
  const {
    hoveredNetworkId,
    networkMetricsData,
    regionData,
  } = mapController;

  const { focus, regionId } = params;
  const isSystemOrNetwork = (focus === 'system' || focus === 'network')

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
      networkMetricsData.fetch({ regionId });
    }
  }, [networkMetricsData, regionData, regionId]);

  const markersRef = useRef([]);
  const networkIdsRef = useRef([]);

  useEffect(() => {
    if (isSystemOrNetwork && regionData.data?.user.region.networks && map) {
      markersRef.current = [];
      networkIdsRef.current = [];
      regionData.data?.user.region.networks
        .slice(0) // can't sort the fetched array directly, must copy first
        // more southern network labels appear above
        .sort((a, b) => {
          if (a.bounds.ne[1] === b.bounds.ne[1]) {
            return b.bounds.ne[0]- a.bounds.ne[0];
          }
          return b.bounds.ne[1] - a.bounds.ne[1];
        })
        .forEach(network => {
          const bounds = network.bounds;
          const isVerticalNetwork = (bounds.ne[1] - bounds.sw[1]) > ((bounds.ne[0] - bounds.sw[0]) * 1.38);
          const markerElement = document.createElement('div');
          const root = createRoot(markerElement);
          const metricsValue = networkMetricsData.data?.user.region.networks.find(metricsForNetwork => metricsForNetwork.id === network.id).latestHealthScore;
          root.render(<MapNetworkLabel network={network} metricsValue={metricsValue} goToNetwork={() => goToMaintain({ focus: 'network', networkId: network.id })} />)
          markersRef.current.push(
            new mapboxgl.Marker(markerElement)
              .setLngLat([(bounds.sw[0] + bounds.ne[0] + (isVerticalNetwork ? .05 : 0)) / 2, (bounds.sw[1] + bounds.ne[1] + (isVerticalNetwork ? 0 : .03)) / 2])
              .addTo(map)
          );
          networkIdsRef.current.push(parseInt(network.id, 10));
        });
      return () => {
        markersRef.current.forEach(marker => marker.remove());
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSystemOrNetwork, map, regionData.data?.user.region.networks, networkMetricsData.data?.user]);

  useEffect(() => {
    markersRef.current.forEach((marker, index) => {
      const metricsValue = networkMetricsData.data?.user.region.networks.find(metricsForNetwork => parseInt(metricsForNetwork.id, 10) === networkIdsRef.current[index]).latestHealthScore;

      // feels very hacky, but works
      marker._element.style.zIndex =
        (networkIdsRef.current[index] === hoveredNetworkId)
          ? '2'
          : (metricsValue < 60)
            ? '1'
            : '';
    });
  }, [hoveredNetworkId, networkMetricsData.data?.user]);
};
export default useMapboxNetworkLabels;
