import React from 'react';

const getClassesForScore = s =>
  typeof s !== 'number' // score loading or unknown
    ? 'bg-gray2'
    : s >= 80
    ? 'bg-green1'
    : s >= 60
      ? 'bg-yellow1'
      : s >= 40
        ? 'white bg-orange'
        : 'white bg-red';

// should roughly go with the ScorePill ones
const sizeConfigMap = {
  small: {
    font: 'fs-6 semibold',
  },
  medium: {
    font: 'fs-5 semibold'
  },
  large: {
    font: 'fs-4 semibold',
    style: {
      width: '42px', // hard-coding :'(
    },
  },
  xlarge: {
    font: 'fs-4 semibold',
  },
};

const ScoreWithDot = ({ score, className = '', size = "medium", isInline = true, style = {} }) => {
  className += ' flex-shrink-0 items-center';

  const sizeConfig = sizeConfigMap[size];
  className += ' ' + sizeConfig.font;

  if (isInline) {
    className += ' inline-flex';
  } else {
    className += ' flex';
  }

  const dotClassName = 'w-1 h-1 bo-radius-circle ml-3 ' + getClassesForScore(score);

  if (typeof score !== 'number') {
    score = <span className="gray1 nowrap">--</span>;
  }

  style = {
    ...(sizeConfig.style || {}),
    ...style,
  };

  return (
    <div className={ className } style={ style }>
      <div>{ score }</div>
      <div className={ dotClassName } />
    </div>
  );
};

export default ScoreWithDot;
