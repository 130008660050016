import { observer } from 'mobx-react-lite';
import columnDefinitions from '../../displayConfigs/columnDefinitions';
import useAppController from '../../hooks/useAppController';
import Triangle from '../shared/icons/Triangle';
import { Fragment, useEffect } from 'react';
import formatDirection from '../../helpers/formatDirection';

const configs = [
  ['Volume', 'flowLabsVolumeCountPerTod', 'journeyCount'],
  ['Mobility', 'controlDelayTotal', 'forwardTravelTimeMedian', 'reverseTravelTimeMedian'],
  ['Signal Operations', 'avgSplitFailuresPerTod', 'arrivalsOnGreenRatio', 'controlDelayMean', 'controlDelayMeanMain', 'controlDelayMeanSide'],
  ['Environmental Impact', 'co2EquivalentGramsVolumeWeightedTotal', 'fuelLitresVolumeWeightedTotal'],
  ['Safety', 'deceleration10FpssRatio', 'postClearanceAllMovementRedLightRunRatio', 'dilemmaZoneRatio'],
  ['Economic Impact', 'annualizedCostOfDelay'/*, 'annualizedFuelCost'*/],
];

const LitmusReport = ({ reportSettings }) => {
  const { litmusController, mapController, params } = useAppController();

  let beforeRow = litmusController.beforeMetrics?.data?.user?.network?.metrics;
  let afterRow = litmusController.afterMetrics?.data?.user?.network?.metrics;
  const { regionData } = mapController;
  const { regionId, networkId } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  const network = regionData.data?.user.region.networks.find(network => network.id === networkId);

  // if we have _either_ before or after metrics, we still render below
  if (!network || (!beforeRow && !afterRow)) {
    return null;
  }

  beforeRow ||= {};
  afterRow ||= {};

  return (
    <div>
      <table className="w-100% collapse table-fixed tl normal gray1">
        <tbody>

        {
          (
            configs.map((fields, sectionIndex) => {
              return (
                <Fragment key={ sectionIndex }>
                  {
                    fields.map((field, fieldIndex) => {
                      let labelDisplay, labelClassName, beforeDisplay, afterDisplay, beforeVal, afterVal, changeTriangles, changeDisplay;
                      if (!fieldIndex) {
                        labelClassName = 'white semibold pt-9'
                        labelDisplay = field;

                        if (!sectionIndex) { // first section, header row
                          beforeDisplay = (
                            <div className="">
                              <div>BEFORE</div>
                              <div className="fs-6 gray2">
                                { reportSettings.beforeDate.subtract(28, 'days').format('MM/DD/YY') }
                                {' '}
                                -
                                {' '}
                                { reportSettings.beforeDate.format('MM/DD/YY') }
                              </div>
                            </div>
                          );
                          afterDisplay = (
                            <div className="">
                              <div>AFTER</div>
                              <div className="fs-6 gray2">
                                { reportSettings.afterDate.subtract(28, 'days').format('MM/DD/YY') }
                                {' '}
                                -
                                {' '}
                                { reportSettings.afterDate.format('MM/DD/YY') }
                              </div>
                            </div>
                          );
                          changeDisplay = <div className="mr-5">CHANGE</div>
                        }

                      } else {
                        const columnDefinition = columnDefinitions[field] || {};
                        const { comparisonToolLabel, label, subLabel, getValue, format, formatLitmus } = columnDefinition;

                        labelClassName = 'gray1'
                        labelDisplay = comparisonToolLabel
                          ? comparisonToolLabel
                          : label
                            ? <span>{ label } <span className="gray2">{ subLabel }</span></span>
                            : field;

                        if (field === 'forwardTravelTimeMedian') {
                          labelDisplay = <span>Mainline travel time <span className="nowrap gray2">({ formatDirection(network.routes[0].direction) })</span></span>;
                        } else if (field === 'reverseTravelTimeMedian') {
                          labelDisplay = <span>Mainline travel time <span className="nowrap gray2">({ formatDirection(network.routes[1].direction) })</span></span>;
                        }

                        if (typeof getValue === 'function') {
                          beforeVal = getValue(beforeRow);
                          afterVal = getValue(afterRow);
                        } else {
                          beforeVal = beforeRow[field];
                          afterVal = afterRow[field];
                        }

                        if (typeof formatLitmus === 'function') {
                          beforeDisplay = formatLitmus(beforeVal);
                          afterDisplay = formatLitmus(afterVal);
                        } else if (typeof format === 'function') {
                          beforeDisplay = format(beforeVal);
                          afterDisplay = format(afterVal);
                        } else {
                          beforeDisplay = beforeVal;
                          afterDisplay = afterVal;
                        }

                        // lower is better, except for those fields:
                        const isHigherBetter = [
                          'arrivalsOnGreenRatio',
                        ].includes(field);

                        const isChangeNeutral = [
                          'flowLabsVolumeCountPerTod',
                          'journeyCount',
                        ].includes(field);

                        let changeAmount = null;
                        if (typeof beforeVal === 'number' && typeof afterVal === 'number') {
                          if (beforeVal) {
                            changeAmount = (afterVal / beforeVal) - 1.0;
                          } else {
                            if (afterVal === 0) {
                              changeAmount = 0
                            } else if (afterVal > 0) {
                              changeAmount = 1;
                            } else {
                              // afterVal < 0
                              changeAmount = -1;
                            }
                          }
                        }


                        let changeColor;
                        let changeDisplayClass;
                        if (!changeAmount) { // no change
                          changeTriangles = null;
                          changeDisplayClass = 'gray3'
                        } else {

                          if (isChangeNeutral) {
                            changeColor = 'gray';
                          } else if ((isHigherBetter && changeAmount > 0) || (!isHigherBetter && changeAmount < 0)) { // good
                            changeColor = 'green';
                            changeDisplayClass = 'green bg-green5';
                          } else { // bad
                            changeColor = 'red';
                            changeDisplayClass = 'red bg-red5';
                          }

                          const numberOfTriangles = Math.abs(changeAmount) > .25
                            ? 3
                            : Math.abs(changeAmount) > .1
                              ? 2
                              : 1;

                          let changeTriangleClass = 'inline-flex w-6 justify-center';
                          if (changeAmount > 0) {
                            changeTriangleClass += ' pt-3';
                          } else {
                            changeTriangleClass += ' pb-4 rotate-180';
                          }

                          let changeTriangleSize = '1';

                          changeTriangles = (
                            <div className={ changeTriangleClass }>
                              <Triangle width={ changeTriangleSize } height={ changeTriangleSize } color={ changeColor } />
                              { numberOfTriangles <= 1 ? null : (
                                <Triangle width={ changeTriangleSize } height={ changeTriangleSize } color={ changeColor } />
                              ) }
                              { numberOfTriangles <= 2 ? null : (
                                <Triangle width={ changeTriangleSize } height={ changeTriangleSize } color={ changeColor } />
                              ) }
                            </div>
                          );
                        }

                        if (changeAmount === null) {
                          changeDisplay = <div className="inline-block pa-5 gray3">--</div>;
                        } else {
                          changeDisplay = <div className={ `inline-flex justify-around items-center w-6 pv-1 bo-radius-1 ${ changeDisplayClass }` }>{ changeAmount > 0 ? '+' : '' }{ (changeAmount * 100).toFixed(1) }%</div>;
                        }

                      }

                      return (
                        <tr key={ fieldIndex } className={fieldIndex ? 'bob-1 bo-gray5' : ''}>
                          <td width="41%" valign="bottom" className={ `pv-6 ${ labelClassName }` }>{ labelDisplay }</td>
                          <td width="22%" valign="bottom" className="pv-6 tc medium">{ beforeDisplay }</td>
                          <td width="22%" valign="bottom" className="pv-6 tc medium">{ afterDisplay }</td>
                          <td width="15%" valign={ (!fieldIndex && !sectionIndex) ? 'bottom' : 'middle' } className="pv-6 tr">
                            { changeTriangles }
                            { changeDisplay }
                          </td>
                        </tr>
                      );
                    })
                  }
                </Fragment>
              );
            })
          )
        }
        </tbody>
      </table>
    </div>
  );
};

export default observer(LitmusReport);
