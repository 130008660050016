import { types } from 'mobx-state-tree';
import gql from 'graphql-tag';
import formatHistogramData from '../../helpers/formatHistogramData';
import createGraphqlFetcherType from '../models/createGraphqlFetcherType';


/*
  Time Series Queries
  TODO: Organize into the appropriate metrics view?
*/

const networkTodTimeSeriesQuery = gql`
  query ($networkId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      network(id: $networkId) {
        id
        tods {
          id
          latestMetricsTimeSeries {
            referenceDate {
              day
              month
            }

            # Operations
            flowLabsVolumeCountPerHour
            controlDelayMedian
            controlDelay85th
            arrivalsOnGreenRatio
            avgGreenTimePerCycle
            avgSplitFailuresPerCycle
            platoonRatio
    #       programmedCycleTimeAvg           # Remove?
            queueLength85thVehicleNumber
            queueLengthMedianVehicleNumber
            queueLength15thVehicleNumber

            # Safety
            approachVelocityMph85th
            approachVelocityMphMedian
            approachVelocityMph15th
            deceleration10FpssRatio
            dilemmaZoneRatio
            speedLimitMphMode
            throughLeftRedLightRunRatio

            # Detection
    #         detectorHealthScore            # Remove?

            # Environmental
            fuelGallonsVolumeWeightedTotalRa
            co2EquivalentGramsVolumeWeightedTotalRa
            no2GramsVolumeWeightedTotalRa
          }
        }
      }
    }
  }
`;

const routeTimeSeriesQuery = gql`
  query ($routeId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      route(id: $routeId) {
        id
        latestMetricsTimeSeries {
          referenceDate {
            day
            month
          }
          
          controlDelayMean
          stopCountMean
          travelTime85th
          travelTimeMedian
          travelTime15th
        }
      }
    }
  }
`;

const signalTimeSeriesQuery = gql`
  query ($signalId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      signal(id: $signalId) {
        id
        latestMetricsTimeSeries {
          referenceDate {
            day
            month
          }

          # Operations
          arrivalsOnGreenRatio
#         avgForceOffsPerCycle           # Remove?
#         avgGapOutsPerCycle             # Remove?
          avgGreenTimePerCycle
          avgSplitFailuresPerCycle
          controlDelay85th
          controlDelayMedian
          flowLabsVolumeCountPerHour
#          phaseSkipsPerCycle            # Remove?
          platoonRatio
          programmedCycleTimeMode
          queueLength85thVehicleNumber
          queueLengthMedianVehicleNumber
          queueLength15thVehicleNumber

          # Safety
          approachVelocityMph85th
          approachVelocityMphMedian
          approachVelocityMph15th
          deceleration10FpssRatio
          dilemmaZoneRatio
          speedLimitMphMode
          throughLeftRedLightRunRatio

          # Detection
#         detectorHealthScore            # Remove?

          # Environmental
          controlCo2EquivalentGramsVolumeWeightedTotalRa
          controlFuelGallonsVolumeWeightedTotalRa
          controlNo2GramsVolumeWeightedTotalRa
        }
        phases {
          id
          number
          latestMetricsTimeSeries {
            referenceDate {
              day
              month
            }

            # Operations
            arrivalsOnGreenRatio
            avgForceOffsPerCycle
            avgGapOutsPerCycle
            avgGreenTimePerCycle
            avgSplitFailuresPerCycle
            controlDelay85th
            controlDelayMedian
            flowLabsVolumeCountPerHour
            phaseSkipsPerCycle
            platoonRatio
            programmedSplitTimeMode
            queueLength85thVehicleNumber
            queueLengthMedianVehicleNumber
            queueLength15thVehicleNumber

            # Safety
            approachVelocityMph85th
            approachVelocityMphMedian
            approachVelocityMph15th
            deceleration10FpssRatio
            dilemmaZoneRatio
            speedLimitMphMode
            throughLeftRedLightRunRatio

            # Detection
            detectorHealthScore

            # Environmental
            controlCo2EquivalentGramsVolumeWeightedTotalRa
            controlFuelGallonsVolumeWeightedTotalRa
            controlNo2GramsVolumeWeightedTotalRa
          }
        }
      }
    }
  }
`;

/*
  Histograms Queries
  (divided into Fragments so that we can organize them into the appropriate metrics view in the future)
*/

// FRAGMENT
const networkOperationsHistogramsFragment = gql`
  fragment UserNetworkOperationsHistograms on UserNetwork {
    id # TODO: Placeholder: Remove when ready
    # vehicularHistograms {
    #    controlDelayHistogram {
    #      controlDelayA
    #      controlDelayB
    #      controlDelayC
    #      controlDelayD
    #      controlDelayE
    #      controlDelayF
    #      
    #      binMaxSeconds
    #      binMinSeconds
    #    }
    # }
  }
`;

// FRAGMENT
const networkSafetyHistogramsFragment = gql`
  fragment UserNetworkSafetyHistograms on UserNetwork {
    id # TODO: Placeholder: Remove when ready
    # vehicularHistograms {
    #    RLRHistogram {
    #      arrivalTimeGreenIndicationStoppedAggressively
    #      arrivalTimeYellowIndicationStoppedAggressively
    #      arrivalTimeRedClearanceIndicationStoppedAggressively
    #      arrivalTimeRedIndicationStoppedAggressively
    #      
    #      arrivalTimeGreenIndicationStoppedUnaggressively
    #      arrivalTimeYellowIndicationStoppedUnaggressively
    #      arrivalTimeRedClearanceIndicationStoppedUnaggressively
    #      arrivalTimeRedIndicationStoppedUnaggressively
    #      
    #      arrivalTimeGreenIndicationDidntStop
    #      arrivalTimeYellowIndicationDidntStop
    #      arrivalTimeRedClearanceIndicationDidntStop
    #      arrivalTimeRedIndicationDidntStop
    #      
    #      binMaxSeconds
    #      binMinSeconds
    #    }
    #    aggressiveBrakingCauseBarChart {
    #      dilemmaZone
    #      queueing
    #      other
    #    }
    #    aggressiveBrakingHistogram {
    #      isAggressiveBraking
    #      isNotAggressiveBraking
    #      
    #      binMaxFeetPerSecondSquared
    #      binMinFeetPerSecondSquared
    #    }
    # }
  }
`;

// FRAGMENT
const mainRouteEdgesSafetyHistogramsFragment = gql`
  fragment UserMainRouteEdgesSafetyHistograms on UserNetwork {
    mainRouteEdges {
      id
      vehicularHistograms {
        speedHistogram {
          speedBelowLimit
          speedAboveLimit1
          speedAboveLimit2
          binMinMilesPerHour
          binMaxMilesPerHour
        }
        minAccelerationHistogram {
          minAccelerationIsAggressive
          minAccelerationIsNotAggressive
          binMinFeetPerSecondSquared
          binMaxFeetPerSecondSquared
        }
        maxAccelerationHistogram {
          maxAccelerationIsAggressive
          maxAccelerationIsNotAggressive
          binMinFeetPerSecondSquared
          binMaxFeetPerSecondSquared
        }
      }
    }
  }
`;

// FRAGMENT
const mainRouteEdgesMobilityHistogramsFragment = gql`
  fragment UserMainRouteEdgesMobilityHistograms on UserNetwork {
    mainRouteEdges {
      id
      vehicularHistograms {
        delayHistogram {
          delay
          binMinSeconds
          binMaxSeconds
        }
      }
    }
  }
`;

// FRAGMENT
const signalOperationsHistogramsFragment = gql`
  fragment UserSignalOperationsHistograms on UserSignal {
    id # TODO: Placeholder: Remove when ready
    # vehicularHistograms {
    #    controlDelayHistogram {
    #      controlDelayA
    #      controlDelayB
    #      controlDelayC
    #      controlDelayD
    #      controlDelayE
    #      controlDelayF
    #      
    #      binMaxSeconds
    #      binMinSeconds
    #    }
    # }
  }
`;

// FRAGMENT
const signalSafetyHistogramsFragment = gql`
  fragment UserSignalSafetyHistograms on UserSignal {
    id # TODO: Placeholder: Remove when ready
    # vehicularHistograms {
    #    RLRHistogram {
    #      arrivalTimeGreenIndicationStoppedAggressively
    #      arrivalTimeYellowIndicationStoppedAggressively
    #      arrivalTimeRedClearanceIndicationStoppedAggressively
    #      arrivalTimeRedIndicationStoppedAggressively
    #      
    #      arrivalTimeGreenIndicationStoppedUnaggressively
    #      arrivalTimeYellowIndicationStoppedUnaggressively
    #      arrivalTimeRedClearanceIndicationStoppedUnaggressively
    #      arrivalTimeRedIndicationStoppedUnaggressively
    #      
    #      arrivalTimeGreenIndicationDidntStop
    #      arrivalTimeYellowIndicationDidntStop
    #      arrivalTimeRedClearanceIndicationDidntStop
    #      arrivalTimeRedIndicationDidntStop
    #      
    #      binMaxSeconds
    #      binMinSeconds
    #    }
    #    aggressiveBrakingCauseBarChart {
    #      dilemmaZone
    #      queueing
    #      other
    #    }
    #    aggressiveBrakingHistogram {
    #      isAggressiveBraking
    #      isNotAggressiveBraking
    #      
    #      binMaxFeetPerSecondSquared
    #      binMinFeetPerSecondSquared
    #    }
    # }
  }
`;

// FRAGMENT
const phaseOperationsHistogramsFragment = gql`
  fragment UserPhaseOperationsHistograms on UserPhase {
    greenTimeHistograms {
      forceOff
      forceOffSplitFailure
      gapOut
      gapOutSplitFailure
      maxOut
      maxOutSplitFailure
      skips
      binMinSeconds
      binMaxSeconds
    }
    vehicularHistograms {
      controlDelayHistogram {
        controlDelayA
        controlDelayB
        controlDelayC
        controlDelayD
        controlDelayE
        controlDelayF
        
        binMaxSeconds
        binMinSeconds
      }
      # Also in phaseSafetyHistogramsFragment, but uncomment if/when queries are separated by metricsView
      #  arrivalModulusCycleLengthHistogram {
      #    arrivalTimeGreenIndication
      #    arrivalTimeYellowIndication
      #    arrivalTimeRedClearanceIndication
      #    arrivalTimeRedIndication
      #    
      #    binMaxSeconds
      #    binMinSeconds 
      #  }
    }
  }
`;

// TODO: Use for phaseOperations when 15-mins buckets are ready; Note different query params structure
// const signalPTTHistogramQuery_15minBuckets = gql`
//   query ($signalId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
//     user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays, periodStart: "2022-04-02", periodEnd: "2022-05-01", startTime: "00:00", endTime: "23:59") {
//       signal(id: $signalId) {
//         phases {
//           id
//           number
//           greenTimeHistograms {
//             forceOff
//             forceOffSplitFailure
//             gapOut
//             gapOutSplitFailure
//             maxOut
//             maxOutSplitFailure
//             skips
//             binMinSeconds
//             binMaxSeconds
//           }
//           dayPeriodHistograms {
//             forceOff
//             forceOffSplitFailure
//             gapOut
//             gapOutSplitFailure
//             maxOut
//             maxOutSplitFailure
//             skips
//             binMinSeconds
//             binMaxSeconds
//           }
//         }
//       }
//     }
//   }
// `;

// FRAGMENT
const phaseSafetyHistogramsFragment = gql`
  fragment UserPhaseSafetyHistograms on UserPhase {
    vehicularHistograms {
      arrivalModulusCycleLengthHistogram {
        arrivalTimeGreenIndication
        arrivalTimeYellowIndication
        arrivalTimeRedClearanceIndication
        arrivalTimeRedIndication
        
        binMaxSeconds
        binMinSeconds
      }
      RLRHistogram {
        arrivalTimeGreenIndicationStoppedAggressively
        arrivalTimeYellowIndicationStoppedAggressively
        arrivalTimeRedClearanceIndicationStoppedAggressively
        arrivalTimeRedIndicationStoppedAggressively
        
        arrivalTimeGreenIndicationStoppedUnaggressively
        arrivalTimeYellowIndicationStoppedUnaggressively
        arrivalTimeRedClearanceIndicationStoppedUnaggressively
        arrivalTimeRedIndicationStoppedUnaggressively
        
        arrivalTimeGreenIndicationDidntStop
        arrivalTimeYellowIndicationDidntStop
        arrivalTimeRedClearanceIndicationDidntStop
        arrivalTimeRedIndicationDidntStop
        
        binMaxSeconds
        binMinSeconds
      }
      aggressiveBrakingCauseBarChart {
        dilemmaZone
        queueing
        other
      }
      approachSpeedHistogram {
        approachSpeedBelowLimit
        approachSpeedAboveLimit1
        approachSpeedAboveLimit2
        
        binMaxMilesPerHour
        binMinMilesPerHour
      }
      aggressiveBrakingHistogram {
        isAggressiveBraking
        isNotAggressiveBraking
        
        binMaxFeetPerSecondSquared
        binMinFeetPerSecondSquared
      }
    }
  }
`;

const networkHistogramsQuery = gql`
  query ($networkId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      network(id: $networkId) {
        id
        ...UserNetworkOperationsHistograms
        ...UserNetworkSafetyHistograms
      }
    }
  }

  ${ networkOperationsHistogramsFragment }
  ${ networkSafetyHistogramsFragment }
  `;

const mainRouteEdgesHistogramsQuery = gql`
  query ($networkId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      network(id: $networkId) {
        id
        ...UserMainRouteEdgesMobilityHistograms
        ...UserMainRouteEdgesSafetyHistograms
      }
    }
  }
  
  ${ mainRouteEdgesMobilityHistogramsFragment }
  ${ mainRouteEdgesSafetyHistogramsFragment }
`;

// TODO: Create a phase query endpoint to reduce overhead?
const signalHistogramsQuery = gql`
  query ($signalId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      signal(id: $signalId) {
        id
        ...UserSignalOperationsHistograms
        ...UserSignalSafetyHistograms

        phases {
          id
          number
          ...UserPhaseOperationsHistograms
          ...UserPhaseSafetyHistograms
        }
      }
    }
  }

  ${ signalOperationsHistogramsFragment }
  ${ signalSafetyHistogramsFragment }
  ${ phaseOperationsHistogramsFragment }
  ${ phaseSafetyHistogramsFragment }
`;


const ChartController = types
  .model({
    // operations



    // safety



    // environmental



    // mobility
    routeTimeSeriesData: createGraphqlFetcherType(routeTimeSeriesQuery),


    // @todo: should split this into operations vs safety vs environmental time series data?
    networkTodTimeSeriesData: createGraphqlFetcherType(networkTodTimeSeriesQuery),
    signalTimeSeriesData: createGraphqlFetcherType(signalTimeSeriesQuery),
    // TODO: should also split histograms data into operations/safety/environmental data?
    networkHistogramsData: createGraphqlFetcherType(networkHistogramsQuery),
    mainRouteEdgesHistogramsData: createGraphqlFetcherType(mainRouteEdgesHistogramsQuery),
    signalHistogramsData: createGraphqlFetcherType(signalHistogramsQuery),


    //TODO: Remove when edges can be specified in UI
    mainRouteEdgeId: types.maybeNull(types.string),
    //TODO: For testing purposes only
    params: types.frozen({}),
  })
  .views(self => ({
    get isNetworkTodTimeSeriesDataReady() {
      // const { todId, aggregationPeriodDays, networkId } = self.params;
      return /*self.networkTimeSeriesData.areCurrentVariables({ signalId, aggregationPeriodDays, todId }) &&*/ self.networkTodTimeSeriesData.hasLoaded && self.networkTodTimeSeriesData?.data?.user?.network;
    },
    get isRouteTimeSeriesDataReady() {
      // const { todId, aggregationPeriodDays, routeId } = self.params;
      return /*self.routeTimeSeriesData.areCurrentVariables({ routeId, aggregationPeriodDays, todId }) &&*/ self.routeTimeSeriesData.hasLoaded && self.routeTimeSeriesData?.data?.user?.route;
    },
    get isSignalTimeSeriesDataReady() {
      // const { todId, aggregationPeriodDays, signalId } = self.params;
      return /*self.signalTimeSeriesData.areCurrentVariables({ signalId, aggregationPeriodDays, todId }) &&*/ self.signalTimeSeriesData.hasLoaded && self.signalTimeSeriesData?.data?.user?.signal;
    },
    get isNetworkHistogramsDataReady() {
      // const { todId, aggregationPeriodDays, networkId } = self.params;
      return /*self.networkHistogramsData.areCurrentVariables({ todId, aggregationPeriodDays, networkId }) &&*/ self.networkHistogramsData.hasLoaded && self.networkHistogramsData?.data?.user?.network;
    },
    get isMainRouteEdgesHistogramsDataReady() {
      // const { todId, aggregationPeriodDays, networkId } = self.params;
      return /*self.mainRouteEdgesHistogramsData.areCurrentVariables({ todId, aggregationPeriodDays, networkId }) &&*/ self.mainRouteEdgesHistogramsData.hasLoaded && self.mainRouteEdgesHistogramsData?.data?.user?.network;
    },
    get isSignalHistogramsDataReady() {
      // const { todId, aggregationPeriodDays, signalId } = self.params;
      return /*self.signalHistogramsData.areCurrentVariables({ todId, aggregationPeriodDays, signalId }) &&*/ self.signalHistogramsData.hasLoaded && self.signalHistogramsData?.data?.user?.signal;
    },
  }))
  .views(self => ({
    get networkTodTimeSeriesMap() {
      const networkTodTimeSeriesMap = {};

      if (self.isNetworkTodTimeSeriesDataReady) {

        const tods = self.networkTodTimeSeriesData.data.user.network.tods || [];
        tods.forEach(tod => {
          networkTodTimeSeriesMap[tod.id] = tod.latestMetricsTimeSeries;
        });
      }

      return networkTodTimeSeriesMap;
    },
    get routeTimeSeriesMap() {
      const routeTimeSeriesMap = {};

      if (self.isRouteTimeSeriesDataReady) {

        const route = self.routeTimeSeriesData.data.user.route;
        routeTimeSeriesMap[route.id] = route.latestMetricsTimeSeries;

      }

      return routeTimeSeriesMap;
    },
    get signalTimeSeriesMap() {
      const signalTimeSeriesMap = {};

      if (self.isSignalTimeSeriesDataReady) {

        const signal = self.signalTimeSeriesData.data.user.signal;
        signalTimeSeriesMap[signal.id] = signal.latestMetricsTimeSeries;

      }

      return signalTimeSeriesMap;
    },
    get phaseTimeSeriesMap() {
      const phaseTimeSeriesMap = {};

      if (self.isSignalTimeSeriesDataReady) {
        const signal = self.signalTimeSeriesData.data.user.signal;
        signal.phases.forEach(({ id, latestMetricsTimeSeries }) => {
          phaseTimeSeriesMap[id] = latestMetricsTimeSeries;
        });

      }

      return phaseTimeSeriesMap;
    },
    get networkHistogramsMap() {
      const networkHistogramsMap = {};
      if (self.isNetworkHistogramsDataReady) {

        const network = self.networkHistogramsData.data.user.network;
        networkHistogramsMap[network.id] = {
                                             ...network.vehicularHistograms,
                                           };
      }
      return networkHistogramsMap;
    },
    get mainRouteEdgesHistogramsMap() {
      const mainRouteEdgesHistogramsMap = {};

      if (self.isMainRouteEdgesHistogramsDataReady) {

        const network = self.mainRouteEdgesHistogramsData.data.user.network;
        network.mainRouteEdges.forEach(mainRouteEdge => {
          mainRouteEdgesHistogramsMap[mainRouteEdge.id] = {
                                                            ...mainRouteEdge.vehicularHistograms,
                                                          };
        });
      }
      return mainRouteEdgesHistogramsMap;
    },
    get signalHistogramsMap() {
      const signalHistogramsMap = {};

      if (self.isSignalHistogramsDataReady) {

        const signal = self.signalHistogramsData.data.user.signal;
        signalHistogramsMap[signal.id] = {
                                           ...signal.vehicularHistograms,
                                         };
      }
      return signalHistogramsMap;
    },
    get phaseHistogramsMap() {
      const phaseHistogramsMap = {};

      if (self.isSignalHistogramsDataReady) {

        const signal = self.signalHistogramsData.data.user.signal;
        signal.phases.forEach(phase => {
          phaseHistogramsMap[phase.id] = {
                                           'number': phase.number,
                                           'greenTimeHistograms': phase.greenTimeHistograms,
                                           ...phase.vehicularHistograms,
                                         };
        });
      }
      return phaseHistogramsMap;
    },
  }))
  .views(self => ({
    // TODO: Split up histogram queries to search by metricsView? possibly by individual histogram?
    //       (e.g. instead of loading all histograms for all phases of a signal + all of the signal's histograms, all at once)
    // TODO: Cache formatted histograms in a map
    getHistogram: (objectType, id, histogramName, mapToUse = objectType + 'HistogramsMap') => {
      const histogramData = self[mapToUse]?.[id]?.[histogramName];
      // console.log(`Preformatted GetHistogram data: ${JSON.stringify(histogramData)}`); //TODO: Remove when done
      const histogramFormattedData = histogramData && formatHistogramData(objectType, histogramName, self[mapToUse][id]); // TODO: Optimize: Currently passing in the entire <>HistogramsMap for object metadata to customize formatting
      // console.log(`GetHistogram data: ${JSON.stringify(histogramFormattedData)}`); //TODO: Remove when done
      return histogramFormattedData;
    },
  }))
  .views(self => ({
    getNetworkTodTimeSeries: todId =>
      self.networkTodTimeSeriesMap.hasOwnProperty(todId)
        ? self.networkTodTimeSeriesMap[todId]
        : [],
    getRouteTimeSeries: routeId =>
    self.routeTimeSeriesMap.hasOwnProperty(routeId)
      ? self.routeTimeSeriesMap[routeId]
      : [],
    getSignalTimeSeries: signalId =>
      self.signalTimeSeriesMap.hasOwnProperty(signalId)
        ? self.signalTimeSeriesMap[signalId]
        : [],
    getPhaseTimeSeries: phaseId => 
      self.phaseTimeSeriesMap.hasOwnProperty(phaseId)
        ? self.phaseTimeSeriesMap[phaseId]
        : [],

    getNetworkHistogram: (networkId, histogramName) => self.getHistogram('network', networkId, histogramName),
    getMainRouteEdgesHistogram: (mainRouteEdgesId, histogramName) => self.getHistogram('mainRouteEdges', mainRouteEdgesId, histogramName),
    getSignalHistogram: (signalId, histogramName) => self.getHistogram('signal', signalId, histogramName),
    getPhaseHistogram: (phaseId, histogramName) => self.getHistogram('phase', phaseId, histogramName),
  }))
  .actions(self => ({
    setMainRouteEdgeId: mainRouteEdgeId => self.mainRouteEdgeId = mainRouteEdgeId,

    // TODO: For testing purposes only
    setTestParams: params => self.params = params,
  }));

export default ChartController;
