import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useAppController from '../../hooks/useAppController';
import useWindowSize from '../../hooks/useWindowSize';
import { cloneDeep } from 'lodash';
import { stringify } from '../../helpers/stableSerialize';
import { animationDurationMs } from './useMapbox';
import MapSide from './MapSide';

const MapBoundsManagerAndSideContainer = () => {
  const { params, mapController } = useAppController();

  const {
    regionData,
  } = mapController;

  const { focus, regionId, networkId, signalId } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);


  const windowSize = useWindowSize();
  const sideMenuWidth = Math.floor(windowSize.innerWidth * .6);

  let bounds;

  if (regionData.data?.user?.region) {
    if ((focus === 'signal' || focus === 'phase') && signalId && networkId) {
      bounds = regionData.data.user.region
        .networks.find(network => network.id === networkId)
        .signals.find(signal => signal.id === signalId)
        .bounds;
    } else if (focus === 'network' && networkId) {
      bounds = regionData.data.user.region
        .networks.find(network => network.id === networkId)
        .bounds;
    } else {
      bounds = regionData.data.user.region
        .bounds;
    }
  }

  const boundsStr = bounds ? stringify(bounds) : null;

  useEffect(() => {
    if (bounds) {
      const mapBounds = cloneDeep(bounds);
      let basePadding;
      let padding;

      if ((focus === 'signal' || focus === 'phase') && signalId) {
        basePadding = 0;
        padding = { right: sideMenuWidth };
      } else if (focus === 'network' && networkId) {
        basePadding = 15;
        padding = { right: sideMenuWidth };

        const isVerticalNetwork = (bounds.ne[1] - bounds.sw[1]) > ((bounds.ne[0] - bounds.sw[0]) * 1.38);
        const mapPaddingDegrees = 0.005;

        mapBounds.sw[0] -= (mapPaddingDegrees * 1.38);
        mapBounds.sw[1] -= (mapPaddingDegrees);
        mapBounds.ne[0] += (mapPaddingDegrees * 1.38);
        mapBounds.ne[1] += mapPaddingDegrees * (isVerticalNetwork ? 2 : 1);
      } else { // system view
        basePadding = 20;
      }

      mapController.setMapBounds(mapBounds, basePadding, padding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundsStr, networkId, signalId]);

  const isSideMenuVisible = focus !== 'system';

  return (
    <div
      className={ `absolute flex flex-column z-4 top-5 right-5 bottom-5 duration-2 ${ isSideMenuVisible ? 'o-100' : 'o-0 pointer-events-none' }` }
      style={{
        width: sideMenuWidth + 'px',
        right: isSideMenuVisible ? undefined : '-200px',
        transition: isSideMenuVisible
          ? `right ${ animationDurationMs * 2 / 3 }ms ${ animationDurationMs * 5 }ms, opacity ${ animationDurationMs / 2 }ms ${ animationDurationMs * 4.75 }ms`
          : `right ${ animationDurationMs / 2 }ms, opacity ${ animationDurationMs / 2 }ms`,
        maxHeight: '100%',
        // backdropFilter: 'blur(10px) brightness(50%)',
      }}
    >
      <div className="bo-radius-3 bg-black z-4 flex-shrink-0 h-100%">
        <MapSide />
      </div>
    </div>
  );
};

export default observer(MapBoundsManagerAndSideContainer);
