import { bearing, lineIntersect, lineString, point, transformScale, transformTranslate } from '@turf/turf';

export const getBoolFeature = featureStateName => ['boolean', ['feature-state', featureStateName], false];
export const getNumFeature = featureStateName => ['number', ['feature-state', featureStateName], -1];
const metricsNum = getNumFeature('metricsValue');
export const getMetricsSteps = (...args) => ['case', ['<', metricsNum, 0], args[args.length - 1], ['step', metricsNum, ...args]];
export const getZoomInterpolationValue = value => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  10, ["*", value, ["^", 2, -7]],
  24, ["*", value, ["^", 2, 7]]
];
export const turfOptions = { units: 'meters' };

// normal lineOffset function ends up being not perpendicular, because it assumes long/lat degrees are equal :(
// so we use code below, from https://github.com/Turfjs/turf/issues/1838#issuecomment-927835876
export const lineOffsetPerpendicular = (line, distance, { units = 'kilometers' } = {}) => {
  const lineCoords = line.geometry.coordinates;
  const transformAngle = distance < 0 ? -90 : 90;
  if (distance < 0) distance = -distance;

  const offsetLines = [];
  for (let i = 0; i < lineCoords.length - 1; i++) { // Translating each segment of the line to correct position
    const angle = bearing(lineCoords[i], lineCoords[i + 1]) + transformAngle;
    const firstPoint = transformTranslate(point(lineCoords[i]), distance, angle, { units })?.geometry.coordinates;
    const secondPoint = transformTranslate(point(lineCoords[i + 1]), distance, angle, { units })?.geometry.coordinates;
    offsetLines.push([firstPoint, secondPoint]);
  }

  const offsetCoords = [offsetLines[0][0]]; // First point inserted
  for (let i = 0; i < offsetLines.length; i++) { // For each translated segment of the initial line
    if (offsetLines[i + 1]) { // If there's another segment after this one
      const firstLine = transformScale(lineString(offsetLines[i]), 2); // transformScale is useful in case the two segment don't have an intersection point
      const secondLine = transformScale(lineString(offsetLines[i + 1]), 2); // Which happen when the resulting offset line is bigger than the initial one
      const intersection = lineIntersect(firstLine, secondLine);
      if (intersection?.features?.[0]) {
        // We're calculating the intersection point between the two translated & scaled segments
        offsetCoords.push(intersection.features[0].geometry.coordinates);
      }
    } else offsetCoords.push(offsetLines[i][1]); // If there's no other segment after this one, we simply push the last point of the line
  }

  return lineString(offsetCoords);
};
