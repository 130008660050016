import React from 'react';
import ScoreWithDot from '../components/shared/elements/ScoreWithDot';
import formatDirection from '../helpers/formatDirection';

const defaultNumberFormatter = new Intl.NumberFormat('en-us');
// const compactSecondsFormatter = new Intl.NumberFormat('en-us', { maximumSignificantDigits: 3, notation: 'compact' });
const compactDollarFormatter = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumSignificantDigits: 3, notation: 'compact' });

const emptyValue = <span className="lightgray4 nowrap">--</span>

export const formatFloat = (value, decimalPlaces = 1, formatIfNumeric = defaultNumberFormatter.format) => {
  if (typeof value === 'number') {
    value = value.toFixed(value ? decimalPlaces : 0);
    // TODO: Fix -0:
    // value = value.toFixed(Math.abs(value*10**decimalPlaces) > 0 ? decimalPlaces : 0);
    if (formatIfNumeric) {
      return formatIfNumeric(value);
    }
    return value;
  }

  return emptyValue;
};

export const formatInt = (value, formatIfNumeric = val => defaultNumberFormatter.format(parseInt(val, 10))) => {
  if (typeof value === 'number') {
    return formatIfNumeric(value);
  }

  return emptyValue;
};

export const formatZeroIfFalsy = value => value ? value : 0;

const Unit = ({ children }) => <span className="normal o-60">{ children }</span>

export const formatPercentageWithSign = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ (value > 0) ? '+' : '' }{ value }<Unit>%</Unit></>);
export const formatPercentage = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ value }<Unit>%</Unit></>);
export const formatCompactDollar = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, compactDollarFormatter.format);
export const formatRatio = (value, decimalPlaces = 1) => formatFloat((typeof value === 'number') ? value * 100 : value, decimalPlaces, value => <>{ value }<Unit>%</Unit></>);
export const formatSmallRatio = value => formatFloat((typeof value === 'number') ? value * 100 : value, 3, value => <>{ value }<Unit>%</Unit></>);
export const formatSeconds = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ value }<Unit>s</Unit></>);
export const formatSecondsToHours = (value, decimalPlaces = 1) => formatFloat((typeof value === 'number') ? value / 3_600 : value, decimalPlaces, value => <>{ value }<Unit>hrs</Unit></>);
export const formatKmhToMph = (value, decimalPlaces = 1) => formatFloat((typeof value === 'number') ? value * 0.6213711922 : value, decimalPlaces, value => <>{ value }<Unit>mph</Unit></>);
export const formatMph = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ value }<Unit>mph</Unit></>);
export const formatGramsToMetricTons = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ (value / 1_000_000).toFixed(decimalPlaces) }<Unit>t</Unit></>);
export const formatGramsInt = value => typeof value === 'number' ? (<>{ formatInt(value) }<Unit>g</Unit></>) : emptyValue;
export const formatGramsToKilograms = (value, decimalPlaces = 1) => formatFloat(value, decimalPlaces, value => <>{ (value / 1_000).toFixed(decimalPlaces) }<Unit>kg</Unit></>);
export const formatGramsToKilogramsInt = value => typeof value === 'number' ? (<>{ formatInt(value/1_000) }<Unit>kg</Unit></>) : emptyValue;
export const formatGallons = (value, decimalPlaces = 1) => typeof value === 'number' ? (<>{ formatFloat(value, decimalPlaces) }<Unit>gal</Unit></>) : emptyValue;

// detector-only columns at the end
const columnDefinitions = {
  network: {
    id: 'network',
    label: 'Network',
    minWidth: 220,
    align: 'left',
    getValue: row => row.network.name,
    isUnsortable: true,
  },
  signal: {
    id: 'signal',
    label: 'Intersection',
    minWidth: 130,
    getValue: row => row.signal.code,
    isUnsortable: true,
  },
  signalPhase: {
    id: 'signalPhase',
    label: 'Intersection • Phase',
    minWidth: 130,
    getValue: row => row.signal.code + ' • ' + row.phase.number,
    isUnsortable: true,
  },
  timeOfDay: {
    id: 'timeOfDay',
    label: 'Time Of Day',
    minWidth: 260,
    getValue: row => row?.tod?.humanName,
    isUnsortable: true,
  },
/*
  networkHealthScore: {
    id: 'networkHealthScore',
    label: 'Network Health Score',
    metricsView: 'operations',
    minWidth: 100,
    getValue: row => (typeof row.healthScore === 'number') ? (row.healthScore ? Math.round(row.healthScore) : 0) : null,
    format: (value, row) => <ScorePill score={ value } size="xlarge" className="mh-auto" />,
    sortColumn: 'healthScore',
  },
  intersectionHealthScore: {
    id: 'intersectionHealthScore',
    label: 'Intersection Health Score',
    metricsView: 'operations',
    minWidth: 100,
    getValue: row => (typeof row.healthScore === 'number') ? (row.healthScore ? Math.round(row.healthScore) : 0) : null,
    format: (value, row) => <ScorePill score={ value } size="xlarge" className="mh-auto" />,
    sortColumn: 'healthScore',
  },
  phaseHealthScore: {
    id: 'phaseHealthScore',
    label: 'Phase Health Score',
    metricsView: 'operations',
    minWidth: 100,
    getValue: row => (typeof row.healthScore === 'number') ? (row.healthScore ? Math.round(row.healthScore) : 0) : null,
    format: (value, row) => <ScorePill score={ value } size="xlarge" className="mh-auto" />,
    sortColumn: 'healthScore',
  },
  networkSafetyScore: {
    id: 'networkSafetyScore',
    label: 'Network Safety Score',
    metricsView: 'safety',
    minWidth: 100,
    getValue: row => (typeof row.safetyScore === 'number') ? (row.safetyScore ? Math.round(row.safetyScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'networkSafetyText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'safetyScore',
  },
  intersectionSafetyScore: {
    id: 'intersectionSafetyScore',
    label: 'Intersection Safety Score',
    metricsView: 'safety',
    minWidth: 100,
    getValue: row => (typeof row.safetyScore === 'number') ? (row.safetyScore ? Math.round(row.safetyScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'signalSafetyText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'safetyScore',
  },
  phaseSafetyScore: {
    id: 'phaseSafetyScore',
    label: 'Phase Safety Score',
    metricsView: 'safety',
    minWidth: 100,
    getValue: row => (typeof row.safetyScore === 'number') ? (row.safetyScore ? Math.round(row.safetyScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'phaseSafetyText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'safetyScore',
  },
  networkEnvironmentalScore: {
    id: 'networkEnvironmentalScore',
    label: 'Network Environmental Score',
    metricsView: 'environmental',
    minWidth: 100,
    getValue: row => (typeof row.environmentalScore === 'number') ? (row.environmentalScore ? Math.round(row.environmentalScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'networkEnvironmentalText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'environmentalScore',
  },
  intersectionEnvironmentalScore: {
    id: 'intersectionEnvironmentalScore',
    label: 'Intersection Environmental Score',
    metricsView: 'environmental',
    minWidth: 100,
    getValue: row => (typeof row.environmentalScore === 'number') ? (row.environmentalScore ? Math.round(row.environmentalScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'signalEnvironmentalText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'environmentalScore',
  },
  phaseEnvironmentalScore: {
    id: 'phaseEnvironmentalScore',
    label: 'Phase Environmental Score',
    metricsView: 'environmental',
    minWidth: 100,
    getValue: row => (typeof row.environmentalScore === 'number') ? (row.environmentalScore ? Math.round(row.environmentalScore) : 0) : null,
    format: (value, row) => value ? <div className={ `pa-2 ${ getScoreColor(value, 'phaseEnvironmentalText') }` }>{ value }</div> : emptyValue,
    sortColumn: 'environmentalScore',
  },
*/
  detectorHealthScore: {
    id: 'detectorHealthScore',
    label: 'Detector Health Score',
    // metricsView: 'operations',
    // minWidth: 100,
    getValue: row => (typeof row.detectorHealthScore === 'number') ? (row.detectorHealthScore ? Math.round(row.detectorHealthScore) : 0) : null,
    format: (value, row) => <div><ScoreWithDot score={ value } size="xlarge" className="tc" /></div>,
  },

  controlDelayMedian: {
    id: 'controlDelayMedian',
    label: 'Control Delay',
    subLabel: '(median)',
    metricsView: 'operations',
    format: formatSeconds,
  },
  levelOfServiceMedian: {
    id: 'levelOfServiceMedian',
    label: 'Median LOS',
    metricsView: 'operations',
  },
  controlDelay85th: {
    id: 'controlDelay85th',
    label: 'Control Delay',
    subLabel: <span className="nowrap">(85th percentile)</span>,
    metricsView: 'operations',
    minWidth: 100,
    format: formatSeconds,
  },
  levelOfServiceDefRatio: {
    id: 'levelOfServiceDefRatio',
    label: 'High Delay Percentage',
    subLabel: <span className="nowrap">(% of vehicles)</span>,
    getInfo: () => 'Percentage of vehicles experiencing a control delay of 60 seconds or longer.',
    metricsView: 'operations',
    format: formatRatio,
  },

  flowLabsVolumeCountPerHour: {
    id: 'flowLabsVolumeCountPerHour',
    buttonLabel: 'Volume',
    label: 'Average Hourly Volume',
    subLabel: <span className="nowrap">(# of vehicles)</span>,
    comparisonToolLabel: 'Daily Intersection Approaches',
    format: formatFloat,
  },
  flowLabsVolumeCountPerTod: {
    id: 'flowLabsVolumeCountPerTod',
    label: 'Daily Intersection Approaches',
    format: formatInt,
  },
  journeyCount: {
    id: 'journeyCount',
    label: 'Daily Vehicle Journeys',
    format: formatInt,
  },
  avgSplitFailuresPerTod: {
    id: 'avgSplitFailuresPerTod',
    label: 'Split Failures',
    subLabel: '(mean)',
    metricsView: 'operations',
    format: formatFloat,
  },
  avgSplitFailuresPerPhase: {
    id: 'avgSplitFailuresPerPhase',
    label: 'Split Failures',
    subLabel: '(% of phase activations)',
    metricsView: 'operations',
    format: formatRatio,
  },
  avgSplitFailuresPerCycle: {
    id: 'avgSplitFailuresPerCycle',
    label: 'Split Failures',
    subLabel: '(% of cycles)',
    metricsView: 'operations',
    format: formatRatio,
  },
  platoonRatio: {
    id: 'platoonRatio',
    label: 'Platoon Ratio',
    metricsView: 'operations',
    format: value => value.toFixed(2),
  },
  queueLengthMeanVehicleNumber: {
    id: 'queueLengthMeanVehicleNumber',
    label: 'Average Queue Length',
    subLabel: <span className="nowrap">(# of vehicles)</span>,
    metricsView: 'operations',
    format: formatFloat,
  },
  queueLengthMedianVehicleNumber: {
    id: 'queueLengthMedianVehicleNumber',
    buttonLabel: 'Queue Length',
    label: 'Median Queue Length',
    subLabel: <span className="nowrap">(# of vehicles)</span>,
    metricsView: 'operations',
    format: formatFloat,
  },
  // queueLengthMedianVehicleNumber: {
  // },
  queueLength15thVehicleNumber: {
    id: 'queueLength15thVehicleNumber',
    label: 'Queue Length 15th %',
    subLabel: <span className="nowrap">(# of vehicles)</span>,
    metricsView: 'operations',
    format: formatFloat,
  },
  queueLength85thVehicleNumber: {
    id: 'queueLength85thVehicleNumber',
    label: 'Queue Length 85th %',
    subLabel: <span className="nowrap">(# of vehicles)</span>,
    metricsView: 'operations',
    format: formatFloat,
  },
  arrivalsOnGreenRatio: {
    id: 'arrivalsOnGreenRatio',
    label: 'Arrivals on Green',
    metricsView: 'operations',
    format: formatRatio,
  },
  annualizedCostOfDelay: {
    id: 'annualizedCostOfDelay',
    label: <><span className="nowrap">Annual Cost</span>&nbsp;of Delay</>,
    metricsView: 'operations',
    format: formatCompactDollar,
  },
  annualizedFuelCost: {
    id: 'annualizedFuelCost',
    label: 'Annual Fuel Cost',
    metricsView: 'operations',
    format: formatCompactDollar,
  },
  hourlyVolumeControlDelay: {
    id: 'hourlyVolumeControlDelay',
    label: <>
      <span className="nowrap">Hourly Control</span>&nbsp;Delay
    </>,
    metricsView: 'operations',
    format: formatSecondsToHours,
  },

  throughLeftRedLightRunRatio: {
    id: 'throughLeftRedLightRunRatio',
    label: 'Red Light Running',
    subLabel: <span className="nowrap">(% of vehicles)</span>,
    metricsView: 'safety',
    format: value => formatRatio(value, 2),
  },
  speedLimitMode: {
    id: 'speedLimitMode',
    label: <>Speed Limit</>,
    metricsView: 'safety',
    format: value => formatKmhToMph(value, 0),
  },
  approachVelocityMedian: {
    id: 'approachVelocityMedian',
    label: 'Approach Speed',
    subLabel: '(median)',
    metricsView: 'safety',
    format: formatKmhToMph,
  },
  approachVelocity85th: {
    id: 'approachVelocity85th',
    label: 'Approach Speed',
    subLabel: <span className="nowrap">(85th percentile)</span>,
    metricsView: 'safety',
    format: formatKmhToMph,
  },
  speedLimitMphMode: {
    id: 'speedLimitMphMode',
    label: <>Speed Limit</>,
    metricsView: 'safety',
    format: value => formatMph(value, 0),
    sortColumn: 'speedLimitMode',
  },
  approachVelocityMphMedian: {
    id: 'approachVelocityMphMedian',
    label: 'Approach Speed',
    subLabel: '(median)',
    metricsView: 'safety',
    format: formatMph,
    sortColumn: 'approachVelocityMedian',
  },
  approachVelocityMph85th: {
    id: 'approachVelocityMph85th',
    label: 'Approach Speed',
    subLabel: <span className="nowrap">(85th percentile)</span>,
    metricsView: 'safety',
    format: formatMph,
    sortColumn: 'approachVelocity85th',
  },
  dilemmaZoneRatio: {
    id: 'dilemmaZoneRatio',
    label: 'Dilemma Zone Entry',
    subLabel: <span className="nowrap">(% of vehicles)</span>,
    metricsView: 'safety',
    format: value => formatRatio(value, 2),
  },
  deceleration10FpssRatio: {
    id: 'deceleration10FpssRatio',
    label: 'Dangerous Braking',
    subLabel: '(% of vehicles)',
    metricsView: 'safety',
    format: value => formatRatio(value, 2),
  },
  excessiveSpeedLeftRatio: {
    id: 'excessiveSpeedLeftRatio',
    label: 'Excessive Speed',
    subLabel: <span className="nowrap">(% of left turns)</span>,
    metricsView: 'safety',
    format: formatRatio,
  },
  excessiveSpeedRightRatio: {
    id: 'excessiveSpeedRightRatio',
    label: 'Excessive Speed',
    subLabel: <span className="nowrap">(% of right turns)</span>,
    metricsView: 'safety',
    format: formatRatio,
  },
  postClearanceAllMovementRedLightRunRatio: {
    id: 'postClearanceAllMovementRedLightRunRatio',
    label: 'Red Light Running',
    subLabel: <span className="nowrap">(% of vehicles)</span>,
    format: formatSmallRatio,
  },
  co2EquivalentGramsVolumeWeightedTotal: {
    id: 'co2EquivalentGramsVolumeWeightedTotal',
    buttonLabel: <>CO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle CO<sub>2</sub></span>&nbsp;<span className="nowrap">Emissions</span></>,
    subLabel: <span className="nowrap">(kilograms, 28-day total)</span>,
    metricsView: 'environmental',
    format: formatGramsToKilogramsInt,
  },
  co2EquivalentGramsVolumeWeightedTotalRa: {
    id: 'co2EquivalentGramsVolumeWeightedTotalRa',
    buttonLabel: <>CO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle CO<sub>2</sub></span>&nbsp;<span className="nowrap">Emissions</span></>,
    subLabel: <span className="nowrap">(kilograms)</span>,
    metricsView: 'environmental',
    format: formatGramsToKilogramsInt,
  },
  fuelLitresVolumeWeightedTotal: {
    id: 'fuelLitresVolumeWeightedTotal',
    label: 'Fuel Consumption',
    getInfo: () => <span>The CO<sub>2</sub> equivalent <span className="semibold">total emissions</span> over the <span className="semibold">Last 28 days</span> for the Time Of Day indicated.</span>,
    metricsView: 'environmental',
    format: formatGramsToKilograms,
  },
  fuelGallonsVolumeWeightedTotal: {
    id: 'fuelGallonsVolumeWeightedTotal',
    buttonLabel: 'Fuel Consumption',
    label: <span className="nowrap">Total Fuel Consumption</span>,
    subLabel: <span className="nowrap">(gallons, 28-day total)</span>,
    getInfo: () => <span>The fuel consumption total (for the Time Of Day) over the <span className="semibold">Last 28 days</span>.</span>,
    metricsView: 'environmental',
    format: formatGallons,
  },
  fuelGallonsVolumeWeightedTotalRa: {
    id: 'fuelGallonsVolumeWeightedTotalRa',
    buttonLabel: 'Fuel Consumption',
    label: <span className="nowrap">Total Fuel Consumption</span>,
    subLabel: <span className="nowrap">(gallons)</span>,
    metricsView: 'environmental',
    format: formatGallons,
  },
  controlFuelGallonsVolumeWeightedTotal: {
    id: 'controlFuelGallonsVolumeWeightedTotal',
    buttonLabel: 'Ctrl Fuel Consumption',
    label: <span className="nowrap">Total Control Fuel Consumption</span>,
    subLabel: <span className="nowrap">(gallons, 28-day total)</span>,
    getInfo: () => <span>The control fuel consumption total (for the Time Of Day) over the <span className="semibold">Last 28 days</span>.</span>,
    metricsView: 'environmental',
    format: formatGallons,
  },
  controlFuelGallonsVolumeWeightedTotalRa: {
    id: 'controlFuelGallonsVolumeWeightedTotalRa',
    buttonLabel: 'Ctrl Fuel Consumption',
    label: <span className="nowrap">Total Control Fuel Consumption</span>,
    subLabel: <span className="nowrap">(gallons)</span>,
    metricsView: 'environmental',
    format: formatGallons,
  },
  controlCo2EquivalentGramsVolumeWeightedMean: {
    id: 'controlCo2EquivalentGramsVolumeWeightedMean',
    label: <>Per Journey CO<sub>2</sub>&nbsp;Control Emissions</>,
    subLabel: <span className="nowrap">(grams)</span>,
    metricsView: 'environmental',
    format: formatGramsInt,
  },
  controlCo2EquivalentGramsVolumeWeightedTotal: {
    id: 'controlCo2EquivalentGramsVolumeWeightedTotal',
    buttonLabel: <>Ctrl CO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle CO<sub>2</sub></span>&nbsp;<span className="nowrap">Control Emissions</span></>,
    subLabel: <span className="nowrap">(kilograms, 28-day total)</span>,
    metricsView: 'environmental',
    format: formatGramsToKilogramsInt,
  },
  controlCo2EquivalentGramsVolumeWeightedTotalRa: {
    id: 'controlCo2EquivalentGramsVolumeWeightedTotalRa',
    buttonLabel: <>Ctrl CO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle CO<sub>2</sub></span>&nbsp;<span className="nowrap">Control Emissions</span></>,
    subLabel: <span className="nowrap">(kilograms)</span>,
    metricsView: 'environmental',
    format: formatGramsToKilogramsInt,
  },
  no2GramsVolumeWeightedTotal: {
    id: 'no2GramsVolumeWeightedTotal',
    buttonLabel: <>NO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle NO<sub>2</sub></span>&nbsp;<span className="nowrap">Emissions</span></>,
    subLabel: <span className="nowrap">(grams, 28-day total)</span>,
    metricsView: 'environmental',
    format: formatGramsInt,
  },
  no2GramsVolumeWeightedTotalRa: {
    id: 'no2GramsVolumeWeightedTotalRa',
    buttonLabel: <>NO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle NO<sub>2</sub></span>&nbsp;<span className="nowrap">Emissions</span></>,
    subLabel: <span className="nowrap">(grams)</span>,
    metricsView: 'environmental',
    format: formatGramsInt,
  },
  controlNo2GramsVolumeWeightedTotal: {
    id: 'controlNo2GramsVolumeWeightedTotal',
    buttonLabel: <>Ctrl NO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle NO<sub>2</sub></span>&nbsp;<span className="nowrap">Control Emissions</span></>,
    subLabel: <span className="nowrap">(grams, 28-day total)</span>,
    metricsView: 'environmental',
    format: formatGramsInt,
  },
  controlNo2GramsVolumeWeightedTotalRa: {
    id: 'controlNo2GramsVolumeWeightedTotalRa',
    buttonLabel: <>Ctrl NO<sub>2</sub>&nbsp;Emissions</>,
    label: <><span className="nowrap">Total Vehicle NO<sub>2</sub></span>&nbsp;<span className="nowrap">Control Emissions</span></>,
    subLabel: <span className="nowrap">(grams)</span>,
    metricsView: 'environmental',
    format: formatGramsInt,
  },

  // cycleTime is only for networks and signals
  programmedCycleTimeMode: {
    id: 'programmedCycleTimeMode',
    label: 'Programmed Cycle',
    metricsView: 'operations',
    format: formatSeconds,
  },
  programmedCycleTimeAvg: {
    id: 'programmedCycleTimeAvg',
    label: 'Avg Programmed Cycle',
    metricsView: 'operations',
    format: formatSeconds,
  },

  // phase-only columns below
  avgForceOffsPerPhase: {
    id: 'avgForceOffsPerPhase',
    label: 'Force-Offs',
    subLabel: '(% of activations)',
    metricsView: 'operations',
    format: formatRatio,
  },
  avgForceOffsPerCycle: {
    id: 'avgForceOffsPerCycle',
    label: 'Force-Offs',
    subLabel: '(% of cycles)',
    metricsView: 'operations',
    format: formatRatio,
  },
  avgGapOutsPerPhase: {
    id: 'avgGapOutsPerPhase',
    label: 'Gap-Outs',
    subLabel: '(% of activations)',
    metricsView: 'operations',
    format: formatRatio,
  },
  avgGapOutsPerCycle: {
    id: 'avgGapOutsPerCycle',
    label: 'Gap-Outs',
    subLabel: '(% of cycles)',
    metricsView: 'operations',
    format: formatRatio,
  },
  avgMaxOutsPerPhase: {
    id: 'avgMaxOutsPerPhase',
    label: 'Max-Outs',
    subLabel: '(% of activations)',
    metricsView: 'operations',
    format: formatRatio,
  },
  phaseSkipsPerCycle: {
    id: 'phaseSkipsPerCycle',
    label: 'Skips',
    subLabel: '(% of cycles)',
    metricsView: 'operations',
    format: formatRatio,
  },
  programmedSplitTimeMode: {
    id: 'programmedSplitTimeMode',
    label: 'Programmed Split',
    metricsView: 'operations',
    format: formatSeconds,
  },
  avgGreenTimePerCycle: {
    id: 'avgGreenTimePerCycle',
    label: <span className="nowrap">Green Time</span>,
    subLabel: '(per Cycle)',
    metricsView: 'operations',
    format: formatSeconds,
  },
  avgGreenOccupancyRatioPerPhase: {
    id: 'avgGreenOccupancyRatioPerPhase',
    label: 'Green Occupancy Ratio',
    subLabel: '(mean)',
    metricsView: 'operations',
    format: formatRatio,
  },
  greenOccupancyRatio85th: {
    id: 'greenOccupancyRatio85th',
    label: 'Green Occupancy Ratio',
    subLabel: <span className="nowrap">(85th percentile)</span>,
    metricsView: 'operations',
    format: formatRatio,
  },
  avgRed5OccupancyRatioPerPhase: {
    id: 'avgRed5OccupancyRatioPerPhase',
    label: 'Red Occupancy Ratio',
    subLabel: '(mean)',
    metricsView: 'operations',
    format: formatRatio,
  },
  red5OccupancyRatio85th: {
    id: 'red5OccupancyRatio85th',
    label: 'Red Occupancy Ratio',
    subLabel: <span className="nowrap">(85th percentile)</span>,
    metricsView: 'operations',
    format: formatRatio,
  },
  aggressiveBrakingCause: {
    id: 'aggressiveBrakingCause',
    label: 'Cause of Hard Braking',
  },


  // detector-only columns
  detector: {
    id: 'detector',
    label: 'Detector ID',
    minWidth: 100,
    getValue: row => row.detector?.code,
    isUnsortable: true,
  },
  movement: {
    id: 'movement',
    label: 'Movement',
    minWidth: 180,
    getValue: row => row.detector?.approach?.directionType + ' • ' + row.detector?.movementType,
    isUnsortable: true,
  },
  detectionTypes: {
    id: 'detectionTypes',
    label: 'Detector Type',
    minWidth: 220,
    align: 'left',
    getValue: row => row.detector?.detectionTypes,
    isUnsortable: true,
  },
  volumeDiscrepancy: {
    id: 'volumeDiscrepancy',
    label: 'Volume Error',
    minWidth: 100,
    format: formatPercentageWithSign,
    getInfo: () => <span>The difference between volume estimates from <span className="semibold">Flow Labs virtual sensors™</span> and the count estimates from your detectors.</span>,
  },
  volumeDiscrepancyIndex: {
    id: 'volumeDiscrepancyIndex',
    label: 'Volume Error Index',
    minWidth: 100,
    format: formatFloat,
    getInfo: () => '',
  },
  vehicleCaptureIndex: {
    id: 'vehicleCaptureIndex',
    label: 'Vehicle Capture Index',
    minWidth: 100,
    format: formatFloat,
    getInfo: () => 'The proportion of vehicles that the detector is capturing. A low index means one or more of the detectors are dropping calls',
  },
  correlationIndex: {
    id: 'correlationIndex',
    label: 'Correlation Index',
    minWidth: 100,
    format: formatFloat,
    getInfo: () => 'How closely detector calls are correlated with connected vehicle arrivals. A low index means that the detector is either dropping calls or picking up calls from another lane.',
  },

  // route-only columns
  travelTimeMedian: {
    id: 'travelTimeMedian',
    label: 'Travel Time',
    subLabel: '(median)',
    metricsView: 'mobility',
    format: formatSeconds,
  },
  stopCountMean: {
    id: 'stopCountMean',
    label: 'Stops per Vehicle',
    subLabel: '(mean)',
    metricsView: 'mobility',
    format: formatFloat,
  },
  controlDelayMean: {
    id: 'controlDelayMean',
    label: 'Total Control Delay',
    subLabel: '(mean)',
    comparisonToolLabel: <span>Control Delay (mean)</span>,
    metricsView: 'mobility',
    format: formatSeconds,
  },
  controlDelayMeanMain: {
    id: 'controlDelayMeanMain',
    label: <span className="pl-8">Mainline delay</span>,
    subLabel: '(mean)',
    format: formatSeconds,
  },
  controlDelayMeanSide: {
    id: 'controlDelayMeanSide',
    label: <span className="pl-8">Side Street Delay</span>,
    subLabel: '(mean)',
    format: formatSeconds,
  },
  controlDelayTotal: {
    id: 'controlDelayTotal',
    label: 'Network Delay',
    format: formatSecondsToHours,
  },
  forwardTravelTimeMedian: {
    id: 'forwardTravelTimeMedian',
    format: formatSeconds,
  },
  reverseTravelTimeMedian: {
    id: 'reverseTravelTimeMedian',
    format: formatSeconds,
  },
  direction: {
    id: 'direction',
    label: 'Direction',
    metricsView: 'mobility',
    format: formatDirection,
  },
};

export default columnDefinitions;
