const mapping = {
  EB: 'Eastbound',
  NB: 'Northbound',
  SB: 'Southbound',
  WB: 'Westbound',
};

const formatDirection = key => mapping[key];

export default formatDirection;
