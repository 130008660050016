import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import useMapbox from './useMapbox';
import useAppController from '../../hooks/useAppController';
import useGoTo from '../../hooks/useGoTo';

import useMapboxSystemsAndNetworks from './useMapboxSystemsAndNetworks';
import useMapboxSignalsAndPhases from './useMapboxSignalsAndPhases';
import useMapboxTiles from './useMapboxTiles';
import useMapboxNetworkLabels from './useMapboxNetworkLabels';

const MapContainerAndTileManager = () => {
  const goTo = useGoTo('map');
  const goToMaintain = useGoToMaintain('map');
  const [bounds, setBounds] = useState(null);
  const [basePadding, setBasePadding] = useState(0);
  const [padding, setPadding] = useState({});
  const mapRef = useRef(null);
  const { mapController } = useAppController();

  const {
    mapBoundsSw0,
    mapBoundsSw1,
    mapBoundsNe0,
    mapBoundsNe1,
    mapBasePadding,
    mapPaddingTop,
    mapPaddingRight,
    mapPaddingBottom,
    mapPaddingLeft,
    setMapContainer,
    mapContainer,
    setMap,
  } = mapController;

  useEffect(() => {
    setBounds({
      sw: [mapBoundsSw0, mapBoundsSw1],
      ne: [mapBoundsNe0, mapBoundsNe1],
    });

    setBasePadding(mapBasePadding);

    setPadding({
      top: mapPaddingTop,
      right: mapPaddingRight,
      bottom: mapPaddingBottom,
      left: mapPaddingLeft,
    });
  }, [mapBoundsSw0, mapBoundsSw1, mapBoundsNe0, mapBoundsNe1, mapBasePadding, mapPaddingTop, mapPaddingRight, mapPaddingBottom, mapPaddingLeft]);

  const mapboxResult = useMapbox({
    mapContainer,
    mapRef,
    bounds,
    basePadding,
    padding,
  });

  const {
    // isMapLoading,
    map,
    // isMapTransitioning,
    // zoom,
    // tileBounds,
  } = mapboxResult;

  useEffect(() => {
    setMap(map);
  }, [setMap, map]);

  useMapboxTiles(mapboxResult);
  useMapboxSystemsAndNetworks(mapboxResult);
  useMapboxNetworkLabels(map);
  useMapboxSignalsAndPhases(mapboxResult);

  /*
  useEffect(() => {
    const onKeyPress = e => {
      switch (e.key) {
        case '1':
        case '2':
        case '3':
        case '4':
          goToMaintain({
            focus: 'network',
            networkId: e.key,
            tool: 'abacus',
            todId: null,
            abacusMetric: null,
            routeId: null,
            signalId: null,
            phaseId: null,
          });
          break;

        case 'r':
          goTo({
            regionId: '1',
          });
          break;

        default:
          break;
      }
    };

    document.addEventListener('keypress', onKeyPress)

    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  return (
    <div className="absolute fill">
      <div className="relative w-100% h-100% overflow-hidden">
        <div
          key="mapContainer"
          className="w-100% h-100%"
          ref={
            elem => {
              if (elem) {
                setMapContainer(elem);
              }
            }
          }
        />
      </div>
    </div>
  );
};

export default observer(MapContainerAndTileManager);
