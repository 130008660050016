import { colors } from '../components/FlowMaterialTheme';
import { formatFloat, formatInt, formatRatio, formatZeroIfFalsy } from './columnDefinitions';
import getMonthAbbreviation from '../helpers/getMonthAbbreviation';


// TODO: Move to/import from columnDefinitions?
const formatSecond = value => (typeof value === 'number') ? value.toFixed(1) + 's' : null;

export const SignalCodeTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={3} dx={0} textAnchor="end" className="fill-gray2 normal" transform="rotate(-65)" fontSize="10">
        { payload.value }
      </text>
    </g>
  );
};


export const formatCurrChartToChartName = currChart => ALL_BAR_CHARTS.includes(currChart) ? currChart : currChart.split('_')[1]; // e.g. phase_greenTimeHistograms => greenTimeHistograms

// TODO: Pull title from columnDefinitions?
export const CHART_NAMES_TO_TITLES = {
  // Time Series
  healthScoreTimeSeries: 'Health Score Time Series',
  detectorHealthScoreTimeSeries: 'Detector Health Score Time Series',
  flowLabsVolumeCountPerHourTimeSeries: 'Volume Count Per Hour Time Series',
  programmedSplitTimeModeTimeSeries: 'Programmed Split Time Series',
  programmedCycleTimeModeTimeSeries: 'Programmed Cycle Time Series',
  avgGreenTimePerCycleTimeSeries: 'Avg Green Time Per Cycle Time Series',
  arrivalsOnGreenRatioTimeSeries: 'Arrivals On Green (AOG) Ratio Time Series',
  controlDelayMedianTimeSeries: 'Control Delay Time Series',
  queueLengthMedianVehicleNumberTimeSeries: 'Queue Length Time Series',
  avgSplitFailuresPerPhaseTimeSeries: 'Avg Split Failures Per Phase Time Series',
  avgSplitFailuresPerCycleTimeSeries: 'Avg Split Failures Per Cycle Time Series',
  platoonRatioTimeSeries: 'Platoon Ratio Time Series',
  phaseSkipsPerCycleTimeSeries: 'Skips Per Cycle Time Series',
  avgForceOffsPerCycleTimeSeries: 'Avg Force Offs Per Cycle Time Series',
  avgGapOutsPerCycleTimeSeries: 'Avg Gap Outs Per Cycle Time Series',
  throughLeftRedLightRunRatioTimeSeries: 'Red Light Running (RLR) Ratio Time Series',
  dilemmaZoneRatioTimeSeries: 'Dilemma Zone Entry (DZE) Ratio Time Series',
  deceleration10FpssRatioTimeSeries: 'Dangerous Braking Ratio Time Series',
  approachVelocityMphMedianTimeSeries: 'Approach Velocity Time Series',
  stopCountMeanTimeSeries: 'Stops Per Vehicle Time Series',
  travelTimeMedianTimeSeries: 'Travel Time Time Series',
  controlDelayMeanTimeSeries: 'Total Mainline Control Delay Time Series',
  fuelGallonsVolumeWeightedTotalRaTimeSeries: 'Total Fuel Consumption Time Series',
  controlFuelGallonsVolumeWeightedTotalRaTimeSeries: 'Total Control Fuel Consumption Time Series',
  co2EquivalentGramsVolumeWeightedTotalRaTimeSeries: 'Total Vehicle CO2 Emissions Time Series',
  controlCo2EquivalentGramsVolumeWeightedTotalRaTimeSeries: 'Total Vehicle CO2 Control Emissions Total Time Series',
  controlCo2EquivalentGramsVolumeWeightedMeanTimeSeries: 'Per Journey Vehicle CO2 Control Emissions Time Series',
  no2GramsVolumeWeightedTotalRaTimeSeries: 'Total Vehicle NO2 Emissions Time Series',
  controlNo2GramsVolumeWeightedTotalRaTimeSeries: 'Total Vehicle NO2 Control Emissions Time Series',
  // Bar Charts
  flowLabsVolumeCountPerHourBySignalBarChart: 'Volume Count Per Hour By Signal',
  controlDelayMedianBySignalBarChart: "Control Delay By Signal", // Network Level Control Delay
  arrivalsOnGreenRatioBySignalBarChart: "Arrivals On Green (AOG) Ratio By Signal",
  platoonRatioBySignalBarChart: "Platoon Ratio By Signal",
  avgSplitFailuresPerCycleBySignalBarChart: "Avg Split Failures Per Cycle By Signal",
  queueLengthMedianVehicleNumberBySignalBarChart: "Queue Length By Signal",
  programmedCycleTimeModeBySignalBarChart: "Programmed Cycle By Signal",
  avgGreenTimePerCycleBySignalBarChart: "Avg Green Time Per Cycle By Signal",
  throughLeftRedLightRunRatioBySignalBarChart: "Red Light Running (RLR) Ratio By Signal",
  dilemmaZoneRatioBySignalBarChart: "Dilemma Zone Entry (DZE) Ratio By Signal",
  deceleration10FpssRatioBySignalBarChart: "Dangerous Braking Ratio By Signal",
  approachVelocityMphMedianBySignalBarChart: "Approach Velocity By Signal",
  stopCountMeanBySignalBarChart: "Stops Per Vehicle By Signal", // Route Level Stop Count
  controlDelayMeanBySignalBarChart: "Control Delay By Signal", // Route Level Control Delay
  flowLabsVolumeCountPerHourByPhaseBarChart: "Volume Count Per Hour By Phase",
  controlDelayMedianByPhaseBarChart: "Control Delay By Phase",
  arrivalsOnGreenRatioByPhaseBarChart: "Arrivals On Green (AOG) Ratio By Phase",
  phaseSkipsPerCycleByPhaseBarChart: "Skips Per Cycle By Phase",
  platoonRatioByPhaseBarChart: "Platoon Ratio By Phase",
  avgSplitFailuresPerCycleByPhaseBarChart: "Avg Split Failures Per Cycle By Phase",
  queueLengthMedianVehicleNumberByPhaseBarChart: "Queue Length By Phase",
  avgForceOffsPerCycleByPhaseBarChart: "Avg Force Offs Per Cycle By Phase",
  avgGapOutsPerCycleByPhaseBarChart: "Avg Gap Outs Per Cycle By Phase",
  programmedSplitTimeModeByPhaseBarChart: "Programmed Split By Phase",
  avgGreenTimePerCycleByPhaseBarChart: "Avg Green Time Per Cycle By Phase",
  throughLeftRedLightRunRatioByPhaseBarChart: "Red Light Running (RLR) Ratio By Phase",
  dilemmaZoneRatioByPhaseBarChart: "Dilemma Zone Entry (DZE) Ratio By Phase",
  deceleration10FpssRatioByPhaseBarChart: "Dangerous Braking Ratio By Phase",
  approachVelocityMphMedianByPhaseBarChart: "Approach Velocity By Phase",
  controlFuelGallonsVolumeWeightedTotalRaByPhaseBarChart: "Total Control Fuel Consumption By Phase",
  controlCo2EquivalentGramsVolumeWeightedTotalRaByPhaseBarChart: "Total Vehicle CO2 Control Emissions By Phase",
  controlNo2GramsVolumeWeightedTotalRaByPhaseBarChart: "Total Vehicle NO2 Control Emissions By Phase",
  // Histograms
  greenTimeHistograms: 'Phase Terminations',
  approachSpeedHistogram: 'Speeding', // Phase Level Speeding
  arrivalModulusCycleLengthHistogram: 'Arrivals On Green (AOG)',
  RLRHistogram: 'Redlight Running (RLR)', 
  aggressiveBrakingHistogram: 'Aggressive Braking',
  controlDelayHistogram: 'Control Delay',
  aggressiveBrakingCauseBarChart: 'Dilemma Zone Entry: Aggressive Braking Cause',
  speedHistogram: 'Speeding', // Edges Level Speeding
  delayHistogram: 'Control Delay', // Edge Level Control Delay
  minAccelerationHistogram: 'Min Acceleration',
  maxAccelerationHistogram: 'Max Acceleration',
};


/* TODO: Automate generation of Chart Fields (along with Chart Field Configs)
         since chart fields are the same for a chart type.
         Differences in fields come up when looking across traffic entity (TE) levels (Phase, Signal, etc).
         Automating differences across TE levels for Chart Field Configs would be more involved.
           These differences would require having a specific TE name for the same field, e.g. healthScore ->  phase_healthScore, signal_healthScore, etc . *See histogram chart fields and their configs.
           For now, we are dynamically adding those different configs in the Chart component, e.g. `name: phaseId ? 'Phase Health Score' : 'Intersection Health Score'`.
*/

/*
  TIME_SERIES_CHART_FIELDS Conventions:
    Time series chart fields are based on currChart->abacusMetric param.
    List X Axis field for each graph last; for our own automation purposes.
    Note that the order of the fields matters. (e.g. re-charts Bar Charts stack Bars in order, i.e. first at bottom, last at top)
 */

const NETWORK_TIME_SERIES_CHART_FIELDS = {
  network_flowLabsVolumeCountPerHourTimeSeries: [
    'flowLabsVolumeCountPerHour',
    'referenceDate',
  ],
  network_avgGreenTimePerCycleTimeSeries: [
    'avgGreenTimePerCycle',
    'referenceDate',
  ],
  network_controlDelayMedianTimeSeries: [
    'controlDelay85th',
    'controlDelayMedian',
    'flowLabsVolumeCountPerHour',
    'referenceDate',
  ],
  network_arrivalsOnGreenRatioTimeSeries: [
    'arrivalsOnGreenRatio',
    'referenceDate',
  ], // todo add signal offset
  network_platoonRatioTimeSeries: [
    'platoonRatio',
    'referenceDate',
  ],
  network_avgSplitFailuresPerCycleTimeSeries: [
    'avgSplitFailuresPerCycle',
    'referenceDate',
  ],
  network_queueLengthMedianVehicleNumberTimeSeries: [
    'queueLength85thVehicleNumber',
    'queueLengthMedianVehicleNumber',
    'queueLength15thVehicleNumber',
    'referenceDate',
  ],
  network_throughLeftRedLightRunRatioTimeSeries: [
    'throughLeftRedLightRunRatio',
    'referenceDate',
  ],
  network_dilemmaZoneRatioTimeSeries: [
    'dilemmaZoneRatio',
    'referenceDate',
  ],
  network_deceleration10FpssRatioTimeSeries: [
    'deceleration10FpssRatio',
    'referenceDate',
  ],
  network_approachVelocityMphMedianTimeSeries: [
    'approachVelocityMph85th',
    'approachVelocityMphMedian',
    'approachVelocityMph15th',
    'speedLimitMphMode',
    'referenceDate',
  ],
  network_fuelGallonsVolumeWeightedTotalRaTimeSeries: [
    'fuelGallonsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  network_co2EquivalentGramsVolumeWeightedTotalRaTimeSeries: [
    'co2EquivalentGramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  network_no2GramsVolumeWeightedTotalRaTimeSeries: [
    'no2GramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
};

const ROUTE_TIME_SERIES_CHART_FIELDS = {
  route_controlDelayMeanTimeSeries: [
    'controlDelayMean',
    'referenceDate',
  ],
  route_travelTimeMedianTimeSeries: [
    'travelTime85th',
    'travelTimeMedian',
    'travelTime15th',
    'referenceDate',
  ],
  route_stopCountMeanTimeSeries: [
    'stopCountMean',
    'referenceDate',
  ],
  // 'completeIntersections.controlDelayMean': ['completeIntersections.controlDelayMean', 'referenceDate'],
  // 'completeIntersections.stopCountMean': ['completeIntersections.stopCountMean', 'referenceDate'],
  // completeStopCountMean: ['completeStopCountMean', 'referenceDate'],
  // completeControlDelayMean: ['completeControlDelayMean', 'referenceDate'],
};

const SIGNAL_TIME_SERIES_CHART_FIELDS = {
  // signal_healthScoreTimeSeries: [
  //   'flowLabsVolumeCountPerHour',
  //   'programmedCycleTimeMode',
  //   'detectorHealthScore',
  //   'healthScore',
  //   'referenceDate'
  // ],
  signal_flowLabsVolumeCountPerHourTimeSeries: [
    'flowLabsVolumeCountPerHour',
    'referenceDate',
  ],
  signal_programmedCycleTimeModeTimeSeries: [
    'programmedCycleTimeMode',
    'referenceDate',
  ],
  signal_avgGreenTimePerCycleTimeSeries: [
    'avgGreenTimePerCycle',
    'referenceDate',
  ],
  signal_arrivalsOnGreenRatioTimeSeries: [
    'arrivalsOnGreenRatio',
    'referenceDate',
  ], // todo add signal offset
  signal_controlDelayMedianTimeSeries: [
    'controlDelay85th',
    'controlDelayMedian',
    'referenceDate',
  ],
  signal_queueLengthMedianVehicleNumberTimeSeries: [
    'queueLength85thVehicleNumber',
    'queueLengthMedianVehicleNumber',
    'queueLength15thVehicleNumber',
    'referenceDate',
  ],
  signal_platoonRatioTimeSeries: [
    'platoonRatio',
    'referenceDate',
  ],
  // signal_avgSplitFailuresPerPhaseTimeSeries: [
  //   'flowLabsVolumeCountPerHour',
  //   'programmedCycleTimeMode',
  //   'avgSplitFailuresPerPhase',
  //   'referenceDate',
  // ],
  signal_avgSplitFailuresPerCycleTimeSeries: [
    'avgSplitFailuresPerCycle',
    'referenceDate',
  ],
  signal_throughLeftRedLightRunRatioTimeSeries: [
    'throughLeftRedLightRunRatio',
    'referenceDate',
  ],
  signal_dilemmaZoneRatioTimeSeries: [
    'dilemmaZoneRatio',
    'referenceDate',
  ],
  signal_deceleration10FpssRatioTimeSeries: [
    'deceleration10FpssRatio',
    'referenceDate',
  ],
  signal_approachVelocityMphMedianTimeSeries: [
    'approachVelocityMph85th',
    'approachVelocityMphMedian',
    'approachVelocityMph15th',
    'speedLimitMphMode',
    'referenceDate',
  ],
  signal_controlFuelGallonsVolumeWeightedTotalRaTimeSeries: [
    'controlFuelGallonsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  signal_controlCo2EquivalentGramsVolumeWeightedTotalRaTimeSeries: [
    'controlCo2EquivalentGramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  signal_controlNo2GramsVolumeWeightedTotalRaTimeSeries: [
    'controlNo2GramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  // signal_controlCo2EquivalentGramsVolumeWeightedMeanTimeSeries: [
  //   'flowLabsVolumeCountPerHour',
  //   'programmedCycleTimeMode',
  //   'controlCo2EquivalentGramsVolumeWeightedMean',
  //   'referenceDate',
  // ],
};

const PHASE_TIME_SERIES_CHART_FIELDS = {
  // phase_healthScoreTimeSeries: [
  //   'flowLabsVolumeCountPerHour',
  //   'programmedSplitTimeMode',
  //   'detectorHealthScore',
  //   'healthScore',
  //   'referenceDate',
  // ],
  phase_flowLabsVolumeCountPerHourTimeSeries: [
    'flowLabsVolumeCountPerHour',
    'referenceDate',
  ],
  phase_programmedSplitTimeModeTimeSeries: [
    'programmedSplitTimeMode',
    'referenceDate',
  ],
  phase_avgGreenTimePerCycleTimeSeries: [
    'avgGreenTimePerCycle',
    'referenceDate',
  ],
  phase_arrivalsOnGreenRatioTimeSeries: [
    'arrivalsOnGreenRatio',
    'referenceDate',
  ], // todo add signal offset
  phase_controlDelayMedianTimeSeries: [
    'controlDelay85th',
    'controlDelayMedian',
    'referenceDate',
  ],
  phase_queueLengthMedianVehicleNumberTimeSeries: [
    'queueLength85thVehicleNumber',
    'queueLengthMedianVehicleNumber',
    'queueLength15thVehicleNumber',
    'referenceDate',
  ],
  phase_phaseSkipsPerCycleTimeSeries: [
    'phaseSkipsPerCycle',
    'referenceDate',
  ],
  phase_platoonRatioTimeSeries: [
    'platoonRatio',
    'referenceDate',
  ],
  phase_avgSplitFailuresPerPhaseTimeSeries: [
    'avgSplitFailuresPerPhase',
    'referenceDate',
  ],
  phase_avgSplitFailuresPerCycleTimeSeries: [
    'avgSplitFailuresPerCycle',
    'referenceDate',
  ],
  phase_avgForceOffsPerCycleTimeSeries: [
    'avgForceOffsPerCycle',
    'referenceDate',
  ],
  phase_avgGapOutsPerCycleTimeSeries: [
    'avgGapOutsPerCycle',
    'referenceDate',
  ],
  phase_throughLeftRedLightRunRatioTimeSeries: [
    'throughLeftRedLightRunRatio',
    'referenceDate',
  ],
  phase_dilemmaZoneRatioTimeSeries: [
    'dilemmaZoneRatio',
    'referenceDate',
  ],
  phase_deceleration10FpssRatioTimeSeries: [
    'deceleration10FpssRatio',
    'referenceDate',
  ],
  phase_approachVelocityMphMedianTimeSeries: [
    'approachVelocityMph85th',
    'approachVelocityMphMedian',
    'approachVelocityMph15th',
    'speedLimitMphMode',
    'referenceDate',
  ],
  phase_detectorHealthScoreTimeSeries: [
    'detectorHealthScore',
    'referenceDate',
  ],
  phase_controlFuelGallonsVolumeWeightedTotalRaTimeSeries: [
    'controlFuelGallonsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  phase_controlCo2EquivalentGramsVolumeWeightedTotalRaTimeSeries: [
    'controlCo2EquivalentGramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  phase_controlNo2GramsVolumeWeightedTotalRaTimeSeries: [
    'controlNo2GramsVolumeWeightedTotalRa',
    'referenceDate',
  ],
  // phase_controlCo2EquivalentGramsVolumeWeightedMeanTimeSeries: [
  //   'flowLabsVolumeCountPerHour',
  //   'programmedSplitTimeMode',
  //   'controlCo2EquivalentGramsVolumeWeightedMean',
  //   'referenceDate',
  // ],
};

const ALL_TIME_SERIES_CHART_FIELDS = {
  ...NETWORK_TIME_SERIES_CHART_FIELDS,
  ...ROUTE_TIME_SERIES_CHART_FIELDS,
  ...SIGNAL_TIME_SERIES_CHART_FIELDS,
  ...PHASE_TIME_SERIES_CHART_FIELDS,
};


/*
  BAR_CHART_CHART_FIELDS Conventions:
    For bar charts with categorical X axes, whereas Histograms are for those with continuous X axes.
    List X Axis field for each graph last; for our own automation purposes.
    Note that the order of the fields matters. (e.g. re-charts Bar Charts stack Bars in order, i.e. first at bottom, last at top)
 */
// TODO: Allow fields to have configs for multiple kinds of charts
const NETWORK_BAR_CHART_CHART_FIELDS = { 
  flowLabsVolumeCountPerHourBySignalBarChart: [
    'signal_flowLabsVolumeCountPerHour_bar',
    'signalCode',    
  ],
  controlDelayMedianBySignalBarChart: [
    'signal_controlDelayMedian_bar',
    'signalCode',    
  ],
  arrivalsOnGreenRatioBySignalBarChart: [
    'signal_arrivalsOnGreenRatio_bar',
    'signalCode',
  ],
  platoonRatioBySignalBarChart: [
    'signal_platoonRatio_bar',
    'signalCode',
  ],
  avgSplitFailuresPerCycleBySignalBarChart:[
    'signal_avgSplitFailuresPerCycle_bar',
    'signalCode',
  ],
  queueLengthMedianVehicleNumberBySignalBarChart: [
    'signal_queueLengthMedianVehicleNumber_bar',
    'signalCode',
  ],
  programmedCycleTimeModeBySignalBarChart: [
    'signal_programmedCycleTimeMode_bar',
    'signalCode',
  ],
  avgGreenTimePerCycleBySignalBarChart: [
    'signal_avgGreenTimePerCycle_bar',
    'signalCode',
  ],
  throughLeftRedLightRunRatioBySignalBarChart: [
    'signal_throughLeftRedLightRunRatio_bar',
    'signalCode',
  ],
  dilemmaZoneRatioBySignalBarChart: [
    'signal_dilemmaZoneRatio_bar',
    'signalCode',
  ],
  deceleration10FpssRatioBySignalBarChart: [
    'signal_deceleration10FpssRatio_bar',
    'signalCode',
  ],
  approachVelocityMphMedianBySignalBarChart: [
    'signal_approachVelocityMphMedian_bar',
    'signalCode',
  ],
};

const ROUTE_BAR_CHART_CHART_FIELDS = {
  stopCountMeanBySignalBarChart: [
    'signal_stopCountMean_bar',
    'signalCode',
  ],
  controlDelayMeanBySignalBarChart: [
    'signal_controlDelayMean_bar',
    'signalCode',
  ],
};

const SIGNAL_BAR_CHART_CHART_FIELDS = {
  flowLabsVolumeCountPerHourByPhaseBarChart: [
    'phase_flowLabsVolumeCountPerHour_bar',
    'number',
  ],
  controlDelayMedianByPhaseBarChart: [
    'phase_controlDelayMedian_bar',
    'number',
  ],
  arrivalsOnGreenRatioByPhaseBarChart: [
    'phase_arrivalsOnGreenRatio_bar',
    'number',
  ],
  phaseSkipsPerCycleByPhaseBarChart: [
    'phase_phaseSkipsPerCycle_bar',
    'number',
  ],
  platoonRatioByPhaseBarChart: [
    'phase_platoonRatio_bar',
    'number',
  ],
  avgSplitFailuresPerCycleByPhaseBarChart: [
    'phase_avgSplitFailuresPerCycle_bar',
    'number',
  ],
  queueLengthMedianVehicleNumberByPhaseBarChart: [
    'phase_queueLengthMedianVehicleNumber_bar',
    'number',
  ],
  avgForceOffsPerCycleByPhaseBarChart: [
    'phase_avgForceOffsPerCycle_bar',
    'number',
  ],
  avgGapOutsPerCycleByPhaseBarChart: [
    'phase_avgGapOutsPerCycle_bar',
    'number',
  ],
  programmedSplitTimeModeByPhaseBarChart: [
    'phase_programmedSplitTimeMode_bar',
    'number',
  ],
  avgGreenTimePerCycleByPhaseBarChart: [
    'phase_avgGreenTimePerCycle_bar',
    'number',
  ],
  throughLeftRedLightRunRatioByPhaseBarChart: [
    'phase_throughLeftRedLightRunRatio_bar',
    'number',
  ],
  dilemmaZoneRatioByPhaseBarChart: [
    'phase_dilemmaZoneRatio_bar',
    'number',
  ],
  deceleration10FpssRatioByPhaseBarChart: [
    'phase_deceleration10FpssRatio_bar',
    'number',
  ],
  approachVelocityMphMedianByPhaseBarChart: [
    'phase_approachVelocityMphMedian_bar',
    'number',
  ],
  controlFuelGallonsVolumeWeightedTotalRaByPhaseBarChart: [
    'phase_controlFuelGallonsVolumeWeightedTotalRa_bar',
    'number',
  ],
  controlCo2EquivalentGramsVolumeWeightedTotalRaByPhaseBarChart: [
    'phase_controlCo2EquivalentGramsVolumeWeightedTotalRa_bar',
    'number',
  ],
  controlNo2GramsVolumeWeightedTotalRaByPhaseBarChart: [
    'phase_controlNo2GramsVolumeWeightedTotalRa_bar',
    'number',
  ],
};


const ALL_BAR_CHART_CHART_FIELDS = {
  ...NETWORK_BAR_CHART_CHART_FIELDS,
  ...ROUTE_BAR_CHART_CHART_FIELDS,
  ...SIGNAL_BAR_CHART_CHART_FIELDS,
}


/*
  HISTOGRAM_CHART_FIELDS Conventions:
    For bar charts with continuous X axes, whereas Bar_Charts are for those with categorical X axes.
    List X Axis field for each graph last; for our own automation purposes.
    Each histogram is prefixed by traffic entity (phase, signal, network, etc)
      to allow a single re-charts chart to display information for the same
      histogram across traffic entities (i.e. comparing the phase's histogram with its signal's).
    Note that the order of the fields matters. (e.g. re-charts Bar Charts stack Bars in order, i.e. first at bottom, last at top)
 */
const NETWORK_HISTOGRAM_CHART_FIELDS = {
  //   network_RLRHistogram: [
  //     'network_RLRHistogram_arrivalTimeGreenIndicationDidntStop',
  //     'network_RLRHistogram_arrivalTimeYellowIndicationDidntStop',
  //     'network_RLRHistogram_arrivalTimeRedIndicationDidntStop',
  //     'network_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop',
  //     'network_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively',
  //     'network_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively',
  //     'network_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively',
  //     'network_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively',
  //     'network_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively',
  //     'network_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively',
  //     'network_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively',
  //     'network_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively',
  //     'network_RLRHistogram_interval',
  //   ],
  //   network_aggressiveBrakingHistogram: [
  //     'network_aggressiveBrakingHistogram_isAggressiveBraking',
  //     'network_aggressiveBrakingHistogram_isNotAggressiveBraking',
  //     'network_aggressiveBrakingHistogram_interval',
  //   ],
  //   network_aggressiveBrakingCauseBarChart: [
  //     'network_aggressiveBrakingCauseBarChart_dilemmaZone_quantity',
  //     'network_aggressiveBrakingCauseBarChart_queueing_quantity',
  //     'network_aggressiveBrakingCauseBarChart_other_quantity',
  //     'network_aggressiveBrakingCauseBarChart_type',
  //   ],
  //   network_controlDelayHistogram: [
  //     'network_controlDelayHistogram_controlDelayA',
  //     'network_controlDelayHistogram_controlDelayB',
  //     'network_controlDelayHistogram_controlDelayC',
  //     'network_controlDelayHistogram_controlDelayD',
  //     'network_controlDelayHistogram_controlDelayE',
  //     'network_controlDelayHistogram_controlDelayF',
  //     'network_controlDelayHistogram_interval'
  //  ],
  };

const MAINROUTEEDGES_HISTOGRAM_CHART_FIELDS = {
  mainRouteEdges_delayHistogram: [
    'mainRouteEdges_delayHistogram_delay',
    'mainRouteEdges_delayHistogram_interval',
  ],
  mainRouteEdges_speedHistogram: [
      'mainRouteEdges_speedHistogram_speedBelowLimit',
      'mainRouteEdges_speedHistogram_speedAboveLimit1',
      'mainRouteEdges_speedHistogram_speedAboveLimit2',
      'mainRouteEdges_speedHistogram_interval',
  ],
  mainRouteEdges_minAccelerationHistogram: [
      'mainRouteEdges_minAccelerationHistogram_minAccelerationIsAggressive',
      'mainRouteEdges_minAccelerationHistogram_minAccelerationIsNotAggressive',
      'mainRouteEdges_minAccelerationHistogram_interval',
  ],
  mainRouteEdges_maxAccelerationHistogram: [
      'mainRouteEdges_maxAccelerationHistogram_maxAccelerationIsAggressive',
      'mainRouteEdges_maxAccelerationHistogram_maxAccelerationIsNotAggressive',
      'mainRouteEdges_maxAccelerationHistogram_interval',
  ],
};

const SIGNAL_HISTOGRAM_CHART_FIELDS = {
  // signal_RLRHistogram: [
  //   'signal_RLRHistogram_arrivalTimeGreenIndicationDidntStop',
  //   'signal_RLRHistogram_arrivalTimeYellowIndicationDidntStop',
  //   'signal_RLRHistogram_arrivalTimeRedIndicationDidntStop',
  //   'signal_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop',
  //   'signal_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively',
  //   'signal_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively',
  //   'signal_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively',
  //   'signal_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively',
  //   'signal_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively',
  //   'signal_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively',
  //   'signal_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively',
  //   'signal_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively',
  //   'signal_RLRHistogram_interval',
  // ],
  // signal_aggressiveBrakingHistogram: [
  //   'signal_aggressiveBrakingHistogram_isAggressiveBraking',
  //   'signal_aggressiveBrakingHistogram_isNotAggressiveBraking',
  //   'signal_aggressiveBrakingHistogram_interval',
  // ],
  // signal_aggressiveBrakingCauseBarChart: [
  //   'signal_aggressiveBrakingCauseBarChart_dilemmaZone_quantity',
  //   'signal_aggressiveBrakingCauseBarChart_queueing_quantity',
  //   'signal_aggressiveBrakingCauseBarChart_other_quantity',
  //   'signal_aggressiveBrakingCauseBarChart_type',
  // ],
  // signal_controlDelayHistogram: [
  //   'signal_controlDelayHistogram_controlDelayA',
  //   'signal_controlDelayHistogram_controlDelayB',
  //   'signal_controlDelayHistogram_controlDelayC',
  //   'signal_controlDelayHistogram_controlDelayD',
  //   'signal_controlDelayHistogram_controlDelayE',
  //   'signal_controlDelayHistogram_controlDelayF',
  //   'signal_controlDelayHistogram_interval'
  // ],
};

const PHASE_HISTOGRAM_CHART_FIELDS = {
  phase_greenTimeHistograms: [
    // NOTE: Since our histograms treat split failures as a subtype of force-off/max-off/gap-out, 
    //       we want to keep their Bars adjacently stacked by keeping their fields adjacent here.
    'phase_greenTimeHistograms_forceOff',
    'phase_greenTimeHistograms_forceOffSplitFailure',
    'phase_greenTimeHistograms_gapOut',
    'phase_greenTimeHistograms_gapOutSplitFailure',
    'phase_greenTimeHistograms_maxOut',
    'phase_greenTimeHistograms_maxOutSplitFailure',
    'phase_greenTimeHistograms_skips',
    'phase_greenTimeHistograms_interval',
  ],
  phase_approachSpeedHistogram: [
    'phase_approachSpeedHistogram_approachSpeedBelowLimit',
    'phase_approachSpeedHistogram_approachSpeedAboveLimit1',
    'phase_approachSpeedHistogram_approachSpeedAboveLimit2',
    'phase_approachSpeedHistogram_interval',
  ],
  phase_arrivalModulusCycleLengthHistogram: [
    'phase_arrivalModulusCycleLengthHistogram_arrivalTimeGreenIndication',
    'phase_arrivalModulusCycleLengthHistogram_arrivalTimeYellowIndication',
    'phase_arrivalModulusCycleLengthHistogram_arrivalTimeRedClearanceIndication',
    'phase_arrivalModulusCycleLengthHistogram_arrivalTimeRedIndication',
    'phase_arrivalModulusCycleLengthHistogram_interval',
  ],
  phase_RLRHistogram: [
    'phase_RLRHistogram_arrivalTimeGreenIndicationDidntStop',
    'phase_RLRHistogram_arrivalTimeYellowIndicationDidntStop',
    'phase_RLRHistogram_arrivalTimeRedIndicationDidntStop',
    'phase_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop',
    'phase_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively',
    'phase_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively',
    'phase_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively',
    'phase_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively',
    'phase_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively',
    'phase_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively',
    'phase_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively',
    'phase_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively',
    'phase_RLRHistogram_interval',
  ],
  phase_aggressiveBrakingHistogram: [
    'phase_aggressiveBrakingHistogram_isAggressiveBraking',
    'phase_aggressiveBrakingHistogram_isNotAggressiveBraking',
    'phase_aggressiveBrakingHistogram_interval',
  ],
  phase_aggressiveBrakingCauseBarChart: [
    'phase_aggressiveBrakingCauseBarChart_dilemmaZone_quantity',
    'phase_aggressiveBrakingCauseBarChart_queueing_quantity',
    'phase_aggressiveBrakingCauseBarChart_other_quantity',
    'phase_aggressiveBrakingCauseBarChart_type',
  ],
  phase_controlDelayHistogram: [
    'phase_controlDelayHistogram_controlDelayA',
    'phase_controlDelayHistogram_controlDelayB',
    'phase_controlDelayHistogram_controlDelayC',
    'phase_controlDelayHistogram_controlDelayD',
    'phase_controlDelayHistogram_controlDelayE',
    'phase_controlDelayHistogram_controlDelayF',
    'phase_controlDelayHistogram_interval'
 ],
};

const ALL_HISTOGRAM_CHART_FIELDS = {
  ...MAINROUTEEDGES_HISTOGRAM_CHART_FIELDS,
  ...NETWORK_HISTOGRAM_CHART_FIELDS,
  ...SIGNAL_HISTOGRAM_CHART_FIELDS,
  ...PHASE_HISTOGRAM_CHART_FIELDS,
};


// Names of All Charts Per Level
export const NETWORK_TIME_SERIES = Object.keys(NETWORK_TIME_SERIES_CHART_FIELDS);
export const NETWORK_BAR_CHARTS = Object.keys(NETWORK_BAR_CHART_CHART_FIELDS);
export const NETWORK_HISTOGRAMS = Object.keys(NETWORK_HISTOGRAM_CHART_FIELDS);
export const ALL_NETWORK_CHARTS = [
  ...NETWORK_TIME_SERIES,
  ...NETWORK_BAR_CHARTS,
  ...NETWORK_HISTOGRAMS,
];

export const ROUTE_TIME_SERIES = Object.keys(ROUTE_TIME_SERIES_CHART_FIELDS);
export const ROUTE_BAR_CHARTS = Object.keys(ROUTE_BAR_CHART_CHART_FIELDS);
export const ALL_ROUTE_CHARTS = [
  ...ROUTE_TIME_SERIES,
  ...ROUTE_BAR_CHARTS,
];

export const MAINROUTEEDGES_HISTOGRAMS = Object.keys(MAINROUTEEDGES_HISTOGRAM_CHART_FIELDS);
export const ALL_MAINROUTEEDGES_CHARTS = [
  ...MAINROUTEEDGES_HISTOGRAMS,
];

export const SIGNAL_TIME_SERIES = Object.keys(SIGNAL_TIME_SERIES_CHART_FIELDS);
export const SIGNAL_BAR_CHARTS = Object.keys(SIGNAL_BAR_CHART_CHART_FIELDS);
export const SIGNAL_HISTOGRAMS = Object.keys(SIGNAL_HISTOGRAM_CHART_FIELDS);
export const ALL_SIGNAL_CHARTS = [
  ...SIGNAL_TIME_SERIES,
  ...SIGNAL_BAR_CHARTS,
  ...SIGNAL_HISTOGRAMS,
];

export const PHASE_TIME_SERIES = Object.keys(PHASE_TIME_SERIES_CHART_FIELDS);
export const PHASE_HISTOGRAMS = Object.keys(PHASE_HISTOGRAM_CHART_FIELDS);
export const ALL_PHASE_CHARTS = [
  ...PHASE_TIME_SERIES,
  ...PHASE_HISTOGRAMS,
];


export const ALL_CHART_FIELDS = {
  ...ALL_TIME_SERIES_CHART_FIELDS,
  ...ALL_BAR_CHART_CHART_FIELDS,
  ...ALL_HISTOGRAM_CHART_FIELDS,
};

export const ALL_TIME_SERIES = Object.keys(ALL_TIME_SERIES_CHART_FIELDS);
export const ALL_BAR_CHARTS = Object.keys(ALL_BAR_CHART_CHART_FIELDS);
export const ALL_HISTOGRAMS = Object.keys(ALL_HISTOGRAM_CHART_FIELDS);
export const ALL_CHARTS = Object.keys(ALL_CHART_FIELDS);

// Depends on X axis field being listed last
export const X_AXIS_FIELDS = new Set([
  ...Object.values(ALL_CHART_FIELDS).map(fields => fields[fields.length - 1]),
]);


export const CHARTS_WITHOUT_LEGEND = new Set ([
  ...ALL_TIME_SERIES,
  ...ALL_BAR_CHARTS,
  'phase_controlDelayHistogram',
  'phase_approachSpeedHistogram',
  'phase_aggressiveBrakingHistogram',
]);

/* Chart Field Configs Conventions: 
    When adding a new chart field's config, first try to add it to CHART_FIELD_CONFIGS_BASE.
    Then if a part of the config needs to be dynamically added, put that code in the Chart component.
*/
// TODO: CLEAN UP AFTER V2 CHARTS ARE DETERMINED
// TODO: Decouple YAxis configs from Charts configs
/*****

 * re-charts UI Config for Individual Graphs

 *****/
 const STANDARD_CHART_PADDING = 12;

 const STANDARD_X_AXIS_PROPS = {
   axisLine: false,
   padding: { left: STANDARD_CHART_PADDING, right: STANDARD_CHART_PADDING },
   tick: { fill: colors.gray2, fontSize: 12, fontWeight: '400' },
 };
 
 const STANDARD_LEFT_Y_AXIS_PROPS = {
   allowDataOverflow: true,
   axisLine: false,
   padding: { top: 3 * STANDARD_CHART_PADDING, bottom: 1 },
   tick: { fill: colors.gray2, fontSize: 12, fontWeight: '400' },
   tickLine: false,
 };
 
 const generateStandardLeftYAxisPropsWithLabel = ({ axisTitle, isRightSide = true, isBottomSide = false, labelXOffset = 0, labelYOffset = 0, ...customAxisProps }) => ({
   ...STANDARD_LEFT_Y_AXIS_PROPS,
   label: ({ viewBox }) => (<>
     {
       Array.isArray(axisTitle)
       ?
         axisTitle.map( (axisTitleWord, idx) =>
         (
             // x, y coors set the bottom corner of the label according to textAnchor; viewBox x, y is the top-left corner of the re-charts Y Axis viewBox(?)
             <text 
               className="fs-6 normal"
               textAnchor="end" 
               fill={colors.gray2} 
               width="48" 
               x={ isRightSide ? viewBox.x + viewBox.width + labelXOffset : viewBox.x + labelXOffset } 
               y={ isBottomSide ? viewBox.y + viewBox.height + labelYOffset : viewBox.y + labelYOffset }
               dy={`${idx*1}em`}
               key={axisTitle.slice(0, idx+1).join()}
             >
               { axisTitleWord }
             </text>
         ))
       :
         (
           // x, y coors set the bottom corner of the label according to textAnchor; viewBox x, y is the top-left corner of the re-charts Y Axis viewBox(?)
           <text 
             className="fs-6 normal"
             textAnchor="end" 
             fill={colors.gray2} 
             width="48" 
             x={ isRightSide ? viewBox.x + viewBox.width + labelXOffset : viewBox.x + labelXOffset } 
             y={ isBottomSide ? viewBox.y + viewBox.height + labelYOffset : viewBox.y + labelYOffset }
           >
             { axisTitle }
           </text>
         )
     }
   </>),
   ...customAxisProps,
 });
 
 const generateStandardHistogramXAxisProps = ({ histogramName, axisTitle, isRightSide = true, isBottomSide = true, labelXOffset = CHARTS_WITHOUT_LEGEND.has(histogramName) ? -15 : -10, labelYOffset = CHARTS_WITHOUT_LEGEND.has(histogramName) ? 12 : 25, customGeneralProps, customAxisProps}) => ({
     isXAxis: true,
     xAxisId: `${histogramName}_interval`,
     axisProps: {
       ...STANDARD_X_AXIS_PROPS,
       domain: ['dataMin', 'auto'],
       interval: 4,
       label: ({ viewBox }) => (
         // x, y coors set the bottom corner of the label according to textAnchor; viewBox x, y is the top-left corner of the re-charts X axis viewBox(?)
         <text
           className="fs-6 normal"
           textAnchor="end"
           fill={colors.gray2}
           x={ isRightSide ? viewBox.x + viewBox.width + labelXOffset : viewBox.x + labelXOffset  }
           y={ isBottomSide ? viewBox.y + viewBox.height + labelYOffset : viewBox.y + labelYOffset }
           dy={'-.5em' /* Hack around re-charts XAxis label positioning */}
         >
           { axisTitle }
         </text>
       ),
       tickLine: false,
       ...customAxisProps,
     },
     sanitizeData: value => Math.round(formatZeroIfFalsy(value)),
     tooltipDisplayField: `${axisTitle}: `,
     ...customGeneralProps,
 });
 
 const generateStandardHistogramYAxisAndChartProps = ({ histogramName, fieldLabel, axisTitle, isRightSide = true, isBottomSide = false, labelXOffset = 0, labelYOffset = 0, color, minimumMaxY = 10, customGeneralProps, customAxisProps, customChartProps }) => ({
   type: 'bar',
   xAxisId: `${histogramName}_interval`,
   yAxisId: histogramName,
   color: color,
   axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: axisTitle,
                                                        domain: [0, dataMax => dataMax && (dataMax > minimumMaxY) ? Math.ceil(dataMax) : 10],
                                                        isRightSide: isRightSide,
                                                        isBottomSide: isBottomSide,
                                                        labelXOffset: labelXOffset,
                                                        labelYOffset: labelYOffset,
                                                        ...customAxisProps } ),
   chartProps: {
     stackId: histogramName,
     name: (
         <span className="nowrap">{ fieldLabel }</span>
     ),
     ...customChartProps,
   },
   sanitizeData: value => Math.round(formatZeroIfFalsy(value)),
   ...customGeneralProps,
 });
 
// Configs for Shared Y Axes
const scoreAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Score' });
const controlDelayAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Delay', domain: [0, dataMax => dataMax ? Math.ceil(dataMax) : 10] });
const queueLengthAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Length', domain: [0, dataMax => dataMax ? Math.ceil(dataMax) : 10] });
const approachSpeedAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Speed', domain: [0, dataMax => dataMax ? Math.ceil(dataMax) : 60] });
const dilemmaZoneAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'DZE %', domain: [0, .1], tickFormatter: value => Math.round(value * 100) });
const dangerousBrakingAxisProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Dangerous','Braking %'], domain: [0, .1], tickFormatter: value => Math.round(value * 100) });
const travelTimeProps = generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Time',  /*domain: ['dataMin', 'dataMax'],*/  tickFormatter: val => Math.round(val) });


/*
  CHART_FIELD_CONFIGS_BASE Conventions:
    getDisplayValue: fn that formats value for display (mainly for <TooltipContent/>) 
    getDisplayValueDot: same as getDisplayValue, which it overrides if both are provided (mainly for <ScoreDot/>)
    axisProps: Props for re-charts XAxis and YAxis components
    chartProps: Props for re-charts Graph child components (e.g. )
    generalProps: Props other than axisProps and chartProps

    NOTE: Dynamic chart field configs should be added in the Chart component.
*/
// TODO?: can this take some things (esp. formatting) from columnConfigs/columnDefinitions.js ??

export const CHART_FIELD_CONFIGS_BASE = {
  // Generally used XAxis
  referenceDate: {
    isXAxis: true,
    xAxisId: 'referenceDate',
    axisProps: {
      axisLine: false,
      interval: 1,
      padding: { left: STANDARD_CHART_PADDING, right: STANDARD_CHART_PADDING },
      tickLine: false,
    },
    sanitizeData: referenceDate => referenceDate.day + ' ' + getMonthAbbreviation(referenceDate.month), // e.g. '22 Oct'
    tooltipDisplayField: null,
  },
  flowLabsVolumeCountPerHour: {
    getDisplayValue: value => (Math.round(parseInt(value * 100, 10)) / 100).toFixed(2),
    getDisplayValueDot: value => (value >= 1000)
      ? (value / 1000).toFixed(1) + 'k'
      : Math.round(value),
    xAxisId: 'referenceDate',
    color: 'yellow',
    contrastColor: 'black',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Veh/Hr', domain: [0, dataMax => Math.max(dataMax, 500)], tickFormatter:  value => Math.round(value), }),
    // {
    //   tickLine: false,
    //   axisLine: false,
    //   tick: { fill: colors.gray1, fontSize: 12, fontWeight: '400' },
    //   tickFormatter: value => Math.round(value),
    //   tickMargin: 5,
    //   orientation: 'right',
    //   padding: { top: 3 * STANDARD_CHART_PADDING },
    //   domain: [0, dataMax => Math.max(dataMax, 500)],
    //   label: ({ viewBox }) => (
    //     <text fill={colors.gray2} x={ viewBox.x + 10 } y="40" className="fs-6 normal">Veh/Hr</text>
    //   ),
    // },
    chartProps: {
      // dataKey: 'flowLabsVolumeCountPerHour',
      name: (
        <>
          <span>Volume</span>
          {' '}
          <span className="nowrap gray2">(veh/hr)</span>
        </>
      ),
    },
    // Used for network_controlDelayMedianTimeSeries
    secondaryConfig: {
      type: 'area',
      chartProps: {
        opacity: .2,
      }
    },
  },
  detectorHealthScore: {
    xAxisId: 'referenceDate',
    yAxisId: 'score',
    color: 'orange',
    axisProps: scoreAxisProps,
    chartProps: {
      name: 'Detector Health Score',
    },
  },
  healthScore: {
    getDisplayValue: value => value,
    getDisplayValueDot: value => value,
    xAxisId: 'referenceDate',
    yAxisId: 'score',
    color: 'purple',
    axisProps: scoreAxisProps,
  },
  programmedCycleTimeMode: {
    getDisplayValue: value => value.toFixed(2) + 's' ,
    getDisplayValueDot: value => Math.round(value) ,
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Programmed', 'Cycle'], domain: [0, 'dataMax'] }),
    // {
    //   hide: true,
    //   allowDataOverflow: true,
    //   padding: { top: STANDARD_CHART_PADDING, bottom: 1 },
    //   domain: [0, 150],
    // },
    chartProps: {
      name: (
        <>
          <span className="nowrap">Programmed</span>
          {' '}
          <span className="nowrap">Cycle</span>
        </>
      ),
    },
  },
  programmedSplitTimeMode: {
    getDisplayValue: value => value.toFixed(2) + 's' ,
    getDisplayValueDot: value => Math.round(value) ,
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Programmed', 'Split'], domain: [0, 'dataMax'] }),
    // {
    //   hide: true,
    //   allowDataOverflow: true,
    //   padding: { top: STANDARD_CHART_PADDING, bottom: 1 },
    //   domain: [0, 'dataMax'],
    // },
    chartProps: {
      name: (
        <>
          <span className="nowrap">Programmed</span>
          {' '}
          <span className="nowrap">Split</span>
        </>
      ),
    },
  },
  arrivalsOnGreenRatio: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'AOG %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Arrivals On Green</span>
          {' '}
          <span className="nowrap gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  avgSplitFailuresPerPhase: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'SF %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Split Failures</span>
          {' '}
          <span className="gray1">(% of phase activations)</span>
        </>
      ),
    },
  },
  avgSplitFailuresPerCycle: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'SF %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Split Failures</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  avgForceOffsPerCycle: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'FO %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Force-Offs</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  avgGapOutsPerCycle: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'GO %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Gap-Outs</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  avgGreenTimePerCycle: {
    getDisplayValue: formatSecond,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Green Time', 'per Cycle'], domain: [0, dataMax => Math.ceil(dataMax)] }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Green Time</span>
          {' '}
          <span className="nowrap">per Cycle</span>
        </>
      ),
    },
  },
  throughLeftRedLightRunRatio: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'blue',
    contrastColor: 'white',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'RLR %', domain: [0, .2], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Red Light Running</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  dilemmaZoneRatio: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'blue',
    contrastColor: 'white',
    axisProps: dilemmaZoneAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dilemma Zone Entry</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  deceleration10FpssRatio: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'blue',
    contrastColor: 'white',
    axisProps: dangerousBrakingAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dangerous Braking</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  controlDelayMedian: {
    getDisplayValue: value => value.toFixed(2) + 's',
    getDisplayValueDot: value => Math.round(value) + 's',
    xAxisId: 'referenceDate',
    yAxisId: 'controlDelay',
    color: 'yellow',
    axisProps: controlDelayAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">Median (seconds)</span>
        </>
      ),
    },
  },
  controlDelay85th: {
    getDisplayValue: value => value.toFixed(2) + 's',
    getDisplayValueDot: value => Math.round(value) + 's',
    xAxisId: 'referenceDate',
    yAxisId: 'controlDelay',
    color: 'yellow',
    axisProps: controlDelayAxisProps,
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">85th % (seconds)</span>
        </>
      ),
    },
  },
  phaseSkipsPerCycle: {
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Skips', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Skips</span>
          {' '}
          <span className="nowrap gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  platoonRatio: {
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => value.toFixed(2),
    xAxisId: 'referenceDate',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Platoon', 'Ratio'], domain: [0, dataMax => dataMax * 1.2], tickFormatter: value => value.toFixed(2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Platoon Ratio</span>
        </>
      ),
    },
  },
  queueLength85thVehicleNumber: {
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'queueLength',
    color: 'yellow',
    axisProps: queueLengthAxisProps,
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Queue Length 85th %</span>
          <br />
          <span className="nowrap gray1">(# of vehicles)</span>
        </>
      ),
    },
  },
  queueLengthMedianVehicleNumber: {
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'queueLength',
    color: 'yellow',
    axisProps: queueLengthAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Queue Length Median</span>
          <br />
          <span className="nowrap gray1">(# of vehicles)</span>
        </>
      ),
    },
  },
  queueLength15thVehicleNumber: {
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'queueLength',
    color: 'yellow',
    axisProps: queueLengthAxisProps,
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Queue Length 15th %</span>
          <br />
          <span className="nowrap gray1">(# of vehicles)</span>
        </>
      ),
    },
  },
  approachVelocityMph85th: {
    getDisplayValue: value => <>{ value.toFixed(1) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'approachSpeed',
    color: 'blue',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Approach Speed</span>
          <br />
          <span className="nowrap gray1">(85th %)</span>
        </>
      ),
    },
  },
  approachVelocityMphMedian: {
    getDisplayValue: value => <>{ value.toFixed(1) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'approachSpeed',
    color: 'blue',
    contrastColor: 'white',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Approach Speed</span>
          <br />
          <span className="nowrap gray1">(median)</span>
        </>
      ),
    },
  },
  approachVelocityMph15th: {
    getDisplayValue: value => <>{ value.toFixed(1) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'approachSpeed',
    color: 'blue',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Approach Speed</span>
          <br />
          <span className="nowrap gray1">(15th %)</span>
        </>
      ),
    },
  },
  speedLimitMphMode: {
    getDisplayValue: value => <>{ Math.round(value) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'referenceDate',
    yAxisId: 'approachSpeed',
    color: 'white',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      strokeWidth: 1,
      opacity: .4,
      name: (
        <>
          <span className="nowrap">Speed Limit</span>
        </>
      ),
    },
  },
  fuelGallonsVolumeWeightedTotalRa: {
    getDisplayValue: value => formatInt(value) + 'gal',
    getDisplayValueDot: value => formatInt(value),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'gal Fuel', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatInt(value) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Fuel Consumption</span>
          {' '}
          <span className="nowrap gray1">(gal, 1-day total)</span>
        </>
      ),
    },
  },
  controlFuelGallonsVolumeWeightedTotalRa: {
    getDisplayValue: value => formatFloat(value, 2) + 'gal',
    getDisplayValueDot: value => formatFloat(value, 1),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'gal Fuel', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatFloat(value, 2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Fuel Consumption</span>
          {' '}
          <span className="nowrap gray1">(gal, 1-day total)</span>
        </>
      ),
    },
  },
  co2EquivalentGramsVolumeWeightedTotalRa: {
    getDisplayValue: value => (value / 1000).toFixed(0) + 'kg',
    getDisplayValueDot: value => (value / 1000).toFixed(0),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'kg CO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => (value / 1000).toFixed(0) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle CO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(kilograms, 1-day total)</span>
        </>
      ),
    },
  },
  controlCo2EquivalentGramsVolumeWeightedTotalRa: {
    getDisplayValue: value => (value / 1000).toFixed(1) + 'kg',
    getDisplayValueDot: value => (value / 1000).toFixed(0),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'kg CO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => (value / 1000).toFixed(1) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle CO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(kilograms, 1-day total)</span>
        </>
      ),
    },
  },
  controlCo2EquivalentGramsVolumeWeightedMean: {
    getDisplayValue: value => (value).toFixed(1) + 'g',
    getDisplayValueDot: value => (value).toFixed(0),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'g CO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => (value).toFixed(0) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle CO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(grams, mean)</span>
        </>
      ),
    },
  },
  no2GramsVolumeWeightedTotalRa: {
    getDisplayValue: value => formatInt(value) + 'g',
    getDisplayValueDot: value => formatInt(value),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'g NO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatInt(value) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle NO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(grams, 1-day total)</span>
        </>
      ),
    },
  },
  controlNo2GramsVolumeWeightedTotalRa: {
    getDisplayValue: value => formatFloat(value, 2) + 'g',
    getDisplayValueDot: value => formatFloat(value, 1),
    xAxisId: 'referenceDate',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'g NO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatFloat(value, 2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle NO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(grams, 1-day total)</span>
        </>
      ),
    },
  },

  // Route Time Series
  travelTime15th: {
    getDisplayValue: formatSecond,
    getDisplayValueDot: Math.round,
    axisProps: travelTimeProps,
    xAxisId: 'referenceDate',
    yAxisId: 'travelTime',
    color: 'green',
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Travel Time</span>
          <br />
          <span className="nowrap gray1">15th % (seconds)</span>
        </>
      ),
      isHideInLegend: true,
    },
  },
  /*
  completeTravelTimeMean: {
    getDisplayValue: formatSecond,
    getDisplayValueDot: Math.round,
    axisProps: travelTimeProps,
    yAxisId: 'travelTime',
    chartProps: {
      strokeDasharray: '7 2',
      name: (
        <>
          <span className="nowrap">Travel Time</span>
          <br />
          <span className="nowrap gray1">Mean (seconds)</span>
        </>
      ),
    },
  },
  */
  travelTimeMedian: {
    getDisplayValue: formatSecond,
    getDisplayValueDot: Math.round,
    axisProps: travelTimeProps,
    xAxisId: 'referenceDate',
    yAxisId: 'travelTime',
    color: 'green',
    chartProps: {
      strokeWidth: '3',
      name: (
        <>
          <span className="nowrap">Travel Time</span>
          <br />
          <span className="nowrap gray1">Median (seconds)</span>
        </>
      ),
      isHideInLegend: true,
    },
  },
  travelTime85th: {
    getDisplayValue: formatSecond,
    getDisplayValueDot: Math.round,
    axisProps: travelTimeProps,
    xAxisId: 'referenceDate',
    yAxisId: 'travelTime',
    color: 'green',
    chartProps: {
      strokeDasharray: '3 2',
      strokeWidth: '1',
      name: (
        <>
          <span className="nowrap">Travel Time</span>
          <br />
          <span className="nowrap gray1">85th % (seconds)</span>
        </>
      ),
      isHideInLegend: true,
    },
  },
  stopCountMean: {
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => value.toFixed(1),
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Stops', }),
    xAxisId: 'referenceDate',
    color: 'green',
    chartProps: {
      name: (
        <>
          <span className="nowrap">Stop Count</span>
          <br/>
          <span className="nowrap gray1">Mean</span>
        </>
      ),
    },
  },
  controlDelayMean: {
    getDisplayValue: value => value.toFixed(2) + 's',
    getDisplayValueDot: Math.round,
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Delay', }),
    xAxisId: 'referenceDate',
    color: 'green',
    chartProps: {
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">Mean</span>
        </>
      ),
    },
  },
  /*
    completeStopCountMean: {
      getDisplayValue: value => value.toFixed(2),
      getDisplayValueDot: value => value.toFixed(1),
      axisProps: getLeftAxisPropsWithLabel({ title: 'Stops' }),
      chartProps: {
        name: (
          <>
            <span className="nowrap">Stop Count</span>
            <br/>
            <span className="nowrap gray1">Mean</span>
          </>
        ),
      },
    },
    completeControlDelayMean: {
      getDisplayValue: value => value.toFixed(2) + 's',
      getDisplayValueDot: Math.round,
      axisProps: getLeftAxisPropsWithLabel({ title: 'Delay' }),
      chartProps: {
        name: (
          <>
            <span className="nowrap">Control Delay</span>
            <br />
            <span className="nowrap gray1">Mean</span>
          </>
        ),
      },
    },
  */
  // TODO: Allow fields to have configs for multiple kinds of charts
  // Generally used XAxis for Bar Charts
  signalCode: {
    isXAxis: true,
    xAxisId: 'signalCode',
    axisProps: {
      padding: { left: STANDARD_CHART_PADDING, right: STANDARD_CHART_PADDING },
      axisLine: false,
      tick: <SignalCodeTick />,
      tickLine: { stroke: colors['gray2'], transform: 'translate(0, -2)' },
      interval: 0,
      label: ({ viewBox }) => (
        // x, y coors set the bottom corner of the label according to textAnchor; viewBox x, y is the top-left corner of the re-charts X axis viewBox(?)
        <text
          className="fs-6 normal"
          textAnchor="end"
          fill={colors.gray2}
          x={ viewBox.x + viewBox.width + -15 }
          y={ viewBox.y + viewBox.height + 15 }
          dy={'-.5em' /* Hack around re-charts XAxis label positioning */}
        >
          { 'Signal Id' }
        </text>
      ),
    },
    tooltipDisplayField: 'Signal ',
  },
  // Generally used XAxis for Bar Charts
  number: {
    isXAxis: true,
    xAxisId: 'number',
    axisProps: {
      padding: { left: STANDARD_CHART_PADDING, right: STANDARD_CHART_PADDING },
      axisLine: false,
      tick: <SignalCodeTick />,
      tickLine: { stroke: colors['gray2'], transform: 'translate(0, -2)' },
      interval: 0,
      label: ({ viewBox }) => (
        // x, y coors set the bottom corner of the label according to textAnchor; viewBox x, y is the top-left corner of the re-charts X axis viewBox(?)
        <text
          className="fs-6 normal"
          textAnchor="end"
          fill={colors.gray2}
          x={ viewBox.x + viewBox.width + -15 }
          y={ viewBox.y + viewBox.height + 12 }
          dy={'-.5em' /* Hack around re-charts XAxis label positioning */}
        >
          { 'Phase Number' }
        </text>
      ),
    },
    tooltipDisplayField: 'Phase ',
  },
  // Network Bar Charts
  signal_flowLabsVolumeCountPerHour_bar: {
    type: 'bar',
    getDisplayValue: value => (Math.round(parseInt(value * 100, 10)) / 100).toFixed(2),
    getDisplayValueDot: value => (value >= 1000)
      ? (value / 1000).toFixed(1) + 'k'
      : Math.round(value),
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Veh/Hr', domain: [0, dataMax => Math.max(dataMax, 500)], tickFormatter:  value => Math.round(value), }),
    chartProps: {
      name: (
        <>
          <span>Volume</span>
          {' '}
          <span className="nowrap gray2">(veh/hr)</span>
        </>
      ),
    },
  },
  signal_controlDelayMedian_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2) + 's',
    getDisplayValueDot: value => Math.round(value) + 's',
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: controlDelayAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">Median (seconds)</span>
        </>
      ),
    },
  },
  signal_programmedCycleTimeMode_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2) + 's' ,
    getDisplayValueDot: value => Math.round(value) ,
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Programmed', 'Cycle'], domain: [0, 'dataMax'] }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Programmed</span>
          {' '}
          <span className="nowrap">Cycle</span>
        </>
      ),
    },
  },
  signal_avgGreenTimePerCycle_bar: {
    type: 'bar',
    getDisplayValue: formatSecond,
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Green Time', 'per Cycle'], domain: [0, dataMax => Math.ceil(dataMax)] }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Green Time</span>
          {' '}
          <span className="nowrap">per Cycle</span>
        </>
      ),
    },
  },
  signal_arrivalsOnGreenRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'AOG %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Arrivals On Green</span>
          {' '}
          <span className="nowrap gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  signal_avgSplitFailuresPerCycle_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'SF %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Split Failures</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  signal_throughLeftRedLightRunRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'signalCode',
    color: 'blue',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'RLR %', domain: [0, .2], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Red Light Running</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  signal_dilemmaZoneRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'signalCode',
    color: 'blue',
    axisProps: dilemmaZoneAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dilemma Zone Entry</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  signal_deceleration10FpssRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'signalCode',
    color: 'blue',
    axisProps: dangerousBrakingAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dangerous Braking</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  signal_platoonRatio_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => value.toFixed(2),
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Platoon', 'Ratio'], domain: [0, dataMax => dataMax * 1.2], tickFormatter: value => value.toFixed(2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Platoon Ratio</span>
        </>
      ),
    },
  },
  signal_queueLengthMedianVehicleNumber_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'signalCode',
    color: 'yellow',
    axisProps: queueLengthAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Queue Length Median</span>
          <br />
          <span className="nowrap gray1">(# of vehicles)</span>
        </>
      ),
    },
  },
  signal_approachVelocityMphMedian_bar: {
    type: 'bar',
    getDisplayValue: value => <>{ value.toFixed(1) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'signalCode',
    color: 'blue',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Approach Speed</span>
          <br />
          <span className="nowrap gray1">(median)</span>
        </>
      ),
    },
  },
  // Route Bar Charts
  signal_controlDelayMean_bar: {
    type: 'bar',
    xAxisId: 'signalCode',
    getDisplayValue: formatSecond,
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Delay' }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">Mean (seconds)</span>
        </>
      ),
    },
  },
  signal_stopCountMean_bar: {
    type: 'bar',
    xAxisId: 'signalCode',
    getDisplayValue: value => formatFloat(value, 2),
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Stops' }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Stops per vehicle</span>
          <br />
          <span className="nowrap gray1">Mean</span>
        </>
      ),
    },
  },
  // Signal Bar Charts
  phase_flowLabsVolumeCountPerHour_bar: {
    type: 'bar',
    getDisplayValue: value => (Math.round(parseInt(value * 100, 10)) / 100).toFixed(2),
    getDisplayValueDot: value => (value >= 1000)
      ? (value / 1000).toFixed(1) + 'k'
      : Math.round(value),
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Veh/Hr', domain: [0, dataMax => Math.max(dataMax, 500)], tickFormatter:  value => Math.round(value), }),
    chartProps: {
      name: (
        <>
          <span>Volume</span>
          {' '}
          <span className="nowrap gray2">(veh/hr)</span>
        </>
      ),
    },
  },
  phase_programmedSplitTimeMode_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2) + 's' ,
    getDisplayValueDot: value => Math.round(value) ,
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Programmed', 'Split'], domain: [0, 'dataMax'] }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Programmed</span>
          {' '}
          <span className="nowrap">Split</span>
        </>
      ),
    },
  },
  phase_avgGreenTimePerCycle_bar: {
    type: 'bar',
    getDisplayValue: formatSecond,
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Green Time', 'per Cycle'], domain: [0, dataMax => Math.ceil(dataMax)] }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Green Time</span>
          {' '}
          <span className="nowrap">per Cycle</span>
        </>
      ),
    },
  },
  phase_arrivalsOnGreenRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'AOG %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Arrivals On Green</span>
          {' '}
          <span className="nowrap gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  phase_avgSplitFailuresPerCycle_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'SF %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Split Failures</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  phase_avgForceOffsPerCycle_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'FO %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Force-Offs</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  phase_avgGapOutsPerCycle_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'GO %', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Gap-Outs</span>
          {' '}
          <span className="gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  phase_throughLeftRedLightRunRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'blue',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'RLR %', domain: [0, .2], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Red Light Running</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  phase_dilemmaZoneRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'blue',
    axisProps: dilemmaZoneAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dilemma Zone Entry</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  phase_deceleration10FpssRatio_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'blue',
    axisProps: dangerousBrakingAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Dangerous Braking</span>
          {' '}
          <span className="gray1">(% of vehicles)</span>
        </>
      ),
    },
  },
  phase_controlDelayMedian_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2) + 's',
    getDisplayValueDot: value => Math.round(value) + 's',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: controlDelayAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Control Delay</span>
          <br />
          <span className="nowrap gray1">Median (seconds)</span>
        </>
      ),
    },
  },
  phase_phaseSkipsPerCycle_bar: {
    type: 'bar',
    getDisplayValue: value => (value * 100).toFixed(2) + '%',
    getDisplayValueDot: value => Math.round(value * 100) + '%',
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'Skips', domain: [0, 1], tickFormatter: value => Math.round(value * 100) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Skips</span>
          {' '}
          <span className="nowrap gray1">(% of cycles)</span>
        </>
      ),
    },
  },
  phase_platoonRatio_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => value.toFixed(2),
    xAxisId: 'number',
    color: 'yellow',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: ['Platoon', 'Ratio'], domain: [0, dataMax => dataMax * 1.2], tickFormatter: value => value.toFixed(2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Platoon Ratio</span>
        </>
      ),
    },
  },
  phase_queueLengthMedianVehicleNumber_bar: {
    type: 'bar',
    getDisplayValue: value => value.toFixed(2),
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'number',
    color: 'yellow',
    axisProps: queueLengthAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Queue Length Median</span>
          <br />
          <span className="nowrap gray1">(# of vehicles)</span>
        </>
      ),
    },
  },
  phase_approachVelocityMphMedian_bar: {
    type: 'bar',
    getDisplayValue: value => <>{ value.toFixed(1) }<span className="normal">mph</span></>,
    getDisplayValueDot: value => Math.round(value),
    xAxisId: 'number',
    color: 'blue',
    axisProps: approachSpeedAxisProps,
    chartProps: {
      name: (
        <>
          <span className="nowrap">Approach Speed</span>
          <br />
          <span className="nowrap gray1">(median)</span>
        </>
      ),
    },
  },
  phase_controlFuelGallonsVolumeWeightedTotalRa_bar: {
    type: 'bar',
    getDisplayValue: value => formatFloat(value, 2) + 'gal',
    getDisplayValueDot: value => formatFloat(value, 1),
    xAxisId: 'number',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'gal Fuel', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatFloat(value, 2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Fuel Consumption</span>
          {' '}
          <span className="nowrap gray1">(gal, 28-day total)</span>
        </>
      ),
    },
  },
  phase_controlCo2EquivalentGramsVolumeWeightedTotalRa_bar: {
    type: 'bar',
    getDisplayValue: value => (value / 1000).toFixed(0) + 'kg',
    getDisplayValueDot: value => (value / 1000).toFixed(0),
    xAxisId: 'number',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'kg CO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => (value / 1000).toFixed(0) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle CO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(kilograms, 28-day total)</span>
        </>
      ),
    },
  },
  phase_controlNo2GramsVolumeWeightedTotalRa_bar: {
    type: 'bar',
    getDisplayValue: value => formatFloat(value, 2) + 'g',
    getDisplayValueDot: value => formatFloat(value, 1),
    xAxisId: 'number',
    color: 'green',
    axisProps: generateStandardLeftYAxisPropsWithLabel({ axisTitle: 'g NO2', domain: [dataMin => Math.min(0, dataMin * 1.2), dataMax => dataMax * 1.2], tickFormatter: value => formatFloat(value, 2) }),
    chartProps: {
      name: (
        <>
          <span className="nowrap">Vehicle NO<sub>2</sub>&nbsp;Emissions</span>
          {' '}
          <span className="nowrap gray1">(grams, 28-day total)</span>
        </>
      ),
    },
  },

  // Phase Termination Type Histograms
  phase_greenTimeHistograms_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_greenTimeHistograms',
    axisTitle: 'Position in cycle (s)',
    customAxisProps: {
      tick: <SignalCodeTick />, //TODO: Replace as general tick?
    },
  }),
  phase_greenTimeHistograms_forceOff: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Force-Offs', 
    color: 'blue2',
    axisTitle: '#',
  }),
  phase_greenTimeHistograms_forceOffSplitFailure: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Force-Offs (Split Failure)',
    color: 'blue',
    axisTitle: '#', 
  }),
  phase_greenTimeHistograms_maxOut: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Max-Outs',
    color: 'purple',
    axisTitle: '#', 
  }),
  phase_greenTimeHistograms_maxOutSplitFailure: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Max-Outs (Split Failure)',
    color: 'purple2',
    axisTitle: '#', 
  }),
  phase_greenTimeHistograms_gapOut: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Gap Outs',
    color: 'teal',
    axisTitle: '#', 
  }),
  phase_greenTimeHistograms_gapOutSplitFailure: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Gap Outs (Split Failure)',
    color: 'teal2',
    axisTitle: '#', 
  }),
  phase_greenTimeHistograms_skips: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_greenTimeHistograms', 
    fieldLabel: 'Skips',
    color: 'orange',
    axisTitle: '#', 
  }),

  // Operations Histograms
  // Control Delay Histogram (Phase, Signal, Network Levels)
  phase_controlDelayHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_controlDelayHistogram',
    axisTitle: 'Control Delay (s)',
    customAxisProps: {
      tick: <SignalCodeTick />
    },
  }),
  phase_controlDelayHistogram_controlDelayA: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service A',
    color: 'green2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_controlDelayHistogram_controlDelayB: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service B',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_controlDelayHistogram_controlDelayC: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service C',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_controlDelayHistogram_controlDelayD: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service D',
    color: 'orange',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_controlDelayHistogram_controlDelayE: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service E',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_controlDelayHistogram_controlDelayF: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_controlDelayHistogram',
    fieldLabel: 'Level of Service F',
    color: 'red2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'signal_controlDelayHistogram',
    axisTitle: 'Control Delay (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />
    },
  }),
  signal_controlDelayHistogram_controlDelayA: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service A',
    color: 'green2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_controlDelayB: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service B',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_controlDelayC: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service C',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_controlDelayD: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service D',
    color: 'orange',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_controlDelayE: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service E',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_controlDelayHistogram_controlDelayF: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_controlDelayHistogram',
    fieldLabel: 'Level of Service F',
    color: 'red2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'network_controlDelayHistogram',
    axisTitle: 'Control Delay (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />
    },
  }),
  network_controlDelayHistogram_controlDelayA: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service A',
    color: 'green2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_controlDelayB: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service B',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_controlDelayC: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service C',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_controlDelayD: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service D',
    color: 'orange',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_controlDelayE: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service E',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_controlDelayHistogram_controlDelayF: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_controlDelayHistogram',
    fieldLabel: 'Level of Service F',
    color: 'red2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
    },
  }),

  // Safety  Histograms
  // RLR Histogram (Phase, Signal, Network Levels)
  phase_RLRHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_RLRHistogram',
    axisTitle: 'Time Since Yellow Start (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
      interval: 'PreserveStartEnd',
    },
  }),
  phase_RLRHistogram_arrivalTimeGreenIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Green',
    color: 'green',
    axisTitle: ['Num', 'Vehicles']
  }),
  phase_RLRHistogram_arrivalTimeYellowIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Yellow',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles']
  }),
  phase_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red Clearance',
    color: 'pink',
    axisTitle: ['Num', 'Vehicles']
  }),
  phase_RLRHistogram_arrivalTimeRedIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red',
    color: 'red',
    axisTitle: ['Num', 'Vehicles']
  }),
  phase_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Green',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Aggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Aggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  
  phase_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Yellow',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  phase_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red Clearance',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  phase_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),

  phase_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Green',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Unaggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Unaggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
    // getDisplayValue: value => //TODO: Display accumulated aggressive + unaggressive stops
  }),
  phase_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Yellow',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  phase_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  phase_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  signal_RLRHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'signal_RLRHistogram',
    axisTitle: 'Time Since Yellow Start (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
      // interval: 'PreserveStartEnd',
    },
  }),
  signal_RLRHistogram_arrivalTimeGreenIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Green',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
  }),
  signal_RLRHistogram_arrivalTimeYellowIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Yellow',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
  }),
  signal_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red Clearance',
    color: 'pink',
    axisTitle: ['Num', 'Vehicles'],
  }),
  signal_RLRHistogram_arrivalTimeRedIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),
  signal_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Green',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Aggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Aggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  
  signal_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Yellow',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  signal_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red Clearance',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  signal_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),

  signal_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Green',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Unaggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Unaggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
    // getDisplayValue: value => //TODO: Display accumulated aggressive + unaggressive stops
  }),
  signal_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Yellow',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  signal_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  signal_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),


  network_RLRHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'network_RLRHistogram',
    axisTitle: 'Time Since Yellow Start (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
    },
  }),
  network_RLRHistogram_arrivalTimeGreenIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Green',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
  }),
  network_RLRHistogram_arrivalTimeYellowIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Yellow',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
  }),
  network_RLRHistogram_arrivalTimeRedClearanceIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red Clearance',
    color: 'pink',
    axisTitle: ['Num', 'Vehicles'],
  }),
  network_RLRHistogram_arrivalTimeRedIndicationDidntStop: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Went Through Intersection on Red',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),
  network_RLRHistogram_arrivalTimeGreenIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Green',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Aggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Aggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  
  network_RLRHistogram_arrivalTimeYellowIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Yellow',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  network_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red Clearance',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  network_RLRHistogram_arrivalTimeRedIndicationStoppedAggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Aggressively On Red',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),

  network_RLRHistogram_arrivalTimeGreenIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Green',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: false,
      legendLabel: 'Stopped Unaggressively',
      // isHideInTooltip: false, //TODO: Display accumulated aggressive + unaggressive stops
      // tooltipLabel: 'Stopped Unaggressively', //TODO: Display accumulated aggressive + unaggressive stops
    },
    // getDisplayValue: value => //TODO: Display accumulated aggressive + unaggressive stops
  }),
  network_RLRHistogram_arrivalTimeYellowIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Yellow',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  network_RLRHistogram_arrivalTimeRedClearanceIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),
  network_RLRHistogram_arrivalTimeRedIndicationStoppedUnaggressively: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_RLRHistogram',
    fieldLabel: 'Stopped Unaggressively On Red Clearance',
    color: 'gray2',
    axisTitle: ['Num', 'Vehicles'],
    customChartProps: {
      isHideInLegend: true,
      // isHideInTooltip: true, //TODO: Display accumulated aggressive + unaggressive stops
    },
  }),


  // Aggressive Braking Histogram (Phase, Signal, Network Levels)
  phase_aggressiveBrakingHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_aggressiveBrakingHistogram',
    axisTitle: 'Acceleration (ft/s^2)',
    customAxisProps: { 
      tick: <SignalCodeTick />
    },
  }),
  phase_aggressiveBrakingHistogram_isAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_aggressiveBrakingHistogram',
    fieldLabel: 'Aggressive Braking',
    color: 'red',
    axisTitle: ['% of', 'Vehicles'],
  }),
  phase_aggressiveBrakingHistogram_isNotAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_aggressiveBrakingHistogram',
    fieldLabel: 'Nonaggressive Braking',
    color: 'gray4',
    axisTitle: ['% of', 'Vehicles'],
  }),

  signal_aggressiveBrakingHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'signal_aggressiveBrakingHistogram',
    axisTitle: 'Acceleration (ft/s^2)',
    customAxisProps: { 
      tick: <SignalCodeTick />
    },
  }),
  signal_aggressiveBrakingHistogram_isAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_aggressiveBrakingHistogram',
    fieldLabel: 'Aggressive Braking',
    color: 'red',
    axisTitle: ['% of', 'Vehicles'],
  }),
  signal_aggressiveBrakingHistogram_isNotAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_aggressiveBrakingHistogram',
    fieldLabel: 'Nonaggressive Braking',
    color: 'gray4',
    axisTitle: ['% of', 'Vehicles'],
  }),

  network_aggressiveBrakingHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'network_aggressiveBrakingHistogram',
    axisTitle: 'Acceleration (ft/s^2)',
    customAxisProps: { 
      tick: <SignalCodeTick />
    },
  }),
  network_aggressiveBrakingHistogram_isAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_aggressiveBrakingHistogram',
    fieldLabel: 'Aggressive Braking',
    color: 'red',
    axisTitle: ['% of', 'Vehicles'],
  }),
  network_aggressiveBrakingHistogram_isNotAggressiveBraking: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_aggressiveBrakingHistogram',
    fieldLabel: 'Nonaggressive Braking',
    color: 'gray4',
    axisTitle: ['% of', 'Vehicles'],
  }),

  // Approach Speed Histogram (Phase Level Only)
  phase_approachSpeedHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_approachSpeedHistogram',
    axisTitle: 'Approach Speed (mph)',
    customAxisProps: {
      tick: <SignalCodeTick />,
      interval: 0,
    },
  }),
  phase_approachSpeedHistogram_approachSpeedBelowLimit: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_approachSpeedHistogram',
    fieldLabel: 'Speed Below Limit',
    color: 'gray4',
    axisTitle: ['% of', 'Vehicles'],
  }),
  phase_approachSpeedHistogram_approachSpeedAboveLimit1: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_approachSpeedHistogram',
    fieldLabel: 'Speed Above Limit Up to 10MPH',
    color: 'pink',
    axisTitle: ['% of', 'Vehicles'],
  }),
  phase_approachSpeedHistogram_approachSpeedAboveLimit2: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_approachSpeedHistogram',
    fieldLabel: 'Speed Above Limit Beyond 10MPH',
    color: 'red',
    axisTitle: ['% of', 'Vehicles'],
  }),

  // Arrival Histogram (Phase Level Only)
  phase_arrivalModulusCycleLengthHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'phase_arrivalModulusCycleLengthHistogram',
    axisTitle: 'Time Since Cycle Start (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
    },
  }),
  phase_arrivalModulusCycleLengthHistogram_arrivalTimeGreenIndication: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_arrivalModulusCycleLengthHistogram',
    fieldLabel: 'Arrival on Green',
    color: 'green',
    axisTitle: ['Num', 'Vehicles'],
  }),
  phase_arrivalModulusCycleLengthHistogram_arrivalTimeYellowIndication: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_arrivalModulusCycleLengthHistogram',
    fieldLabel: 'Arrival on Yellow',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
  }),
  phase_arrivalModulusCycleLengthHistogram_arrivalTimeRedClearanceIndication: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_arrivalModulusCycleLengthHistogram',
    fieldLabel: 'Arrival on Red Clearance',
    color: 'pink',
    axisTitle: ['Num', 'Vehicles'],
  }),
  phase_arrivalModulusCycleLengthHistogram_arrivalTimeRedIndication: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_arrivalModulusCycleLengthHistogram',
    fieldLabel: 'Arrival on Red',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),

  // Edge Delay Histogram (Delay (s) x Number of Vehicles)
  mainRouteEdges_delayHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'mainRouteEdges_delayHistogram',
    axisTitle: 'Delay (s)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
    },
  }),
  mainRouteEdges_delayHistogram_delay: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_delayHistogram',
    fieldLabel: 'Number of Vehicles',
    color: 'blue',
    axisTitle: ['Num', 'Vehicles'],
  }),

  // Edge Speed Histogram (Speed (mph) x Number of Vehicles) *Colors like approach speed?
  mainRouteEdges_speedHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'mainRouteEdges_speedHistogram',
    axisTitle: 'Speed (mph)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
      interval: 'PreserveStartEnd',
    },
  }),
  mainRouteEdges_speedHistogram_speedBelowLimit: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_speedHistogram',
    fieldLabel: 'Speed Below Limit (Or Speed Limit Unknown)',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
  }),
  mainRouteEdges_speedHistogram_speedAboveLimit1: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_speedHistogram',
    fieldLabel: 'Speed Above Limit Up to 10MPH',
    color: 'pink',
    axisTitle: ['Num', 'Vehicles'],
  }),
  mainRouteEdges_speedHistogram_speedAboveLimit2: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_speedHistogram',
    fieldLabel: 'Speed Above Limit Beyond 10MPH',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),

  // Edge Minimum Acceleration Histogram (Acceleration (ft/s^2) x Number of Vehicles)
  mainRouteEdges_minAccelerationHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'mainRouteEdges_minAccelerationHistogram',
    axisTitle: 'Acceleration (ft/s^2)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
      interval: 'PreserveStartEnd',
    },
  }),
  mainRouteEdges_minAccelerationHistogram_minAccelerationIsNotAggressive: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_minAccelerationHistogram',
    fieldLabel: 'Unaggressive Acceleration',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
  }),
  mainRouteEdges_minAccelerationHistogram_minAccelerationIsAggressive: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_minAccelerationHistogram',
    fieldLabel: 'Aggressive Acceleration',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),

  // Edge Maximum Acceleration Histogram (Acceleration (ft/s^2) x Number of Vehicles)
  mainRouteEdges_maxAccelerationHistogram_interval: generateStandardHistogramXAxisProps({ 
    histogramName: 'mainRouteEdges_maxAccelerationHistogram',
    axisTitle: 'Acceleration (ft/s^2)',
    customAxisProps: { 
      tick: <SignalCodeTick />,
      interval: 'PreserveStartEnd',
    },
  }),
  mainRouteEdges_maxAccelerationHistogram_maxAccelerationIsNotAggressive: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_maxAccelerationHistogram',
    fieldLabel: 'Unaggressive Acceleration',
    color: 'gray4',
    axisTitle: ['Num', 'Vehicles'],
  }),
  mainRouteEdges_maxAccelerationHistogram_maxAccelerationIsAggressive: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'mainRouteEdges_maxAccelerationHistogram',
    fieldLabel: 'Aggressive Acceleration',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
  }),

  // DZE Histogram (Note: Different from other histograms)
  phase_aggressiveBrakingCauseBarChart_type: generateStandardHistogramXAxisProps({
    histogramName: 'phase_aggressiveBrakingCauseBarChart',
    axisTitle: 'Type of Cause',
    customGeneralProps: {
      xAxisId: `phase_aggressiveBrakingCauseBarChart_type`,
      sanitizeData: value => value,
    },
    customAxisProps: {
      interval: 'PreserveStartEnd',
      label: null,
    }
  }),
  phase_aggressiveBrakingCauseBarChart_dilemmaZone_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Dilemma Zone Entry',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `phase_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_aggressiveBrakingCauseBarChart_queueing_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Queueing',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `phase_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  phase_aggressiveBrakingCauseBarChart_other_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'phase_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Other',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `phase_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_aggressiveBrakingCauseBarChart_type: generateStandardHistogramXAxisProps({ 
    histogramName: 'signal_aggressiveBrakingCauseBarChart',
    axisTitle: 'Type of Cause',
    customGeneralProps: {
      xAxisId: `signal_aggressiveBrakingCauseBarChart_type`,
      sanitizeData: value => value,
    },
    customAxisProps: {
      interval: 'PreserveStartEnd',
      label: null,
    },
  }),
  signal_aggressiveBrakingCauseBarChart_dilemmaZone_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Dilemma Zone Entry',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `signal_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_aggressiveBrakingCauseBarChart_queueing_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Queueing',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `signal_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  signal_aggressiveBrakingCauseBarChart_other_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'signal_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Other',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `signal_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_aggressiveBrakingCauseBarChart_type: generateStandardHistogramXAxisProps({ 
    histogramName: 'network_aggressiveBrakingCauseBarChart',
    axisTitle: 'Type of Cause',
    customGeneralProps: {
      xAxisId: `network_aggressiveBrakingCauseBarChart_type`,
      sanitizeData: value => value,
    },
    customAxisProps: {
      interval: 'PreserveStartEnd',
      label: null,
    }
  }),
  network_aggressiveBrakingCauseBarChart_dilemmaZone_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Dilemma Zone Entry',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `network_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_aggressiveBrakingCauseBarChart_queueing_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Queueing',
    color: 'yellow',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `network_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
  network_aggressiveBrakingCauseBarChart_other_quantity: generateStandardHistogramYAxisAndChartProps({
    histogramName: 'network_aggressiveBrakingCauseBarChart',
    fieldLabel: 'Other',
    color: 'red',
    axisTitle: ['Num', 'Vehicles'],
    customGeneralProps: {
      xAxisId: `network_aggressiveBrakingCauseBarChart_type`,
    },
    customChartProps: {
      isHideInLegend: true,
    },
  }),
};
