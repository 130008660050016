import React from 'react';
import IconWrapper from './IconWrapper';

const Checkmark = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="1 0.5 15 15">
    <path d="M4 8L7.5 11.5L13 5" strokeWidth="2" strokeLinecap="round" />
  </IconWrapper>
);

export default Checkmark;
