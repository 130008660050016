import React, { forwardRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Inlet, Outlet } from 'react-conduit';
import { useHistory } from 'react-router-dom';

// import paths, { getPathWithVariables } from '../../paths';
import LogoRings from '../shared/icons/multicolor/LogoRings';
import MagnifyingGlass from '../shared/icons/MagnifyingGlass';
import NotificationBell from '../shared/icons/NotificationBell';
import NotificationBellWithDot from '../shared/icons/NotificationBellWithDot';
// import RoutedTabs from '../shared/elements/material/RoutedTabs';
// import TodSelector from './TodSelector';

const Header = forwardRef((props, ref) => {

  const history = useHistory();

  // const { abacusController, mapController, /*searchController*/ } = useAppController();
  // useEffect(() => {
  //   searchController.setParams(params);
  // });

  return (
    <header ref={ref} className="bg-gray5 flex-shrink-0 w-100% flex items-center z-11">
      <div className="ml-8 mr-10 mv-5">
        <LogoRings className="pointer" style={ { width: '44px', height: '24px' } } onClick={() => history.push('/')} />
      </div>
      {/* <RoutedTabs paddingSize={8} tabs={[
        {
          label: 'Home',
          paths: [getPathWithVariables('map', { regionId: 1,}), paths.map], //TODO: Placeholder for demo
        },
        {
          label: 'Abacus',
          paths: [getPathWithVariables('map', { regionId: 1, focus: 'network', tool: 'abacus', networkId: 1, }), paths.map],
        },
        // {
        //   label: 'Cerberus',
        //   paths: [getPathWithVariables('cerberus', { regionId: 1 }), paths.cerberus],
        // },
        // {
        //   label: 'Timing Plans',
        //   paths: ['/timing-plans'],
        // },
      ]} 
      /> */}
      <div className="ml-auto flex">
        <div className="mr-8 mv-5">
          <Outlet label="header-dropdown" />
        </div>
        <div className="mr-8 mv-5">
          <NotificationBellWithDot width="4" height="4" fillColor="black" strokeOpacity='.6' />
        </div>
        <div className="mr-8 mv-5">
          <MagnifyingGlass  width="4" height="4" fillColor="gray2" strokeColor="black" strokeWidth={.3} />
        </div>
      </div>
      {/* <HeaderSearch /> */}
    </header>
  );
});

export default observer(Header);
