import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useAppController from '../../hooks/useAppController';
import LitmusReportSettings from './LitmusReportSettings';
import LitmusReport from './LitmusReport';

const Litmus = () => {
  const { params, litmusController } = useAppController();
  const { beforeMetrics, afterMetrics } = litmusController;
  const [reportSettings, _setReportSettings] = useState({});

  const setReportSettings = newSettings => {
    if (newSettings.beforeDate && newSettings.afterDate && params.networkId && params.todId) {
      // todo: add startTime, endTime
      beforeMetrics.fetch({ networkId: params.networkId, todId: params.todId, referenceDate: newSettings.beforeDate.format('YYYY-MM-DD') });
      afterMetrics.fetch({ networkId: params.networkId, todId: params.todId, referenceDate: newSettings.afterDate.format('YYYY-MM-DD') });
    }

    _setReportSettings(newSettings);
  }

  useEffect(() => {
    // only clears the currently loaded variables, while keeping cache, at first load so we don't have a report
    // when loading litmus before settings are chosen, if the user was already in litmus earlier (since cache remains intact)
    beforeMetrics.clearVariables();
    afterMetrics.clearVariables();
  }, [afterMetrics, beforeMetrics, params.networkId, params.todId]);

  return (
    <div className="flex-grow-1">
      <div className="ph-7">
        <LitmusReportSettings reportSettings={ reportSettings } setReportSettings={ setReportSettings } />
        <LitmusReport reportSettings={ reportSettings } />
      </div>
    </div>
  );
};

export default observer(Litmus);
