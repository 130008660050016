import { print } from 'graphql';

const apiPath = process.env.API_URL || (() => {throw "Environment variable 'API_URL' has not been set"})();
setTimeout(() => {

  console.error('API PATH', apiPath)
}, 1000)
const localStorageAuthTokenKey = 'authToken';
const loginPath = '/login';

export const config = {
  redirectFunction: path => window.history.replaceState(null, '', path),
};

let afterLoginLocation;

const redirectToLogin = () => {
  const newAfterLoginLocation = window.location.pathname + window.location.search + window.location.hash;
  if (!newAfterLoginLocation.startsWith(loginPath)) {
    afterLoginLocation = newAfterLoginLocation;
  }
  setTimeout(() => config.redirectFunction(loginPath), 0);

  return {
    errors: ['Sign-in required'],
  };
};

export const login = token => {
  localStorage.setItem(localStorageAuthTokenKey, token);
  // console.log('setting token to', token);
  config.redirectFunction(afterLoginLocation || '/');
  afterLoginLocation = undefined;
};

const graphqlQuery = async (query, variables = {}, options = {}) => {
  const {
    skipAuthCheck = false,
  } = options;

  const authToken = localStorage.getItem(localStorageAuthTokenKey);
  const headers = {
    'Content-Type': 'application/json',
  };

  if (!skipAuthCheck) {
    if (!authToken) {
      return redirectToLogin();
    }

    headers['Authorization'] = authToken;
  }

  let response, responseJson;

  if (query?.kind && query.kind === 'Document') {
    // query = query?.loc?.source?.body; // original formatting
    query = print(query);
  } else if (typeof query !== 'string') {
    throw new Error('Expected GraphQL query to be either string or a gql tag.');
  }

  try {
    response = await fetch(apiPath, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    responseJson = await response.json();
  } catch (e) {
    return {
      errors: ['Network error'],
    };
  }

  if (!skipAuthCheck && (responseJson?.errors || []).some(err => err.message === 'No Access!')) {
    localStorage.removeItem(localStorageAuthTokenKey);
    return redirectToLogin();
  }

  return responseJson;
};

export default graphqlQuery;
