import React from 'react';
import IconWrapper from './IconWrapper';

const DoubleArrowHead = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="-.5 -1.25 18 18">
    <path d="M12.6663 7.33366L7.99967 2.66699L3.33301 7.33366M12.6663 12.667L7.99967 8.00033L3.33301 12.667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </IconWrapper>
);

export default DoubleArrowHead;
