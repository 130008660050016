import React from 'react';
import IconWrapper from './IconWrapper';

const Delete = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } strokeColor={ color } {...props} viewBox="0 0 16 16">
    <circle fill="none" cx="8" cy="8" r="5.666667" strokeWidth="1" />
    <path d="M6 8H10" fill="none" strokeWidth="1" strokeLinecap="round" />
  </IconWrapper>
);

export default Delete;
