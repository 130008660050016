import React from 'react';
import IconWrapper from './IconWrapper';

const Export = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="-.5 .5 16 16">
    <path d="M8.67634 5.71675V3L11.631 5.95466L14 8.32366L11.5889 10.333L8.67634 12.7604V10.102C3.56297 9.71656 1.77147 12.6756 1.77147 12.6756C1.77147 10.832 1.92337 8.91873 3.37277 7.46996C5.05756 5.78455 7.49749 5.66528 8.67634 5.71675Z" />
  </IconWrapper>
);

export default Export;
