import React from 'react';
import IconWrapper from './IconWrapper';

const Delta = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="3 2 10 12">
    <path fillRule="evenodd" d="M12 12L8 4L4 12H12ZM10 10L8 6L6 10H10Z" />
  </IconWrapper>
);

export default Delta;
