const MapSignalIcon = ({ withDefs, size = '5', opacity = '100' }) => {
  return (
    <svg width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={ `w-${ size } h-${ size } o-${ opacity }` }>
      <g filter="url(#filter0_d_656_108072)">
        <path d="M25.5 18V29H28.5V18H25.5ZM18.5 29V18H15.5V29H18.5ZM22 32.5C20.067 32.5 18.5 30.933 18.5 29H15.5C15.5 32.5899 18.4101 35.5 22 35.5V32.5ZM25.5 29C25.5 30.933 23.933 32.5 22 32.5V35.5C25.5899 35.5 28.5 32.5899 28.5 29H25.5ZM22 14.5C23.933 14.5 25.5 16.067 25.5 18H28.5C28.5 14.4101 25.5899 11.5 22 11.5V14.5ZM22 11.5C18.4101 11.5 15.5 14.4101 15.5 18H18.5C18.5 16.067 20.067 14.5 22 14.5V11.5Z" fill="white" fillOpacity="0.8" style={{ transformOrigin: 'center center', transform: 'scale(.95)' }}/>
        <g filter="url(#filter1_i_656_108072)">
          <rect x="18" y="14" width="9" height="19" rx="4" fill="#5D648E"/>
          <circle cx="22" cy="18" r="2" fill="#E05232"/>
          <circle cx="22" cy="23.5" r="2" fill="#E79417"/>
          <circle cx="22" cy="29" r="2" fill="#B2E96D"/>
        </g>
      </g>
      {
        !withDefs ? null : (
          <defs>
            <filter id="filter0_d_656_108072" x="0.75" y="0" width="42.5" height="53.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="3.25"/>
              <feGaussianBlur stdDeviation="8.125"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.8 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_656_108072"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_656_108072" result="shape"/>
            </filter>
            <filter id="filter1_i_656_108072" x="18" y="14" width="8" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
              <feFlood floodOpacity="0" result="BackgroundImageFix"/>
              <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dy="2"/>
              <feGaussianBlur stdDeviation="2"/>
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0 1 0 0 0 0.4 0"/>
              <feBlend mode="normal" in2="shape" result="effect1_innerShadow_656_108072"/>
            </filter>
          </defs>
        )
      }
    </svg>
  );
};

export default MapSignalIcon;
