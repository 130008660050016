import React, { useEffect, useRef } from 'react';
import ParticleEmitter from 'particle-emitter';

import flowCssConfig from '../css/build/config.json';
import useComponentSize from '@rehooks/component-size';
import useWindowSize from '../hooks/useWindowSize';
import { observer } from 'mobx-react-lite';
import useAppController from '../hooks/useAppController';
import Header from './header/Header';
import useAllParams from '../hooks/useAllParams';
import { stringify } from '../helpers/stableSerialize';
import usePrevious from '../hooks/usePrevious';

const Layout = ({ children }) => {
  const { isHeaderEnabled, isLayoutFixedHeight } = useAppController();

  const windowSize = useWindowSize();

  const headerRef = useRef(null);
  const headerSize = useComponentSize(headerRef);

  const childrenContainerClass = isLayoutFixedHeight ? 'overflow-y-hidden' : 'flex-grow-1 h-100%';
  const childrenContainerStyle = isLayoutFixedHeight ? { height: windowSize.innerHeight - headerSize.height } : {};

  return (
    <>
      <div className="flex flex-column h-100% w-100%">
        {
          isHeaderEnabled && (
            <Header ref={headerRef}/>
          )
        }
        <div className={childrenContainerClass} style={childrenContainerStyle}>
          { children }
        </div>
      </div>
      <style type="text/css">
        { (new ParticleEmitter(flowCssConfig)).getCss() }
      </style>
    </>
  );
};

export default observer(Layout);
