import React from 'react';
import { unstable_createMuiStrictModeTheme as createMuiTheme, ThemeProvider } from '@mui/material/styles';
import Fade from '@mui/material/Fade';


import flowCssConfig from '../css/build/config.json';
import convertRgbaToHex from '../css/util/convertRgbaToHex';

const getKeyValuePairs = array => {
  const obj = {};
  array.forEach(([key, value]) => obj[key] = value);
  return obj;
};

const getListPairsFromConfig = listName => getKeyValuePairs(
  flowCssConfig.lists.find(list => list[0] === listName)[1]
);
const getRulePairsFromConfig = ruleName => getKeyValuePairs(
  flowCssConfig.rules.find(rule => rule[0] === ruleName)[1].map
);

export const borderSizes = getListPairsFromConfig('borderSizes');
export const colors = getListPairsFromConfig('colors');
export const elementSizes = getListPairsFromConfig('elementSizes');
export const shadows = getListPairsFromConfig('shadows');
export const spacingSizes = getListPairsFromConfig('spacingSizes');

console.log('colors', colors);
console.log('elementSizes', elementSizes);
console.log('spacingSizes', spacingSizes);

export const borderRadii = getRulePairsFromConfig('borderRadius');
export const fontSizes = getRulePairsFromConfig('fontSize');
export const fontWeights = getRulePairsFromConfig('fontWeight');
export const lineHeights = getRulePairsFromConfig('lineHeight');


export const themeOptions = {
  breakpoints: {
    keys: ['w'],
    values: { w: 120 },
    unit: 'em',
  },
  direction: 'ltr',
  // mixins: {
  //   toolbar: {
  //     minHeight: 56,
  //     '@media (min-width:0px) and (orientation: landscape)': { minHeight: 48 },
  //     '@media (min-width:600px)': { minHeight: 64 },
  //   },
  // },
  // components: { MuiCssBaseline: { styleOverrides: { body: null } } },
  palette: {
    common: { black: colors.black, white: colors.white },
    mode: 'dark',
    // primary: { light: colors.blue6, main: colors.blue, contrastText: colors.white },
    // secondary: { light: colors.gray6, main: colors.gray2, contrastText: colors.blue },
    // error: { light: colors.red6, main: colors.red, contrastText: colors.white },
    // warning: { light: colors.red3, main: colors.red4, contrastText: colors.black },
    // info: { light: colors.blue6, main: colors.blue, contrastText: colors.white },
    // success: { light: colors.green3, main: colors.green, contrastText: colors.white },
    grey: {
      '50': convertRgbaToHex('rgba(28, 27, 27, .05)'),
      '100': convertRgbaToHex('rgba(28, 27, 27, .1)'),
      '200': convertRgbaToHex('rgba(28, 27, 27, .2)'),
      '300': convertRgbaToHex('rgba(28, 27, 27, .3)'),
      '400': convertRgbaToHex('rgba(28, 27, 27, .4)'),
      '500': convertRgbaToHex('rgba(28, 27, 27, .5)'),
      '600': convertRgbaToHex('rgba(28, 27, 27, .6)'),
      '700': convertRgbaToHex('rgba(28, 27, 27, .7)'),
      '800': convertRgbaToHex('rgba(28, 27, 27, .8)'),
      '900': convertRgbaToHex('rgba(28, 27, 27, .9)'),
      A100: convertRgbaToHex('rgba(28, 27, 27, .35)'),
      A200: convertRgbaToHex('rgba(28, 27, 27, .45)'),
      A400: convertRgbaToHex('rgba(28, 27, 27, .85)'),
      A700: convertRgbaToHex('rgba(28, 27, 27, .7)'),
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    // text: { primary: colors.black, secondary: colors.gray4, disabled: colors.gray4 },
    // divider: colors.gray4,
    // background: { paper: colors.white, default: colors.white, level2: colors.gray2, level1: colors.gray1 },
    // action: {
    //   active: colors.gray6,
    //   hover: colors.gray3,
    //   hoverOpacity: 0.05,
    //   selected: colors.gray4,
    //   selectedOpacity: 0.1,
    //   disabled: colors.gray2,
    //   disabledBackground: colors.gray4,
    //   disabledOpacity: 0.38,
    //   focus: colors.gray4,
    //   focusOpacity: 0.12,
    //   activatedOpacity: 0.12,
    // },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(28, 27, 27, 0.1), 0px 1px 1px 0px rgba(28, 27, 27, 0.07), 0px 1px 3px 0px rgba(28, 27, 27, 0.06)',
    '0px 3px 1px -2px rgba(28, 27, 27, 0.1), 0px 2px 2px 0px rgba(28, 27, 27, 0.07), 0px 1px 5px 0px rgba(28, 27, 27, 0.06)',
    '0px 3px 3px -2px rgba(28, 27, 27, 0.1), 0px 3px 4px 0px rgba(28, 27, 27, 0.07), 0px 1px 8px 0px rgba(28, 27, 27, 0.06)',
    '0px 2px 4px -1px rgba(28, 27, 27, 0.1), 0px 4px 5px 0px rgba(28, 27, 27, 0.07), 0px 1px 10px 0px rgba(28, 27, 27, 0.06)',
    '0px 3px 5px -1px rgba(28, 27, 27, 0.1), 0px 5px 8px 0px rgba(28, 27, 27, 0.07), 0px 1px 14px 0px rgba(28, 27, 27, 0.06)',
    '0px 3px 5px -1px rgba(28, 27, 27, 0.1), 0px 6px 10px 0px rgba(28, 27, 27, 0.07), 0px 1px 18px 0px rgba(28, 27, 27, 0.06)',
    '0px 4px 5px -2px rgba(28, 27, 27, 0.1), 0px 7px 10px 1px rgba(28, 27, 27, 0.07), 0px 2px 16px 1px rgba(28, 27, 27, 0.06)',
    '0px 5px 5px -3px rgba(28, 27, 27, 0.1), 0px 8px 10px 1px rgba(28, 27, 27, 0.07), 0px 3px 14px 2px rgba(28, 27, 27, 0.06)',
    '0px 5px 6px -3px rgba(28, 27, 27, 0.1), 0px 9px 12px 1px rgba(28, 27, 27, 0.07), 0px 3px 16px 2px rgba(28, 27, 27, 0.06)',
    '0px 6px 6px -3px rgba(28, 27, 27, 0.1), 0px 10px 14px 1px rgba(28, 27, 27, 0.07), 0px 4px 18px 3px rgba(28, 27, 27, 0.06)',
    '0px 6px 7px -4px rgba(28, 27, 27, 0.1), 0px 11px 15px 1px rgba(28, 27, 27, 0.07), 0px 4px 20px 3px rgba(28, 27, 27, 0.06)',
    '0px 7px 8px -4px rgba(28, 27, 27, 0.1), 0px 12px 17px 2px rgba(28, 27, 27, 0.07), 0px 5px 22px 4px rgba(28, 27, 27, 0.06)',
    '0px 7px 8px -4px rgba(28, 27, 27, 0.1), 0px 13px 19px 2px rgba(28, 27, 27, 0.07), 0px 5px 24px 4px rgba(28, 27, 27, 0.06)',
    '0px 7px 9px -4px rgba(28, 27, 27, 0.1), 0px 14px 21px 2px rgba(28, 27, 27, 0.07), 0px 5px 26px 4px rgba(28, 27, 27, 0.06)',
    '0px 8px 9px -5px rgba(28, 27, 27, 0.1), 0px 15px 22px 2px rgba(28, 27, 27, 0.07), 0px 6px 28px 5px rgba(28, 27, 27, 0.06)',
    '0px 8px 10px -5px rgba(28, 27, 27, 0.1), 0px 16px 24px 2px rgba(28, 27, 27, 0.07), 0px 6px 30px 5px rgba(28, 27, 27, 0.06)',
    '0px 8px 11px -5px rgba(28, 27, 27, 0.1), 0px 17px 26px 2px rgba(28, 27, 27, 0.07), 0px 6px 32px 5px rgba(28, 27, 27, 0.06)',
    '0px 9px 11px -5px rgba(28, 27, 27, 0.1), 0px 18px 28px 2px rgba(28, 27, 27, 0.07), 0px 7px 34px 6px rgba(28, 27, 27, 0.06)',
    '0px 9px 12px -6px rgba(28, 27, 27, 0.1), 0px 19px 29px 2px rgba(28, 27, 27, 0.07), 0px 7px 36px 6px rgba(28, 27, 27, 0.06)',
    '0px 10px 13px -6px rgba(28, 27, 27, 0.1), 0px 20px 31px 3px rgba(28, 27, 27, 0.07), 0px 8px 38px 7px rgba(28, 27, 27, 0.06)',
    '0px 10px 13px -6px rgba(28, 27, 27, 0.1), 0px 21px 33px 3px rgba(28, 27, 27, 0.07), 0px 8px 40px 7px rgba(28, 27, 27, 0.06)',
    '0px 10px 14px -6px rgba(28, 27, 27, 0.1), 0px 22px 35px 3px rgba(28, 27, 27, 0.07), 0px 8px 42px 7px rgba(28, 27, 27, 0.06)',
    '0px 11px 14px -7px rgba(28, 27, 27, 0.1), 0px 23px 36px 3px rgba(28, 27, 27, 0.07), 0px 9px 44px 8px rgba(28, 27, 27, 0.06)',
    '0px 11px 15px -7px rgba(28, 27, 27, 0.1), 0px 24px 38px 3px rgba(28, 27, 27, 0.07), 0px 9px 46px 8px rgba(28, 27, 27, 0.06)'
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '\'Inter\', serif',
    fontSize: 14,
    fontWeightLight: fontWeights['regular'],
    fontWeightRegular: fontWeights['medium'],
    fontWeightMedium: fontWeights['semibold'],
    fontWeightBold: fontWeights['semibold'],
    h1: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['semibold'],
      fontSize: fontSizes['1'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    h2: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['semibold'],
      fontSize: fontSizes['3'],
      lineHeight: lineHeights['1'],
      letterSpacing: '0em',
    },
    h3: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['semibold'],
      fontSize: fontSizes['4'],
      lineHeight: lineHeights['1'],
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['medium'],
      fontSize: fontSizes['4'],
      lineHeight: lineHeights['1'],
      letterSpacing: '0em',
    },
    h5: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['semibold'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['medium'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['1'],
      letterSpacing: '0em',
    },
    subtitle1: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['semibold'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    subtitle2: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['medium'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['1'],
      letterSpacing: '0em',
    },
    body1: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['medium'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    body2: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['regular'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    button: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['medium'],
      fontSize: fontSizes['5'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
      textTransform: 'none',
    },
    caption: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['regular'],
      fontSize: fontSizes['6'],
      lineHeight: lineHeights['0'],
      letterSpacing: '0em',
    },
    overline: {
      fontFamily: '\'Inter\', serif',
      fontWeight: fontWeights['regular'],
      fontSize: fontSizes['6'],
      lineHeight: lineHeights['3'],
      letterSpacing: '0em',
      textTransform: 'uppercase',
    },
    disabled: {
      color: colors.gray4,
    },
  },
  shape: { borderRadius: borderRadii['pill'] },
  /*
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  */
  nprogress: { color: colors.black },

  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          marginTop: '-1px', // to align with selects
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            backgroundColor: colors.blue,
            border: 'unset',
            borderColor: colors.blue,
            borderStyle: 'solid',
            borderWidth: borderSizes['1'],
            color: colors.white,
            '&:hover': {
              backgroundColor: colors.blue2,
              borderColor: colors.blue2,
            },
            '&:active': {
              backgroundColor: colors.blue2,
              borderColor: colors.blue2,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.blue,
              borderColor: colors.blue,
              color: colors.white,
              opacity: .3,
            },
          },
        },

        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: colors.transparent,
            border: 'unset',
            borderColor: colors.blue,
            borderStyle: 'solid',
            borderWidth: borderSizes['1'],
            color: colors.blue,
            '&:hover': {
              backgroundColor: colors.blue3,
            },
            '&:active': {
              backgroundColor: colors.blue3,
            },
            '&.Mui-disabled': {
              backgroundColor: colors.white,
              borderColor: colors.black,
              color: colors.black,
              opacity: .3,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            color: colors.white,
            borderStyle: 'solid',
            borderWidth: borderSizes['1'],
            borderColor: 'transparent',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: spacingSizes['4'] + ' ' + spacingSizes['7'],
            label: {
              fontSize: fontSizes['6'],
              zIndex: 1, // ensure label goes above click ripple
            },
          },
        },

        {
          props: { size: 'large' },
          style: {
            padding: '.875rem ' + spacingSizes['9'],
            label: {
              fontSize: fontSizes['5'],
              zIndex: 1, // ensure label goes above click ripple
            },
          },
        },
      ],
    },

    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          verticalAlign: 'unset',
        },
      },
      variants: [],
    },

    MuiTooltip: {
      defaultProps: {
        disableFocusListener: true,
      },
      styleOverrides: {
        tooltip: {
          padding: spacingSizes['6'],
          lineHeight: lineHeights['1'],
          fontWeight: fontWeights['medium'],
          borderRadius: borderRadii['1'],
          color: colors['gray'],
          backgroundColor: colors['navy'],
          zIndex: 5000,
        },
        arrow: {
          color: colors['navy'],
        },
      },
      variants: [],
    },

    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        MenuProps: {
          TransitionComponent: Fade, // no scaling
        },
      },
      styleOverrides: {
        root: {
          borderRadius: borderRadii['pill'],

          // https://github.com/mui-org/material-ui/blob/9e097a5d4146004a886a45489ca751e37c43d956/packages/material-ui/src/NativeSelect/NativeSelectInput.js#L70-L74
          '&&&': {
            '.iconPosition-left &': {
              paddingRight: spacingSizes['7'],
              paddingLeft: spacingSizes['10'],
            },
            '.iconPosition-right &': {
              paddingRight: spacingSizes['10'],
              paddingLeft: spacingSizes['7'],
            },
            '.iconPosition-none &': {
              paddingRight: spacingSizes['7'],
              paddingLeft: spacingSizes['7'],
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            transition: 'box-shadow 250ms',
            boxShadow: shadows['gray2'],

            '&:hover': {
              boxShadow: shadows['gray3'],
            },

            '&.MuiSelect-select': {
              color: colors.gray5,
              minHeight: 'unset',
            },

            '&:focus': {
              backgroundColor: colors.white,
              borderRadius: borderRadii['pill'],
              boxShadow: shadows['none'],
            },

            iconOutlined: {
              right: 'unset',
              left: spacingSizes['8'],
            },
          },
        },

        {
          props: { variant: 'filled' },
          style: {
            transition: 'box-shadow 250ms',
            boxShadow: shadows['gray2'],

            '&:hover': {
              boxShadow: shadows['gray3'],
            },

            '&.MuiSelect-select': {
              color: colors.gray5,
              minHeight: 'unset',
            },

            '&:focus': {
              backgroundColor: colors.white,
              borderRadius: borderRadii['pill'],
              boxShadow: shadows['none'],
            },

            iconOutlined: {
              right: 'unset',
              left: spacingSizes['8'],
            },
          },
        },

        {
          props: { variant: 'standard' },
          style: {
            '&:hover': {
              boxShadow: shadows['gray3'],
            },

            '&.MuiSelect-select': {
              color: colors.blue,
              paddingRight: spacingSizes['7'],
              paddingLeft: spacingSizes['10'],
              minHeight: 'unset',
            },

            '&:focus': {
              backgroundColor: colors.white,
              borderRadius: borderRadii['pill'],
              boxShadow: shadows['none'],
            },

            iconOutlined: {
              right: 'unset',
              left: spacingSizes['8'],
            },
          },
        },
      ],
    },

    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        hiddenLabel: true,
      },

      variants: [
        {
          props: { variant: 'filled', size: 'large' },
          style: {
            '& .MuiFilledInput-input': {
              height: '2.5rem',
              padding: '0 ' + spacingSizes['9'],
              borderRadius: borderRadii['pill'],
              backgroundColor: colors['gray2'],
              color: colors['gray5'],
            },
          },
        },{
          props: { variant: 'outlined', size: 'medium' },
          style: {
            '& .MuiOutlinedInput-input': {
              height: '1.8rem',
              padding: '0 ' + spacingSizes['7'],
              borderRadius: borderRadii['pill'],
              backgroundColor: colors.white,
              color: colors['gray5'],
              fontWeight: fontWeights['normal'],
            },
          },
        },
      ],
    },

    MuiInput: {
      styleOverrides: {
        root: {
        },
        notchedOutline: {
          fieldset: {
            borderWidth: borderSizes['0'],
          },
        },
        input: {
          paddingTop: spacingSizes['4'],
          paddingBottom: spacingSizes['4'],
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blue,
            borderWidth: borderSizes['1'],
            borderRadius: borderRadii['pill'],
          },
        },
        notchedOutline: {
          fieldset: {
            borderWidth: borderSizes['0'],
          },
        },
        input: {
          paddingTop: `calc(${ spacingSizes['4'] } + ${ borderSizes['1'] })`,
          paddingBottom: `calc(${ spacingSizes['4'] } + ${ borderSizes['1'] })`,
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          padding: `${ spacingSizes['4'] } ${ spacingSizes['6'] }`,
          background: colors.gray5,
          color: colors.gray2,
          borderRadius: borderRadii['1'],
          '&:hover': {
            background: colors.gray4,
          },

          '&.MuiToggleButtonGroup-grouped:not(:first-of-type), &.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            marginLeft: 'unset',
            borderLeft: 'unset',
            marginRight: spacingSizes['3'],
            borderTopLeftRadius: borderRadii['1'],
            borderBottomLeftRadius: borderRadii['1'],
            borderTopRightRadius: borderRadii['1'],
            borderBottomRightRadius: borderRadii['1'],
          },

          '&.Mui-selected': {
            background: colors.blue,
            color: colors.white,

            '&:hover': {
              background: colors.blue1,
              zIndex: 3,
            },
          },
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blue,
            borderWidth: borderSizes['1'],
            borderRadius: borderRadii['pill'],
          },
        },
        notchedOutline: {
          fieldset: {
            borderWidth: borderSizes['0'],
          },
        },
        input: {
          paddingTop: `calc(${ spacingSizes['4'] } + ${ borderSizes['1'] })`,
          paddingBottom: `calc(${ spacingSizes['4'] } + ${ borderSizes['1'] })`,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          lineHeight: lineHeights['0'],
          borderRadius: borderRadii['pill'],

          '&&': {
            '&:before': {
              content: 'none',
            },
            '&:after': {
              content: 'none',
            },
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: borderRadii['1'],
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: shadows['gray'],

          // this is intended for select dropdowns, may need to be moved to a better place
          marginTop: spacingSizes['6'],
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: colors.navy,
        },
        padding: {
          paddingTop: spacingSizes['4'],
          paddingRight: spacingSizes['5'],
          paddingBottom: spacingSizes['4'],
          paddingLeft: spacingSizes['5'],
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          '.selectVariant-standard &': {
            color: colors.white,
            marginTop: spacingSizes['3'],
            marginBottom: spacingSizes['3'],
            borderColor: colors.white,
            borderStyle: 'solid',
            borderWidth: borderSizes['1'],

            '&.Mui-selected': {
              backgroundColor: colors.blue5,
              borderColor: colors.blue5,
              color: colors.white,

              '&:hover': {
                backgroundColor: colors.blue3,
                borderColor: colors.blue3,
                color: colors.white,
              },

              '&.Mui-focusVisible': {
                backgroundColor: colors.blue5,
                borderColor: colors.blue5,
                color: colors.white,
              },
            },

            '&:hover': {
              borderColor: colors.blue1,
              borderStyle: 'solid',
              borderWidth: borderSizes['1'],
              backgroundColor: colors.blue1,
              color: colors.black,
            },

            '& .MuiTouchRipple-root': {
              color: colors.blue5,
            },
          },


          '.selectVariant-outlined &': {
            color: colors.gray5,
            marginTop: spacingSizes['3'],
            marginBottom: spacingSizes['3'],
            borderColor: colors.white,
            borderStyle: 'solid',
            borderWidth: borderSizes['1'],

            '&.Mui-selected': {
              color: colors.black,
              backgroundColor: colors.white,
              borderColor: colors.white,
              fontWeight: fontWeights['semibold'],

              '&:hover': {
                backgroundColor: colors.blue1,
                borderColor: colors.blue1,
              },
            },

            '&:hover': {
              borderStyle: 'solid',
              borderWidth: borderSizes['1'],
              borderColor: colors.blue1,
              backgroundColor: colors.blue1,
              color: colors.black,
            },

            '& .MuiTouchRipple-root': {
              color: colors.blue5,
            },
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingTop: spacingSizes['4'],
          paddingBottom: spacingSizes['4'],
          borderRadius: borderRadii['1'],
          borderStyle: 'solid',
          borderWidth: borderSizes['1'],
          borderColor: colors.gray2,
          minHeight: 'unset',
        },
      },
    },

    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          children: <span />,
        },
      },
      styleOverrides: {
        root: {
          height: '100%',
          minHeight: 0,
        },
        flexContainer: {
          height: '100%',
        },
        indicator: {
          height: '0.09375rem',
          display: 'flex',
          backgroundColor: colors.white,
          '& span': {
            width: 'calc(100% - ' + spacingSizes['10'] + ')',
            marginLeft: spacingSizes['8'],
          },
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          padding: spacingSizes['0'] + ' ' + spacingSizes['8'],
          minHeight: 0,
          minWidth: 0,
          maxWidth: 'unset',
          fontSize: fontSizes['6'],
          fontWeight: fontWeights['medium'],

          '& .MuiTouchRipple-root': {
            color: colors.gray4,
          },
          '&.Mui-selected': {
            color: colors.white,
          },
          '&:hover': {
            color: colors.gray,
          },
        },
        textColorPrimary: {
          color: colors.gray2,
        },
        wrapper: {
          zIndex: 1, // above ripple
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        // '&:hover': {
        //   backgroundColor: colors.blue2,
        // },
        // root: {
        //   position: 'relative',
        //   width: '900px',
        //   height: '900px',
        //   '&:before': {
        //     content: '""',
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',
        //     background: `linear-gradient(90deg, transparent calc(100% - 100px), ${colors.white} calc(100% - 50px))`,
        //     opacity: 1,
        //     pointerEvents: 'none',
        //     zIndex: 4,
        //   },
        // },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: 0,
          borderCollapse: 'collapse',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            background: 'none',
          },
        },
        // '&:hover': {
        //   backgroundColor: colors.blue2,
        // },
        // root: {
        //   '&.MuiTableRow-hover:hover': {
        //     '.MuiTableCell-root': {
        //       position: 'relative',
        //       '&:before': {
        //         content: '""',
        //         position: 'absolute',
        //         top: 0,
        //         right: 0,
        //         bottom: 0,
        //         left: 0,
        //         backgroundColor: colors.blue,
        //         opacity: .1,
        //         pointerEvents: 'none',
        //       },
        //       '&.firstCell:before': {
        //         borderRadius: borderRadii['2'],
        //         borderTopRightRadius: 0,
        //         borderBottomRightRadius: 0,
        //       },
        //       '&.lastCell:before': {
        //         borderRadius: borderRadii['2'],
        //         borderTopLeftRadius: 0,
        //         borderBottomLeftRadius: 0,
        //       },
        //     },
        //   },
        // '&.MuiTableCell-body': {
        //     backgroundColor: colors.blue5,
        //   },
        // },
      },
    },

    // MuiTableHead: {
    //   styleOverrides: {
    //     root: {
    //       '&.MuiTableCell-root': {
    //
    //       },
    //     },
    //   },
    // },

    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          lineHeight: 1.1,
          padding: 'unset',
          border: 'none',
          margin: 0,
        },
      },
    },

    // MuiTouchRipple: {
    //   styleOverrides: {
    //     rippleVisible: {
    //       opacity: .15,
    //     },
    //     '@keyframes enter': {
    //       '0%': {
    //         transform: 'scale(0)',
    //         opacity: 0,
    //       },
    //       '100%': {
    //         transform: 'scale(1)',
    //         opacity: 0.15,
    //       },
    //     },
    //   },
    // },

  },
};

const theme = createMuiTheme(themeOptions);

const FlowMaterialTheme = ({ children }) => (
  <ThemeProvider theme={theme}>
    { children }
  </ThemeProvider>
);

export default FlowMaterialTheme;
