import React from 'react';
import { colors } from '../../FlowMaterialTheme';

const IconWrapper = (
  {
    className = '',
    style = {},
    width,
    height,
    fillColor = 'none',
    strokeColor = 'none',
    svgProps = {},
    viewBox,
    children,
    ...props
  }) => {

  if (colors.hasOwnProperty(fillColor)) {
    className += ` fill-${ fillColor }`;
  } else {
    style.fill = fillColor;
  }

  if (colors.hasOwnProperty(strokeColor)) {
    className += ` stroke-${ strokeColor }`;
  } else {
    style.stroke = strokeColor;
  }

  if (typeof width !== 'undefined') {
    className += ` w-${ width }`;
  }

  if (typeof height !== 'undefined') {
    className += ` h-${ height }`;
  }

  return (
    <div className={ className } style={ style } { ...props }>
      <svg width="100%" height="100%" viewBox={ viewBox } xmlns="http://www.w3.org/2000/svg" { ...svgProps }>
        { children }
      </svg>
    </div>
  );
};

export default IconWrapper;
