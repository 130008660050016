import { types } from 'mobx-state-tree';
import createGraphqlFetcherType from '../models/createGraphqlFetcherType';
import gql from 'graphql-tag';

const networkMetricsQuery = gql`
  query ($networkId: String!, $todId: String!, $referenceDate: String!) {
    user(todId: $todId) {
      network(id: $networkId) {
        id
        metrics(referenceDate: $referenceDate) {
          # Volume
          flowLabsVolumeCountPerTod
          journeyCount

          # Mobility
          controlDelayTotal
          forwardTravelTimeMedian
          reverseTravelTimeMedian

          # Signal Operations
          avgSplitFailuresPerTod
          arrivalsOnGreenRatio
          controlDelayMean
          controlDelayMeanMain
          controlDelayMeanSide

          # Environmental Impact
          co2EquivalentGramsVolumeWeightedTotal
          fuelLitresVolumeWeightedTotal

          # Safety
          deceleration10FpssRatio
          postClearanceAllMovementRedLightRunRatio
          dilemmaZoneRatio

          # Economic Impact
          annualizedCostOfDelay
#          annualizedFuelCost
        }
      }
    }
  }
`;

const LitmusController = types
  .model({
    beforeMetrics: createGraphqlFetcherType(networkMetricsQuery),
    afterMetrics: createGraphqlFetcherType(networkMetricsQuery),

    // params: types.frozen({}),
  });
  // .actions(self => ({
  // }));
  // .views(self => ({
  // }));

export default LitmusController;
