import React, { useEffect, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Chart from '../components/shared/elements/Chart';
import { ALL_CHARTS, ALL_ROUTE_CHARTS, ALL_MAINROUTEEDGES_CHARTS, ALL_SIGNAL_CHARTS, ALL_PHASE_CHARTS } from '../displayConfigs/chartDefinitions';
import useAppController from '../hooks/useAppController';
import { observer } from 'mobx-react-lite';


const TEST_NETWORK_ID = '1';
const TEST_TOD_ID = '2';
const TEST_ROUTE_ID = ''; // When reactivating, can use 1
const TEST_MAINROUTEEDGE_ID = '3685';
const TEST_SIGNAL_ID = '5';
const TEST_PHASE_ID = '50';
const TEST_AGGREGATION_PERIOD_DAYS = 28;


const TestPageChartContainer = () => {
  const [currChart, setCurrChart] = useState(ALL_CHARTS[0]);
  const [selectableCharts, setSelectableCharts] = useState(ALL_CHARTS);

  const { chartController } = useAppController();

  const [testNetworkId, setTestNetworkId] = useState(TEST_NETWORK_ID);
  const [testTodId, setTestTodId] = useState(TEST_TOD_ID);
  const [testRouteId, setTestRouteId] = useState(TEST_ROUTE_ID);
  const [testMainRouteEdgeId, setTestMainRouteEdgeId] = useState(TEST_MAINROUTEEDGE_ID);
  const [testSignalId, setTestSignalId] = useState(TEST_SIGNAL_ID);
  const [testPhaseId, setTestPhaseId] = useState(TEST_PHASE_ID);
  const [testAggregationPeriodDays, setTestAggregationPeriodDays] = useState(TEST_AGGREGATION_PERIOD_DAYS);

  const [isSettingTestParams, setIsSettingTestParams] = useState(true);

  const { mainRouteEdgeId, setMainRouteEdgeId, setTestParams } = chartController;

  useEffect(() => {
    if (isSettingTestParams) {
      setTestParams({
        networkId: testNetworkId,
        todId: testTodId,
        // routeId: testRouteId,
        signalId: testSignalId,
        phaseId: testPhaseId,
        aggregationPeriodDays: testAggregationPeriodDays,
      });

      setMainRouteEdgeId(testMainRouteEdgeId);

      setSelectableCharts(ALL_CHARTS.filter(chart => !(testNetworkId === '' && ALL_CHARTS.includes(chart)))
                                    .filter(chart => !(testRouteId === '' && ALL_ROUTE_CHARTS.includes(chart)))
                                    .filter(chart => !(mainRouteEdgeId === '' && ALL_MAINROUTEEDGES_CHARTS.includes(chart)))
                                    .filter(chart => !(testSignalId === '' && (ALL_SIGNAL_CHARTS.includes(chart) || ALL_PHASE_CHARTS.includes(chart))))
                                    .filter(chart => !(testPhaseId === '' && ALL_PHASE_CHARTS.includes(chart))));
      }

      setIsSettingTestParams(false);

  }, [isSettingTestParams]);


  // Needed for MUI Tabs indicator bar animation
  const [currTabValue, setCurrTabValue] = useState(0);
  const handleTabChange = (e, newCurrTabValue) => {
    setCurrTabValue(newCurrTabValue);
  };


  if (!currChart) {
    return null;
  }

  return (
    <div className="flex flex-column h-100% w-100% bg-black" >
      <div className="flex-grow-0 flex max-w-100% mv-4 flex-wrap">
        <label className="mb-2 mh-2" htmlFor="testNetworkId">
          NtwkId:
          <input type="text" inputMode="numeric" id="testNetworkId" name="testNetworkId" size="5"  value={testNetworkId} onChange={e => setTestNetworkId(e.currentTarget.value)}/>
        </label>
        <label className="mb-2 mh-2" htmlFor="testTodId">
          TodId:
          <input type="text" inputMode="numeric" id="testTodId" name="testTodId" size="5" value={testTodId} onChange={e => setTestTodId(e.currentTarget.value)}/>
        </label>
        <label className="mb-2 mh-2" htmlFor="testSignalId">
          SignalId (internal):
          <input type="text" inputMode="numeric" id="testSignalId" name="testSignalId" size="5" value={testSignalId} onChange={e => setTestSignalId(e.currentTarget.value)}/>
        </label>
        <label className="mb-2 mh-2" htmlFor="testPhaseId">
          PhaseId:
          <input type ="text" inputMode="numeric" id="testPhaseId" name="testPhaseId" size="5" value={testPhaseId} onChange={e => setTestPhaseId(e.currentTarget.value)}/>
        </label>
        <label className="mb-2 mh-2" htmlFor="testAggregationPeriodDays">
          AggPrdDays:
          <input type="text" inputMode="numeric" id="testAggregationPeriodDays" size="5" name="testAggregationPeriodDays" value={testAggregationPeriodDays}  onChange={e => setTestAggregationPeriodDays(e.currentTarget.value)}/>
        </label>
        <label className="mb-2 mh-2" htmlFor="testMainRouteEdgeId">
          EdgeId:
          <input type="text" inputMode="numeric" id="testMainRouteEdgeId" size="5" name="testMainRouteEdgeId" value={testMainRouteEdgeId}  onChange={e => setTestMainRouteEdgeId(e.currentTarget.value)}/>
        </label>
        {/* <label className="mb-2 mh-2" htmlFor="mainRouteEdgeId">
          RouteId:
          <input type="text" inputMode="numeric" id="testRouteId" size="5" name="testRouteId" value={`${testRouteId} `}  onChange={e => setTestRouteId(e.currentTarget.value)}/>
        </label> */}
        <button className="mh-4 ph-8" type="button" onClick={() => setIsSettingTestParams(true) }>Set</button>
      </div>
      <div className="flex-grow-0 flex-shrink-0">
        <div className="flex max-w-100% overflow-auto">
          {
            <Tabs
              variant="scrollable"
              scrollButtons={false}
              value={currTabValue}
              onChange={handleTabChange}
            >
              {
                selectableCharts
                .map(
                  chart => (
                    <Tab
                      className={'pa-6'}
                      wrapped
                      key={`${chart}_viewsetbutton`}
                      label={chart}
                      onClick={ e => {
                        e.preventDefault();
                        setCurrChart(chart);
                      }}
                    />
                  )
                )
              }
            </Tabs>
          }
        </div>
      </div>
      <div className="flex-grow-1">
        <Chart
          isTestMode={ true }
          currChart={ currChart }
        />
      </div>
    </div>
  );
};

export default observer(TestPageChartContainer);
