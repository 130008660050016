import React from 'react';
import IconWrapper from './IconWrapper';

const LogoText = ({ color = 'gray1', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="0 0 279 32">
    <path d="M5.77778 5.28889V14.7556H20.8V19.6444H5.77778V31.5556H0V0.444444H22.7111V5.28889H5.77778Z" />
    <path d="M29.3208 0.444444H35.0986V26.6667H51.3653V31.5556H29.3208V0.444444Z" />
    <path d="M70.9181 32C67.7477 32 64.8884 31.3185 62.3403 29.9556C59.7921 28.563 57.7921 26.6519 56.3403 24.2222C54.8884 21.763 54.1625 19.0222 54.1625 16C54.1625 12.9778 54.8884 10.2519 56.3403 7.82222C57.7921 5.36296 59.7921 3.45185 62.3403 2.08889C64.8884 0.696296 67.7477 0 70.9181 0C74.0884 0 76.9477 0.696296 79.4958 2.08889C82.044 3.45185 84.044 5.34815 85.4958 7.77778C86.9477 10.2074 87.6736 12.9481 87.6736 16C87.6736 19.0519 86.9477 21.7926 85.4958 24.2222C84.044 26.6519 82.044 28.563 79.4958 29.9556C76.9477 31.3185 74.0884 32 70.9181 32ZM70.9181 26.9333C72.9921 26.9333 74.8588 26.4741 76.5181 25.5556C78.1773 24.6074 79.481 23.3037 80.4292 21.6444C81.3773 19.9556 81.8514 18.0741 81.8514 16C81.8514 13.9259 81.3773 12.0593 80.4292 10.4C79.481 8.71111 78.1773 7.40741 76.5181 6.48889C74.8588 5.54074 72.9921 5.06667 70.9181 5.06667C68.844 5.06667 66.9773 5.54074 65.3181 6.48889C63.6588 7.40741 62.3551 8.71111 61.4069 10.4C60.4588 12.0593 59.9847 13.9259 59.9847 16C59.9847 18.0741 60.4588 19.9556 61.4069 21.6444C62.3551 23.3037 63.6588 24.6074 65.3181 25.5556C66.9773 26.4741 68.844 26.9333 70.9181 26.9333Z" />
    <path d="M139.289 0.444444L128.977 31.5556H122.844L115.155 8.53333L107.333 31.5556H101.155L90.8441 0.444444H96.8441L104.577 24.0889L112.622 0.444444H117.955L125.822 24.2222L133.777 0.444444H139.289Z" />
    <path d="M158.974 0.444444H164.752V26.6667H181.018V31.5556H158.974V0.444444Z" />
    <path d="M206.997 24.3556H191.441L188.375 31.5556H182.419L196.419 0.444444H202.108L216.152 31.5556H210.108L206.997 24.3556ZM205.086 19.8222L199.219 6.22222L193.397 19.8222H205.086Z" />
    <path d="M243.176 15.3778C244.894 15.9407 246.257 16.8889 247.265 18.2222C248.272 19.5259 248.776 21.1556 248.776 23.1111C248.776 25.8074 247.739 27.8963 245.665 29.3778C243.59 30.8296 240.568 31.5556 236.598 31.5556H221.131V0.444444H235.709C239.383 0.444444 242.213 1.17037 244.198 2.62222C246.183 4.04444 247.176 6.01481 247.176 8.53333C247.176 10.0741 246.82 11.437 246.109 12.6222C245.398 13.8074 244.42 14.7259 243.176 15.3778ZM226.909 4.97778V13.5556H235.087C237.102 13.5556 238.642 13.2 239.709 12.4889C240.805 11.7481 241.353 10.6815 241.353 9.28889C241.353 7.86667 240.805 6.8 239.709 6.08889C238.642 5.34815 237.102 4.97778 235.087 4.97778H226.909ZM236.242 27.0222C240.716 27.0222 242.953 25.5259 242.953 22.5333C242.953 19.5407 240.716 18.0444 236.242 18.0444H226.909V27.0222H236.242Z" />
    <path d="M265.609 32C263.209 32 260.883 31.6593 258.631 30.9778C256.409 30.2963 254.646 29.3926 253.342 28.2667L255.342 23.7778C256.616 24.7852 258.172 25.6148 260.009 26.2667C261.875 26.8889 263.742 27.2 265.609 27.2C267.92 27.2 269.638 26.8296 270.764 26.0889C271.92 25.3481 272.498 24.3704 272.498 23.1556C272.498 22.2667 272.172 21.5407 271.52 20.9778C270.898 20.3852 270.098 19.9259 269.12 19.6C268.142 19.2741 266.809 18.9037 265.12 18.4889C262.749 17.9259 260.823 17.363 259.342 16.8C257.89 16.237 256.631 15.363 255.564 14.1778C254.527 12.963 254.009 11.3333 254.009 9.28889C254.009 7.57037 254.468 6.01482 255.386 4.62222C256.335 3.2 257.742 2.07407 259.609 1.24444C261.505 0.414815 263.816 0 266.542 0C268.438 0 270.305 0.237037 272.142 0.711112C273.979 1.18519 275.564 1.86667 276.898 2.75556L275.075 7.24444C273.712 6.44445 272.29 5.83704 270.809 5.42222C269.327 5.00741 267.89 4.8 266.498 4.8C264.216 4.8 262.512 5.18518 261.386 5.95556C260.29 6.72593 259.742 7.74815 259.742 9.02222C259.742 9.91111 260.053 10.637 260.675 11.2C261.327 11.763 262.142 12.2074 263.12 12.5333C264.098 12.8593 265.431 13.2296 267.12 13.6444C269.431 14.1778 271.327 14.7407 272.809 15.3333C274.29 15.8963 275.549 16.7704 276.586 17.9556C277.653 19.1407 278.186 20.7407 278.186 22.7556C278.186 24.4741 277.712 26.0296 276.764 27.4222C275.846 28.8148 274.438 29.9259 272.542 30.7556C270.646 31.5852 268.335 32 265.609 32Z" />
  </IconWrapper>
);

export default LogoText;
