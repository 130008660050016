const convertRgbaToHex = (rgbaColor, backgroundRgbColor = 'rgb(255, 255, 255)') => {
  rgbaColor = rgbaColor
    .replace('rgba', '')
    .replace('(', '')
    .replace(')', '');

  const values = rgbaColor
    .split(',')
    .map(v => parseFloat(v.trim()));

  if (values.length !== 4) {
    throw new Error ('rgba color does not contain 4 values');
  }

  backgroundRgbColor = backgroundRgbColor
    .replace('rgb', '')
    .replace('(', '')
    .replace(')', '')

  const backgroundValues = backgroundRgbColor
    .split(',')
    .map(v => parseFloat(v.trim()));

  const alpha = values.splice(3)[0];
  const valuesOnBackground = values.map(
    (v, i) => ((1 - alpha) * backgroundValues[i]) + (alpha * v)
  );

  return '#' +
    valuesOnBackground
      .map(v => Math.round(v).toString(16))
      .map(v => v.length === 1 ? ('0' + v) : v) // pad
      .join('');
};

export default convertRgbaToHex;
