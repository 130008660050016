import React from 'react';
import IconWrapper from './IconWrapper';

const MagnifyingGlass = ({ fillColor = 'blue', strokeColor = 'blue', strokeWidth = 2, ...props }) => (
  <IconWrapper fillColor={fillColor} strokeColor={strokeColor} strokeWidth={strokeWidth} {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" fill={fillColor} stroke={strokeColor} strokeWidth={strokeWidth} d="M11 7C11 9.20914 9.20914 11 7 11C4.79086 11 3 9.20914 3 7C3 4.79086 4.79086 3 7 3C9.20914 3 11 4.79086 11 7ZM10.0002 11.0002C9.16451 11.628 8.1257 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 8.27532 11.5225 9.43912 10.7366 10.3224L11.0607 10.6464L13.7054 13.2912L14.059 13.6448L13.3519 14.3519L12.9983 13.9983L10.3536 11.3536L10.0002 11.0002Z"/>
  </IconWrapper>
);

export default MagnifyingGlass;
