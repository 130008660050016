import { observer } from 'mobx-react-lite';

import Abacus from '../abacus/Abacus';
import LogoRings from '../shared/icons/multicolor/LogoRings';
import useAppController from '../../hooks/useAppController';
import { useEffect } from 'react';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import Litmus from '../litmus/Litmus';

const MapSide = () => {
  const { params, mapController } = useAppController();
  const goToMaintain = useGoToMaintain('map');

  const {
    regionData,
  } = mapController;

  const { focus, regionId, networkId, signalId, phaseId, tool } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  useEffect(() => {
    if (focus && focus !== 'system' && !tool) {
      goToMaintain({
        tool: 'abacus',
      }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focus, tool]);

  let network, signal, phase;

  if (regionData.data?.user?.region) {
    if (focus === 'signal' || focus === 'phase' || focus === 'network') {
      network = regionData.data.user.region
        .networks.find(network => network.id === networkId);
    }

    if (focus === 'signal' || focus === 'phase') {
      signal = network.signals.find(signal => signal.id === signalId);
    }

    if (focus === 'phase') {
      phase = signal.phases.find(phase => phase.id === phaseId);
    }
  }

  if (!network) {
    return null;
  }

  return (
    <div className="pa-9 h-100% overflow-auto thin-scrollbars">
      <div className="flex flex-column min-h-100%">
        <div className="flex-grow-0 mb-7">
          <p className="semibold fs-3 ma-0 white">{ network.name } { signal ? ' • ' + signal.name : ''}</p>
          <p className="semibold fs-4 ma-0 mt-2 gray2">
            {
              !signal ? <>&nbsp;</> :
                <>
                  Intersection { signal.code } { phase ? (' • Phase ' + phase.number) : '' }
                </>
            }
          </p>
        </div>
        <div className="flex-grow-1 flex flex-column">
          <div
            className="flex-grow-0 pa-7 bo-radius-3 flex items-center pointer"
            style={{
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%)'
            }}
            onClick={() => goToMaintain({ tool: 'abacus' })}
          >
            <div className="bo-radius-circle boa-2 bo-gray5 inline-flex pa-3 mr-7">
              <LogoRings className="w-3 h-3" />
            </div>
            <div>
              <p className="fs-4 ma-0 gray2">Abacus</p>
              <p className="fs-3 ma-0 mt-2 semibold">Diagnostics</p>
            </div>
          </div>
          {
            tool === 'abacus' ? <Abacus /> : null
          }
          <div
            className="flex-grow-0 pa-7 bo-radius-3 flex items-center pointer"
            style={{
              background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0) 100%)'
            }}
            onClick={() => goToMaintain({ tool: 'litmus' })}
          >
            <div className="bo-radius-circle boa-2 bo-gray5 inline-flex pa-3 mr-7">
              <LogoRings className="w-3 h-3" />
            </div>
            <div>
              <p className="fs-4 ma-0 gray2">Litmus</p>
              <p className="fs-3 ma-0 mt-2 semibold">Performance Reports</p>
            </div>
          </div>
          {
            tool === 'litmus' ? <Litmus /> : null
          }
        </div>
      </div>
    </div>
  );
};

export default observer(MapSide);
