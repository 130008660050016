import React from 'react';
import IconWrapper from './IconWrapper';

const ArrowHead = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="-1 -1 18 18">
    <path d="M3 10L8 5L13 10" strokeWidth="2" strokeLinecap="round" />
  </IconWrapper>
);

export default ArrowHead;
