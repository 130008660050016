import React from 'react';
import IconWrapper from './IconWrapper';

const AlertTriangle = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="9 4 22 21">
    <path d="M16.9335 8.24031C18.1009 6.31152 20.8991 6.31152 22.0665 8.2403L28.244 18.4466C29.4541 20.4459 28.0145 23 25.6775 23L13.3225 23C10.9855 23 9.54587 20.4459 10.756 18.4466L16.9335 8.24031Z" strokeWidth="0.4" />
  </IconWrapper>
);

export default AlertTriangle;
