import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useAppController from '../../hooks/useAppController';
import ArrowHead from '../shared/icons/ArrowHead';
import useGoTo from '../../hooks/useGoTo';
import useGoToMaintain from '../../hooks/useGoToMaintain';

const MapBreadcrumbs = () => {
  const { params, mapController } = useAppController();
  const goTo = useGoTo('map');
  const goToMaintain = useGoToMaintain('map');

  const {
    regionData,
  } = mapController;

  const { focus, regionId, networkId, signalId } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  let network, signal;

  if (regionData.data?.user?.region) {
    if (focus === 'signal' || focus === 'phase' || focus === 'network') {
      network = regionData.data.user.region
        .networks.find(network => network.id === networkId);
    }

    if (focus === 'signal' || focus === 'phase') {
      signal = network.signals.find(signal => signal.id === signalId);
    }
  }

  const signalSecondaryName = signal?.secondaryName
    ? ' / ' + signal.secondaryName.replace('. (Eagle Mntn.)', '')
    : '';
  return (
    <>
      <div
        className="absolute z-3 top--3 right-0 left-0 pointer-events-none h-8"
        style={{
          background: 'linear-gradient(180deg, #080A0B, rgba(8, 10, 11, 0))'
        }}
      />
      <div className="absolute top-0 left-0 z-3 pl-9 pt-6">
        <span
          className={ `${ (focus === 'system') ? 'white' : 'gray2' } pointer` }
          onClick={() => goTo({ focus: 'system', regionId: 1 })}
        >
          North Utah
        </span>
        {
          !network ? null :
            <>
              <span className="ph-4">
                <div className="inline-block rotate-90 pb-2">
                  <ArrowHead width="2" height="2" color="gray2" />
                </div>
              </span>
              <span
                className={ `${ (focus === 'network') ? 'white' : 'gray2' } pointer` }
                onClick={() => goToMaintain({ focus: 'network', networkId: network.id })}
              >
                { network.name }
              </span>
            </>
        }
        {
          !signal ? null :
            <>
              <span className="ph-4">
                <div className="inline-block rotate-90 pb-2">
                  <ArrowHead width="2" height="2" color="gray2" />
                </div>
              </span>
              <span
                className="white pointer"
                onClick={() => goToMaintain({ focus: 'signal', phaseId: null })}
              >
                { signal.name } { signalSecondaryName }
              </span>
            </>
        }
      </div>
    </>
  );
};

export default observer(MapBreadcrumbs);
