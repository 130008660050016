import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useAnimationFrame from 'use-animation-frame';
// suppress transpiling mapbox-gl
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
// import MapboxDraw from '@mapbox/mapbox-gl-draw';
// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

export const animationDurationMs = 150;

const pi = Math.PI;
const piOver180 = pi/180;
const twoToThePower = {};

for (let i = 0; i <= 24; i++) {
  twoToThePower[i] = Math.pow(2, i);
}

const lngToTile = (lng, zoom) => Math.floor(
  twoToThePower[zoom] * (lng + 180) / 360
);
const latToTile = (lat, zoom) => Math.floor(
  twoToThePower[zoom] * (1 - Math.log(Math.tan(lat * piOver180) + 1/Math.cos(lat*piOver180))/pi)/2
);



const easeQuadraticInOut = t => {
  if (t <= 0)
    return 0;
  if (t >= 1)
    return 1;

  return t < 0.5
    ? 2 * t * t
    : -1 + (4 - 2 * t) * t;

  // or cubic?
  // const t2 = t * t, t3 = t2 * t;
  // return 4 * (t < 0.5 ? t3 : 3 * (t - t2) + t3 - 0.75);
};

const getMapboxBounds = bounds => new mapboxgl.LngLatBounds([
  { lng: bounds.sw[0], lat: bounds.sw[1] },
  { lng: bounds.ne[0], lat: bounds.ne[1] },
]);

const setInteraction = (map, enabled = true, interactions = ['scrollZoom', 'boxZoom', 'dragRotate', 'dragPan', 'keyboard', /*'doubleClickZoom',*/ 'touchZoomRotate']) => {
  if (!map || !interactions.length) {
    return;
  }
  const action = enabled ? 'enable' : 'disable';
  interactions.forEach(interaction => {
    map[interaction][action]();
  });
};

const useMapbox = (
  {
    mapContainer,
    mapRef,
    bounds,
    basePadding = 20,
    padding,
    // style = 'mapbox://styles/hamza-flowlabs/ckw3tqd260mxq14pqw897u8dm',
    // style = 'mapbox://styles/jenwxh/cl3euj72d001615pkdk3lhmjw',
    style = 'mapbox://styles/hamza-flowlabs/cl59utl55000015r0b3mvu38x',

    // if the user zooms out more than 2 ^ onZoomOutThreshold, we trigger onZoomOut
    onZoomOut,
    onZoomOutThreshold = 1.35,
  }
) => {
  const [forceRefreshTracker, setForceRefreshTracker] = useState(0);
  const needToRerenderRef = useRef(true);
  const needToResizeMapRef = useRef(false);
  const isTransitioningRef = useRef(true);
  const initialZoomRef = useRef(-1);
  const isLoading = useRef(true);
  const boundsJson = bounds ? JSON.stringify(bounds) : null;
  const multiplier = Math.pow(2, Math.max(window.innerHeight / 720, window.innerWidth / 1280));
  basePadding = basePadding * multiplier;

  padding = typeof padding === 'object'
    ? {
      top: padding.top || 0,
      right: padding.right || 0,
      bottom: padding.bottom || 0,
      left: padding.left || 0,
    }
    : {
      top: padding || 0,
      right: padding || 0,
      bottom: padding || 0,
      left: padding || 0,
    };

  const paddingObject = {
    top: Math.floor(padding.top + basePadding),
    right: Math.floor(padding.right + basePadding),
    bottom: Math.floor(padding.bottom + basePadding),
    left: Math.floor(padding.left + basePadding),
  };

  const paddingJson = JSON.stringify(paddingObject);

  const forceRerender = useCallback(() => {
    if (mapRef.current && !isTransitioningRef.current) {
      needToRerenderRef.current = true;
    }
  }, [mapRef.current]);

  useAnimationFrame(() => {
    const map = mapRef.current;
    if (map && needToRerenderRef.current) {
      needToRerenderRef.current = false;
      setForceRefreshTracker(x => x + 1);
    }

    if (map && needToResizeMapRef.current) {
      needToResizeMapRef.current = false;
      map.resize();
    }
  });

  const onResize = useCallback(() => {
    if (mapContainer && mapRef.current) {
      needToResizeMapRef.current = true;
    }
  }, [mapContainer, mapRef.current]);

  useLayoutEffect(() => {
    if (!mapContainer) {
      return;
    }

    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(() => onResize());

      resizeObserver.observe(mapContainer);

      return () => {
        resizeObserver.disconnect();
        resizeObserver = null;
      }
    } else {
      window.addEventListener('resize', onResize);

      onResize();

      return () => window.removeEventListener('resize', onResize);
    }
  }, [mapContainer, onResize]);

  const doInitialThings = useCallback(() => {
    const map = mapRef.current
    // need a better way to set initialZoomRef.current while the map is loading than by checking map.getZoom() < 21
    if (boundsJson && mapContainer && map && map.getZoom() < 21) {
      isLoading.current = false;
      initialZoomRef.current = map.getZoom();
    }
  }, [mapContainer, mapRef.current, boundsJson, paddingJson]);

  const fitBounds = useCallback((onZoomComplete) => {
    const map = mapRef.current;
    if (boundsJson && mapContainer && map) {
      isTransitioningRef.current = true;
      needToRerenderRef.current = true;

      setInteraction(map, false);

      let timeoutId1, timeoutId2, timeoutId3;

      const onTransitionComplete = () => {
        if (!isTransitioningRef.current) {

          doInitialThings();

          setInteraction(map, true);
          forceRerender();

          if (typeof onZoomComplete === 'function') {
            onZoomComplete(mapRef, initialZoomRef);
          }

          clearTimeout(timeoutId1);
          clearTimeout(timeoutId2);
          clearTimeout(timeoutId3);
        }
      };

      // interrupt any existing transition
      if (map.getZoom()) {
        map.setZoom(map.getZoom());
      }

      map.once('moveend', () => {
        onTransitionComplete();
        timeoutId1 = setTimeout(onTransitionComplete, animationDurationMs);
        timeoutId2 = setTimeout(onTransitionComplete, animationDurationMs * 2);
        timeoutId3 = setTimeout(() => {
          isTransitioningRef.current = false;
          needToRerenderRef.current = true;
        }, animationDurationMs * 3);
      });

      map.fitBounds(getMapboxBounds(bounds), {
        padding: paddingObject,
        duration: animationDurationMs,
        pitch: 0,
        elevation: 0,
        angle: 0,
        easing: t => {
          const result = easeQuadraticInOut(t);

          // transition complete, allow our moveend listener to do its thing
          // if we never get here, it's because the transition was interrupted
          if (result > .9999 && isTransitioningRef.current) {
            isTransitioningRef.current = false;
            needToRerenderRef.current = true;
          }

          return result;
        },
      });
    }
  }, [mapContainer, mapRef.current, boundsJson, paddingJson]);

  useEffect(() => {
    if (boundsJson && mapContainer) {
      const handleZoomOut = (mapRef, initialZoomRef) => {
        if (
          onZoomOut
          && !isLoading.current // we're loading
          && !isTransitioningRef.current // we're transitioning
          && (mapRef.current?.getZoom?.() || 0) < (initialZoomRef.current || 0) - onZoomOutThreshold
        ) {
          onZoomOut();
        }
      }

      let map;

      if (mapRef.current) {
        map = mapRef.current;
        fitBounds(handleZoomOut);
      } else {
        mapboxgl.accessToken = 'pk.eyJ1IjoiaGFtemEtZmxvd2xhYnMiLCJhIjoiY2tua3Jucmx1MDJsdTJ2cDFuNHdvZWZqYiJ9.K0ABAgfDeEWLSPVYq3_pCw';

        const mapboxBounds = getMapboxBounds(bounds);

        map = new mapboxgl.Map({
          container: mapContainer,
          style,
          bounds: mapboxBounds,
          fitBoundsOptions: {
            padding: paddingObject,
          },
          trackResize: true,
          refreshExpiredTiles: false,
          minZoom: 8,
          fadeDuration: 50,
          doubleClickZoom: false,
        });

        // draw.current = new MapboxDraw();
        //
        // map.addControl(draw.current, 'bottom-left');

        mapRef.current = map;
      }

      const onZoom = () => {
        handleZoomOut(mapRef, initialZoomRef);
      };

      const onMove = () => {
        const newBounds = map.getBounds();
        const sw = newBounds.getSouthWest();
        const ne = newBounds.getNorthEast();
        const zoom = Math.floor(map.getZoom());
        const newTileBounds = {
          minX: lngToTile(sw.lng, zoom),
          maxX: lngToTile(ne.lng, zoom),
          maxY: latToTile(sw.lat, zoom),
          minY: latToTile(ne.lat, zoom),
        };

        if (
          !tileBoundsRef.current
          || tileBoundsRef.current.minX !== newTileBounds.minX
          || tileBoundsRef.current.maxX !== newTileBounds.maxX
          || tileBoundsRef.current.minY !== newTileBounds.minY
          || tileBoundsRef.current.maxY !== newTileBounds.maxY
        ) {
          tileBoundsRef.current = newTileBounds;
          forceRerender();
        }
      };

      const onLoad = () => {
        // mapRef.current = map;
        //
        doInitialThings();

        map.on('zoom', onZoom);
        map.on('viewreset', forceRerender);
        map.on('move', onMove);
        map.on('moveend', forceRerender);

        onMove();
      };


      if (map._loaded) {
        map.on('zoom', onZoom);
        map.on('viewreset', forceRerender);
        map.on('move', onMove);
        map.on('moveend', forceRerender);
      } else {
        map.on('load', onLoad);
      }

      return () => {
        if (map._loaded) {
          map.off('zoom', onZoom);
          map.off('viewreset', forceRerender);
          map.off('move', forceRerender);
          map.off('moveend', forceRerender);
        } else {
          map.off('load', onLoad);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundsJson, paddingJson]);

  // if (draw.current) {
  //   console.log('draw.getAll()', JSON.stringify(draw.current?.getAll()));
  // }

  let width = 1;
  let height = 1;
  let zoom = -1;

  if (mapRef.current?._loaded) {
    const rect = mapRef.current.getCanvas().getBoundingClientRect();
    width = rect.width;
    height = rect.height;
    zoom = mapRef.current.getZoom();
  }

  const tileBoundsRef = useRef(null);

  return {
    fitBounds,
    forceRefreshTracker,
    map: mapRef.current,
    initialZoom: initialZoomRef.current,
    isMapTransitioning: !mapRef.current || isTransitioningRef.current,
    width,
    height,
    zoom,
    tileBounds: tileBoundsRef.current,
    isMapLoading: isLoading.current,
    bounds, // although passed in, returned here for convenience
  };
};

export default useMapbox;
