import { useEffect, useMemo, useRef } from 'react';
import { bboxClip, bearing, center, length, lineSliceAlong, point, points, transformTranslate } from '@turf/turf';
import { getZoomInterpolationValue, turfOptions, lineOffsetPerpendicular, getBoolFeature, getMetricsSteps } from './mapboxHelpers';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import useAppController from '../../hooks/useAppController';
import { colors } from '../FlowMaterialTheme';
import networkPolygons from './networkPolygons.json';
import { createRoot } from 'react-dom/client';
import MapNetworkLabel from './MapNetworkLabel';
import mapboxgl from 'mapbox-gl';
import MapPhaseLabel from './MapPhaseLabel';
import MapVolumeLabel from './MapVolumeLabel';
import MapSignalIcon from '../shared/icons/multicolor/MapSignalIcon';

const outerLineWidth = 3;
const linePadding = .15; // on each side
const innerLineWidth = outerLineWidth - (linePadding * 2);
const halfLineWidth = outerLineWidth / 2;
const turnedSegmentLength = 8.5;

const anchorDirections = [
  'bottom-left',
  'left',
  'top-left',
  'top',
  'top-right',
  'right',
  'bottom-right',
  'bottom',
];

const getPreferredTurn = (turn1, turn2) => {
  if (turn2 === 'through' && turn1) {
    return turn1;
  }

  return turn2;
}

const getAnchorFromBearing = bearing => {
  bearing ||= 0;
  while (bearing < 0) {
    bearing += 360;
  }
  const desiredI = Math.floor((bearing - 22.5) / 45)
  for (let i = 0; i < anchorDirections.length; i++) {
    if (i >= desiredI) {
      return anchorDirections[i];
    }
  }
}

const getPhaseLabelPositionAndAnchor = (feature, turn) => {
  const coordinates = feature.geometry.coordinates;
  if (coordinates.length < 2) {
    throw new Error('can\'t place phase label with this feature!');
  }

  const firstSegment = [coordinates[0], coordinates[1]];
  const firstSegmentBearing = bearing(firstSegment[0], firstSegment[1]);
  const desiredBearing = turn === 'center'
    ? (firstSegmentBearing + 180) % 360
    : turn === 'right'
      ? firstSegmentBearing + 90
      : firstSegmentBearing - 90;

  return [
    transformTranslate(point(firstSegment[0]), outerLineWidth * 4.5, desiredBearing, turfOptions).geometry.coordinates,
    getAnchorFromBearing(desiredBearing),
  ];
}

const getVolumeLabelPositionAndAnchor = (feature, turn) => {
  const coordinates = feature.geometry.coordinates;
  if (coordinates.length < 2) {
    throw new Error('can\'t place volume label with this feature!');
  }

  const firstSegment = [coordinates[0], coordinates[1]];
  const firstSegmentBearing = bearing(firstSegment[0], firstSegment[1]);
  const centerBearing = (firstSegmentBearing + 180) % 360;
  const desiredBearing = turn === 'through'
    ? centerBearing
    : turn === 'right'
      ? firstSegmentBearing + 90
      : firstSegmentBearing - 90;

  let position;
  if (turn === 'through') {
    position = transformTranslate(point(firstSegment[0]), outerLineWidth * 3, centerBearing, turfOptions)
  } else {
    position = transformTranslate(
      transformTranslate(point(firstSegment[0]), outerLineWidth * 3, centerBearing, turfOptions),
      outerLineWidth * 3,
      desiredBearing,
      turfOptions
    )
  }

  return [
    position.geometry.coordinates,
    getAnchorFromBearing(centerBearing),
  ];
}

const useMapboxSignalsAndPhases = mapboxResult => {
  const { bounds, isMapLoading, map } = mapboxResult;
  const goToMaintain = useGoToMaintain('map');
  const { params, mapController } = useAppController();
  const { networkMapGeometryData, signalPhaseMetricsData, regionData, hoveredPhaseId, setHoveredPhaseId } = mapController;
  const { focus, networkId, phaseId, signalId, regionId, todId } = params;

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId })
    }
  }, [regionId, regionData]);

  useEffect(() => {
    if (networkId) {
      networkMapGeometryData.fetch({ networkId });
    }
  }, [networkId, networkMapGeometryData, signalPhaseMetricsData]);

  useEffect(() => {
    if (networkId && todId) {
      signalPhaseMetricsData.fetch({ networkId, todId });
    }
  }, [networkId, todId, signalPhaseMetricsData]);

  const isSignalOrPhaseFocus = (focus === 'signal' || focus === 'phase')

  useEffect(() => {
    if (!isSignalOrPhaseFocus || isMapLoading || !map) {
      return;
    }

    const mapGeometry = networkMapGeometryData.data?.user?.network?.mapGeometry;

    if (!mapGeometry) {
      return;
    }

    const networkGeometrySource = {
      'type': 'FeatureCollection',
      // 'features': combinedSignal.map((row, index) => ({
      'features': mapGeometry.map((row, index) => ({
        'id': index,
        'type': 'Feature',
        'geometry': {
          'coordinates': row.pathCoordinates,
          'type': 'LineString'
        },
      }))
    };

    const onLoad = () => {
      map.addSource('networkGeometrySource', {
        'type': 'geojson',
        'data': networkGeometrySource,
      });

      map.addLayer({
        'id': 'allSignalEdgesLayer',
        'type': 'line',
        'source': 'networkGeometrySource',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#2a2b3b',
          'line-width': getZoomInterpolationValue(50),
        },
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }


    return () => {
      if (map.getLayer('allSignalEdgesLayer')) {
        map.removeLayer('allSignalEdgesLayer');
      }

      if (map.getSource('networkGeometrySource')) {
        map.removeSource('networkGeometrySource');
      }
    };
  }, [isSignalOrPhaseFocus, networkMapGeometryData.data, isMapLoading, map]);

  const markersRef = useRef([]);

  const phaseIdsRef = useRef([]);
  const phaseMarkerPositionsRef = useRef([]);
  const phaseMarkerAnchorsRef = useRef([]);
  const movementIdsRef = useRef([]);
  const volumeMarkerPositionsRef = useRef([]);
  const volumeMarkerAnchorsRef = useRef([]);

  const arrowShapesMemo = useMemo(() => {
    if (!isSignalOrPhaseFocus || isMapLoading || !map  || !bounds) {
      return;
    }

    const mapGeometry = networkMapGeometryData.data?.user.network.mapGeometry;
    const network = regionData.data?.user.region.networks.find(network => network.id === networkId);
    const signal = network?.signals.find(signal => signal.id === signalId);

    if (!mapGeometry || !signal) {
      return;
    }

    const { phases } = signal;

    const boundsPaddingPhaseEdges = .00042;
    const clipBoundsPhaseEdges = [
      bounds.sw[0] + boundsPaddingPhaseEdges,
      bounds.sw[1] + boundsPaddingPhaseEdges * .72,
      bounds.ne[0] - boundsPaddingPhaseEdges,
      bounds.ne[1] - boundsPaddingPhaseEdges * .72,
    ];

    const phaseEdgeFeatures = mapGeometry
      .map(row => ({
        'id': row.signal?.id + '-' + row.phase?.id,
        'type': 'Feature',
        'geometry': {
          'coordinates': row.pathCoordinates,
          'type': 'LineString'
        },
      }))
      .map(feature => {
        // can't use map here because bboxClip returns a different feature (without an id, for example)
        feature.geometry.coordinates = bboxClip(feature, clipBoundsPhaseEdges).geometry.coordinates;
        return feature;
      })
      .filter(feature => feature.geometry.coordinates.length > 1);

    const combinedPhases = [phaseEdgeFeatures[0]];

    for (let i = 1; i < phaseEdgeFeatures.length; i++) {
      const edge1 = phaseEdgeFeatures[i].geometry.coordinates;
      const edge1PhaseId = phaseEdgeFeatures[i].id;
      let found = false;

      for (let j = 0; j < combinedPhases.length; j++) {
        const edge2 = combinedPhases[j].geometry.coordinates;
        const edge2PhaseId = combinedPhases[j].id;

        if (edge1PhaseId !== edge2PhaseId) continue;

        if (
          (edge1[0][0] === edge2[edge2.length - 1][0])
          && (edge1[0][1] === edge2[edge2.length - 1][1])
        ) {
          combinedPhases[j].geometry.coordinates = [
            ...edge2,
            ...edge1.slice(1),
          ];
          found = true;
        } else if (
          (edge2[0][0] === edge1[edge1.length - 1][0])
          && (edge2[0][1] === edge1[edge1.length - 1][1])
        ) {
          combinedPhases[j].geometry.coordinates = [
            ...edge1,
            ...edge2.slice(1),
          ];
          found = true;
        }
        if (found) {
          break;
        }
      }
      if (!found) {
        combinedPhases.push(phaseEdgeFeatures[i]);
      }
    }

    const combinedPhasesSource = {
      'type': 'FeatureCollection',
      'features': combinedPhases
    };

    const completedPhaseIds = new Set();
    const uniqueTurnsByApproachString = {};
    const featuresByApproachString = {};
    const featurePhaseIdByApproachTurnString = {};
    const movementIdByApproachTurnString = {};

    // we combine the edges for each phase so the lines are continuous, then we make arrow polygons for each turn of each approach
    const arrowShapes = [];
    const arrowShapeIdReferences = []; // we keep track of the feature ids here, because we want string ids and mapbox wants numeric ones (which are the indexes of this array)
    combinedPhasesSource.features
      .filter(Boolean)
      .forEach(feature => {
        const featureParts = feature.id.split('-');
        const featureSignalId = featureParts?.[0];
        const featurePhaseId = featureParts?.[1];

        if (!featurePhaseId || completedPhaseIds.has(featurePhaseId)) {
          return;
        }

        completedPhaseIds.add(featurePhaseId);
        const phase = phases.find(phase => phase.id === featurePhaseId);

        if (!phase?.movements?.length) {
          return;
        }

        const coordinates = feature.geometry.coordinates;
        const approachString = JSON.stringify(coordinates);

        if (!uniqueTurnsByApproachString.hasOwnProperty(approachString)) {
          uniqueTurnsByApproachString[approachString] = new Set();
        }

        if (!featuresByApproachString.hasOwnProperty(approachString)) {
          featuresByApproachString[approachString] = feature;
        }

        phase.movements.forEach(({ turn, id/*, isOverlap, priority*/ }) => {
          if (/*!isOverlap && priority === 'protected' && */!uniqueTurnsByApproachString[approachString].has(turn)) {
            uniqueTurnsByApproachString[approachString].add(turn);
            const key = approachString + '-' + turn;
            if (!featurePhaseIdByApproachTurnString.hasOwnProperty(key)) {
              featurePhaseIdByApproachTurnString[key] = featurePhaseId + '-' + featureSignalId;
              // } else if (!featurePhaseIdsByApproachTurnString[key].includes(featurePhaseId)) {
              //   featurePhaseIdsByApproachTurnString[key].push(featurePhaseId);
            }
            if (!movementIdByApproachTurnString.hasOwnProperty(key)) {
              movementIdByApproachTurnString[key] = id;
            }
            //
            // if (!phasesByApproachString[approachString].includes(featurePhaseId)) {
            //   phasesByApproachString[approachString].push(featurePhaseId);
            // }
          }
        });
      });

    for (const approachString in uniqueTurnsByApproachString) {
      const numTurns = uniqueTurnsByApproachString[approachString].size;
      if (!numTurns) {
        console.log('Approach string', approachString, 'has no turns that are both protected and non-overlapping');
        return;
      }

      const feature = featuresByApproachString[approachString];
      const coordinates = feature.geometry.coordinates;
      ['left', 'through', 'right'].forEach((turn, turnIndex) => {
        if (uniqueTurnsByApproachString[approachString].has(turn)) {
          const featurePhaseIdSignalId = featurePhaseIdByApproachTurnString[approachString + '-' + turn];
          const baseOffset = (turnIndex - 1) * outerLineWidth;
          const lastSegment = [coordinates[coordinates.length - 2], coordinates[coordinates.length - 1]];
          const lastSegmentBearing = bearing(lastSegment[0], lastSegment[1]);

          let arrowShape;

          if (turn === 'through') {
            const leftSide = lineOffsetPerpendicular(feature, baseOffset - halfLineWidth + linePadding, turfOptions);
            const rightSide = lineOffsetPerpendicular(feature, baseOffset + halfLineWidth - linePadding, turfOptions);
            rightSide.geometry.coordinates.reverse();

            const centerOfEndOfSegment = baseOffset ? transformTranslate(point(lastSegment[1]), baseOffset, lastSegmentBearing + 90, turfOptions) : point(lastSegment[1]);

            const arrowTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing, turfOptions).geometry.coordinates;
            const arrowLeftTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing - 90, turfOptions).geometry.coordinates;
            const arrowRightTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing + 90, turfOptions).geometry.coordinates;

            arrowShape = {
              'id': featurePhaseIdSignalId + '-' + turn,
              'type': 'Feature',
              'geometry': {
                'coordinates': [[
                  ...leftSide.geometry.coordinates,
                  arrowLeftTip,
                  arrowTip,
                  arrowRightTip,
                  ...rightSide.geometry.coordinates,
                  leftSide.geometry.coordinates[0],
                ]],
                'type': 'Polygon',
              },
            }
          } else {
            const approachLength = length(feature, turfOptions);
            const chopLength = 20;
            const minKeepLength = 20 - outerLineWidth;
            // chop chopLength, but keep at least minKeepLength or approachLength, whichever is smaller
            const keepLength = Math.max(Math.min(approachLength, minKeepLength), approachLength - chopLength);
            const keepLine = lineSliceAlong(feature, 0, keepLength, turfOptions);

            const leftSide = lineOffsetPerpendicular(keepLine, baseOffset - halfLineWidth + linePadding, turfOptions);
            const rightSide = lineOffsetPerpendicular(keepLine, baseOffset + halfLineWidth - linePadding, turfOptions);
            rightSide.geometry.coordinates.reverse();

            const leftSideStartPoint = leftSide.geometry.coordinates[0];
            const leftSideEndPoint = leftSide.geometry.coordinates[leftSide.geometry.coordinates.length - 1];
            const rightSideStartPoint = rightSide.geometry.coordinates[0];
            // we don't need no stinkin' rightSideEndPoint

            if (turn === 'left') {
              const leftSideEndOfTurnedSegment = transformTranslate(point(leftSideEndPoint), turnedSegmentLength, lastSegmentBearing - 90, turfOptions).geometry.coordinates;
              const rightSideStartOfTurnedSegment = transformTranslate(point(leftSideEndPoint), innerLineWidth, lastSegmentBearing, turfOptions).geometry.coordinates;
              const rightSideEndOfTurnedSegment = transformTranslate(point(rightSideStartOfTurnedSegment), turnedSegmentLength, lastSegmentBearing - 90, turfOptions).geometry.coordinates;

              const centerOfEndOfSegment = center(points([leftSideEndOfTurnedSegment, rightSideEndOfTurnedSegment]));

              const arrowTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing - 90, turfOptions).geometry.coordinates;
              const arrowLeftTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing - 180, turfOptions).geometry.coordinates;
              const arrowRightTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing, turfOptions).geometry.coordinates;

              const curvedSegments = [];

              let degreesToTurn = bearing(point(leftSideEndPoint), point(rightSideStartPoint)) - bearing(point(leftSideEndPoint), point(rightSideStartOfTurnedSegment));
              if (degreesToTurn < -180) {
                degreesToTurn += 360;
              } else if (degreesToTurn > 180) {
                degreesToTurn -= 360;
              }
              for (let i = 1; i < 16; i++) {
                curvedSegments.push(
                  transformTranslate(point(leftSideEndPoint), innerLineWidth, lastSegmentBearing + (i * degreesToTurn / 16), turfOptions).geometry.coordinates
                );
              }

              arrowShape = {
                'id': featurePhaseIdSignalId + '-' + turn,
                'type': 'Feature',
                'geometry': {
                  'coordinates': [[
                    ...leftSide.geometry.coordinates,
                    leftSideEndOfTurnedSegment,

                    arrowLeftTip,
                    arrowTip,
                    arrowRightTip,

                    rightSideEndOfTurnedSegment,
                    rightSideStartOfTurnedSegment,

                    ...curvedSegments,

                    ...rightSide.geometry.coordinates,
                    leftSideStartPoint,
                  ]],
                  'type': 'Polygon',
                },
              }
            } else { // turn === 'right'
              const rightSideEndOfTurnedSegment = transformTranslate(point(rightSideStartPoint), turnedSegmentLength, lastSegmentBearing + 90, turfOptions).geometry.coordinates;

              const leftSideStartOfTurnedSegment = transformTranslate(point(rightSideStartPoint), innerLineWidth, lastSegmentBearing, turfOptions).geometry.coordinates;
              const leftSideEndOfTurnedSegment = transformTranslate(point(leftSideStartOfTurnedSegment), turnedSegmentLength, lastSegmentBearing + 90, turfOptions).geometry.coordinates;

              const centerOfEndOfSegment = center(points([rightSideEndOfTurnedSegment, leftSideEndOfTurnedSegment]));

              const arrowTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing + 90, turfOptions).geometry.coordinates;
              const arrowLeftTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing, turfOptions).geometry.coordinates;
              const arrowRightTip = transformTranslate(centerOfEndOfSegment, outerLineWidth, lastSegmentBearing + 180, turfOptions).geometry.coordinates;

              const curvedSegments = [];

              let degreesToTurn = bearing(point(rightSideStartPoint), point(leftSideStartOfTurnedSegment)) - bearing(point(rightSideStartPoint), point(leftSideEndPoint));
              if (degreesToTurn < -180) {
                degreesToTurn += 360;
              } else if (degreesToTurn > 180) {
                degreesToTurn -= 360;
              }
              for (let i = 16; i > 0; i--) {
                curvedSegments.push(
                  transformTranslate(point(rightSideStartPoint), innerLineWidth, lastSegmentBearing - (i * degreesToTurn / 16), turfOptions).geometry.coordinates
                );
              }

              arrowShape = {
                'id': featurePhaseIdSignalId + '-' + turn,
                'type': 'Feature',
                'geometry': {
                  'coordinates': [[
                    ...leftSide.geometry.coordinates,

                    ...curvedSegments,

                    leftSideStartOfTurnedSegment,
                    leftSideEndOfTurnedSegment,

                    arrowLeftTip,
                    arrowTip,
                    arrowRightTip,

                    rightSideEndOfTurnedSegment,


                    ...rightSide.geometry.coordinates,
                    leftSideStartPoint,
                  ]],
                  'type': 'Polygon',
                },
              }
            }
          }


          arrowShapeIdReferences.push(arrowShape.id);
          arrowShape.id = arrowShapes.length; // must be numeric otherwise mapbox sees it as an undefined id for some reason
          arrowShapes.push(arrowShape);
        }
      });
    }

    phaseMarkerPositionsRef.current = [];
    phaseMarkerAnchorsRef.current = [];
    phaseIdsRef.current = [];
    volumeMarkerPositionsRef.current = [];
    volumeMarkerAnchorsRef.current = [];
    movementIdsRef.current = [];
    for (const approachString in uniqueTurnsByApproachString) {
      const numTurns = uniqueTurnsByApproachString[approachString].size;
      if (!numTurns) {
        return;
      }

      const uniqueTurns = uniqueTurnsByApproachString[approachString];
      const feature = featuresByApproachString[approachString];
      const throughPosition = (uniqueTurns.has('left') && uniqueTurns.has('right'))
        ? 'center'
        : uniqueTurns.has('right')
          ? 'left'
          : 'right';
      const uniqueTurnsPerPhase = {};
      ['left', 'through', 'right'].forEach((turn) => {
        if (uniqueTurnsByApproachString[approachString].has(turn)) {
          const featurePhaseIdSignalId = featurePhaseIdByApproachTurnString[approachString + '-' + turn];
          const phaseId = featurePhaseIdSignalId.split('-')[0];
          uniqueTurnsPerPhase[phaseId] = getPreferredTurn(uniqueTurnsPerPhase[phaseId], turn);
        }
      });
      ['left', 'through', 'right'].forEach((turn) => {
        if (uniqueTurnsByApproachString[approachString].has(turn)) {
          const featurePhaseIdSignalId = featurePhaseIdByApproachTurnString[approachString + '-' + turn];
          const phaseId = featurePhaseIdSignalId.split('-')[0];
          if (uniqueTurnsPerPhase[phaseId] === turn) {
            phaseIdsRef.current.push(featurePhaseIdSignalId);
            feature.geometry.coordinates = bboxClip(feature, clipBoundsPhaseEdges).geometry.coordinates;
            const [position, anchor] = getPhaseLabelPositionAndAnchor(feature, turn === 'through' ? throughPosition : turn);
            phaseMarkerPositionsRef.current.push(position);
            phaseMarkerAnchorsRef.current.push(anchor)
          }
        }
      });
      // through first, since when there are many turns per movement, through placement is preferred
      ['through', 'left', 'right'].forEach((turn) => {
        if (uniqueTurnsByApproachString[approachString].has(turn)) {
          const movementId = movementIdByApproachTurnString[approachString + '-' + turn];
          if (!movementIdsRef.current.includes(movementId)) {
            movementIdsRef.current.push(movementId);
            feature.geometry.coordinates = bboxClip(feature, clipBoundsPhaseEdges).geometry.coordinates;
            const [position, anchor] = getVolumeLabelPositionAndAnchor(feature, turn);
            volumeMarkerPositionsRef.current.push(position);
            volumeMarkerAnchorsRef.current.push(anchor)
          }
        }
      });
    }

    const combinedSignal = [mapGeometry[0].pathCoordinates];
    for (let i = 1; i < mapGeometry.length; i++) {
      const edge1 = mapGeometry[i].pathCoordinates;
      let found = false;
      for (let j = 0; j < combinedSignal.length; j++) {
        const edge2 = combinedSignal[j];
        if (edge1[0][0] === edge2[edge2.length - 1][0] && edge1[0][1] === edge2[edge2.length - 1][1]) {
          combinedSignal[j] = [
            ...edge2,
            ...edge1.slice(1),
          ];
          found = true;
        } else if (edge2[0][0] === edge1[edge1.length - 1][0] && edge2[0][1] === edge1[edge1.length - 1][1]) {
          combinedSignal[j] = [
            ...edge1,
            ...edge2.slice(1),
          ];
          found = true;
        }
        if (found) {
          break;
        }
      }
      if (!found) {
        combinedSignal.push(edge1);
      }
    }

    return {
      arrowShapeIdReferences,
      arrowShapesSource: {
        'type': 'FeatureCollection',
        'features': arrowShapes,
      },
    };
  }, [bounds, isMapLoading, isSignalOrPhaseFocus, map, networkId, signalId, networkMapGeometryData.data?.user.network.mapGeometry, regionData.data?.user.region.networks]);

  useEffect(() => {
    if (isSignalOrPhaseFocus && map && signalPhaseMetricsData.data?.user) {
      markersRef.current = [];

      const signalMetrics = signalPhaseMetricsData.data.user.network.signals.find(metricsForSignal => metricsForSignal.id === signalId);
      // phaseIdsRef.current.push(featurePhaseIdSignalId);
      phaseMarkerPositionsRef.current.forEach((coordinates, index) => {
        let metricsValue;

        const featurePhaseIdSignalId = phaseIdsRef.current[index];
        const [phaseId, signalId] = featurePhaseIdSignalId.split('-');

        if (signalPhaseMetricsData.data?.user ) {
          const phaseMetrics = signalMetrics.phases?.find(metricsForPhase => metricsForPhase.id === phaseId);
          if (typeof phaseMetrics?.latestHealthScore === 'string' || typeof phaseMetrics?.latestHealthScore === 'number') {
            metricsValue = parseInt(phaseMetrics.latestHealthScore, 10);
          }
        }

        const network = regionData.data?.user.region.networks.find(network => network.id === networkId);
        const signal = network?.signals.find(signal => signal.id === signalId);
        const phase = signal?.phases?.find(phase => phase.id === phaseId);

        const markerElement = document.createElement('div');
        const root = createRoot(markerElement);
        root.render(
          <MapPhaseLabel phase={phase} metricsValue={metricsValue} goToMaintain={goToMaintain} />
        );

        markersRef.current.push(
          new mapboxgl.Marker(markerElement, { anchor: phaseMarkerAnchorsRef.current[index] })
            .setLngLat(coordinates)
            .addTo(map)
        );
      });

      if (signalMetrics) {
        const movementMetricsById = {};
        signalMetrics.phases.forEach(phase => {
          phase.movements.forEach(movement => {
            movementMetricsById[movement.id] = movement.latestAverageVolume;
          });
        });

        volumeMarkerPositionsRef.current.forEach((coordinates, index) => {
          const movementId = movementIdsRef.current[index];

          if (!movementMetricsById[movementId]) { // this also takes care of NaN
            return;
          }

          const markerElement = document.createElement('div');
          const root = createRoot(markerElement);
          root.render(
            <MapVolumeLabel volume={parseInt(movementMetricsById[movementId], 10)} />
          );

          markersRef.current.push(
            new mapboxgl.Marker(markerElement, { anchor: volumeMarkerAnchorsRef.current[index] })
              .setLngLat(coordinates)
              .addTo(map)
          );
        });
      }

      return () => {
        markersRef.current.forEach(marker => marker.remove());
      };
    }
  }, [isSignalOrPhaseFocus, map, arrowShapesMemo, regionData.data?.user, signalPhaseMetricsData.data?.user, goToMaintain, networkId, signalId, phaseId]);

  useEffect(() => {
    if (!isSignalOrPhaseFocus || isMapLoading || !map) {
      return;
    }

    if (!arrowShapesMemo?.arrowShapeIdReferences?.length) {
      return;
    }

    const color = getMetricsSteps(colors.red, 60, /*colors.pink, 80,*/ '#686c93');
    const outlineColor = getMetricsSteps(colors.red2, 60, /*'#2a2b3b', 80,*/ '#383a57');
    const outlineColorFocused = getMetricsSteps(colors.red3, 60, /*'#2a2b3b', 80,*/ '#242641');

    const onLoad = () => {
      map.addSource('arrowShapesSource', {
        'type': 'geojson',
        'data': arrowShapesMemo.arrowShapesSource,
      });

      map.addLayer({
        'id': 'arrowShapesLayer',
        'type': 'fill',
        'source': 'arrowShapesSource',
        'layout': {},
        'paint': {
          // 'fill-color': '#42475f',
          // 'fill-color': colors.red,
          'fill-color': color,
          // 'fill-opacity': 1,
          'fill-opacity': [
            'case',
            getBoolFeature('isFocused'),
            1,
            ['case', getBoolFeature('isHovered'), .75, .45],
          ],
        },
      });

      map.addLayer({
        'id': 'arrowShapesOutlineLayer',
        'type': 'line',
        'source': 'arrowShapesSource',
        'layout': {
          'line-join': 'round',
          'line-cap': 'square',
        },
        'paint': {
          // 'line-opacity': 1,
          // 'line-color': '#2a2b3b',

          'line-color': [
            'case',
            getBoolFeature('isFocused'),
            outlineColorFocused,
            outlineColor,
          ],
          'line-opacity': [
            'case',
            getBoolFeature('isFocused'),
            1,
            .5,
          ],
          // 'line-width': getZoomInterpolationValue(1),
          'line-width': getZoomInterpolationValue(.6),
        },
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    let hoveredId = null;

    const onMouseMove = event => {
      if (!event?.features?.length) return;

      map.getCanvas().style.cursor = 'pointer';

      // hoveredId = arrowShapeIdReferences[event.features[0].id];
      hoveredId = event.features[0].id;

      const [hoveredPhaseId, /*signalId, turn*/] = arrowShapesMemo.arrowShapeIdReferences[hoveredId].split('-');

      setHoveredPhaseId(parseInt(hoveredPhaseId, 10));
    };

    const onMouseLeave = () => {
      hoveredId = null;
      setHoveredPhaseId(0);

      map.getCanvas().style.cursor = '';
    };

    const onClick = () => {
      if (typeof hoveredId === 'number') {
        const [phaseId,/*signalId,*//*turn*/] = arrowShapesMemo.arrowShapeIdReferences[hoveredId].split('-');
        const featureState = map.getFeatureState({ source: 'arrowShapesSource', id: hoveredId });
        if (featureState?.isFocused) {
          goToMaintain({
            focus: 'signal',
            phaseId: null,
          }, true);
        } else {
          goToMaintain({
            focus: 'phase',
            phaseId,
          }, true);
        }
      }
    };

    map.on('mousemove', 'arrowShapesLayer', onMouseMove);
    map.on('mouseleave', 'arrowShapesLayer', onMouseLeave);
    map.on('click', 'arrowShapesLayer', onClick);


    return () => {
      // reset cursor and feature state
      onMouseLeave();

      if (map.getLayer('arrowShapesLayer')) {
        map.removeLayer('arrowShapesLayer');
      }

      if (map.getLayer('arrowShapesOutlineLayer')) {
        map.removeLayer('arrowShapesOutlineLayer');
      }


      if (map.getSource('arrowShapesSource')) {
        map.removeSource('arrowShapesSource');
      }

      map.off('mousemove', 'arrowShapesLayer', onMouseMove);
      map.off('mouseleave', 'arrowShapesLayer', onMouseLeave);
      map.off('click', 'arrowShapesLayer', onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowShapesMemo, bounds, isMapLoading, isSignalOrPhaseFocus, map]);

  useEffect(() => {
    if (hoveredPhaseId && arrowShapesMemo?.arrowShapeIdReferences && map && map.getSource('arrowShapesSource')) {
      for (let i in arrowShapesMemo.arrowShapeIdReferences) {
        const [phaseId, /*signalId, turn*/] = arrowShapesMemo.arrowShapeIdReferences[i].split('-');
        map.setFeatureState({ source: 'arrowShapesSource', id: i }, { isHovered: hoveredPhaseId === parseInt(phaseId, 10) });
      }

      return () => {
        if (map.getSource('arrowShapesSource')) {
          for (let i in arrowShapesMemo.arrowShapeIdReferences) {
            map.setFeatureState({ source: 'arrowShapesSource', id: i }, { isHovered: false });
          }
        }
      }
    }
  }, [arrowShapesMemo?.arrowShapeIdReferences, hoveredPhaseId, map, !map?.getSource('arrowShapesSource')]);

  useEffect(() => {
    if (isMapLoading || !map || !isSignalOrPhaseFocus || !arrowShapesMemo?.arrowShapeIdReferences || !signalPhaseMetricsData.data?.user || !networkMapGeometryData.data?.user || !map.getSource('arrowShapesSource')) {
      return;
    }

    for (let i in arrowShapesMemo.arrowShapeIdReferences) {
      const [phaseId, signalId, /*turn*/] = arrowShapesMemo.arrowShapeIdReferences[i].split('-');
      const signal = signalPhaseMetricsData.data.user.network.signals.find(metricsForSignal => metricsForSignal.id === signalId);
      const phase = signal?.phases?.find(metricsForPhase => metricsForPhase.id === phaseId);
      map.setFeatureState(
        { source: 'arrowShapesSource', id: i },
        { metricsValue:
            (typeof phase?.latestHealthScore === 'string' || typeof phase?.latestHealthScore === 'number')
              ? parseInt(phase.latestHealthScore, 10)
              : -1
        });
    }

    // return () => {
    //
    // };
  }, [isMapLoading, map, isSignalOrPhaseFocus, arrowShapesMemo?.arrowShapeIdReferences, networkMapGeometryData.data?.user, signalPhaseMetricsData.data?.user, !map?.getSource('arrowShapesSource')]);

  useEffect(() => {
    if (!map || isMapLoading || !arrowShapesMemo?.arrowShapeIdReferences?.length || !map.getSource('arrowShapesSource')) {
      return;
    }

    for (let i in arrowShapesMemo.arrowShapeIdReferences) {
      const [arrowPhaseId, /*signalId*/, /*turn*/] = arrowShapesMemo.arrowShapeIdReferences[i].split('-');
      map.setFeatureState({ source: 'arrowShapesSource', id: i }, { isFocused: phaseId === arrowPhaseId });
    }

  }, [phaseId, arrowShapesMemo?.arrowShapeIdReferences, map, isMapLoading, !map?.getSource('arrowShapesSource')]);

  useEffect(() => {
    if (!isSignalOrPhaseFocus || isMapLoading || !map || !networkMapGeometryData.data) {
      return;
    }

    const signals = networkMapGeometryData.data?.user.network.signals;

    if (!signals?.length) {
      return;
    }

    const signalCoordinatesSource = {
      'type': 'FeatureCollection',
      'features': signals.map(signal => ({
        'id': signal.id,
        'type': 'Feature',
        'geometry': {
          'coordinates': signal.coordinates,
          'type': 'Point'
        },
      })),
    }

    const markers = [];
    const color = getMetricsSteps(colors.red, 60, /*colors.pink, 80,*/ colors.gray2);

    const onLoad = () => {
      map.addSource('signalCoordinatesSource', {
        'type': 'geojson',
        'data': signalCoordinatesSource,
      });

      map.addLayer({
        'id': 'signalCoordinatesFillLayer',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-color': color,
          'circle-radius': 12.5,
          // 'circle-blur': 2,
          'circle-opacity': .35,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      map.addLayer({
        'id': 'signalCoordinatesLayer',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-stroke-color': color,
          'circle-opacity': 0,
          'circle-stroke-width': 2,
          'circle-stroke-opacity': .6,
          // 'circle-color': colors.red,
          'circle-radius': 12.5,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      map.addLayer({
        'id': 'signalCoordinatesLayerInner',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-stroke-color': color,
          'circle-opacity': 0,
          'circle-stroke-width': 1,
          'circle-stroke-opacity': .6,
          // 'circle-color': colors.red,
          'circle-radius': 10,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      signals.forEach((signal, index) => {
        const coordinates = signal.coordinates;
        const markerElement = document.createElement('div');
        markerElement.className = 'pointer-events-none';
        const root = createRoot(markerElement);
        root.render(<MapSignalIcon withDefs={!index} size="6" opacity="70" />);
        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .setOffset([-.5, 5])
          .addTo(map);

        markers.push(marker);
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    return () => {
      if (map.getLayer('signalCoordinatesLayer')) {
        map.removeLayer('signalCoordinatesLayer');
      }
      if (map.getLayer('signalCoordinatesLayerInner')) {
        map.removeLayer('signalCoordinatesLayerInner');
      }
      if (map.getLayer('signalCoordinatesFillLayer')) {
        map.removeLayer('signalCoordinatesFillLayer');
      }

      if (map.getSource('signalCoordinatesSource')) {
        map.removeSource('signalCoordinatesSource');
      }

      markers.forEach(marker => marker.remove());
    };
  }, [isSignalOrPhaseFocus, isMapLoading, map, networkMapGeometryData.data]);

  useEffect(() => {
    if (isMapLoading || !map || !isSignalOrPhaseFocus || !signalPhaseMetricsData.data?.user || !networkMapGeometryData.data?.user) {
      return;
    }

    const signals = networkMapGeometryData.data.user.network.signals;

    const onLoad = () => {
      signals.forEach(({ id }) => {
        const metricsValue = signalPhaseMetricsData.data?.user.network.signals?.find(metricsForSignal => metricsForSignal.id === id)?.latestHealthScore || 0;
        map.setFeatureState({ source: 'signalCoordinatesSource', id }, { metricsValue });
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    // return () => {
    //
    // };
  }, [isMapLoading, map, isSignalOrPhaseFocus, networkMapGeometryData.data?.user, signalPhaseMetricsData.data?.user]);

  useEffect(() => {
    if (!isSignalOrPhaseFocus || isMapLoading || !map) {
      return;
    }

    let hoveredId = null;

    const onMouseMove = event => {
      if (!event?.features?.length) return;

      map.getCanvas().style.cursor = 'pointer';

      if (hoveredId) {
        map.removeFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, 'isHovered');
      }

      hoveredId = event.features[0].id;

      map.setFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, { isHovered: true });
    };

    const onMouseLeave = () => {
      if (hoveredId && map.getSource('signalCoordinatesSource')) {
        map.removeFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, 'isHovered');
      }

      hoveredId = null;

      map.getCanvas().style.cursor = '';
    };

    const onClick = () => {
      if (hoveredId) {
        goToMaintain({
          focus: 'signal',
          phaseId: null,
        });
      }
    };

    map.on('mousemove', 'signalCoordinatesFillLayer', onMouseMove);
    map.on('mouseleave', 'signalCoordinatesFillLayer', onMouseLeave);
    map.on('click', 'signalCoordinatesFillLayer', onClick);

    return () => {
      // reset cursor and feature state
      onMouseLeave();

      map.off('mousemove', 'signalCoordinatesFillLayer', onMouseMove);
      map.off('mouseleave', 'signalCoordinatesFillLayer', onMouseLeave);
      map.off('click', 'signalCoordinatesFillLayer', onClick);
    };
  }, [isSignalOrPhaseFocus, goToMaintain, isMapLoading, map]);
};


export default useMapboxSignalsAndPhases;
