import React from 'react';
import IconWrapper from './IconWrapper';

const GearThick = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="0 0 16 16">
    <path d="M6.01812 2.16621C6.01812 1.79827 6.32303 1.5 6.69914 1.5H8.74221C9.11832 1.5 9.42323 1.79827 9.42323 2.16621V2.48387C9.42323 2.78785 9.63533 3.04914 9.92728 3.15325C10.3873 3.31732 10.8184 3.54031 11.211 3.81289C11.4459 3.97597 11.7566 4.00726 12.006 3.86638L12.4568 3.61179C12.7826 3.42782 13.1991 3.537 13.3871 3.85564L14.4086 5.58649C14.5967 5.90513 14.4851 6.31258 14.1594 6.49655L13.8041 6.69718C13.5443 6.84396 13.4176 7.13944 13.4584 7.43029C13.492 7.66952 13.5094 7.91381 13.5094 8.16206C13.5094 8.41034 13.492 8.65465 13.4584 8.8939C13.4176 9.18475 13.5442 9.48023 13.8041 9.62702L14.1592 9.82758C14.485 10.0115 14.5966 10.419 14.4085 10.7376L13.387 12.4685C13.1989 12.7871 12.7824 12.8963 12.4567 12.7123L12.006 12.4578C11.7566 12.3169 11.4458 12.3482 11.211 12.5113C10.8184 12.7838 10.3873 13.0068 9.92728 13.1709C9.63533 13.275 9.42323 13.5363 9.42323 13.8403V14.1579C9.42323 14.5259 9.11832 14.8241 8.74221 14.8241H6.69914C6.32303 14.8241 6.01812 14.5259 6.01812 14.1579V13.5533C6.01812 13.2819 5.84788 13.0411 5.60043 12.9184C5.28687 12.7628 4.99077 12.5785 4.71576 12.3689C4.47882 12.1883 4.15361 12.1477 3.8935 12.2946L3.54331 12.4924C3.21758 12.6764 2.80108 12.5672 2.61302 12.2486L1.59149 10.5177C1.40343 10.1991 1.51503 9.79161 1.84076 9.60765L2.28578 9.3563C2.53488 9.21561 2.66258 8.93726 2.6362 8.65707C2.62086 8.49408 2.61302 8.32897 2.61302 8.16206C2.61302 7.99518 2.62086 7.83009 2.6362 7.66712C2.66257 7.38694 2.53487 7.10858 2.28578 6.9679L1.84062 6.71648C1.5149 6.53251 1.40329 6.12507 1.59135 5.80643L2.61288 4.07557C2.80094 3.75693 3.21745 3.64775 3.54318 3.83172L3.89344 4.02955C4.15356 4.17646 4.47877 4.13584 4.71571 3.95526C4.99074 3.74566 5.28685 3.5613 5.60043 3.40574C5.84788 3.28298 6.01812 3.04219 6.01812 2.77078V2.16621ZM8.06119 10.8269C9.56565 10.8269 10.7853 9.63381 10.7853 8.16206C10.7853 6.69032 9.56565 5.49724 8.06119 5.49724C6.55672 5.49724 5.3371 6.69032 5.3371 8.16206C5.3371 9.63381 6.55672 10.8269 8.06119 10.8269Z" />
  </IconWrapper>
);

export default GearThick;
