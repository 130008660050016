import { flow, types } from 'mobx-state-tree';
import graphqlQuery from '../../helpers/graphqlQuery';
import delay from '../../helpers/delay';
import { stringify } from '../../helpers/stableSerialize';

const queryVariablesSeparator = '---';

const GraphqlFetcher = types
  .model({
    cache: types.frozen({}),
    data: types.frozen(null),
    errors: types.frozen([]),
    currentKey: '',
    loadingKey: '',
    query: types.frozen(null),
    hasLoaded: false,
  })
  .views(self => ({
    getCacheKeyForVariables: variables => {
      if (!self.query) {
        throw new Error('Cannot generate cache key without a graphql query');
      }
      return self.query + queryVariablesSeparator + stringify(variables)
    },
  }))
  .views(self => ({
    getCacheValue: (variables = {}) => {
      if (!variables) {
        throw new Error('Must specify variables to get cache value for');
      }

      const cacheKey = self.getCacheKeyForVariables(variables);

      if (self.cache.hasOwnProperty(cacheKey)) {
        return self.cache[cacheKey];
      }
    },
    get isLoading() {
      return Boolean(self.loadingKey);
    },
    areCurrentVariables: variables => self.getCacheKeyForVariables(variables) === self.currentKey,
  }))
  .actions(self => ({
    clearCache: variables => {
      if (!variables) {
        self.cache = {};
        return;
      }
      const cacheKey = self.getCacheKeyForVariables(variables);

      if (!self.cache.hasOwnProperty(cacheKey)) {
        // not in cache, nothing to do
        return;
      }

      // create a new cache without the key, and return it
      const newObj = {};

      Object.keys(self.cache).forEach(key => {
        if (key !== cacheKey && self.cache.hasOwnProperty(key)) {
          newObj[key] = self.cache[key];
        }
      });

      self.cache = newObj;
    },
    primeCache: (variables = {}, newCacheData, isFinal = true) => {
      if (!variables) {
        throw new Error('Must specify variables when priming cache');
      }

      const cacheKey = self.getCacheKeyForVariables(variables);

      self.cache = {
        ...self.cache,
        [cacheKey]: {
          data: newCacheData,
          errors: [],
          isOptimistic: !isFinal,
        },
      };
    },
  }))
  .actions(self => ({
    setQuery: query => self.query = query,
    clearVariables: () => {
        self.data = null;
        self.errors = [];
        self.loadingKey = '';
        self.currentKey = '';
        self.hasLoaded = false;
    },
    fetch: flow(function * fetch(variables = {}, skipCache = false) {
      const cacheKey = self.getCacheKeyForVariables(variables);

      if (self.currentKey === cacheKey) {
        // already loaded or loading
        return;
      }

      self.currentKey = cacheKey;

      if (!skipCache && self.cache.hasOwnProperty(cacheKey)) {
        // get data from cache
        self.data = self.cache[cacheKey].data;
        self.errors = self.cache[cacheKey].errors;

        // get data from cache, but also load it from network and overwrite below
        if (self.cache[cacheKey].isOptimistic) {
          self.loadingKey = cacheKey;
          self.hasLoaded = false;

          // get data from cache and stop
        } else {
          self.loadingKey = '';
          self.hasLoaded = true;
          return;
        }
      } else {
        // load data from network below
        self.data = null;
        self.errors = [];
        self.loadingKey = cacheKey;
        self.hasLoaded = false;
      }

      // some test lag
      // yield delay(1000);

      const result = yield graphqlQuery(self.query, variables);

      const { data, errors } = result;

      if (errors?.length && errors[0] === 'Sign-in required') {
        // allow for redirect to login to happen -- don't need 200ms but just in case...
        yield delay(200);
        self.loadingKey = '';
        self.currentKey = '';
        return;
      }

      self.cache = {
        ...self.cache,
        [cacheKey]: { data, errors },
      };
      self.data = data;
      self.errors = errors;
      self.loadingKey = '';
      self.hasLoaded = true;
    }),
  }));

export default GraphqlFetcher;
