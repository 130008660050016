import { parse } from 'path-to-regexp';
import { generatePath } from 'react-router-dom';

const paths = {
  map: '/map/:regionId([0-9]+)/:focus(system|network|signal|phase)?',
  forgotPassword: '/forgot-password/:emailAddress?/:secretKey?',
  dashboard: '/',
  login: '/login',
};

export const pathVariables = {};

for (const pathName in paths) {
  const optional = new Set();
  const required = new Set();

  const path = paths[pathName];
  const parts = parse(path);

  parts.forEach(part => {
    if (typeof part !== 'object' || !part.name) {
      return;
    }

    if (part.modifier === '?') {
      optional.add(part.name);
    } else {
      required.add(part.name);
    }
  });

  pathVariables[pathName] = {
    optional,
    required,
  };
}

const encodeParams = (queryParam, variables) => {
  const values = Array.isArray(variables[queryParam]) ? variables[queryParam] : [ variables[queryParam] ];
  return values.map(value => encodeURIComponent(queryParam) + '=' + encodeURIComponent(value)).join('&');
};

export const getPathWithVariables = (pathName, variables = {}) => {
  const path = paths[pathName];

  if (!path) {
    throw new Error(`Invalid pathName specified for ${pathName} with ${JSON.stringify(variables)}`);
  }

  const optional = pathVariables[pathName].optional;

  // clone original set, so we can modify the clone to ensure all required vars are specified
  const required = new Set(pathVariables[pathName].required);

  const queryParams = new Set();

  for (const variableName in variables) {
    if (required.has(variableName)) {
      required.delete(variableName);
    } else if (!optional.has(variableName) && variables[variableName] !== null && variables[variableName] !== undefined) {
      queryParams.add(variableName);
    }
  }

  // missing required variables
  if (required.size) {
    throw new Error(`Path "${ pathName }" ("${ path }") requires the following variables that were not specified: "${ Array.from(required).join('", "') }"`)
  }

  let resultPath = generatePath(path, variables);

  if (queryParams.size) {
    resultPath += '?'
      + Array.from(queryParams)
        .map(queryParam => encodeParams(queryParam, variables))
        .join('&');
  }

  return resultPath;
}

export default paths;
