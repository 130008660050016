import useAllParams from './useAllParams';
import { stringify } from '../helpers/stableSerialize';
import usePrevious from './usePrevious';
import useAppController from './useAppController';
import { useEffect } from 'react';

// this hook keeps the appController's params in sync with the url/query params. it's intended to be
// used only by the WithParams component in App.js

// there are a couple of reasons why we use this pattern, rather than relying on useParams directly
// 1. it makes the order in which subcomponents re-render on param updates deterministic and more stable,
//    when multiple redirects happen in the component tree
// 2. it triggers less re-renders on components that need params, relative to ones that use useParams,
//    because of the deterministic check below to only update the object when something has actually changed
// 3. this way there's now always a single place to update params in the appController, for all stores that
//    need/use it
const useUpdateAppControllerParams = () => {
  const { setParams } = useAppController();
  const params = useAllParams();
  const paramsStringified = stringify(params);
  const previousParamsStringified = usePrevious(paramsStringified);
  const haveParamsChanged = paramsStringified !== previousParamsStringified;

  // on initial render, set the params before rendering the children
  if (typeof previousParamsStringified === 'undefined') {
    setParams(params);
  }

  useEffect(() => {
    if (haveParamsChanged) {
      setParams(params);
    }
  }, [setParams, params, haveParamsChanged]);

  return params;
};

export default useUpdateAppControllerParams;
