import React from 'react';
import IconWrapper from './IconWrapper';

const Dot = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="8" r="4" />
  </IconWrapper>
);

export default Dot;
