import { observer } from 'mobx-react-lite';

const MapVolumeLabel = ({ volume }) => {
  return (
    <div className="fs-5 semibold gray2 o-80">
      { volume }
    </div>
  );
};

export default observer(MapVolumeLabel);
