import { types } from 'mobx-state-tree';
import createGraphqlFetcherType from '../models/createGraphqlFetcherType';
import gql from 'graphql-tag';

// TODO: Move to mapController?
const networkStructureQuery = gql`
  query ($networkId: String!) {
    user {
      network(id: $networkId) {
        id
        name
#       bounds {
#         sw
#         ne
#       }
#       routes {
#         id
#         direction
#         edges {
#           id
#           path
#         }
#         signalIds
#       }
#       mainRouteEdges {
#         id
#         path
#       }
        tods {
          id
          #          startTime { hours }
          #          endTime { hours }
          humanName
        }
#       mainRouteAdjacentEdges {
#         id
#         path
#       }
#       edges (withinDistance: 150) {
#         id
#         path
#       }
#       signals {
#         id
#         code
#         name
#         description
#         coordinates
#         bounds {
#           ne
#           sw
#         }
#       }
      }
    }
  }
`;

const networkTodMetricsQuery = gql`
  query ($networkId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      network(id: $networkId) {
        id

        routes {
          id
          direction
          signalIds
#         edges {
#           id
#           latestMobilityScore
#         }

          latestMetrics {
            direction
            
            travelTimeMedian
            travelTime15th
            travelTime85th
            controlDelayMean
            stopCountMean

            # travelTimeMean
            # journeyCount
            # completeIntersections
            # completeIntersections
          }

          latestRouteSignalMetrics {
            signal {
              id
              code
            }
            stopCountMean
            controlDelayMean
          }
        }

        signals {
          id
          code
#         name
#          description
#          coordinates
#          bounds {
#            ne
#            sw
#          }
          # TODO: Separate detectors query?
          phases {
            detectors {
              latestDetectorHealthScore
              code
              detectionTypes
              movementType
              approach {
                directionType
#               phaseNumber
              }
            }
          }
          latestMetrics {
        
            # Operations
            arrivalsOnGreenRatio
#           avgForceOffsPerCycle           # Remove?
#           avgGapOutsPerCycle             # Remove?
            avgGreenTimePerCycle
            avgSplitFailuresPerCycle
            controlDelay85th
            controlDelayMedian
            flowLabsVolumeCountPerHour
#           phaseSkipsPerCycle            # Remove?
            platoonRatio
            programmedCycleTimeMode
            queueLength85thVehicleNumber
            queueLengthMedianVehicleNumber
            queueLength15thVehicleNumber
    
            # Safety
            approachVelocityMph85th
            approachVelocityMphMedian
            approachVelocityMph15th
            deceleration10FpssRatio
            dilemmaZoneRatio
            speedLimitMphMode
            throughLeftRedLightRunRatio
    
            # Detection
#           detectorHealthScore            # Remove?
    
            # Environmental
            controlCo2EquivalentGramsVolumeWeightedTotal
            controlFuelGallonsVolumeWeightedTotal
            controlNo2GramsVolumeWeightedTotal
          }
        }

        tods {
          id
          latestMetrics {

            # Operations
            flowLabsVolumeCountPerHour
            controlDelayMedian
            arrivalsOnGreenRatio
            avgGreenTimePerCycle
            avgSplitFailuresPerCycle
            platoonRatio
            programmedCycleTimeAvg
            queueLengthMedianVehicleNumber

            # Safety
            approachVelocityMphMedian
            deceleration10FpssRatio
            dilemmaZoneRatio
            throughLeftRedLightRunRatio

            # Detection
            detectorHealthScore

            # Environmental
#           fuelGallonsVolumeWeightedTotal
            fuelGallonsVolumeWeightedTotalRa
#           co2EquivalentGramsVolumeWeightedTotal
            co2EquivalentGramsVolumeWeightedTotalRa
#           no2GramsVolumeWeightedTotal
            no2GramsVolumeWeightedTotalRa
          }
        }
      }
    }
  }
`;

const signalMetricsQuery = gql`
  query ($signalId: String!, $todId: String!, $aggregationPeriodDays: Int!) {
    user(todId: $todId, aggregationPeriodDays: $aggregationPeriodDays) {
      signal(id: $signalId) {
        id
        name
        code

        latestMetrics {

          # Operations
          arrivalsOnGreenRatio
          avgForceOffsPerCycle
          avgGapOutsPerCycle
          avgGreenTimePerCycle
          avgSplitFailuresPerCycle
          controlDelayMedian
          flowLabsVolumeCountPerHour
          phaseSkipsPerCycle
          platoonRatio
          programmedCycleTimeMode
          queueLengthMedianVehicleNumber
          
          # Safety
          approachVelocityMphMedian
          deceleration10FpssRatio
          dilemmaZoneRatio
          throughLeftRedLightRunRatio

          # Detection
          detectorHealthScore

          # Environmental
#         controlCo2EquivalentGramsVolumeWeightedTotal
          controlCo2EquivalentGramsVolumeWeightedTotalRa
#         controlFuelGallonsVolumeWeightedTotal
          controlFuelGallonsVolumeWeightedTotalRa
#         controlNo2GramsVolumeWeightedTotal
          controlNo2GramsVolumeWeightedTotalRa
        }

        phases(eagerlyLoadMovements: true) {
          id
          number

          latestMetrics {

            # Operations
            arrivalsOnGreenRatio
            avgForceOffsPerCycle
            avgGapOutsPerCycle
            avgGreenTimePerCycle
            avgSplitFailuresPerCycle
            controlDelayMedian
            flowLabsVolumeCountPerHour
            phaseSkipsPerCycle
            platoonRatio
            programmedSplitTimeMode
            queueLengthMedianVehicleNumber

            # Safety
            approachVelocityMphMedian
            deceleration10FpssRatio
            dilemmaZoneRatio
            throughLeftRedLightRunRatio

            # Detection
            detectorHealthScore

            # Environmental
#           controlCo2EquivalentGramsVolumeWeightedTotal
            controlCo2EquivalentGramsVolumeWeightedTotalRa
#           controlFuelGallonsVolumeWeightedTotal
            controlFuelGallonsVolumeWeightedTotalRa
#           controlNo2GramsVolumeWeightedTotal
            controlNo2GramsVolumeWeightedTotalRa
          }

          # TODO: Separate detectors query?
          detectors {
            latestDetectorHealthScore
            code
            detectionTypes
            movementType
            approach {
              directionType
#             phaseNumber
            }
          }

#          movements {
#            id
#            turn
#            isOverlap
#            priority
#            maxSignalNumLanes
#            pathSegments {
#              edge {
#                id
#                path
#              }
#              laneIndex
#            }
#          }
        }
      }
    }
  }
`;


const AbacusController = types
  .model({
    networkStructureData: createGraphqlFetcherType(networkStructureQuery),
    networkTodMetricsData: createGraphqlFetcherType(networkTodMetricsQuery),
    signalMetricsData: createGraphqlFetcherType(signalMetricsQuery),
  })
  // TODO: Point MetricsMap to latestMetrics?
  .views(self => ({
    get isNetworkTodMetricsDataReady() {
      // const { aggregationPeriodDays, networkId } = self.params;
      return /*self.networkMetricsData.areCurrentVariables({ networkId, aggregationPeriodDays }) &&*/ self.networkTodMetricsData.hasLoaded && self.networkTodMetricsData?.data?.user?.network;
    },
    get isSignalMetricsDataReady() {
      // const { aggregationPeriodDays, signalId } = self.params;
      return /*self.signalMetricsData.areCurrentVariables({ signalId, aggregationPeriodDays }) &&*/ self.signalMetricsData.hasLoaded && self.signalMetricsData?.data?.user?.signal;
    },
  }))
  .views(self => ({
    get networkTodMetricsMap() {
      const networkTodMetricsMap = {};

      if (self.isNetworkTodMetricsDataReady) {
        const tods = self.networkTodMetricsData.data.user.network.tods || [];
        tods.forEach(tod => {
          networkTodMetricsMap[tod.id] = tod;
        });
      }

      return networkTodMetricsMap;
    },
    get signalMetricsMap() {
      const signalMetricsMap = {};

      if (self.isSignalMetricsDataReady) {

        const signal = self.signalMetricsData.data.user.signal;
        signalMetricsMap[signal.id] = signal;

      }

      return signalMetricsMap;
    },
    get phaseMetricsMap() {
      const phaseMetricsMap = {};

      if (self.isSignalMetricsDataReady) {

        const signal = self.signalMetricsData.data.user.signal;
        signal.phases.forEach(phase => {
          phaseMetricsMap[phase.id] = phase;
        });

      }

      return phaseMetricsMap;
    },
  }))
  .views(self => ({
    getNetworkTodMetrics: todId =>
      self.networkTodMetricsMap.hasOwnProperty(todId)
        ? self.networkTodMetricsMap[todId]
        : null,
    getSignalMetrics: signalId =>
      self.signalMetricsMap.hasOwnProperty(signalId)
        ? self.signalMetricsMap[signalId]
        : null,
    getPhaseMetrics: phaseId =>
      self.phaseMetricsMap.hasOwnProperty(phaseId)
        ? self.phaseMetricsMap[phaseId]
        : null,
  }));
  // .actions(self => ({
  //   setParams: params => self.params = params,
  // }));

export default AbacusController;
