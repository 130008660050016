import React from 'react';
import IconWrapper from './IconWrapper';

const Minus = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="1 1 14 14">
    <path d="M12 8H4" strokeWidth="1.5" strokeLinecap="round" />
  </IconWrapper>
);

export default Minus;
