import React from 'react';
import IconWrapper from '../IconWrapper';
import { colors } from '../../../FlowMaterialTheme';

const LogoRings = (props) => (
  <IconWrapper {...props} viewBox="0 0 640 850">
    <circle cx="320" cy="320" r="290" stroke={ colors.red } strokeWidth="50" />
    <circle cx="320" cy="424" r="290" stroke={ colors.yellow } strokeWidth="50" />
    <circle cx="320" cy="530" r="290" stroke={ colors.green } strokeWidth="50" />
  </IconWrapper>
);

export default LogoRings;
