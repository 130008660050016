import React from 'react';
import IconWrapper from './IconWrapper';

const TripleDot = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="0 0 16 16">
    <circle cx="8" cy="3" r="1.5" />
    <circle cx="8" cy="8" r="1.5" />
    <circle cx="8" cy="13" r="1.5" />
  </IconWrapper>
);

export default TripleDot;
