import React, { Fragment, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Select from '../shared/elements/material/Select';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import useAppController from '../../hooks/useAppController';


const TodSelector = () => {
  const { params, mapController } = useAppController();
  const { regionData, } = mapController;
  const { networkId, todId } = params || {};
  const goToMaintain = useGoToMaintain('map');

  const network = params.focus !== 'system'
    ? regionData.data?.user.region.networks.find(network => network.id === networkId)
    : null;
  const tods = network?.tods;

  const renderBeforeMenuItems = useCallback(() => (
    <div className="pt-3 pb-5 ph-7 fs-5 gray1 normal flex items-center">
      <div className="bo-radius-circle bo-gray1 boa-1 fs-9 w-3 h-3 flex items-center justify-around ph-1 flex-shrink-0 mr-6">?</div>
      <div className="flex-grow-1">Affects the metrics displayed on this page</div>
    </div>
  ), []);

  if (!(tods?.length) || !todId || !tods.find(tod => tod.id === todId)) {
    return null;
  }

  return (
    <div className="flex-shrink-0 flex items-center ph-5 fs-4">
      <div className="ml-auto" style={{ width: '22rem' }}>
        <Select
          width="100%"
          iconPosition="right"
          variant="outlined"
          renderBeforeMenuItems={ renderBeforeMenuItems }
          renderValue={
            selectedTodId => {
              const tod = tods.find(tod => tod.id === selectedTodId);
              if (!tod) {
                return null;
              }
              const { humanName } = tod;
              // const score = getNetworkTodScore(selectedTodId);
              // let scoreElem;
              // if (metricsView === 'safety') {
              //   scoreElem = score ? <div className={ `pa-2 ${ getScoreColor(score, 'networkSafetyText') } mr-7` }>{ score }</div> : <span className="lightgray4 nowrap">--</span>
              // } else {
              //   scoreElem = <ScorePill score={ score } size="small" className="inline-block mr-7" />;
              // }
              return (
                <div className="white flex items-center">
                  <span className="ml-3 mr-7 fw-3 white">Time Of Day</span>
                  <span className="flex-grow-1 gray1">{ humanName }</span>
                  {/* { scoreElem } */}
                </div>
              );
            }
          }
          value={ todId }
          onChange={
            e => {
              goToMaintain({ 
                todId: e.target.value,
              })
            }
          }
          options={
            tods.map(tod => ({
              value: tod.id,
              label: () => {
                const { id, humanName/*, startTime, endTime*/ } = tod;
                const match = humanName ? / [0-9]+/.exec(humanName) : null;
                const dayPart = match ? humanName.substring(0, match.index) : '';
                const timePart = match ? humanName.substring(match.index + 1) : '';
                const [startTimePart, endTimePart] = match ? timePart.split(' - ') : ['', ''];
                // const startTimeIsMorning = startTime.hours >= 5 && startTime.hours < 17;
                // const endTimeIsMorning = endTime.hours >= 5 && endTime.hours < 17;
                // const score = getNetworkTodScore(id);
                // let scoreElem;
                // if (metricsView === 'safety') {
                //   scoreElem = score ? <div className={ `pa-2 ${ getScoreColor(score, 'networkSafetyText') }` }>{ score }</div> : <span className="lightgray4 nowrap">--</span>
                // } else {
                //   scoreElem = <ScorePill score={ score } className="inline-block ml-5" />;
                // }

                return (
                  <div className="gray fs-5 w-100% flex items-center">
                    <div className="flex-shrink-0 w-7 white semibold">{ dayPart }</div>
                    <div className="flex-grow-1 ml-8 flex items-center justify-around">
                      <div className="w-7 tr flex-shrink-0">
                        <div className="inline-flex items-center ml-auto">
                          {/*<div className={ `pa-2 mr-2 bo-radius-3 flex-shrink-0 ${ startTimeIsMorning ? 'bg-drabyellow' : 'bg-darkblue1' }` } />*/}
                          <div>{ startTimePart }</div>
                        </div>
                      </div>
                      {/*<div className={ `ml-3 pa-1 fs-8 bo-radius-3 flex-shrink-0 ${ startTimeIsMorning ? 'bg-drabyellow' : 'bg-darkblue1' }` }>{ startTimeIsMorning ? '🌞' : '🌙' }</div>*/}
                      <div className="ph-5 flex-shrink-0">-</div>
                      {/*<div className={ `mr-3 pa-1 fs-8 bo-radius-3 flex-shrink-0 ${ endTimeIsMorning ? 'bg-drabyellow' : 'bg-darkblue1' }` }>{ endTimeIsMorning ? '🌞' : '🌙' }</div>*/}
                      <div className="w-7 tl flex-grow-1">
                        <div className="inline-flex items-center ml-auto">
                          <div>{ endTimePart }</div>
                          {/*<div className={ `pa-2 ml-2 bo-radius-3 flex-shrink-0 ${ endTimeIsMorning ? 'bg-drabyellow' : 'bg-darkblue1' }` } />*/}
                        </div>
                      </div>
                    </div>
                    {/* { scoreElem } */}
                  </div>
                );
              },
            }))
          }
        />
      </div>
      {/* <div className={ (tods?.length && todId) ? 'ml-4' : 'ml-auto' }>
        {
          aggregationPeriod && (
            <Select
              width="11"
              iconPosition="right"
              variant="outlined"
              renderValue={
                selectedAggregationPeriod => (
                  <div className="darkgray2 h-4 fs-6 flex items-center">
                    <span className="ml-3 mr-7 bold black">Data</span>
                    <span>{ aggregationPeriodPresets[selectedAggregationPeriod].name }</span>
                  </div>
                )
              }
              renderBeforeMenuItems={ renderBeforeMenuItems }
              value={ aggregationPeriod }
              onChange={
                e => {
                  goToMaintain({ aggregationPeriod: e.target.value }, true)
                }
              }
              options={ aggregationPeriodPresetKeys.map(key => ({ value: key, label: aggregationPeriodPresets[key].label })) }
            />
          )
        }
      </div> */}
    </div>
  );
};

export default observer(TodSelector);
