import { useHistory } from 'react-router-dom';
import { getPathWithVariables } from '../paths';
import { useCallback, useEffect, useRef } from 'react';

const useGoTo = (pathName) => {
  const history = useHistory();
  const historyRef = useRef({ replace: history.replace, push: history.push });
  useEffect(() => {
    historyRef.current.replace = history.replace;
    historyRef.current.push = history.push;
  }, [history]);
  return useCallback((variables = {}, replace = false) => {
    const fn = replace ? historyRef.current.replace : historyRef.current.push;
    fn(getPathWithVariables(pathName, variables))
  }, [pathName]);
};

export default useGoTo;
