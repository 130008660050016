import getScoreValue from '../../helpers/getScoreValue';
import Dot from '../shared/icons/Dot';
import useAppController from '../../hooks/useAppController';
import { observer } from 'mobx-react-lite';

const MapNetworkLabel = ({ goToNetwork, network, metricsValue }) => {
  const color = getScoreValue(metricsValue, 'networkHealthColor');
  const { mapController } = useAppController();
  const { setHoveredNetworkId } = mapController;
  const networkId = parseInt(network.id, 10);
  return (
    <div
      onClick={ goToNetwork }
      onMouseEnter={ () => setHoveredNetworkId(networkId) }
      onMouseLeave={ () => setHoveredNetworkId(0) }
      className="bg-navy bo-radius-pill pv-1 ph-6 flex items-center fs-5 pointer uppercase gray2"
    >
      {
        (typeof metricsValue !== 'number' || metricsValue >= 60)
          ? network.name
          : (
            <>
              <div className={ `pr-4` }>
                { network.name }
              </div>
              <div className="h-2 bo-gray4 bor-1"></div>
              <div className={ `pl-4 flex items-center ${ color }` }>
                { network.id === '1' ? 2 : 1 }
                <div className="pb-3">
                  <Dot width={2} height={2} color={ color } />
                </div>
              </div>
            </>
          )
      }
      {/*

      */}
    </div>
  );
};

export default observer(MapNetworkLabel);
