import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useAppController from '../../hooks/useAppController';
import LogoRings from '../shared/icons/multicolor/LogoRings';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import { Tooltip } from '@mui/material';
import columnDefinitions from '../../displayConfigs/columnDefinitions';
import AbacusTabsContainer from './AbacusTabsContainer';
import { ABACUS_METRIC_TO_TABS_BY_LEVEL } from '../../displayConfigs/abacusTabsDefinitions';

const BUTTON_WITH_UNAVAILABLE_VALUE_LABEL = '--';

const signalButtonConfigs = [
  {
    label: 'Signal Operations',
    buttonRows: [
      ['flowLabsVolumeCountPerHour', 'programmedCycleTimeMode', 'controlDelayMedian', 'queueLengthMedianVehicleNumber',],
      ['arrivalsOnGreenRatio', 'platoonRatio', 'avgSplitFailuresPerCycle', 'avgForceOffsPerCycle',],
      ['avgGapOutsPerCycle', 'phaseSkipsPerCycle',],
    ],
  },
  {
    label: 'Safety',
    buttonRows: [
      ['throughLeftRedLightRunRatio', 'dilemmaZoneRatio', 'deceleration10FpssRatio', 'approachVelocityMphMedian'],
    ],
  },
  {
    label: 'Detection',
    buttonRows: [
      ['detectorHealthScore'],
    ],
    skipFormatButtons: new Set([
      'detectorHealthScore',
    ]),
  },
  {
    label: 'Environmental Impact',
    buttonRows: [
      ['controlFuelGallonsVolumeWeightedTotalRa', 'controlCo2EquivalentGramsVolumeWeightedTotalRa', 'controlNo2GramsVolumeWeightedTotalRa',],
    ],
  },
];

const phaseButtonConfigs = [
  {
    label: 'Phase Operations',
    buttonRows: [
      ['flowLabsVolumeCountPerHour', 'programmedSplitTimeMode', 'avgGreenTimePerCycle', 'controlDelayMedian',],
      ['queueLengthMedianVehicleNumber', 'arrivalsOnGreenRatio', 'platoonRatio', 'avgSplitFailuresPerCycle',],
      ['avgForceOffsPerCycle', 'avgGapOutsPerCycle', 'phaseSkipsPerCycle',],
    ],
  },
  {
    label: 'Safety',
    buttonRows: [
      ['throughLeftRedLightRunRatio', 'dilemmaZoneRatio', 'deceleration10FpssRatio', 'approachVelocityMphMedian'],
      // ['aggressiveBrakingCause',],
    ],
    blankLabelButtons: new Set([
      'aggressiveBrakingCause',
    ]),
  },
  {
    label: 'Detection',
    buttonRows: [
      ['detectorHealthScore'],
    ],
    skipFormatButtons: new Set([
      'detectorHealthScore',
    ]),
  },
  {
    label: 'Environmental Impact',
    buttonRows: [
      ['controlFuelGallonsVolumeWeightedTotalRa', 'controlCo2EquivalentGramsVolumeWeightedTotalRa', 'controlNo2GramsVolumeWeightedTotalRa',],
    ],
  },
];


// Note: Displays phase metrics when a phaseId is provided along with the signalId.
const AbacusSignal = () => {
  const goToMaintain = useGoToMaintain('map');
  const { params, abacusController, mapController } = useAppController();

  const {
    regionData,
  } = mapController;

  const {
    signalMetricsData,

    getSignalMetrics,
    getPhaseMetrics,
  } = abacusController;

  const { focus, regionId, networkId, signalId, phaseId, abacusMetric, todId, aggregationPeriodDays } = params || {};

  useEffect(() => {
    if (regionId) {
      regionData.fetch({ regionId });
    }
  }, [regionData, regionId]);

  useEffect(() => {
    if (
      (phaseId || signalId)
      && todId && aggregationPeriodDays
    ) {
      signalMetricsData.fetch({ signalId, todId, aggregationPeriodDays: Number(aggregationPeriodDays) });
    }
  },[signalMetricsData, phaseId, signalId, todId, aggregationPeriodDays]);

  const isPhaseMode = focus === 'phase' && phaseId && phaseId > 0;
  const buttonConfigs = isPhaseMode ? phaseButtonConfigs : signalButtonConfigs;

  useEffect(() => {
    if (!abacusMetric) {
      goToMaintain({
        //TODO: Change to select first interactable button by default
        abacusMetric: buttonConfigs[0].buttonRows[0][0], // select first button by default
      }, true);
    }
  }, [abacusMetric, buttonConfigs, goToMaintain]);


  if (!regionData.data || !signalMetricsData.data || !networkId || !signalId) {
    if ([signalMetricsData,
      regionData,].some(dataFetcher => dataFetcher.isLoading)
    ) {
      return <div className="flex-grow-1 w-100% flex justify-center items-center">Loading...</div>;
    } else {
      return null;
    }
  }

  const network = regionData.data.user.region.networks.find(network => network.id === networkId);
  const signalData = getSignalMetrics(signalId);
  const phaseData = getPhaseMetrics(phaseId);
  const latestSignalMetrics = signalData?.latestMetrics;
  const latestPhaseMetrics = phaseData?.latestMetrics;

  if (!network?.name || !signalData?.name) {
    return null;
  }

  return (
    <div className="flex-grow-1 flex flex-column">
      <div className="flex-grow-0 pa-7">
        <table className="w-100%" cellSpacing="4">
          <tbody>
          {
            buttonConfigs.map(({ label, buttonRows, blankLabelButtons, skipFormatButtons }) => buttonRows.map(
              ((buttonRow, index) => (
                <tr key={index}>
                  <td className="ph-3 pv-4 medium" valign="top" width="13%">{ (index === 0) ? label : '' }</td>
                  {
                    buttonRow.map(buttonKey => {

                        let { buttonLabel, label, subLabel, format, getValue } = columnDefinitions[buttonKey] || { label: buttonKey};

                        const latestMetrics = isPhaseMode ? latestPhaseMetrics : latestSignalMetrics;

                        const value = latestMetrics && (latestMetrics?.[buttonKey] !== null && latestMetrics?.[buttonKey] !== undefined)
                          ? (
                            getValue ? getValue(latestMetrics) : latestMetrics[buttonKey]
                          )
                          : (!blankLabelButtons || (blankLabelButtons && !blankLabelButtons.has(buttonKey)))
                            ? BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                            : null;

                        const formattedValue = value !== BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                        && ((typeof format === 'function' && !skipFormatButtons)
                          || (typeof format === 'function' && (skipFormatButtons && !skipFormatButtons.has(buttonKey))))
                          ? format(value)
                          : value;

                        if (!buttonLabel) {
                          buttonLabel = label;
                        }

                        const isInactive = value === BUTTON_WITH_UNAVAILABLE_VALUE_LABEL
                          || (!isPhaseMode && (!ABACUS_METRIC_TO_TABS_BY_LEVEL.signal?.[buttonKey] || ABACUS_METRIC_TO_TABS_BY_LEVEL.signal[buttonKey].length === 0))
                          || (isPhaseMode && (!ABACUS_METRIC_TO_TABS_BY_LEVEL.phase?.[buttonKey] || ABACUS_METRIC_TO_TABS_BY_LEVEL.phase[buttonKey].length === 0));

                        let className = 'bo-radius-2 pv-4 ph-7 user-select-none flex ';

                        if (isInactive) {
                          className += 'bg-gray6';
                        } else if (buttonKey === abacusMetric) {
                          className += 'bg-blue';
                        } else {
                          className += 'bg-gray6 hover-bg-gray5 pointer';
                        }

                        return (
                          <td className="ph-3" valign="top" width="20%" key={buttonKey}>
                            <Tooltip key={ index + buttonKey } PopperProps={{ style: { pointerEvents: 'none' }}} title={ <span className="white semibold">{ label }&nbsp;<span className="fs-8 lightgray4 normal">{ subLabel }</span></span> } enterDelay={ 500 } arrow>
                              <div className={ className } onClick={isInactive ? null
                                : () => goToMaintain({ abacusMetric: buttonKey }, true) }>
                                <div className="gray1 truncate-ellipsis flex items-center">{ buttonLabel }</div>
                                {
                                  (!blankLabelButtons || (blankLabelButtons && !blankLabelButtons.has(buttonKey)))
                                    ? <div className="flex-shrink-0 ml-auto pl-5 tr semibold white flex items-center">{ formattedValue }</div>
                                    : null
                                }
                              </div>
                            </Tooltip>
                          </td>
                        );
                      }
                    )
                  }
                  {
                    new Array(4 - buttonRow.length).fill().map((_, i) => <td key={i} width="20%" />)
                  }
                </tr>
              ))
            ))
          }
          </tbody>
        </table>
      </div>
      <div className="flex-grow-1 flex flex-column pa-7 w-100%">
        <AbacusTabsContainer level={isPhaseMode ? "phase" : "signal"}/>
      </div>
    </div>
  );
};

export default observer(AbacusSignal);
