import React from 'react';
import IconWrapper from './IconWrapper';

const Reject = ({ color = 'blue', ...props }) => (
  <IconWrapper strokeColor={ color } {...props} viewBox="-.5 -.5 17 17">
    <path d="M3.75 3.75L12.25 12.25M12.25 3.75L3.75 12.25" strokeWidth="2.5"  strokeLinecap="round" />
  </IconWrapper>
);

export default Reject;
