import { useEffect, useRef, useState } from 'react';
import useAppController from '../../hooks/useAppController';
import { colors } from '../FlowMaterialTheme';
import { getZoomInterpolationValue } from './mapboxHelpers';

const useMapboxTiles = mapboxResult => {
  const { isMapLoading, map, isMapTransitioning, zoom, tileBounds } = mapboxResult;
  const { params, mapController } = useAppController();
  const { edgeMetricsData } = mapController;
  const { focus, todId } = params;
  const [currentMetric, setCurrentMetric] = useState('mobilityScore');
  const [forceRefreshTracker, setForceRefreshTracker] = useState(0);
  const forceRefresh = () => setForceRefreshTracker(x => x + 1);

  // initially false until we load the source data
  const [mayNeedToFetchEdgeMetrics, setMayNeedToFetchEdgeMetrics] = useState(false);
  const previousEdgeIdString = useRef('');

  useEffect(() => {
    if (isMapLoading || !map) {
      return;
    }

    const onSourceData = () => {
      if (map.getSource('edgesSource')) {
        if (map.isSourceLoaded('edgesSource')) {
          previousEdgeIdString.current = '';
          forceRefresh();
        }
        setMayNeedToFetchEdgeMetrics(true);
      }
    };

    const onLoad = () => {
      if (!map.getSource('edgesSource')) {
        map.addSource('edgesSource', {
          type: 'vector',
          // Use any Mapbox-hosted tileset using its tileset id.
          // Learn more about where to find a tileset id:
          // https://docs.mapbox.com/help/glossary/tileset-id/
          // url: 'mapbox://mapbox.mapbox-terrain-v2'
          // url: 'http://localhost:3000/api/mvt/{x}/{y}/{z}'
          tiles: [window.location.origin + '/api/mvt/{x}/{y}/{z}'],
        });
        map.on('sourcedata', onSourceData);
      }

      const currentLayers = map.getStyle().layers;
      const indexOfBottomOfOurLayers =  Math.min(currentLayers.findIndex(x => x.id === 'poi-label') + 1, currentLayers.length - 1);
      const layerBefore = currentLayers[indexOfBottomOfOurLayers];

      // we need to add a whole other layer, that way colored roads are always on top
      map.addLayer({
        'id': 'edgesLayerUpper',
        'type': 'line',
        'source': 'edgesSource',
        'source-layer': 'roads',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round',
        },
        'paint': {
          'line-color': [
            'step',
            ['to-number', ['feature-state', 'metricsValue']],
            // colors.red,
            // 20,
            // colors.pink,
            // 40,
            // colors.yellow,
            // 60,
            // colors.green,
            // 80,
            // colors.white,
            (focus === 'signal' || focus === 'phase') ? colors.red5 : colors.red,
            60,
            // (focus === 'signal' || focus === 'phase') ? colors.pink5 : colors.pink,
            // 80,
            '#242950', // road colors in the mapbox style, although opacity is 0 also so moot
          ],
          'line-width': getZoomInterpolationValue(15),
          'line-opacity': [
              'step',
              ['to-number', ['feature-state', 'metricsValue']],
              0,
              1,
              1,
              60,
              0,
            ],
        }
      }, layerBefore.id);

      map.addLayer({
        'id': 'edgesLayerLower',
        'type': 'line',
        'source': 'edgesSource',
        'source-layer': 'roads',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#111222', // match road colors from mapbox style
          'line-width': (focus === 'signal' || focus === 'phase')
            ? getZoomInterpolationValue(50)
            : getZoomInterpolationValue(10),
        },
      }, 'edgesLayerUpper');

      /*
      map.addLayer({
        'id': 'edgesLayer',
        'type': 'line',
        'source': 'edgesSource',
        'source-layer': 'roads',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          // 'line-color': '#ff69b4',
          'line-color': [
            'step',
            ['to-number', ['feature-state', 'metricsValue']],
            '#242950', // road colors in style
            1,
            colors.red,
            61,
            colors.yellow,
            81,
            '#242950', // road colors in style
          ],
          'line-color': [
            'rgba',
            // ['*', ['to-number', ['feature-state', 'metricsValue']], 2.55], // 0-100 to 0-255
            ['case', ['to-boolean', ['feature-state', 'metricsValue']], ['-', 255, ['*', ['to-number', ['feature-state', 'metricsValue']], 2.55]], 120],
            ['case', ['to-boolean', ['feature-state', 'metricsValue']], ['*', ['to-number', ['feature-state', 'metricsValue']], 2], 120],
            ['case', ['to-boolean', ['feature-state', 'metricsValue']], ['*', ['to-number', ['feature-state', 'metricsValue']], 2], 180],
            ['case', ['to-boolean', ['feature-state', 'metricsValue']], 1, .5],
          ],
          // 'line-width': ['case', ['to-boolean', ['feature-state', 'metricsValue']], 4, 2],
          // 'line-width': 4,
          'line-width': [
            'step',
            ['to-number', ['feature-state', 'metricsValue']],
            2, // road colors in style
            1,
            6, // line width is 6 when metricsValue is between 0 and 80 (we add 1 to actual value, thus why we compare between 1 and 81)
            81,
            2, // road colors in style
          ],
          // 'line-opacity': ['case', ['to-boolean', ['feature-state', 'metricsValue']], 1, 0],
          'line-opacity': [
            'step',
            ['to-number', ['feature-state', 'metricsValue']],
            0,
            1,
            1,
            80,
            0
          ],
        }
      });
      */
    };

    if (map._loaded) {
      onLoad();
      onSourceData();
    } else {
      map.on('load', onLoad);
    }

    return () => {
      if (map.getLayer('edgesLayerLower')) {
        map.removeLayer('edgesLayerLower');
      }

      if (map.getLayer('edgesLayerUpper')) {
        map.removeLayer('edgesLayerUpper');
      }
    };
  }, [isMapLoading, map, focus]);

  useEffect(() => {
    if (!tileBounds || isMapLoading || !map || isMapTransitioning) {
      return;
    }

    setMayNeedToFetchEdgeMetrics(true);
  }, [tileBounds, isMapLoading, map, isMapTransitioning]);

  const edgeMetricsCache = useRef({});
  const edgeMetricsCacheKey = currentMetric + '-' + todId;

  useEffect(() => {
    setMayNeedToFetchEdgeMetrics(true);
    forceRefresh();

    if (!edgeMetricsCache.current[edgeMetricsCacheKey]) {
      edgeMetricsCache.current[edgeMetricsCacheKey] = new Set();
    }

    const cache = edgeMetricsCache.current[edgeMetricsCacheKey];

    return () => {
      cache.clear();
    };
  }, [edgeMetricsCacheKey]);

  const areLayersCreated = map && map.getLayer('edgesLayerLower');

  useEffect(() => {
    if (mayNeedToFetchEdgeMetrics && tileBounds && map && !isMapLoading && areLayersCreated && !isMapTransitioning) {
      const features = map.getLayer('edgesLayerLower')
        ? map.queryRenderedFeatures({ layers: ['edgesLayerLower'] })
        : [];

      const edgeIds = features
        .map(({ id }) => id);

      // we add the currentMetric to force a refetch when it changes
      const edgeIdString = edgeMetricsCacheKey + ',' + edgeIds.sort().join(',');

      if (edgeIds.length && previousEdgeIdString.current !== edgeIdString) {
        const atLeastOneEdgeNotSeenBefore = edgeIds.find(edgeId => !edgeMetricsCache.current[edgeMetricsCacheKey].has(edgeId));

        if (atLeastOneEdgeNotSeenBefore && !edgeMetricsData.isLoading) {
          const graphqlVariables = {
            ...tileBounds, // min and max for x and y
            zoom: Math.floor(zoom),
            metricsField: currentMetric,
            todId: todId,
          };
          if (!edgeMetricsData.areCurrentVariables(graphqlVariables) || edgeMetricsData.getCacheValue(graphqlVariables) === undefined) {
            edgeMetricsData.fetch(graphqlVariables);
          }
        }

        previousEdgeIdString.current = edgeIdString;
      }

      setMayNeedToFetchEdgeMetrics(false);
    }
  }, [mayNeedToFetchEdgeMetrics, tileBounds, map, currentMetric, edgeMetricsCacheKey, isMapLoading, areLayersCreated, isMapTransitioning, forceRefreshTracker, edgeMetricsData, zoom, todId]);

  useEffect(() => {
    if (isMapLoading || !map || !edgeMetricsData.data || !map.getSource('edgesSource') || mayNeedToFetchEdgeMetrics) {
      return;
    }

    const cache = edgeMetricsCache.current[edgeMetricsCacheKey];
    const metricsData = edgeMetricsData.data?.user.edgeTileMetrics;

    metricsData.ids.forEach((id, index) => {
      map.setFeatureState(
        {
          source: 'edgesSource',
          sourceLayer: 'roads',
          id: parseInt(id, 10),
        },
        {
          metricsValue: metricsData.values[index] === null ? 0 : parseFloat(metricsData.values[index]) + 1,
        },
      );
      cache.add(parseInt(id, 10))
    });

    return () => {
      cache.clear();
    };
  }, [isMapLoading, map, edgeMetricsData.data, edgeMetricsCacheKey, edgeMetricsCache, Boolean(map?.getSource('edgesSource')), mayNeedToFetchEdgeMetrics]);

  useEffect(() => {
    const onKeyPress = e => {
      switch (e.key) {
        case 'x':
          setCurrentMetric(currentMetric => ((currentMetric === 'mobilityScore') ? 'safetyScore' : 'mobilityScore'));
          setMayNeedToFetchEdgeMetrics(true);
          break;

        default:
          break;
      }
    };

    document.addEventListener('keypress', onKeyPress)

    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMetric, todId]);
};

export default useMapboxTiles;
