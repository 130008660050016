// suppress transpiling mapbox-gl
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { colors } from '../FlowMaterialTheme';
import useAppController from '../../hooks/useAppController';
import { getBoolFeature, getMetricsSteps, getZoomInterpolationValue } from './mapboxHelpers';
import useGoToMaintain from '../../hooks/useGoToMaintain';
import networkPolygons from './networkPolygons.json';
import MapSignalIcon from '../shared/icons/multicolor/MapSignalIcon';
import usePrevious from '../../hooks/usePrevious';

const useMapboxSystemsAndNetworks = (mapboxResult) => {
  const { isMapLoading, map, } = mapboxResult;
  const goToMaintain = useGoToMaintain('map');
  const { params, mapController } = useAppController();
  const {
    networkMapGeometryData,
    hoveredNetworkId,
    setHoveredNetworkId,
    networkMetricsData,
    signalPhaseMetricsData,
  } = mapController;

  const { focus, networkId, regionId, todId } = params;

  const previousHoveredNetworkId = usePrevious(hoveredNetworkId);

  useEffect(() => {
    if (regionId) {
      networkMetricsData.fetch({ regionId });
    }
  }, [networkMetricsData, regionId]);

  useEffect(() => {
    if (networkId) {
      networkMapGeometryData.fetch({ networkId });
    }
  }, [networkId, networkMapGeometryData, signalPhaseMetricsData]);

  useEffect(() => {
    if (networkId && todId) {
      signalPhaseMetricsData.fetch({ networkId, todId });
    }
  }, [networkId, todId, signalPhaseMetricsData]);

  useEffect(() => {
    if (isMapLoading || !map) {
      return;
    }

    const onLoad = () => {
      map.addSource('networkPolygons', {
        'type': 'geojson',
        'data': networkPolygons,
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    // cleanup is two useEffects down, because we only want to remove the source after the layers are removed
  }, [isMapLoading, map]);

  useEffect(() => {
    if (isMapLoading || !map || (focus !== 'system' && focus !== 'network')) {
      return;
    }

    const badThreshold = 60;
    const polygonColor = getMetricsSteps(colors.red, badThreshold, colors.white);

    const polygonOpacity =
      focus === 'system'
        ? [
          'case',
          getBoolFeature('isHovered'),
          getMetricsSteps(.7, badThreshold, .4),
          getMetricsSteps(.6, badThreshold, .3),
        ]
        : [
          'case',
          ['==', ['id'], parseInt(networkId, 10)], // if network is currently focused
          .1,
          [
            'case',
            getBoolFeature('isHovered'),
            getMetricsSteps(.7, badThreshold, .3),
            getMetricsSteps(.6, badThreshold, .2),
          ],
        ];

    const lineOpacity =
      focus === 'system'
        ? 1
        : [
          'case',
          ['==', ['id'], parseInt(networkId, 10)], // if network is currently focused
          .3,
          1,
        ];

    const onLoad = () => {
      map.addLayer({
        'id': 'networkPolygons',
        'type': 'fill',
        'source': 'networkPolygons',
        'layout': {},
        'paint': {
          'fill-color': polygonColor,
          'fill-opacity': polygonOpacity,
        },
      });

      map.addLayer({
        'id': 'networkPolygonsOutline',
        'type': 'line',
        'source': 'networkPolygons',
        'layout': {},
        'paint': {
          'line-color': polygonColor,
          'line-width': 1,
          'line-dasharray': [1, 1],
          'line-opacity': lineOpacity,
        },
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    return () => {
      if (map.getLayer('networkPolygonsOutline')) {
        map.removeLayer('networkPolygonsOutline');
      }

      if (map.getLayer('networkPolygons')) {
        map.removeLayer('networkPolygons');
      }
    };
  }, [map, isMapLoading, focus, networkId]);

  useEffect(() => {
    if (isMapLoading || !map || focus !== 'system') {
      return;
    }

    // let hoveredId = null;

    const onMouseMove = event => {
      if (!event?.features?.length || event.features[0].id === parseInt(networkId, 10)) return;

      setHoveredNetworkId(event.features[0].id);
    };

    const onMouseLeave = () => {
      setHoveredNetworkId(0);
    };

    const onClick = event => {
      if (event?.features?.length) {
        goToMaintain({
          focus: 'network',
          networkId: event.features[0].id,
        });
      }
    };

    map.on('mousemove', 'networkPolygons', onMouseMove);
    map.on('mouseleave', 'networkPolygons', onMouseLeave);
    map.on('click', 'networkPolygons', onClick);

    return () => {
      // reset cursor and feature state
      onMouseLeave();

      map.off('mousemove', 'networkPolygons', onMouseMove);
      map.off('mouseleave', 'networkPolygons', onMouseLeave);
      map.off('click', 'networkPolygons', onClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isMapLoading, focus, networkId]);

  useEffect(() => {
    if (isMapLoading || !map || (focus !== 'system' && focus !== 'network')) {
      return;
    }

    if (previousHoveredNetworkId !== hoveredNetworkId) {
      if (previousHoveredNetworkId) {
        map.removeFeatureState({ source: 'networkPolygons', id: previousHoveredNetworkId }, 'isHovered');
      }
      if (hoveredNetworkId) {
        map.getCanvas().style.cursor = 'pointer';
        map.setFeatureState({ source: 'networkPolygons', id: hoveredNetworkId }, { isHovered: true });
      } else {
        map.getCanvas().style.cursor = '';
      }
    }
  }, [previousHoveredNetworkId, hoveredNetworkId, map, isMapLoading, focus])

  useEffect(() => {
    if (isMapLoading || !map || !networkMetricsData.data?.user) {
      return;
    }

    const onLoad = () => {
      networkPolygons.features.forEach(({ id }) => {
        const metricsValue = networkMetricsData.data?.user.region.networks.find(metricsForNetwork => metricsForNetwork.id === id).latestHealthScore || 0;
        map.setFeatureState({ source: 'networkPolygons', id }, { metricsValue });
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    // return () => {
    //
    // };
  }, [isMapLoading, map, networkMetricsData.data?.user]);

  // cleanup source AFTER layers are cleaned up
  useEffect(() => {
    if (isMapLoading || !map) {
      return;
    }

    return () => {
      if (map.getSource('networkPolygons')) {
        map.removeSource('networkPolygons');
      }
    };
  }, [isMapLoading, map]);


  const markersRef = useRef([]);
  const signalGeometryFeaturesRef = useRef({});

  useEffect(() => {
    if (focus !== 'network' || isMapLoading || !map || !networkMapGeometryData.data) {
      return;
    }

    const mapGeometry = networkMapGeometryData.data?.user.network.mapGeometry;
    const signals = networkMapGeometryData.data?.user.network.signals;

    if (!mapGeometry || !signals?.length) {
      return;
    }

    const networkGeometrySource = {
      'type': 'FeatureCollection',
      'features': mapGeometry.map((row, index) => {
        if (row.signal?.id) {
          if (!signalGeometryFeaturesRef.current.hasOwnProperty(row.signal.id)) {
            signalGeometryFeaturesRef.current[row.signal.id] = new Set();
          }

          signalGeometryFeaturesRef.current[row.signal.id].add(index);

          return {
            'id': index,
            'type': 'Feature',
            'geometry': {
              'coordinates': row.pathCoordinates,
              'type': 'LineString'
            },
          };
        }

        return null;
      }).filter(Boolean),
    };

    const signalCoordinatesSource = {
      'type': 'FeatureCollection',
      'features': signals.map(signal => ({
        'id': signal.id,
        'type': 'Feature',
        'geometry': {
          'coordinates': signal.coordinates,
          'type': 'Point'
        },
      })),
    }

    const markers = markersRef.current;
    const color = getMetricsSteps(colors.red, 60, /*colors.pink, 80,*/ colors.gray2);

    const onLoad = () => {
      map.addSource('networkGeometrySource', {
        'type': 'geojson',
        'data': networkGeometrySource,
      });

      map.addLayer({
        'id': 'networkGeometryLayer',
        'type': 'line',
        'source': 'networkGeometrySource',
        'layout': {
          'line-join': 'round',
          'line-cap': 'butt'
        },
        'paint': {
          'line-color': color,
          'line-width': 2,
        },
      });

      map.addSource('signalCoordinatesSource', {
        'type': 'geojson',
        'data': signalCoordinatesSource,
      });

      map.addLayer({
        'id': 'signalCoordinatesFillLayer',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-color': color,
          'circle-radius': 10,
          // 'circle-blur': 2,
          'circle-opacity': .65,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      map.addLayer({
        'id': 'signalCoordinatesLayer',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-stroke-color': color,
          'circle-opacity': 0,
          'circle-stroke-width': 2,
          // 'circle-color': colors.red,
          'circle-radius': 10,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      map.addLayer({
        'id': 'signalCoordinatesLayerInner',
        'type': 'circle',
        'source': 'signalCoordinatesSource',
        'layout': {
          // 'line-join': 'round',
          // 'line-cap': 'butt'
        },
        'paint': {
          'circle-stroke-color': color,
          'circle-opacity': 0,
          'circle-stroke-width': 1,
          // 'circle-color': colors.red,
          'circle-radius': 7.5,
          // 'line-color': colors.red,
          // 'line-width': 2,
        },
      });

      signals.forEach((signal, index) => {
        const coordinates = signal.coordinates;
        const markerElement = document.createElement('div');
        markerElement.className = 'pointer-events-none';
        const root = createRoot(markerElement);
        root.render(<MapSignalIcon withDefs={!index} />);
        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .setOffset([-.5, 5])
          .addTo(map);

        markers.push(marker);
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    return () => {
      if (map.getLayer('signalCoordinatesLayer')) {
        map.removeLayer('signalCoordinatesLayer');
      }
      if (map.getLayer('signalCoordinatesLayerInner')) {
        map.removeLayer('signalCoordinatesLayerInner');
      }
      if (map.getLayer('signalCoordinatesFillLayer')) {
        map.removeLayer('signalCoordinatesFillLayer');
      }

      if (map.getSource('signalCoordinatesSource')) {
        map.removeSource('signalCoordinatesSource');
      }

      if (map.getLayer('networkGeometryLayer')) {
        map.removeLayer('networkGeometryLayer');
      }

      if (map.getSource('networkGeometrySource')) {
        map.removeSource('networkGeometrySource');
      }

      markers.forEach(marker => marker.remove());
    };
  }, [focus, isMapLoading, map, networkMapGeometryData.data]);

  useEffect(() => {
    if (isMapLoading || !map || focus !== 'network' || !signalPhaseMetricsData.data?.user || !networkMapGeometryData.data?.user) {
      return;
    }

    const signals = networkMapGeometryData.data.user.network.signals;

    const onLoad = () => {
      signals.forEach(({ id }) => {
        if (signalGeometryFeaturesRef.current.hasOwnProperty(id)) {
          const metricsValue = signalPhaseMetricsData.data?.user.network.signals?.find(metricsForSignal => metricsForSignal.id === id)?.latestHealthScore || 0;
          map.setFeatureState({ source: 'signalCoordinatesSource', id }, { metricsValue });
          Array.from(signalGeometryFeaturesRef.current[id]).forEach(featureId => {
            map.setFeatureState({ source: 'networkGeometrySource', id: featureId }, { metricsValue });
          });
        }
      });
    };

    if (map._loaded) {
      onLoad();
    } else {
      map.on('load', onLoad);
    }

    // return () => {
    //
    // };
  }, [isMapLoading, map, focus, networkMapGeometryData.data?.user, signalPhaseMetricsData.data?.user]);

  useEffect(() => {
    if (focus !== 'network' || isMapLoading || !map) {
      return;
    }

    let hoveredId = null;

    const onMouseMove = event => {
      if (!event?.features?.length) return;

      map.getCanvas().style.cursor = 'pointer';

      if (hoveredId) {
        map.removeFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, 'isHovered');
      }

      hoveredId = event.features[0].id;

      map.setFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, { isHovered: true });
    };

    const onMouseLeave = () => {
      if (hoveredId && map.getSource('signalCoordinatesSource')) {
        map.removeFeatureState({ source: 'signalCoordinatesSource', id: hoveredId }, 'isHovered');
      }

      hoveredId = null;

      map.getCanvas().style.cursor = '';
    };

    const onClick = () => {
      if (hoveredId) {
        goToMaintain({
          focus: 'signal',
          signalId: hoveredId,
        });
      }
    };

    map.on('mousemove', 'signalCoordinatesFillLayer', onMouseMove);
    map.on('mouseleave', 'signalCoordinatesFillLayer', onMouseLeave);
    map.on('click', 'signalCoordinatesFillLayer', onClick);

    return () => {
      // reset cursor and feature state
      onMouseLeave();

      map.off('mousemove', 'signalCoordinatesFillLayer', onMouseMove);
      map.off('mouseleave', 'signalCoordinatesFillLayer', onMouseLeave);
      map.off('click', 'signalCoordinatesFillLayer', onClick);
    };
  }, [focus, goToMaintain, isMapLoading, map]);
};

export default useMapboxSystemsAndNetworks;
