import React from 'react';
import IconWrapper from './IconWrapper';

const Draggable = ({ color = 'blue', ...props }) => (
  <IconWrapper fillColor={ color } {...props} viewBox="0 0 16 16">
    <circle cx="5.25" cy="2.5" r="1.25" />
    <circle cx="10.75" cy="2.5" r="1.25" />
    <circle cx="5.25" cy="8" r="1.25" />
    <circle cx="10.75" cy="8" r="1.25" />
    <circle cx="5.25" cy="13.5" r="1.25" />
    <circle cx="10.75" cy="13.5" r="1.25" />
  </IconWrapper>
);

export default Draggable;
